import { Button, ButtonProps, Icon } from '@chakra-ui/react';
import React from 'react';
import { FaPlus } from 'react-icons/fa';

export const AddAttachmentButton = (props: ButtonProps) => {
  return (
    <Button
      w="lg"
      h="full"
      rounded="2xl"
      borderWidth="3px"
      borderStyle="dashed"
      color="gray.400"
      bg="white"
      _hover={{
        borderColor: 'gray.400',
        color: 'gray.500',
      }}
      fontSize="2xl"
      data-pw="reportAttachmentCreateButton"
      leftIcon={<Icon as={FaPlus} />}
      {...props}
    >
      Add attachment
    </Button>
  );
};
