import { LooseObject } from '@piccolohealth/util';

export const blobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.readAsDataURL(blob);
  });
};

export const readResizedImage = (
  file: File,
  options: { maxWidth: number; maxHeight: number; maxRatio?: number; minRatio?: number },
): Promise<Blob> => {
  const { maxWidth, maxHeight } = options;

  return new Promise((resolve, reject) => {
    const image = document.createElement('img');
    const canvas = document.createElement('canvas');
    const reader = new FileReader();

    reader.onload = (e: ProgressEvent<FileReader>) => {
      image.src = e.target?.result as any;
      image.onload = function () {
        const context = canvas.getContext('2d');
        context?.drawImage(image, 0, 0);

        let width = image.width;
        let height = image.height;

        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        const ratio = width / height;

        // reject with an error if ratio is not within the range
        if (options.maxRatio && ratio > options.maxRatio) {
          return reject('Image is too wide');
        }

        if (options.minRatio && ratio < options.minRatio) {
          return reject('Image is too tall');
        }

        canvas.width = width;
        canvas.height = height;

        context?.drawImage(image, 0, 0, width, height);
        canvas.toBlob(
          (blob) => (blob ? resolve(blob) : reject('Could not decode image')),
          'image/png',
          1,
        );
      };
    };
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
};

export const readPdfAsImage = (file: File): Promise<Blob> => {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onload = async () => {
      const pdfjs = await import('pdfjs-dist');
      //@ts-ignore
      const pdfjsWorker = await import('pdfjs-dist/build/pdf.worker.entry');

      pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

      const buffer = await file.arrayBuffer();

      // Load the PDF document
      const pdf = await pdfjs.getDocument(buffer).promise;

      // Get the first page of the PDF
      const page = await pdf.getPage(1);

      // Set the canvas size to match the PDF page size
      const canvas = document.createElement('canvas');
      const canvasContext = canvas.getContext('2d') as CanvasRenderingContext2D;
      const viewport = page.getViewport({ scale: 2 });
      canvas.width = viewport.width;
      canvas.height = viewport.height;

      // Render the PDF page on the canvas
      const renderContext = {
        canvasContext,
        viewport,
      };
      await page.render(renderContext).promise;

      canvas.toBlob(
        (blob) => (blob ? resolve(blob) : reject('Could not convert pdf to image')),
        'image/png',
        1,
      );
    };

    reader.onerror = (error) => reject(error);
    reader.readAsArrayBuffer(file);
  });
};

export const readFileAsText = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      const result = fileReader.result;
      if (result) {
        resolve(result.toString());
      } else {
        reject('Could not decode file');
      }
    };
    fileReader.readAsText(file);
  });
};

export const readFileAsJson = async (file: File): Promise<LooseObject> => {
  const text = await readFileAsText(file);
  try {
    return JSON.parse(text);
  } catch (err) {
    return Promise.reject(err);
  }
};
