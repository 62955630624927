import { Button, Icon } from '@chakra-ui/react';
import {
  PERMISSIONS,
  ReportTemplateVariable,
  ReportTemplateVariableSource,
} from '@piccolohealth/echo-common';
import React from 'react';
import { FaCog } from 'react-icons/fa';
import { usePermission } from '../../../hooks/usePermission';
import { showModal } from '../../generic/Modal';
import { ReportTemplateVariableEditModal } from './ReportTemplateVariableEditModal';

interface Props {
  reportTemplateId: string;
  variable: ReportTemplateVariable;
}

export const ReportTemplateVariableEditButton = (props: Props) => {
  const { reportTemplateId, variable } = props;
  const hasPermission = usePermission(PERMISSIONS.reportTemplateUpdate).value;
  // Only disable variables that are both not editable and dynamic. We still want to allow
  // editing of 'non-editable' variables in the admin report template variables editor.
  const isDisabled =
    (!variable.isEditable && variable.source === ReportTemplateVariableSource.Dynamic) ||
    !hasPermission;

  return (
    <Button
      size="sm"
      leftIcon={<Icon as={FaCog} />}
      isDisabled={isDisabled}
      onClick={() =>
        showModal(ReportTemplateVariableEditModal, {
          variable,
          reportTemplateId,
        })
      }
    >
      Edit
    </Button>
  );
};
