import { DateTime } from '@piccolohealth/util';
import { DiffResponse } from '@piccolohealth/echo-common';
import _ from 'lodash';
import React from 'react';
import {
  Control,
  FieldValues,
  FormProvider,
  Path,
  useForm,
  useFormContext,
  useFormState,
} from 'react-hook-form';
import { useAsyncDebounce } from '../../../hooks/useDebouncedAsyncCallback';
import { typedMemo } from '@piccolohealth/ui';

export interface OnSaveResponse<A> {
  values: A;
  existingChangesetId: string | null | undefined;
}

export interface OnSaveOptions<A> {
  dirtyValues: A;
  takeOwnership: boolean;
  existingChangesetId: string | null | undefined;
  changesetId: string;
}

interface AutoSaverProps<A extends FieldValues> extends AutoSaveProps<A> {
  control: Control;
}

function AutoSaver<A extends FieldValues>(props: AutoSaverProps<A>) {
  const {
    startingValues,
    changesetId,
    initialExistingChangesetId,
    control,
    debounceMs,
    onSave,
    diff,
    combine,
  } = props;

  const { watch, reset, getValues, handleSubmit, setError, clearErrors } = useFormContext<A>();
  const { isSubmitting } = useFormState({ control });
  const lastTouchedAt = React.useRef(DateTime.now());

  const [takeOwnership, setTakeOwnership] = React.useState(true);
  const [existingChangesetId, setExistingChangesetId] = React.useState(initialExistingChangesetId);
  const lastSubmittedData = React.useRef(getValues());

  const debouncedSave = useAsyncDebounce(async () => {
    if (!isSubmitting) {
      await handleSubmit(save)();
    }
  }, debounceMs);

  const save = React.useCallback(
    async (data: A) => {
      const diffResponse = diff(lastSubmittedData.current as A, data as A);

      // // If there are no dirty fields, don't bother sending.
      // // This can occur when a user types, but then backspaces,
      // // and the values remain the same.
      if (!diffResponse.isDiff) {
        return;
      }

      // TODO: Is this necessary
      const values = _.cloneDeep(data as A);

      const req = {
        dirtyValues: diffResponse.diff,
        takeOwnership,
        existingChangesetId,
        changesetId,
      };

      try {
        const response = await onSave(req);
        setTakeOwnership(false);
        setExistingChangesetId(response.existingChangesetId);

        const serverValues = response.values;
        const currentFormValues = getValues();
        lastSubmittedData.current = serverValues;

        // Check to see if the form has changed since we submitted,
        // by comparing the current form values, with the values
        // we submitted.

        // If the form has changed since we sent the request, i.e. a user
        // has typed since the request was sent issue another save request.
        // This prevents the form being overridden with values mid-typing.
        const diffResponseSinceSent = diff(values, currentFormValues);

        // Reset the form to the values we received from the server, merged with
        // the current diff of the server and what we last sent.
        reset(combine(serverValues, diffResponseSinceSent.diff));
      } catch (err) {
        setError('serverError' as Path<A>, { message: err.message });
        throw err;
      }
    },
    [
      changesetId,
      existingChangesetId,
      takeOwnership,
      getValues,
      setError,
      onSave,
      reset,
      diff,
      combine,
    ],
  );

  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    const subscription = watch(() => {
      lastTouchedAt.current = DateTime.now();
      clearErrors();

      debouncedSave();
    });
    return () => subscription.unsubscribe();
  }, [watch, debouncedSave, clearErrors, debounceMs]);

  React.useEffect(() => {
    if (DateTime.now().diff(lastTouchedAt.current).milliseconds > debounceMs) {
      reset(startingValues);
    }
  }, [reset, startingValues, debounceMs]);

  return null;
}

interface AutoSaveProps<A extends FieldValues> {
  debounceMs: number;
  startingValues: A;
  changesetId: string;
  initialExistingChangesetId: string | null | undefined;
  onSave: (options: OnSaveOptions<A>) => Promise<OnSaveResponse<A>>;
  diff: (prev: A, curr: A) => DiffResponse<A>;
  combine: (prev: A, curr: A) => A;
}

export function AutoSave<A extends FieldValues>(props: React.PropsWithChildren<AutoSaveProps<A>>) {
  const {
    startingValues,
    changesetId,
    initialExistingChangesetId,
    onSave,
    diff,
    combine,
    debounceMs,
    children,
  } = props;

  const methods = useForm({
    defaultValues: startingValues as any,
  });

  return (
    <FormProvider {...methods}>
      <AutoSaver
        control={methods.control}
        startingValues={startingValues}
        debounceMs={debounceMs}
        changesetId={changesetId}
        initialExistingChangesetId={initialExistingChangesetId}
        onSave={onSave}
        diff={diff}
        combine={combine}
      />
      {children}
    </FormProvider>
  );
}

export const AutoSaveMemo = typedMemo(AutoSave);
