import * as Types from '@piccolohealth/echo-common';
import { createGqlQuery } from '../fetcher';
import { MeasurementMappingsAndVariants } from '../queries/dicom.query';

export const getMeasurementMappingsAndVariantsBaseKey = (
  variables: Types.MeasurementMappingsAndVariantsQueryVariables,
) => ['dicom', 'measurementMappingsAndVariants', variables];

export const useMeasurementMappingsAndVariantsQuery = createGqlQuery<
  Types.MeasurementMappingsAndVariantsQueryVariables,
  Types.MeasurementMappingsAndVariantsQuery
>(getMeasurementMappingsAndVariantsBaseKey, MeasurementMappingsAndVariants);
