import React from 'react';
import { HookedFormItem } from '../../../components/forms/hookform/HookedFormItem';
import { HookedInput } from '../../../components/forms/hookform/HookedInput';
import { HookedUserChooser } from '../../../components/forms/hookform/HookedUserChooser';

export const DistributionFaxPayloadForm = () => {
  return (
    <>
      <HookedFormItem name="payload.from.sharerId" label="From user" helperText="Who is fax from?">
        <HookedUserChooser
          name="payload.from.sharerId"
          data-pw="distributionModalEmailSharerSelect"
          placeholder="Choose a user"
          isDisabled={false}
        />
      </HookedFormItem>

      <HookedFormItem
        name="payload.to.faxNumber"
        label="To fax number"
        helperText="Which fax number do you want to distribute to?"
      >
        <HookedInput
          name="payload.to.faxNumber"
          placeholder="Enter a fax number. Must include area code (eg 02, 03, 07, 08, etc)"
        />
      </HookedFormItem>
    </>
  );
};
