import gql from 'graphql-tag';

export const Organizations = gql`
  query Organizations {
    organizations {
      id
      shortId
      name
      status
      logo
    }
  }
`;
