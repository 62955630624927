import gql from 'graphql-tag';

export const CreateOrganization = gql`
  mutation CreateOrganization($createOrganizationRequest: CreateOrganizationRequest!) {
    createOrganization(request: $createOrganizationRequest) {
      id
      name
      addressLineOne
      addressLineTwo
      email
      phone
      logo
      timezone
      featureFlags
      roles {
        id
        name
        description
        permissions
      }
    }
  }
`;
