import { Badge, BadgeProps } from '@chakra-ui/react';
import { prettyBytes } from '@piccolohealth/util';
import React from 'react';

interface Props extends Omit<BadgeProps, 'size'> {
  size: number;
}

export const InstanceSizeBadge = (props: Props) => {
  const { size, ...rest } = props;

  return (
    <Badge size="sm" colorScheme="gray" {...rest}>
      Size: {prettyBytes(size)}
    </Badge>
  );
};
