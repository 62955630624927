import * as Types from '@piccolohealth/echo-common';
import { useMutation, useQueryClient } from 'react-query';
import { gqlFetcher, MutationOptions } from '../fetcher';
import { UpdateWorklistEntry } from '../mutations/updateWorklistEntry.mutation';

export const useUpdateWorklistEntryMutation = (
  options?: MutationOptions<
    Types.UpdateWorklistEntryMutation,
    Types.UpdateWorklistEntryMutationVariables
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(UpdateWorklistEntry), {
    ...options,
    onSettled: async (resp, error, variables, ctx) => {
      await options?.onSettled?.(resp, error, variables, ctx);

      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('worklistEntries'),
      });
    },
  });
};
