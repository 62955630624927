import {
  Button,
  ButtonGroup,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { ReportTemplate } from '@piccolohealth/echo-common';
import React from 'react';
import { useRestoreReportTemplateMutation } from '../../../graphql/hooks/useRestoreReportTemplateMutation';
import { useAppContext } from '../../../hooks/useAppContext';
import { createModal } from '../../generic/Modal';

interface Props {
  version: ReportTemplate;
}

export const TemplateRestoreVersionModal = createModal<Props>((props) => {
  const { version, modal } = props;

  const { organization, successToast, errorToast } = useAppContext();

  const mutation = useRestoreReportTemplateMutation();

  const onClick = async () => {
    await mutation
      .mutateAsync({
        organizationId: organization.id,
        reportTemplateVersionId: version.versionId,
      })
      .then(() => {
        successToast('Report template restored successfully');
        modal.hide();
      })
      .catch((err) => {
        errorToast(`Error restoring report template: ${err.message}`);
      });
  };

  return (
    <Modal isOpen={modal.visible} onClose={modal.hide} onCloseComplete={modal.remove}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Do you want to restore this version?</ModalHeader>
        <ModalCloseButton />
        <ModalFooter>
          <ButtonGroup size="sm">
            <Button key="close" onClick={modal.hide}>
              Close
            </Button>
            <Button
              key="submit"
              colorScheme="purple"
              isLoading={mutation.isLoading}
              loadingText="Restoring..."
              type="submit"
              onClick={onClick}
              data-pw="reportTemplateVersionRestoreModalSubmitButton"
            >
              Restore
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});
