import { Badge, BadgeProps } from '@chakra-ui/react';
import React from 'react';

interface Props extends Omit<BadgeProps, 'size'> {
  modality: string;
}

export const InstanceModalityBadge = (props: Props) => {
  const { modality, ...rest } = props;

  return (
    <Badge size="sm" colorScheme="gray" {...rest}>
      Modality: {modality}
    </Badge>
  );
};
