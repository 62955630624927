import { Button, ButtonGroup, Stack, Text } from '@chakra-ui/react';
import { ChakraV3 } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import { PiccoloError } from '@piccolohealth/echo-common';
import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  error: PiccoloError;
}

export const ReportSaveError = (props: Props) => {
  const { error } = props;

  const message = P.run(() => {
    switch (error.type) {
      case 'ReportTemplateMismatch': {
        return (
          <Text>
            The report template this report is based on, has changed since you started editing.
            Changes you have made have not been saved.
          </Text>
        );
      }
      case 'ReportUpdateConflict': {
        const lockedBy = error?.metadata?.lockedBy;

        return (
          <Text>
            This report has been edited by another user (<strong>{lockedBy.name}</strong>) since you
            started editing. Changes you have made have not been saved.
          </Text>
        );
      }
      // Should never occur
      default:
        return <Text>Report save error</Text>;
    }
  });

  return (
    <ChakraV3.Error
      title="Saving report failed"
      description={
        <Stack fontSize="sm">
          {message}
          <Text fontWeight="semibold">Please reload and try again</Text>
        </Stack>
      }
      extra={
        <ButtonGroup>
          <Button colorScheme="purple" onClick={() => history.go()}>
            Reload
          </Button>
          <Link to="/">
            <Button>Go back</Button>
          </Link>
        </ButtonGroup>
      }
      data-pw="reportEditorError"
    />
  );
};
