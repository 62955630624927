import gql from 'graphql-tag';

export const UnshareReport = gql`
  mutation UnshareReport($organizationId: ID!, $reportShareId: ID!, $reportId: ID!) {
    unshareReport(
      organizationId: $organizationId
      reportShareId: $reportShareId
      reportId: $reportId
    )
  }
`;
