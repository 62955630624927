import { HStack, MenuItem, Spacer, Text } from '@chakra-ui/react';
import { PERMISSIONS } from '@piccolohealth/echo-common';
import React from 'react';
import { FaTasks } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { DistributionsBadge } from '../../features/distributions/components/DistributionsBadge';
import { useAppContext } from '../../hooks/useAppContext';
import { usePermission } from '../../hooks/usePermission';

interface Props {
  reportId: string;
}

export const ReportDistributionsManageMenuItem = (props: Props) => {
  const { reportId } = props;
  const { organization } = useAppContext();
  const hasPermission = usePermission(PERMISSIONS.distributionRead).value;
  const navigate = useNavigate();

  const onClick = React.useCallback(() => {
    navigate(`/organizations/${organization.id}/reports/${reportId}/distributions`);
  }, [navigate, organization, reportId]);

  return (
    <MenuItem
      icon={<FaTasks />}
      isDisabled={!hasPermission}
      data-pw="reportManageDistributionsMenuItem"
      onClick={onClick}
    >
      <HStack>
        <Text>Manage distributions</Text>
        <Spacer />
        {hasPermission && <DistributionsBadge reportId={reportId} />}
      </HStack>
    </MenuItem>
  );
};
