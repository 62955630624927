import { useQuery, UseQueryOptions } from 'react-query';
import { useConfig } from '../context/ConfigContext';

export type HealthQueryResponse = {
  buildInfo: { commit: string; branch: string };
  details: { database: { status: string } };
  status: 'ok' | 'error';
};

export const useHealthQuery = (
  options: Omit<UseQueryOptions<HealthQueryResponse, unknown>, 'queryKey'> = {},
) => {
  const { config } = useConfig();

  return useQuery<HealthQueryResponse, unknown>(
    ['health'],
    async () => {
      const response = await fetch(`${config.api.url}/health`);
      return response.json();
    },
    options,
  );
};
