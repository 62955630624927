import { VStack } from '@chakra-ui/react';
import { ReportTemplate } from '@piccolohealth/echo-common';
import React from 'react';
import { usePaginatedVariablesQuery } from '../../../hooks/usePaginatedVariablesQuery';
import { useVariablesTableFilter } from '../../../hooks/useVariablesTableFilter';
import { ReportTemplateVariablesTable } from './ReportTemplateVariablesTable';
import { ReportTemplateVariablesTableFilterHeader } from './ReportTemplateVariablesTableFilterHeader';

interface Props {
  reportTemplate: ReportTemplate;
}

export const ReportTemplateVariablesEditor = (props: Props) => {
  const { reportTemplate } = props;
  const filter = useVariablesTableFilter();

  const { isLoading, errors, entries, pagination, refetch } = usePaginatedVariablesQuery(
    filter,
    reportTemplate.variables,
  );

  return (
    <VStack align="start" p={4}>
      <ReportTemplateVariablesTableFilterHeader filter={filter} reportTemplate={reportTemplate} />
      <ReportTemplateVariablesTable
        reportTemplate={reportTemplate}
        variables={entries}
        errors={errors}
        isLoading={isLoading}
        pagination={pagination}
        refetch={refetch}
      />
    </VStack>
  );
};
