import { Box, Input } from '@chakra-ui/react';
import { useNode } from '@craftjs/core';
import _ from 'lodash';
import React from 'react';
import { useCompiledTemplate } from '../../../../hooks/useCompiledTemplate';
import { BaseNodeProps, cleanProps } from '../../../../utils/craftjs';
import { FormItem } from '../../../forms/FormItem';
import { useSSRNode } from '../../hooks/useSSRNode';
import { NodeBackgroundProps, NodeBackgroundSettings } from '../nodes/NodeBackground';
import { NodeBorderProps, NodeBorderSettings } from '../nodes/NodeBorder';
import { NodeLayoutProps, NodeLayoutSettings } from '../nodes/NodeLayout';
import { NodeSizeProps, NodeSizeSettings } from '../nodes/NodeSize';
import { NodeSpacingProps, NodeSpacingSettings } from '../nodes/NodeSpacing';
import {
  NodeTypographyProps,
  NodeTypographySettings,
  parseFontSizeAndWeight,
} from '../nodes/NodeTypography';
import { SettingsAccordion } from '../settings/SettingsAccordion';
import { SettingsItem } from '../settings/SettingsItem';

export interface Props
  extends BaseNodeProps,
    NodeLayoutProps,
    NodeSizeProps,
    NodeSpacingProps,
    NodeTypographyProps,
    NodeBorderProps,
    NodeBackgroundProps {
  text: string;
}

export const Text = (props: Props) => {
  const { text, bold, italic, underline, fontSize, fontWeight, variant, ssr, ...rest } = props;

  const {
    connectors: { connect, drag },
  } = useSSRNode(props);

  const fontSizeAndWeight =
    _.isUndefined(fontSize) && !_.isUndefined(variant)
      ? parseFontSizeAndWeight(variant)
      : { fontSize };

  const { compiledTemplate } = useCompiledTemplate(text);

  const style = _.pickBy(
    {
      fontWeight: bold ? 'bold' : undefined,
      fontStyle: italic ? 'italic' : undefined,
      textDecoration: underline ? 'underline' : undefined,
      textDecorationThickness: '2px',
      textUnderlineOffset: '2px',
      display: 'inline',
      ...fontSizeAndWeight,
      ...rest,
    },
    _.identity,
  );

  return (
    <Box
      ref={(ref: any) => connect(drag(ref))}
      {...cleanProps(style)}
      display="inline"
      dangerouslySetInnerHTML={{ __html: compiledTemplate }}
    />
  );
};

const TextSettings = () => {
  const {
    actions: { setProp },
    text,
  } = useNode((node) => ({
    text: node.data.props.text,
  }));

  return (
    <SettingsAccordion>
      <SettingsItem title="Text">
        <FormItem id="text" label="Text">
          <Input
            defaultValue={text}
            onChange={(e) => setProp((props: Props) => (props.text = e.target.value), 1000)}
            size="sm"
          />
        </FormItem>
      </SettingsItem>

      <SettingsItem title="Layout">
        <NodeLayoutSettings />
      </SettingsItem>

      <SettingsItem title="Spacing">
        <NodeSpacingSettings />
      </SettingsItem>

      <SettingsItem title="Size">
        <NodeSizeSettings />
      </SettingsItem>

      <SettingsItem title="Typography">
        <NodeTypographySettings />
      </SettingsItem>

      <SettingsItem title="Background">
        <NodeBackgroundSettings />
      </SettingsItem>

      <SettingsItem title="Border">
        <NodeBorderSettings />
      </SettingsItem>
    </SettingsAccordion>
  );
};

Text.defaultProps = {
  text: 'Text',
  ...NodeLayoutSettings.defaultProps,
  ...NodeSpacingSettings.defaultProps,
  ...NodeSizeSettings.defaultProps,
  ...NodeTypographySettings.defaultProps,
  ...NodeBackgroundSettings.defaultProps,
  ...NodeBorderSettings.defaultProps,
};

Text.craft = {
  name: 'Text',
  props: Text.defaultProps,
  related: {
    settings: TextSettings,
  },
};
