import gql from 'graphql-tag';

export const CreateDistribution = gql`
  mutation CreateDistribution($organizationId: ID!, $reportId: ID!, $request: JSON!) {
    createDistribution(organizationId: $organizationId, reportId: $reportId, request: $request) {
      id
      organizationId
      reportId
    }
  }
`;
