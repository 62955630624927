import { motion } from 'framer-motion';
import React from 'react';

interface Props {
  isOpen: boolean;
}

export const MotionSlideOut = (props: React.PropsWithChildren<Props>) => {
  const { isOpen } = props;

  return (
    <motion.div
      initial={isOpen}
      animate={{
        opacity: isOpen ? 1 : 0,
        width: isOpen ? 'auto' : 0,
      }}
      transition={{
        type: 'tween',
        ease: 'easeInOut',
      }}
      style={{
        flexShrink: 0,
        height: '100%',
        overflow: 'hidden',
      }}
    >
      {props.children}
    </motion.div>
  );
};
