import { Input } from '@chakra-ui/react';
import React from 'react';
import { FormItem } from '../../../components/forms/FormItem';

interface Props {
  value: string;
  onChange: (value: string) => void;
}

export const NameFilterControl = (props: Props) => {
  const { value, onChange } = props;
  return (
    <FormItem id="name" label="Name">
      <Input
        size="sm"
        defaultValue={value}
        placeholder="Search by name"
        onChange={(event) => onChange(event.target.value)}
      />
    </FormItem>
  );
};
