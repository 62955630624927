import React from 'react';
import { useCounter } from 'react-use';

export const useOnScreen = (ref: React.RefObject<Element>, rootMargin = '0px') => {
  const [isIntersecting, setIntersecting] = React.useState(false);
  const [onScreenCount, onScreenCountActions] = useCounter(0);

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          onScreenCountActions.inc(1);
        }
        setIntersecting(entry.isIntersecting);
      },
      {
        rootMargin,
      },
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
    // Remove the observer as soon as the component is unmounted
    return () => observer.disconnect();
  }, [ref, rootMargin, onScreenCountActions]);

  return {
    onScreen: isIntersecting,
    onScreenCount,
  };
};
