import { Badge } from '@chakra-ui/react';
import { NumberedBadge } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { useEchoIQIntegrationAssessmentQuery } from '../../graphql/hooks/useIntegrationsQuery';
import { useAppContext } from '../../hooks/useAppContext';

interface Props {
  reportId: string;
}

export const EchoIQAssessmentBadge = (props: Props) => {
  const { reportId } = props;
  const { organization } = useAppContext();

  const { data } = useEchoIQIntegrationAssessmentQuery(
    {
      organizationId: organization.id,
      request: { reportId },
    },
    // Refetch echoIQ results periodically, as they happen async on server since they can take a while
    {
      refetchInterval: (data) =>
        P.isNil(data?.organization?.integrations.echoIQ.assessment) ? 30000 : false,
    },
  );

  const assessmentCount = data?.organization?.integrations.echoIQ.assessment ? 1 : 0;

  return (
    <>
      <Badge colorScheme="green">BETA</Badge>
      <NumberedBadge count={assessmentCount} />
    </>
  );
};
