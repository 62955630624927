import { BaseNodeProps } from '../../../../utils/craftjs';
import React from 'react';
import { useSSRNode } from '../../hooks/useSSRNode';
import { Box } from '@chakra-ui/react';

export interface Props extends BaseNodeProps {
  draggable?: boolean;
}

export const Section = (props: React.PropsWithChildren<Props>) => {
  const { children } = props;

  const {
    connectors: { connect, drag },
  } = useSSRNode(props);

  return (
    <Box
      ref={(ref) => connect(drag(ref))}
      minH="60px"
      mb={4}
      p={8}
      rounded="md"
      shadow="md"
      borderWidth="1px"
    >
      {children}
    </Box>
  );
};

Section.defaultProps = {};

Section.craft = {
  name: 'Section',
  props: Section.defaultProps,
};
