import { Tag, TagProps } from '@chakra-ui/react';
import { WorklistEntryStatus } from '@piccolohealth/echo-common';

import React from 'react';

interface Props extends TagProps {
  status: WorklistEntryStatus;
}

export const WorklistEntryStatusTag = (props: Props) => {
  const { status, ...rest } = props;

  switch (status) {
    case WorklistEntryStatus.Scheduled:
      return (
        <Tag colorScheme="blue" {...rest}>
          Scheduled
        </Tag>
      );
    case WorklistEntryStatus.Completed:
      return (
        <Tag colorScheme="green" {...rest}>
          Completed
        </Tag>
      );
  }
};
