import { HStack } from '@chakra-ui/react';
import { FeatureFlag, orPermission, PERMISSIONS, uuid } from '@piccolohealth/echo-common';
import React from 'react';
import { useAppContext } from '../../hooks/useAppContext';
import { usePermission } from '../../hooks/usePermission';
import { NavButton } from '../generic/NavButton';

export const LinksNav = () => {
  const { auth, organization } = useAppContext();
  const hasReportsPermission = usePermission(
    orPermission(PERMISSIONS.reportsRead, PERMISSIONS.reportsReadShared),
  ).value;

  const hasWorklistPermission = usePermission(orPermission(PERMISSIONS.worklistRead)).value;
  const hasWorklistFeature = organization.hasFeature(FeatureFlag.Worklist);

  if (auth.isAuthenticated && hasReportsPermission) {
    return (
      <HStack spacing={4}>
        <NavButton to={`/organizations/${organization.id}/reports`} replace state={uuid()}>
          Reports
        </NavButton>
        {hasWorklistPermission && hasWorklistFeature && (
          <NavButton to={`/organizations/${organization.id}/worklist`}>Worklist</NavButton>
        )}
      </HStack>
    );
  }

  return null;
};
