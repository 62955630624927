import { Box } from '@chakra-ui/react';
import { Page } from '@piccolohealth/echo-common';
import { MotionSlideOut, ScrollArea } from '@piccolohealth/ui';
import React from 'react';
import { useMeasure } from 'react-use';
import { useReport } from '../../context/ReportContext';
import { deserializeNodes, renderNodesToJSX } from '../../utils/craftjs';
import { RESOLVERS } from '../templates/report/helpers';

interface Props {
  parentWidth: number;
  allowShowReport: boolean;
  isReportShowing: boolean;
}

export const ImageViewerReportSlideOut = React.memo((props: Props) => {
  const { parentWidth, allowShowReport, isReportShowing } = props;
  const [containerRef, { width }] = useMeasure<HTMLDivElement>();

  const slideOutWidth = parentWidth * 0.5;
  const baseWidth = 1000;
  const scale = width ? width / Page.clientWidth : 1;
  const pageWidth = Page.clientWidth * (scale ?? 1);
  const pageHeight = pageWidth / Page.a4Ratio;

  const { reportTemplate, isDisabled } = useReport();

  const { nodes, pageNodes } = React.useMemo(() => {
    if (!allowShowReport) {
      return { nodes: [], pageNodes: [] };
    }

    const nodes = deserializeNodes(reportTemplate.reportNodes);
    const pageNodes = nodes.filter((node) => (node.type as any)?.resolvedName === 'Page');
    return { nodes, pageNodes };
  }, [allowShowReport, reportTemplate.reportNodes]);

  if (!allowShowReport) {
    return null;
  }

  return (
    <MotionSlideOut isOpen={isReportShowing}>
      <Box
        h="full"
        w={`min(${slideOutWidth}px, ${baseWidth}px)`}
        maxW={`${slideOutWidth}px`}
        minW={`min(${slideOutWidth}px, ${baseWidth}px)`}
        flexShrink={0}
        overflowY="hidden"
      >
        <ScrollArea ref={containerRef} h="full" overflowX="hidden">
          {pageNodes.map((page, index) => (
            <Box
              key={`${page.id}-${index}`}
              h={`${pageHeight}px`}
              w={`${pageWidth}px`}
              transform={`scale(${scale})`}
              transformOrigin="left top"
              mb={4}
            >
              <Box w={`${Page.clientWidth}px`} cursor={isDisabled ? 'not-allowed' : 'inherit'}>
                {renderNodesToJSX(nodes, RESOLVERS, page.id, false)}
              </Box>
            </Box>
          ))}
        </ScrollArea>
      </Box>
    </MotionSlideOut>
  );
});
