import { MenuItem, Text } from '@chakra-ui/react';
import { MeasurementMapping } from '@piccolohealth/echo-common';
import React from 'react';
import { FaCog } from 'react-icons/fa';
import { showModal } from '../../../components/generic/Modal';
import { MeasurementMappingEditModal } from './MeasurementMappingEditModal';

interface Props {
  measurementMapping: MeasurementMapping;
}

export const MeasurementMappingEditMenuItem = (props: Props) => {
  const { measurementMapping } = props;

  return (
    <MenuItem
      icon={<FaCog />}
      data-pw="measurementMappingEditMenuItem"
      onClick={() => showModal(MeasurementMappingEditModal, { measurementMapping })}
    >
      <Text>Edit mapping</Text>
    </MenuItem>
  );
};
