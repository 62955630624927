import { HStack, Kbd, Spacer, Text } from '@chakra-ui/react';
import { Select, SelectComponents, SelectOption, SelectProps } from '@piccolohealth/ui';
import {
  getReportVariableValue,
  MedicalObjectsIntegrationProvider,
  ReportVariable,
} from '@piccolohealth/echo-common';
import _ from 'lodash';
import React from 'react';
import { useReportMinimalQuery } from '../../graphql/hooks/useReportQuery';
import { useUsersMinimalQuery } from '../../graphql/hooks/useUsersQuery';
import { useAppContext } from '../../hooks/useAppContext';

export type ProviderMapping = {
  user: {
    id: string;
    name: string;
  };
  mapping: {
    userId: string;
    providerNumber: string;
  };
};

export type Provider = {
  providerNumber: string;
  name: string;
};

export interface ProviderFromChooserProps
  extends Omit<SelectProps<ProviderMapping, any>, 'value' | 'onChange' | 'options'> {
  mappings: {
    userId: string;
    providerNumber: string;
  }[];
  value: Provider | null;
  onChange: (value: Provider) => void;
  reportId?: string;
  isDisabled?: boolean;
}

export const ProviderFromChooser = (props: ProviderFromChooserProps) => {
  const { mappings, value, isDisabled, reportId, onChange, ...rest } = props;

  const { organization } = useAppContext();

  const { data, isFetched, isLoading } = useUsersMinimalQuery({
    organizationId: organization.id,
  });

  const options: SelectOption<ProviderMapping>[] = _.chain(mappings)
    .map((mapping) => {
      const user = _.find(data?.organization?.users, { id: mapping.userId });

      if (!user) {
        return undefined;
      }

      return {
        value: mapping.providerNumber,
        label: `${user.name} - ${mapping.providerNumber}`,
        raw: { user, mapping },
      };
    })
    .compact()
    .value();

  useReportMinimalQuery(
    {
      organizationId: organization.id,
      reportId: reportId ?? '',
    },
    {
      enabled: !_.isNil(reportId) && isFetched,
      onSuccess: (data) => {
        if (!_.isEmpty(value?.providerNumber)) {
          return;
        }

        const variables = (data.organization?.report?.variables ?? []) as ReportVariable[];
        const cardiologistValue = _.head(getReportVariableValue(variables, 'cardiologist'));

        const suggestedProvider = _.find(options, (option) =>
          _.isEqual(option.raw.user.name, cardiologistValue),
        );

        if (suggestedProvider) {
          return onChange({
            providerNumber: suggestedProvider.value,
            name: suggestedProvider.raw.user.name,
          });
        }
      },
    },
  );

  const selectedValue = _.find(options, (o) => o.value === value?.providerNumber) ?? null;

  const onChangePrime = React.useCallback(
    (value: SelectOption<ProviderMapping>) => {
      return onChange({ providerNumber: value.value, name: value.raw.user.name });
    },
    [onChange],
  );

  const components = React.useMemo<
    SelectComponents<ProviderMapping, MedicalObjectsIntegrationProvider>
  >(() => {
    return {
      OptionGroup: (props) => (
        <HStack fontWeight="semibold" color="gray.500" py={2} px={1} fontSize="xs">
          <Text>{props.group.label}</Text>
        </HStack>
      ),
      Option: (props) => (
        <HStack>
          <Text>{props.option.raw.user.name}</Text>
          <Spacer />
          <Kbd fontSize="sm">{props.option.raw.mapping.providerNumber}</Kbd>
        </HStack>
      ),
      Value: (props) => (
        <HStack w="full" mr={1}>
          <Text>{props.option.raw.user.name}</Text>
          <Spacer />
          <Kbd fontSize="sm">{props.option.raw.mapping.providerNumber}</Kbd>
        </HStack>
      ),
    };
  }, []);

  return (
    <Select
      {...rest}
      options={options}
      value={selectedValue}
      onChange={onChangePrime}
      isDisabled={isDisabled}
      isLoading={isLoading}
      placeholder="Choose a provider number"
      components={components}
    />
  );
};
