import { Alert, AlertDescription, AlertTitle, Button, Flex, HStack } from '@chakra-ui/react';
import React from 'react';
import { useConfig } from '../../context/ConfigContext';
import { useHealthQuery } from '../../hooks/useHealthQuery';

export const VersionCheckObserver = () => {
  const { config } = useConfig();

  const { data } = useHealthQuery({
    refetchInterval: 20000,
  });

  const currentServerVersion = data?.buildInfo.commit ?? null;
  const currentClientVersion = config.buildInfo.commit;

  const outOfDate = React.useMemo(() => {
    return (
      currentClientVersion && currentServerVersion && currentClientVersion !== currentServerVersion
    );
  }, [currentClientVersion, currentServerVersion]);

  const refreshPage = () => {
    window.location.reload();
  };

  if (!outOfDate) {
    return null;
  }

  return (
    <Alert
      status="info"
      bg="purple.500"
      variant="solid"
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      rounded="none"
      border="none"
    >
      <HStack spacing={4}>
        <Flex>
          <AlertTitle color="white">Piccolo has been updated.</AlertTitle>
          <AlertDescription color="white">
            Please refresh the page to get the latest version.
          </AlertDescription>
        </Flex>
        <Button size="xs" bg="gray.100" color="purple.500" onClick={refreshPage}>
          Refresh
        </Button>
      </HStack>
    </Alert>
  );
};
