import {
  Box,
  Button,
  Icon,
  List,
  ListItem,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Spacer,
  Tag,
  Text,
} from '@chakra-ui/react';
import { Label } from '@piccolohealth/echo-common';
import debounce from 'debounce-promise';
import _ from 'lodash';
import React from 'react';
import { FormProvider, useController, useForm } from 'react-hook-form';
import { FaCheck, FaEllipsisH, FaRegTrashAlt } from 'react-icons/fa';
import { useEditLabelMutation } from '../../graphql/hooks/useEditLabelMutation';
import { useAppContext } from '../../hooks/useAppContext';
import { HookedInput } from '../forms/hookform/HookedInput';
import { HookedTextArea } from '../forms/hookform/HookedTextArea';
import { showModal } from '../generic/Modal';
import { LabelDeleteModal } from '../modals/LabelsDeleteModal';

interface FormValues {
  name: string;
  description?: string | null;
  color: string;
}

interface Props {
  label: Label;
}

const HookedLabelColorItems = (props: { name: string }) => {
  const { name } = props;

  const {
    field: { ref, ...inputProps },
  } = useController({ name });

  const colors = ['blue', 'cyan', 'green', 'orange', 'pink', 'purple', 'red', 'teal', 'yellow'];

  return (
    <List display="inline-block" overflowY="auto" w="full">
      {_.map(colors, (item, index) => {
        return (
          <ListItem
            key={`${item}${index}`}
            px={3}
            py={1}
            display="flex"
            flexDir="row"
            alignItems="center"
            _hover={{ bg: 'gray.100' }}
            onClick={() => inputProps.onChange(item)}
            data-pw="labelEditColorButton"
          >
            <Tag colorScheme={item}>{_.upperFirst(item)}</Tag>
            <Spacer />
            {item === inputProps.value ? (
              <Icon as={FaCheck} data-pw="labelEditColorButtonCheckmark" />
            ) : null}
          </ListItem>
        );
      })}
    </List>
  );
};

const LabelMenuContents = (props: { label: Label }) => {
  const { label } = props;
  const { organization, errorToast } = useAppContext();
  const editLabelMutation = useEditLabelMutation();

  const initialValues: FormValues = {
    name: label.name,
    description: label.description,
    color: label.color,
  };

  const methods = useForm({
    defaultValues: initialValues,
  });

  const watch = methods.watch;

  const autosave = debounce(async (values: Partial<FormValues>) => {
    const request = _.pickBy(values, (value, key) => {
      return !_.isEqual(value, initialValues[key as keyof FormValues]);
    });

    await editLabelMutation
      .mutateAsync({
        organizationId: organization.id,
        labelId: label.id,
        request,
      })
      .catch((err) => errorToast(`Error editing label: ${err.message}`));
  }, 1000);

  // Watch the form
  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    const subscription = watch(autosave);
    return () => subscription.unsubscribe();
  }, [watch, autosave]);

  return (
    <Box
      onClick={(e) => e.stopPropagation()}
      onMouseDown={(e) => e.stopPropagation()}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <FormProvider {...methods}>
        <MenuGroup title="Name" color="gray.500">
          <Box px={3} pt={1} pb={2}>
            <HookedInput name="name" data-pw="labelEditNameInput" />
          </Box>
        </MenuGroup>
        <MenuGroup title="Description" color="gray.500">
          <Box px={3} pt={1} pb={2}>
            <HookedTextArea
              name="description"
              fontSize="sm"
              py={3 / 2}
              px={3}
              rows={2}
              data-pw="labelEditDescriptionInput"
            />
          </Box>
        </MenuGroup>
        <MenuDivider />
        <MenuItem
          icon={<FaRegTrashAlt />}
          onClick={() => showModal(LabelDeleteModal, { labelId: label.id })}
          fontSize="sm"
          data-pw="labelDeleteMenuButton"
        >
          <Text color="danger">Delete</Text>
        </MenuItem>
        <MenuDivider />
        <MenuGroup title="Color" color="gray.500">
          <HookedLabelColorItems name="color" />
        </MenuGroup>
      </FormProvider>
    </Box>
  );
};

export const ManageLabelMenu = (props: Props) => {
  const { label } = props;

  return (
    <Menu closeOnSelect={false} strategy="fixed" isLazy autoSelect={false}>
      <MenuButton
        as={Button}
        variant="ghost"
        _hover={{ bg: `gray.200` }}
        _active={{ bg: `gray.200` }}
        size="xs"
        data-pw={`labelManageMenuButton-${label.id}`}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Icon as={FaEllipsisH} />
      </MenuButton>
      <MenuList zIndex={1900}>
        <LabelMenuContents label={label} />
      </MenuList>
    </Menu>
  );
};
