import React from 'react';
import { useEditor } from '@craftjs/core';
import { useLayer } from '@craftjs/layers';
import { Box, Flex, Icon, IconButton } from '@chakra-ui/react';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';

const Expand = (props: { expanded: boolean; onMouseDown: () => void }) => {
  const commonProps = { size: 'xs', variant: 'ghost', colorScheme: 'purple' };

  return props.expanded ? (
    <IconButton
      icon={<Icon as={FaAngleUp} />}
      onMouseDown={props.onMouseDown}
      aria-label="Expand"
      {...commonProps}
    />
  ) : (
    <IconButton
      icon={<Icon as={FaAngleDown} />}
      onMouseDown={props.onMouseDown}
      aria-label="Unexpand"
      {...commonProps}
    />
  );
};

export const LayerHeader: React.FC = () => {
  const {
    id,
    depth,
    expanded,
    children,
    connectors: { drag, layerHeader },
    actions: { toggleLayer },
  } = useLayer((layer) => {
    return {
      expanded: layer.expanded,
    };
  });

  const { selected, displayName } = useEditor((state, query) => ({
    hidden: state.nodes[id] && state.nodes[id].data.hidden,
    selected: query.getEvent('selected').first() === id,
    topLevel: query.node(id).isTopLevelCanvas(),
    displayName:
      state.nodes[id] && state.nodes[id].data.custom.displayName
        ? state.nodes[id].data.custom.displayName
        : state.nodes[id].data.displayName,
  }));

  return (
    <Flex
      ref={(ref) => ref && drag(ref)}
      alignContent="center"
      px="10px"
      py="4px"
      bg={selected ? '#2680eb' : 'transparent'}
      color={selected ? '#fff' : 'inherit'}
    >
      <Flex
        ref={(ref) => ref && layerHeader(ref)}
        w="100%"
        p="0"
        ml={`${depth * 10}px`}
        alignContent="center"
        justifyContent="space-between"
      >
        <Box fontSize="sm">{displayName}</Box>
        <Box>
          {children && children.length ? (
            <Expand expanded={expanded} onMouseDown={() => toggleLayer()} />
          ) : null}
        </Box>
      </Flex>
    </Flex>
  );
};
