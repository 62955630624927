import { Box, Divider, HStack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FeatureFlag, OrganizationStatus, PERMISSIONS } from '@piccolohealth/echo-common';
import { P, timezoneList } from '@piccolohealth/util';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useUpdateOrganizationMutation } from '../../../graphql/hooks/useUpdateOrganizationMutation';
import { useAppContext } from '../../../hooks/useAppContext';
import { usePermission } from '../../../hooks/usePermission';
import { FormSection } from '../../forms/FormSection';
import { HookedFeatureFlagSwitches } from '../../forms/hookform/HookedFeatureFlagSwitches';
import { HookedFormItem } from '../../forms/hookform/HookedFormItem';
import { HookedImageUploader } from '../../forms/hookform/HookedImageUploader';
import { HookedInput } from '../../forms/hookform/HookedInput';
import { HookedResetButton } from '../../forms/hookform/HookedResetButton';
import { HookedSelect } from '../../forms/hookform/HookedSelect';
import { HookedSubmitButton } from '../../forms/hookform/HookedSubmitButton';
import { Content } from '../../generic/Content';

type FormValues = {
  name?: string;
  addressLineOne?: string | null;
  addressLineTwo?: string | null;
  email?: string | null;
  phone?: string | null;
  logo?: string | null;
  timezone?: string;
  stripeCustomerId?: string | null;
  stripeSubscriptionId?: string | null;
  status: OrganizationStatus;
  featureFlags: FeatureFlag[];
};

export const OrganizationSettings = () => {
  const { organization, successToast, errorToast } = useAppContext();

  const mutation = useUpdateOrganizationMutation();

  const hasSupportPermission = usePermission(PERMISSIONS.organizationSupport).value;

  const initialValues: FormValues = {
    name: organization.name,
    addressLineOne: organization.addressLineOne,
    addressLineTwo: organization.addressLineTwo,
    email: organization.email,
    phone: organization.phone,
    logo: organization.logo,
    timezone: organization.timezone,
    stripeCustomerId: organization.stripeCustomerId,
    stripeSubscriptionId: organization.stripeSubscriptionId,
    status: organization.status,
    featureFlags: organization.featureFlags,
  };

  const validationSchema = Yup.object({
    name: Yup.string().nullable(),
    addressLineOne: Yup.string().nullable(),
    addressLineTwo: Yup.string().nullable(),
    email: Yup.string().email('Invalid email address').nullable(),
    phone: Yup.string().nullable(),
    timezone: Yup.string(),
    stripeCustomerId: Yup.string().nullable(),
    stripeSubscriptionId: Yup.string().nullable(),
    status: Yup.string(),
  });

  const methods = useForm({
    defaultValues: initialValues as any,
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (values: Partial<FormValues>) => {
    const request = P.pickBy(values, (value, key) => {
      return !P.isEqual(value, initialValues[key]);
    });

    await mutation
      .mutateAsync({
        organizationId: organization.id,
        updateOrganizationRequest: request,
      })
      .then(() => {
        successToast('Organization settings edited successfully');
      })
      .catch((err) => {
        errorToast(`Error editing organization settings: ${err.message}`);
      });
  };

  const timezoneOptions = timezoneList.map((timezone) => ({
    value: timezone,
    label: timezone,
    raw: timezone,
  }));

  const statusOptions = Object.keys(OrganizationStatus).map((key) => {
    return {
      key,
      value: OrganizationStatus[key as keyof typeof OrganizationStatus],
      label: key,
      raw: OrganizationStatus[key as keyof typeof OrganizationStatus],
    };
  });

  const supportForms = hasSupportPermission && (
    <>
      <HookedFormItem label="Stripe customer ID" name="stripeCustomerId">
        <HookedInput name="stripeCustomerId" placeholder="Stripe customer ID" />
      </HookedFormItem>
      <HookedFormItem label="Stripe subscription ID" name="stripeSubscriptionId">
        <HookedInput name="stripeSubscriptionId" placeholder="Stripe subscription ID" />
      </HookedFormItem>
      <HookedFormItem label="Status" name="status">
        <HookedSelect name="status" options={statusOptions} />
      </HookedFormItem>
      <HookedFormItem label="Feature flags" name="featureFlags">
        <HookedFeatureFlagSwitches name="featureFlags" />
      </HookedFormItem>
    </>
  );

  return (
    <Content title="Organization">
      <Box maxW="400px" px={4}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <FormSection>
              <HookedFormItem label="Name" name="name">
                <HookedInput name="name" placeholder="Name" />
              </HookedFormItem>
              <HookedFormItem label="Address Line 1" name="addressLineOne">
                <HookedInput name="addressLineOne" placeholder="Address Line 1" />
              </HookedFormItem>
              <HookedFormItem label="Address Line 2" name="addressLineTwo">
                <HookedInput name="addressLineTwo" placeholder="Address Line 2" />
              </HookedFormItem>
              <HookedFormItem label="Email" name="email">
                <HookedInput name="email" placeholder="Email" />
              </HookedFormItem>
              <HookedFormItem label="Phone" name="phone">
                <HookedInput name="phone" placeholder="Phone" />
              </HookedFormItem>
              <HookedFormItem label="Logo" name="logo">
                <HookedImageUploader name="logo" maxWidth={300} maxHeight={225} />
              </HookedFormItem>
              <HookedFormItem label="Timezone" name="timezone">
                <HookedSelect name="timezone" options={timezoneOptions} />
              </HookedFormItem>
              {supportForms}
              <Divider />
            </FormSection>
            <HStack alignItems="center" mt={6}>
              <HookedResetButton>Reset</HookedResetButton>
              <HookedSubmitButton>Save</HookedSubmitButton>
            </HStack>
          </form>
        </FormProvider>
      </Box>
    </Content>
  );
};
