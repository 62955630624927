import { Permission } from '@piccolohealth/echo-common';
import { useAsync } from 'react-use';
import { useOrganization } from '../context/OrganizationContext';

interface UsePermissionResponse {
  value: boolean;
  isLoading: boolean;
}

export const usePermission = (permission: Permission): UsePermissionResponse => {
  const organization = useOrganization();

  const asyncState = useAsync(async () => {
    if (!organization?.hasPermission) {
      return true;
    }

    return organization.hasPermission(permission);
  }, [organization?.hasPermission]);

  return {
    isLoading: asyncState.loading,
    value: asyncState.value ?? false,
  };
};
