import { Badge, Center, HStack, Image, Spacer, Text } from '@chakra-ui/react';
import { DistributionType } from '@piccolohealth/echo-common';
import _ from 'lodash';
import React from 'react';
import { useDistributionTypeInfo } from '../../hooks/useDistributionTypeInfo';
import { Select, SelectCheck, SelectOption } from '@piccolohealth/ui';

type DistributionTypeOption = {
  value: DistributionType;
  title: string;
  logo: any;
  available: boolean;
};

const getOptions = () => {
  const options: SelectOption<DistributionTypeOption>[] = _.chain(DistributionType)
    .values()
    .map((value) => {
      const { title, logo, enabled, available } = useDistributionTypeInfo(value);

      return {
        label: title,
        value,
        disabled: !enabled,
        raw: {
          title,
          value,
          logo,
          available,
        },
      };
    })
    .sortBy((v) => v.disabled && !v.raw.available)
    .value();

  return options;
};

export interface DistributionTypeChooserProps {
  value: DistributionType;
  onChange: (value: DistributionType) => void;
}

export const DistributionTypeChooser = (props: DistributionTypeChooserProps) => {
  const { value, onChange, ...rest } = props;
  const options = getOptions();

  const selectedValue = React.useMemo(() => {
    return _.find(options, (option) => option.value === value) ?? null;
  }, [value, options]);

  const onChangePrime = React.useCallback(
    (option: SelectOption<DistributionTypeOption>) => {
      onChange(option.raw.value);
    },
    [onChange],
  );

  return (
    <Select
      options={options}
      value={selectedValue}
      onChange={onChangePrime}
      components={{
        Option: (props) => (
          <HStack spacing={2}>
            <Center h={10} w={8}>
              <Image src={props.option.raw.logo} />
            </Center>
            <Text>{props.option.label}</Text>
            <Spacer />
            <HStack>
              {props.option.raw.available && props.option.disabled && (
                <Badge colorScheme="gray">Not enabled</Badge>
              )}
              {!props.option.raw.available && <Badge colorScheme="green">Coming soon</Badge>}
              <SelectCheck />
            </HStack>
          </HStack>
        ),
        Value: (props) => (
          <HStack spacing={2}>
            <Center h={6} w={5}>
              <Image src={props.option?.raw.logo} />
            </Center>

            <Text>{props.option?.label}</Text>
          </HStack>
        ),
      }}
      {...rest}
    />
  );
};
