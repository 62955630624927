import { Tag, TagProps } from '@chakra-ui/react';
import { ReportTemplateVariable } from '@piccolohealth/echo-common';
import React from 'react';

interface Props extends TagProps {
  type: ReportTemplateVariable['__typename'];
}

export const getColorAndText = (type: ReportTemplateVariable['__typename']) => {
  switch (type) {
    case 'ReportTemplateStaticVariable':
      return { color: 'pink', text: 'Static' };
    case 'ReportTemplateChoiceVariable':
      return { color: 'blue', text: 'Choice' };
    case 'ReportTemplateWallMotionVariable':
      return { color: 'green', text: 'Wall Motion' };
    case 'ReportTemplateMediaAttachmentVariable':
      return { color: 'red', text: 'Media Attachments' };
    default:
      throw new Error(
        'Unable to get color and text for report template variable, type not supported',
      );
  }
};

export const ReportTemplateVariableTypeTag = (props: Props) => {
  const { type, ...rest } = props;
  const { color, text } = getColorAndText(type);

  return (
    <Tag colorScheme={color} {...rest}>
      {text}
    </Tag>
  );
};
