import { DistributionType } from '@piccolohealth/echo-common';
import React from 'react';
import { DistributionEmailPayloadForm } from './DistributionEmailPayloadForm';
import { DistributionFaxPayloadForm } from './DistributionFaxPayloadForm';
import { DistributionHealthLinkPayloadForm } from './DistributionHealthLinkPayloadForm';
import { DistributionMedicalObjectsPayloadForm } from './DistributionMedicalObjectsPayloadForm';

interface Props {
  type: DistributionType;
  reportId?: string;
}

export const DistributionPayloadForm = (props: Props) => {
  switch (props.type) {
    case DistributionType.Email:
      return <DistributionEmailPayloadForm />;
    case DistributionType.MedicalObjects:
      return <DistributionMedicalObjectsPayloadForm reportId={props.reportId} />;
    case DistributionType.HealthLink:
      return <DistributionHealthLinkPayloadForm />;
    case DistributionType.Fax:
      return <DistributionFaxPayloadForm />;
    default:
      return null;
  }
};
