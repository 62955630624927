import React from 'react';
import {
  Spinner as ChakraSpinner,
  Center,
  SpinnerProps as ChakraSpinnerProps,
} from '@chakra-ui/react';

export const Spin = (
  props: ChakraSpinnerProps & {
    showAfterDuration?: number;
  },
) => {
  const [showSpinner, setShowSpinner] = React.useState(false);

  React.useEffect(() => {
    const timer = setTimeout(() => setShowSpinner(true), props.showAfterDuration || 0);
    return () => clearTimeout(timer);
  }, [props.showAfterDuration]);

  return (
    <Center h="full" w="full">
      {showSpinner && <ChakraSpinner color="purple.500" {...props} />}
    </Center>
  );
};
