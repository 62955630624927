import { FILE_SIZE_LIMIT_32MB_BYTES, PiccoloError } from '@piccolohealth/echo-common';
import { P } from '@piccolohealth/util';
import * as Sentry from '@sentry/react';

export const asPiccoloError = (error: any): PiccoloError => {
  // This means it is a graphQL error
  const graphqlError = error.response?.errors?.[0];
  if (graphqlError) {
    const piccoloError = new PiccoloError({
      message: graphqlError.message ?? error,
      type: graphqlError.type ?? 'InternalServerError',
      metadata: graphqlError.metadata,
    });

    return piccoloError;
  }

  // This means it is a regular HTTP error (e.g. an error from /health).
  // Its possible other responses have an error.response, so we must handle
  // this case if no type can be found. There is NGINX, axios, and fetch errors,
  // with different shapes.
  else if (error.response) {
    // NGINX errors
    switch (error.response.status) {
      case 413: {
        const piccoloError = new PiccoloError({
          message: error.response.statusText,
          type: 'FileTooLarge',
          stack: error.stack,
          metadata: {
            limit: FILE_SIZE_LIMIT_32MB_BYTES,
          },
        });

        return piccoloError;
      }
    }

    // Axios errors
    if (error.response.data) {
      const piccoloError = new PiccoloError({
        message: error.response.data.message ?? error,
        type: error.response.data.type ?? 'InternalServerError',
        metadata: error.response.data.metadata,
        stack: error.stack,
      });

      return piccoloError;
    }

    // Fetch erorrs
    const piccoloError = new PiccoloError({
      message: error.response.message ?? error,
      type: error.response.type ?? 'InternalServerError',
      metadata: error.response.metadata,
      stack: error.stack,
    });

    return piccoloError;

    // This means some unknown internal client library has thrown, e.g. a request timeout
  } else {
    const piccoloError = new PiccoloError({
      message: error.message || error,
      type: 'InternalClientError',
      stack: error.stack,
    });
    return piccoloError;
  }
};

export const sendExceptionToSentry = (error: any): void => {
  const extra = P.get<any, any>(error, 'metadata') ?? [];

  Sentry.captureException(error, {
    extra,
  });
};
