import gql from 'graphql-tag';

export const UpdateIntegrations = gql`
  mutation UpdateIntegrations(
    $organizationId: ID!
    $updateIntegrationsRequest: UpdateIntegrationsRequest!
  ) {
    updateIntegrations(organizationId: $organizationId, request: $updateIntegrationsRequest) {
      medicalObjects {
        id
        organizationId
        type
        createdAt
        updatedAt
        settings {
          enabled
          capricornUrl
          practiceId
          practiceName
          providerMappings {
            providerNumber
            userId
          }
        }
      }
      healthLink {
        id
        organizationId
        type
        createdAt
        updatedAt
        settings {
          enabled
          providerMappings {
            providerNumber
            userId
          }
        }
      }
      fax {
        id
        organizationId
        type
        createdAt
        updatedAt
        settings {
          enabled
        }
      }
      echoIQ {
        id
        organizationId
        type
        createdAt
        updatedAt
        settings {
          enabled
          autoAnalyze
          hasDiscount
        }
      }
    }
  }
`;
