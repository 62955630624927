import { Box, Wrap } from '@chakra-ui/react';
import React from 'react';
import { Content } from '../../generic/Content';
import { FaxIntegrationCard } from './fax/FaxIntegrationCard';
import { HealthLinkIntegrationCard } from './healthlink/HealthLinkIntegrationCard';
import { MedicalObjectsIntegrationCard } from './medicalObjects/MedicalObjectsIntegrationCard';
import { EchoIQIntegrationCard } from './echoIQ/EchoIQIntegrationCard';

export const IntegrationSettings = () => {
  return (
    <Content title="Integrations">
      <Box p={4} h="full">
        <Wrap spacing={4} h="full" shouldWrapChildren>
          <MedicalObjectsIntegrationCard />
          <HealthLinkIntegrationCard />
          <FaxIntegrationCard />
          <EchoIQIntegrationCard />
        </Wrap>
      </Box>
    </Content>
  );
};
