import { PiccoloError } from '@piccolohealth/echo-common';
import { ChakraV3 } from '@piccolohealth/ui';
import * as Sentry from '@sentry/react';
import React from 'react';
import { useConfig } from '../../context/ConfigContext';
import { sendExceptionToSentry } from '../../utils/errors';
import { Error } from './Error';

interface Props {
  fallback?: JSX.Element;
}

export const ErrorBoundary = (props: React.PropsWithChildren<Props>) => {
  const { fallback, children } = props;
  const configContext = useConfig();

  return (
    <Sentry.ErrorBoundary
      fallback={({ error }) => {
        sendExceptionToSentry(error);

        if (fallback) {
          return fallback;
        }

        return configContext.config.errorStackEnabled ? (
          <ChakraV3.ErrorOverlay error={error} />
        ) : (
          <Error
            error={
              error instanceof PiccoloError
                ? error
                : new PiccoloError({
                    type: 'InternalClientError',
                    message: 'Sorry, something broke. Please contact support.',
                  })
            }
          />
        );
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};
