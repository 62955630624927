import { Stack } from '@chakra-ui/react';
import React from 'react';
import { AuditTrailFilterHeader } from '../../components/audittrail/AuditTrailFilterHeader';
import { AuditTrailTable } from '../../components/audittrail/AuditTrailTable';
import { Content } from '../../components/generic/Content';
import { useAuditTrailFilterQuery } from '../../graphql/hooks/useAuditTrailQuery';
import { useAuditTrailFilter } from '../../hooks/useAuditTrailFilter';

export const AuditTrail = () => {
  const filter = useAuditTrailFilter();
  const { isLoading, error, entries, pagination, refetch } = useAuditTrailFilterQuery(filter);

  return (
    <Content title="Audit trail">
      <Stack p={4} spacing={4}>
        <AuditTrailFilterHeader filter={filter} />
        <AuditTrailTable
          entries={entries}
          error={error}
          isLoading={isLoading}
          pagination={pagination}
          refetch={refetch}
        />
      </Stack>
    </Content>
  );
};
