import NiceModal, { NiceModalHandler, useModal } from '@ebay/nice-modal-react';
import React from 'react';

export const createModal = <A extends {}>(
  f: React.ComponentType<A & { modal: NiceModalHandler<Record<string, unknown>> }>,
) => {
  return NiceModal.create<A>((props) => {
    const modal = useModal();
    return React.createElement(f, { ...props, modal });
  });
};

export const showModal = NiceModal.show;
