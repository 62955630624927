import React from 'react';
import { useController } from 'react-hook-form';
import { RadioGroup, RadioGroupProps } from '@piccolohealth/ui';

interface Props extends Omit<RadioGroupProps, 'value' | 'onChange'> {
  name: string;
}

export const HookedRadioGroup = (props: Props) => {
  const { name, ...rest } = props;

  const {
    field: { ref, ...inputProps },
  } = useController({ name });

  return <RadioGroup {...rest} {...inputProps} />;
};
