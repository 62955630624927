import { Icon } from '@chakra-ui/react';
import { Element } from '@craftjs/core';
import React from 'react';
import { FaDiceD20, FaImage, FaRegSquare, FaTable } from 'react-icons/fa';
import * as toolbox from '../common/Toolbox';
import { MediaAttachmentZone } from './components/attachments/media/MediaAttachmentZone';
import { InlineAutocomplete } from './components/InlineAutoComplete';
import { InlineDatepicker } from './components/InlineDatepicker';
import { InlineInput } from './components/InlineInput';
import { InlineSelect } from './components/InlineSelect';
import { InlineStatementSite } from './components/InlineStatementSite';
import { InlineStatementSiteList } from './components/InlineStatementSiteList';
import { ManageStatementsButton } from './components/ManageStatementsButton';
import { Measurement } from './components/Measurement';
import { Page } from './components/Page';
import { ToggleableSection } from './components/ToggleableSection';
import { WallMotionDiagram } from './components/WallMotionDiagram';

export const components = {
  ...toolbox.components,
  page: {
    name: 'Page',
    icon: <Icon as={FaTable} />,
    create: (connectors: any, ref: HTMLElement | null) =>
      connectors.create(ref, <Element canvas is={Page} {...Page.defaultProps} />),
  },
  mediaAttachmentZone: {
    name: 'MediaAttachmentZone',
    icon: <Icon as={FaImage} />,
    create: (connectors: any, ref: HTMLElement | null) =>
      connectors.create(ref, <MediaAttachmentZone {...MediaAttachmentZone.defaultProps} />),
  },
  wallMotionDiagram: {
    name: 'Wall Motion Diagram',
    icon: <Icon as={FaDiceD20} />,
    create: (connectors: any, ref: HTMLElement | null) =>
      connectors.create(ref, <WallMotionDiagram />),
  },
  inlineInput: {
    name: 'Inline input',
    icon: <Icon as={FaRegSquare} />,
    create: (connectors: any, ref: HTMLElement | null) =>
      connectors.create(ref, <InlineInput {...InlineInput.defaultProps} />),
  },
  inlineAutocomplete: {
    name: 'Inline autocomplete',
    icon: <Icon as={FaRegSquare} />,
    create: (connectors: any, ref: HTMLElement | null) =>
      connectors.create(ref, <InlineAutocomplete {...InlineAutocomplete.defaultProps} />),
  },
  inlineSelect: {
    name: 'Inline select',
    icon: <Icon as={FaRegSquare} />,
    create: (connectors: any, ref: HTMLElement | null) =>
      connectors.create(ref, <InlineSelect {...InlineSelect.defaultProps} />),
  },
  inlineDatepicker: {
    name: 'Inline datepicker',
    icon: <Icon as={FaRegSquare} />,
    create: (connectors: any, ref: HTMLElement | null) =>
      connectors.create(ref, <InlineDatepicker {...InlineDatepicker.defaultProps} />),
  },
  inlineStatementSite: {
    name: 'Inline statement site',
    icon: <Icon as={FaRegSquare} />,
    create: (connectors: any, ref: HTMLElement | null) =>
      connectors.create(ref, <InlineStatementSite />),
  },
  inlineStatementSiteList: {
    name: 'Inline statement site list',
    icon: <Icon as={FaRegSquare} />,
    create: (connectors: any, ref: HTMLElement | null) =>
      connectors.create(ref, <InlineStatementSiteList />),
  },
  manageStatementsButton: {
    name: 'Manage statements button',
    icon: <Icon as={FaRegSquare} />,
    create: (connectors: any, ref: HTMLElement | null) =>
      connectors.create(ref, <ManageStatementsButton {...ManageStatementsButton.defaultProps} />),
  },
  toggleableSection: {
    name: 'Toggleable section',
    icon: <Icon as={FaRegSquare} />,
    create: (connectors: any, ref: HTMLElement | null) =>
      connectors.create(ref, <ToggleableSection {...ToggleableSection.defaultProps} />),
  },
  measurement: {
    name: 'Measurement',
    icon: <Icon as={FaRegSquare} />,
    create: (connectors: any, ref: HTMLElement | null) =>
      connectors.create(ref, <Measurement {...Measurement.defaultProps}></Measurement>),
  },
};
