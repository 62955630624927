import gql from 'graphql-tag';

export const WorklistEntries = gql`
  query WorklistEntries($organizationId: ID!, $request: GetPaginatedWorklistEntriesRequest) {
    organization(organizationId: $organizationId) {
      worklist {
        entries(request: $request) {
          results {
            id
            organizationId
            machineAet {
              id
              aet
              name
            }
            studyInstanceUID
            patientId
            patientFirstName
            patientLastName
            patientDob
            patientSex
            patientHeight
            patientWeight
            modality
            status
            reportTemplate {
              id
              name
              labelColor
            }
            performingPhysician {
              id
              name
            }
            reportId
            createdAt
            updatedAt
          }
          pagination {
            total
            remaining
            offset
            limit
            currentPage
            totalPages
            hasNextPage
            hasPreviousPage
          }
        }
      }
    }
  }
`;
