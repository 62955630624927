import { Flex, HStack, Text, Wrap, WrapItem } from '@chakra-ui/react';
import { WallMotionModule } from '@piccolohealth/echo-common';
import _ from 'lodash';
import React from 'react';
import { Dot } from '@piccolohealth/ui';

interface Props {
  module: WallMotionModule;
}

export const HorizontalWallMotionLegend = (props: Props) => {
  const { module } = props;
  return (
    <Wrap>
      {_.map(module.scores, ({ number, text, color }, index) => {
        return (
          <WrapItem key={`${text}-${index}`}>
            <HStack overflow="hidden" white-space="nowrap">
              <Dot size="8px" bg={color} />
              <Text fontSize="sm">
                {text} {number ? `(${number})` : `(blank)`}
              </Text>
            </HStack>
          </WrapItem>
        );
      })}
    </Wrap>
  );
};

export const VerticalWallMotionLegend = (props: Props) => {
  const { module } = props;

  return (
    <Flex flexDir="column" justifyContent="center">
      {_.map(module.scores, ({ text, number, color }, index) => {
        return (
          <HStack key={`${text}-${index}`}>
            <Dot size="8px" bg={color} />
            <Text fontSize="sm">
              {text} {number ? `(${number})` : `(blank)`}
            </Text>
          </HStack>
        );
      })}
    </Flex>
  );
};
