import { ReportTemplateVariable } from '@piccolohealth/echo-common';
import _ from 'lodash';
import React from 'react';

const DEFAULT_VARIABLES_TABLE_PAGE_NUMBER = 0;
const DEFAULT_VARIABLES_TABLE_PAGE_SIZE = 10;
const DEFAULT_SEARCH_FILTER = '';
const DEFAULT_TYPE_FILTER: ReportTemplateVariable['__typename'][] = [];

export interface VariablesTableFilter {
  currentPageNumber: number;
  setCurrentPageNumber: (n: number) => void;
  pageSize: number;
  setPageSize: (n: number) => void;

  searchFilter: string;
  typesFilter: ReportTemplateVariable['__typename'][];

  onSearchFilter: (value: string) => void;
  onTypesFilter: (users: ReportTemplateVariable['__typename'][]) => void;
}

export const useVariablesTableFilter = (): VariablesTableFilter => {
  const [searchFilter, setSearchFilter] = React.useState<string>(DEFAULT_SEARCH_FILTER);
  const [typesFilter, setTypesFilter] =
    React.useState<ReportTemplateVariable['__typename'][]>(DEFAULT_TYPE_FILTER);

  const [pageSize, setPageSize] = React.useState<number>(DEFAULT_VARIABLES_TABLE_PAGE_SIZE);
  const [currentPageNumber, setCurrentPageNumber] = React.useState<number>(
    DEFAULT_VARIABLES_TABLE_PAGE_NUMBER,
  );

  const onSearchFilter = _.debounce((value: string) => {
    setSearchFilter(value);
    setCurrentPageNumber(0);
  }, 500);

  const onTypesFilter = (values: ReportTemplateVariable['__typename'][]) => {
    setTypesFilter(values);
    setCurrentPageNumber(0);
  };

  return {
    currentPageNumber,
    setCurrentPageNumber,
    pageSize,
    setPageSize,
    onSearchFilter,
    searchFilter,
    typesFilter,
    onTypesFilter,
  };
};
