import { PermissionName } from '@piccolohealth/echo-common';
import _ from 'lodash';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useAppContext } from '../../hooks/useAppContext';
import { Spin } from '@piccolohealth/ui';
import { PageError } from './PageError';

// TODO: Remove this component when redo permissions
export const AdminProtectedRoute = (props: React.PropsWithChildren<{}>) => {
  const { children } = props;
  const { config, auth, user } = useAppContext();
  const location = useLocation();

  const hasPermission = _.includes(user.extraPermissions, PermissionName.Admin);

  if (auth.isLoading || user.isLoading) {
    return <Spin />;
  }

  if (!auth.isAuthenticated) {
    auth.loginWithRedirect({
      redirectUri: config.auth.redirectUri,
      appState: {
        targetUrl: location?.pathname,
      },
    });
    return <Spin />;
  }

  if (!hasPermission) {
    return <PageError type="Forbidden" />;
  }

  return <>{children}</>;
};
