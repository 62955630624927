import { CheckboxGroup, CheckboxGroupProps } from '@chakra-ui/react';
import React from 'react';
import { useController } from 'react-hook-form';

interface Props extends CheckboxGroupProps {
  name: string;
}

export const HookedCheckboxGroup = (props: React.PropsWithChildren<Props>) => {
  const { name, children, ...rest } = props;

  const { field } = useController({ name });

  return (
    <CheckboxGroup {...rest} defaultValue={field.value} onChange={field.onChange}>
      {children}
    </CheckboxGroup>
  );
};
