import { Button, Icon, Menu, MenuButton, MenuDivider, MenuGroup, MenuList } from '@chakra-ui/react';
import { ReportStatus } from '@piccolohealth/echo-common';
import React from 'react';
import { FaAngleDown } from 'react-icons/fa';
import { ReportDeleteMenuItem } from './ReportDeleteControl';
import { ReportDistributionsAddMenuItem } from './ReportDistributionsAddControl';
import { ReportDistributionsManageMenuItem } from './ReportDistributionsManageControl';
import { ReportDistributionsSendMenuItem } from './ReportDistributionsSendControl';
import { ReportExportMenuItem } from './ReportExportMenuControl';
import { ReportGenerateDraftPdfMenuItem } from './ReportGenerateDraftPdfMenuItem';
import { ReportShareMenuItem } from './ReportShareControl';
import { ReportViewPdfMenuItem } from './ReportViewPdfControl';

interface Props {
  reportId: string;
  reportStatus: ReportStatus;
  onDelete: () => Promise<void>;
}

export const ReportActionMenu = (props: Props) => {
  const { reportId, reportStatus, onDelete } = props;

  return (
    <Menu isLazy lazyBehavior="keepMounted">
      <MenuList>
        <MenuGroup title="PDFs">
          <ReportGenerateDraftPdfMenuItem reportId={reportId} key="generateDraftPdf" />
          <ReportViewPdfMenuItem reportId={reportId} key="viewPdf" />
        </MenuGroup>
        <MenuDivider />
        <MenuGroup title="Distributions">
          <ReportDistributionsSendMenuItem
            reportId={reportId}
            reportStatus={reportStatus}
            key="sendDistributions"
          />
          <ReportDistributionsManageMenuItem reportId={reportId} key="manageDistributions" />
          <ReportDistributionsAddMenuItem
            reportId={reportId}
            reportStatus={reportStatus}
            key="addDistribution"
          />
        </MenuGroup>
        <MenuDivider />
        <MenuGroup title="Report">
          <ReportShareMenuItem reportId={reportId} key="share" />
          <ReportExportMenuItem reportIds={[reportId]} key="export" />
          <ReportDeleteMenuItem reportId={reportId} key="delete" options={{ onDelete }} />
        </MenuGroup>
      </MenuList>
      <MenuButton
        as={Button}
        variant="outline"
        rightIcon={<Icon as={FaAngleDown} />}
        data-pw={`reportActionsButton-${reportId}`}
      >
        Actions
      </MenuButton>
    </Menu>
  );
};
