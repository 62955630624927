import { LooseObject } from '@piccolohealth/echo-common';
import React from 'react';
import { AuthedImage } from '../../generic/AuthedImage';

interface Props {
  id: string;
  src: string;
  onScreenshot?: (data: string, metadata: LooseObject) => void;
}

export const JpegPlayer = (props: Props) => {
  const { id, src, onScreenshot } = props;

  return (
    <AuthedImage
      url={src}
      cacheKey={id}
      onLoaded={(blob) => onScreenshot?.(blob.base64, {})}
      h="100%"
      w="100%"
      objectFit="contain"
    />
  );
};
