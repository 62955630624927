import gql from 'graphql-tag';

export const Reports = gql`
  query Reports($organizationId: ID!, $getReportsRequest: GetPaginatedReportsRequest) {
    organization(organizationId: $organizationId) {
      id
      reports(request: $getReportsRequest) {
        reports {
          id
          organizationId
          status
          variables(aliases: ["patientName", "studyDate", "cardiologist", "sonographer"]) {
            __typename

            ... on ReportStaticVariable {
              id
              alias
              value
              units
            }

            ... on ReportChoiceVariable {
              id
              alias
              value
            }
          }
          reportTemplate {
            id
            versionId
            name
            labelColor
          }
          distributions {
            id
            organizationId
            reportId
            status
            trigger
            payload {
              __typename
              ... on DistributionMedicalObjectsPayload {
                to {
                  providerDetails {
                    id
                    firstName
                    middleName
                    lastName
                    prefix
                    suffix
                  }
                  providerNumber
                }
                from {
                  providerDetails {
                    id
                    firstName
                    middleName
                    lastName
                    prefix
                    suffix
                  }
                  providerNumber
                }
              }
              ... on DistributionHealthLinkPayload {
                to {
                  practitionerId
                  practitionerName {
                    text
                    family
                    given
                    prefix
                    suffix
                  }
                  organizationId
                  organizationName
                  organizationAddress {
                    city
                    country
                    line
                    postalCode
                    state
                    text
                    type
                    use
                  }
                  endpointId
                  endpointName
                  providerNumber
                }
                from {
                  providerNumber
                  name
                }
              }
              ... on DistributionEmailPayload {
                sharerId
                sharerEmail
                sharerName
                shareeEmail
              }
              ... on DistributionFaxPayload {
                to {
                  faxNumber
                }
                from {
                  sharerId
                  sharerEmail
                  sharerName
                }
              }
            }
          }
          labels {
            id
            name
            description
            color
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        pagination {
          total
          remaining
          offset
          limit
          currentPage
          totalPages
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`;
