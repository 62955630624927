import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  DistributionPayloadRequest,
  DistributionRequest,
  DistributionTrigger,
  DistributionType,
} from '@piccolohealth/echo-common';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { HookedSubmitButton } from '../../../components/forms/hookform/HookedSubmitButton';
import { createModal } from '../../../components/generic/Modal';
import { useUpdateOrganizationMutation } from '../../../graphql/hooks/useUpdateOrganizationMutation';
import { useAppContext } from '../../../hooks/useAppContext';
import { getDistributionTypes } from '../../../hooks/useDistributionTypeInfo';
import { distributionValidationSchema } from '../distributionValidationSchema';
import { DefaultDistributionForm } from './DefaultDistributionForm';

type FormValues = Partial<DistributionRequest & { type: DistributionType }>;

export const DefaultDistributionAddModal = createModal<{}>((props) => {
  const { modal } = props;
  const { organization, successToast, errorToast } = useAppContext();

  const { hide, visible } = modal;

  const mutation = useUpdateOrganizationMutation();

  const initialValues: FormValues = {
    trigger: DistributionTrigger.OnFinalize,
    type: DistributionType.Email,
    payload: undefined,
  };

  const methods = useForm({
    defaultValues: initialValues as any,
    resolver: yupResolver(distributionValidationSchema),
    mode: 'all',
  });

  const onSubmit = React.useCallback(
    async (values: FormValues) => {
      const { payloadRequestTypename } = getDistributionTypes(values.type);

      await mutation
        .mutateAsync({
          organizationId: organization.id,
          updateOrganizationRequest: {
            distributionSettings: {
              defaultDistribution: {
                trigger: values.trigger as DistributionTrigger,
                payload: {
                  __typename: payloadRequestTypename,
                  ...values.payload,
                } as DistributionPayloadRequest,
              },
            },
          },
        })
        .then(() => {
          successToast('Default distribution added successfully');
          hide();
        })
        .catch((err) => {
          errorToast(`Error creating default distribution: ${err.message}`);
        });
    },
    [mutation, organization.id, hide, errorToast, successToast],
  );

  return (
    <Modal isOpen={visible} onClose={hide} onCloseComplete={modal.remove} size="xl">
      <ModalOverlay />
      <ModalContent>
        <FormProvider {...methods}>
          <Box as="form" display="contents" onSubmit={methods.handleSubmit(onSubmit)}>
            <ModalHeader>
              <Flex align="start">
                <Box>
                  <Heading size="md">Add a default distribution</Heading>
                  <Text fontSize="md" fontWeight="normal" color="secondary" mt={2}>
                    Please complete the following to proceed
                  </Text>
                </Box>
                <Spacer />
                <ModalCloseButton position="unset" top="unset" right="unset" />
              </Flex>
            </ModalHeader>
            <ModalBody>
              <DefaultDistributionForm />
            </ModalBody>
            <ModalFooter>
              <HStack>
                <Button onClick={hide}>Close</Button>
                <HookedSubmitButton>Add default distribution</HookedSubmitButton>
              </HStack>
            </ModalFooter>
          </Box>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
});
