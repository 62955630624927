import { Checkbox, HStack, Input, SimpleGrid } from '@chakra-ui/react';
import { useNode } from '@craftjs/core';
import React from 'react';
import { FormItem } from '../../../forms/FormItem';
import { RadioGroup } from '@piccolohealth/ui';

export const parseFontSizeAndWeight = (variant: string) => {
  switch (variant) {
    case 'h1':
      return { fontSize: '4xl', fontWeight: 'bold' };
    case 'h2':
      return { fontSize: '3xl', fontWeight: 'bold' };
    case 'h3':
      return { fontSize: 'xl', fontWeight: 'bold' };
    case 'h4':
      return { fontSize: 'md', fontWeight: 'bold' };
    case 'p':
      return { fontSize: 'sm' };
    case 'xs':
      return { fontSize: 'xs' };
    default:
      return { fontSize: variant };
  }
};

export interface NodeTypographyProps {
  fontSize?: string;
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
  fontWeight?: string;
  lineHeight?: string;
  variant?: string; // legacy
  textAlign?: string;
}

export const NodeTypographySettings = () => {
  const {
    actions: { setProp },
    fontSize,
    fontWeight,
    lineHeight,
    bold,
    italic,
    underline,
    textAlign,
  } = useNode<NodeTypographyProps>((node) => ({
    fontSize: node.data.props.fontSize,
    fontWeight: node.data.props.fontWeight,
    lineHeight: node.data.props.lineHeight,
    bold: node.data.props.bold,
    italic: node.data.props.italic,
    underline: node.data.props.underline,
    textAlign: node.data.props.textAlign,
  }));

  return (
    <SimpleGrid columns={2} spacing={8}>
      <FormItem id="fontSize" label="Font size">
        <RadioGroup
          value={fontSize}
          onChange={(e) => setProp((props: NodeTypographyProps) => (props.fontSize = e), 1000)}
          options={[
            { label: '4xl', value: '4xl' },
            { label: '3xl', value: '3xl' },
            { label: 'xl', value: 'xl' },
            { label: 'md', value: 'md' },
            { label: 'sm', value: 'sm' },
            { label: 'xs', value: 'xs' },
          ]}
          size="sm"
          variant="outline"
        />
      </FormItem>
      <FormItem id="fontWeight" label="Font weight">
        <Input
          value={fontWeight}
          onChange={(e) =>
            setProp((props: NodeTypographyProps) => (props.fontWeight = e.target.value))
          }
          size="sm"
        />
      </FormItem>
      <FormItem id="lineHeight" label="Line height">
        <Input
          value={lineHeight}
          onChange={(e) =>
            setProp((props: NodeTypographyProps) => (props.lineHeight = e.target.value))
          }
          size="sm"
        />
      </FormItem>

      <FormItem id="textAlign" label="Text align">
        <Input
          value={textAlign}
          onChange={(e) =>
            setProp((props: NodeTypographyProps) => (props.textAlign = e.target.value))
          }
          size="sm"
        />
      </FormItem>

      <FormItem id="style" label="Style">
        <HStack>
          <Checkbox
            colorScheme="purple"
            isChecked={bold}
            onChange={(e) =>
              setProp((props: NodeTypographyProps) => (props.bold = e.target.checked))
            }
          >
            Bold
          </Checkbox>
          <Checkbox
            colorScheme="purple"
            isChecked={italic}
            onChange={(e) =>
              setProp((props: NodeTypographyProps) => (props.italic = e.target.checked))
            }
          >
            Italic
          </Checkbox>
          <Checkbox
            colorScheme="purple"
            isChecked={underline}
            onChange={(e) =>
              setProp((props: NodeTypographyProps) => (props.underline = e.target.checked))
            }
          >
            Underline
          </Checkbox>
        </HStack>
      </FormItem>
    </SimpleGrid>
  );
};

NodeTypographySettings.defaultProps = {
  fontSize: undefined,
  fontWeight: undefined,
  lineHeight: undefined,
  bold: undefined,
  italic: undefined,
  underline: undefined,
};
