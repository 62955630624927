import React from 'react';
import { useReportAttachmentsMinimalQuery } from '../../../graphql/hooks/useReportQuery';
import { useAppContext } from '../../../hooks/useAppContext';
import { NumberedBadge } from '../../../components/generic/NumberedBadge';

interface Props {
  reportId: string;
}

export const AttachmentsBadge = (props: Props) => {
  const { reportId } = props;
  const { organization } = useAppContext();

  const { data } = useReportAttachmentsMinimalQuery({
    organizationId: organization.id,
    reportId,
  });

  const attachments = data?.organization?.report?.attachments || [];

  return <NumberedBadge count={attachments.length} />;
};
