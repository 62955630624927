import { Button, Icon, Menu, MenuButton, MenuList } from '@chakra-ui/react';
import { PERMISSIONS, ReportTemplate } from '@piccolohealth/echo-common';
import React from 'react';
import { FaAngleDown } from 'react-icons/fa';
import { usePermission } from '../../../hooks/usePermission';
import { ReportTemplateExportMenuItem } from './ReportTemplateExportControl';
import { ReportTemplateMergeMenuItem } from './ReportTemplateMergeControl';
import { ReportTemplateSettingsMenuItem as ReportTemplateSettingsMenuItem } from './ReportTemplateSettingsControl';

interface Props {
  reportTemplate: ReportTemplate;
  reportTemplates: ReportTemplate[];
  onDelete: () => Promise<void>;
}

export const ReportTemplateActionsMenu = (props: Props) => {
  const { reportTemplate, reportTemplates, onDelete } = props;

  const isDisabled = !usePermission(PERMISSIONS.reportTemplateUpdate).value;

  return (
    <Menu>
      <MenuList>
        <ReportTemplateSettingsMenuItem
          reportTemplate={reportTemplate}
          data-pw={`reportTemplateActionsSettingsMenuItem`}
        />
        <ReportTemplateMergeMenuItem
          reportTemplate={reportTemplate}
          reportTemplates={reportTemplates}
          data-pw={`reportTemplateActionsMergeMenuItem`}
          options={{ onDelete }}
        />
        <ReportTemplateExportMenuItem
          reportTemplateId={reportTemplate.id}
          data-pw={`reportTemplateActionsExportMenuItem`}
        />
      </MenuList>
      <MenuButton
        as={Button}
        variant="outline"
        rightIcon={<Icon as={FaAngleDown} />}
        isDisabled={isDisabled}
        data-pw={`reportTemplateActionsButton-${reportTemplate.id}`}
      >
        Actions
      </MenuButton>
    </Menu>
  );
};
