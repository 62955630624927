import { Box, Wrap } from '@chakra-ui/react';
import { Page } from '@piccolohealth/echo-common';
import React from 'react';
import { useReport } from '../../../context/ReportContext';
import { deserializeNodes, renderNodesToJSX } from '../../../utils/craftjs';
import { PaperPage } from '../../generic/PaperPage';
import { RESOLVERS as REPORT_RESOLVERS } from '../../templates/report/helpers';

interface Props {
  shouldWrap: boolean;
  scale?: number;
}

export const WorksheetEditor = (props: Props) => {
  const { shouldWrap, scale } = props;
  const { reportTemplate, isDisabled } = useReport();

  const { nodes, pageNodes } = React.useMemo(() => {
    const nodes = deserializeNodes(reportTemplate.reportNodes);
    const pageNodes = nodes.filter((node) => (node.type as any)?.resolvedName === 'Page');
    return { nodes, pageNodes };
  }, [reportTemplate.reportNodes]);

  const Container = shouldWrap ? Wrap : Box;

  return (
    <Container shouldWrapChildren align="flex-start" justify="center" p={4} spacing={8}>
      {pageNodes.map((page, index) => (
        <Box
          key={`${page.id}-${index}`}
          h="full"
          w={`${Page.clientWidth * (scale ?? 1)}px`}
          transform={`scale(${scale})`}
          transformOrigin="left top"
        >
          <PaperPage
            p={2}
            w={`${Page.clientWidth}px`}
            cursor={isDisabled ? 'not-allowed' : 'inherit'}
            className="editor"
          >
            {renderNodesToJSX(nodes, REPORT_RESOLVERS, page.id, false)}
          </PaperPage>
        </Box>
      ))}
    </Container>
  );
};
