import gql from 'graphql-tag';

export const AutoCompleteEmails = gql`
  query AutoCompleteEmails($organizationId: ID!, $request: PaginatedAutoCompleteEmailsRequest) {
    organization(organizationId: $organizationId) {
      autoComplete {
        emails(request: $request) {
          results {
            count
            similarity
            value
            isExcluded
          }
          pagination {
            total
            remaining
            offset
            limit
            currentPage
            totalPages
            hasNextPage
            hasPreviousPage
          }
        }
      }
    }
  }
`;

export const AutoCompleteReportVariables = gql`
  query AutoCompleteReportVariables(
    $organizationId: ID!
    $request: PaginatedAutoCompleteReportVariablesRequest!
  ) {
    organization(organizationId: $organizationId) {
      autoComplete {
        reportVariables(request: $request) {
          results {
            count
            similarity
            value
            isExcluded
          }
          pagination {
            total
            remaining
            offset
            limit
            currentPage
            totalPages
            hasNextPage
            hasPreviousPage
          }
        }
      }
    }
  }
`;
