import _ from 'lodash';
import { Role, User } from '../graphql/types';

export interface UserAndRoles {
  user: User;
  roles: Role[];
}

export const getUserAndRoles = (organizationId: string, user: User): UserAndRoles => {
  const membership = _.find(
    user.organizationMemberships,
    (organizationMembership) => organizationMembership.organizationId === organizationId,
  );

  return { user, roles: _.compact(membership?.roles) };
};
