import { CheckboxGroup } from './components/CheckboxGroup';
import { Column } from './components/Column';
import { Divider } from './components/Divider';
import { Image } from './components/Image';
import { Section } from './components/Section';
import { Text } from './components/Text';

export const RESOLVERS = {
  Divider,
  Section,
  Column,
  Text,
  Image,
  CheckboxGroup,
};
