import { MenuItem, ToastId } from '@chakra-ui/react';
import { Async } from '@piccolohealth/util';
import { PERMISSIONS } from '@piccolohealth/echo-common';
import React from 'react';
import { FaRegFile } from 'react-icons/fa';
import { useGenerateDraftReportPdf } from '../../graphql/hooks/useGenerateDraftReportPdfMutation';
import { useAppContext } from '../../hooks/useAppContext';
import { usePermission } from '../../hooks/usePermission';

interface Props {
  reportId: string;
}

export const ReportGenerateDraftPdfMenuItem = (props: Props) => {
  const { reportId } = props;
  const hasPermission = usePermission(PERMISSIONS.reportsRead).value;
  const { organization, toast } = useAppContext();
  const mutation = useGenerateDraftReportPdf();

  const toastRef = React.useRef<ToastId>();

  const finishToast = React.useCallback(() => {
    if (toastRef.current) {
      toast.update(toastRef.current, {
        title: 'Draft PDF successfully generated',
        status: 'success',
        isClosable: true,
        duration: 3000,
        position: 'top',
      });
    }
  }, [toast]);

  const startToast = React.useCallback(() => {
    toastRef.current = toast({
      title: 'Generating draft PDF...',
      isClosable: true,
      duration: 3000,
      position: 'top',
    });
  }, [toast]);

  const onClick = React.useCallback(() => {
    startToast();
    mutation
      .mutateAsync({ organizationId: organization.id, reportId: reportId })
      .then(async (resp) => {
        const { viewUrl } = resp.generateDraftReportPdf;
        finishToast();
        // Delay to allow toast to show before opening new tab
        await Async.delay(500);
        window.open(viewUrl || '', '_blank');
      });
  }, [mutation, organization.id, reportId, startToast, finishToast]);

  return (
    <MenuItem icon={<FaRegFile />} onClick={onClick} isDisabled={!hasPermission}>
      View draft PDF
    </MenuItem>
  );
};
