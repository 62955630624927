import { MultiSelect, MultiSelectOption, OnChangeRequest } from '@piccolohealth/ui';
import { User } from '@piccolohealth/echo-common';
import _ from 'lodash';
import React from 'react';
import { useUsersQuery } from '../../../graphql/hooks/useUsersQuery';
import { useAppContext } from '../../../hooks/useAppContext';
import { AuditTrailFilter } from '../../../hooks/useAuditTrailFilter';
import { FormItem } from '../../forms/FormItem';

const userToOption = (user: User): MultiSelectOption<User> => {
  return {
    label: user.name,
    value: user.id,
    raw: user,
  };
};

interface Props {
  filter: AuditTrailFilter;
}

export const AuditTrailUserFilterControl = ({ filter }: Props) => {
  const { usersFilter, onUsersFilter } = filter;
  const { organization } = useAppContext();

  const { data } = useUsersQuery({ organizationId: organization.id });

  const users = (data?.organization?.users as User[]) || [];
  const options = _.map(users, userToOption);

  const onChange = React.useCallback(
    (req: OnChangeRequest<User>) => {
      onUsersFilter(_.map(req.values, (item) => item.value));
    },
    [onUsersFilter],
  );

  const selectedValues = React.useMemo(() => {
    return _.compact(_.map(usersFilter, (v) => _.find(options, (o) => _.isEqual(o.value, v))));
  }, [usersFilter, options]);

  return (
    <FormItem id="user" label="User">
      <MultiSelect
        options={options}
        selectedValues={selectedValues}
        onChange={onChange}
        placeholder="All users"
      />
    </FormItem>
  );
};
