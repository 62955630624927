import { Button, ButtonProps } from '@chakra-ui/react';
import React from 'react';

interface Props extends ButtonProps {
  downloadUrl: string;
}

export const AttachmentDownloadButton = (props: Props) => {
  const { downloadUrl, ...rest } = props;

  return (
    <Button onClick={() => window.open(downloadUrl)} {...rest}>
      Download
    </Button>
  );
};
