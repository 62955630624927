import gql from 'graphql-tag';

export const ReportTemplate = gql`
  query ReportTemplate($organizationId: ID!, $reportTemplateId: ID!) {
    organization(organizationId: $organizationId) {
      id
      reportTemplate(reportTemplateId: $reportTemplateId) {
        id
        organizationId
        versionId
        name
        statementSites {
          id
          name
          statements {
            id
            value
            default
          }
        }
        variables {
          __typename
          ... on ReportTemplateStaticVariable {
            id
            alias
            source
            label
            site
            category
            units
            precision
            controlType
            defaultValue
            isEditable
            mappings {
              id
              alias
              name
              site
              units
              precision
              properties {
                name
                value
              }
            }
          }
          ... on ReportTemplateChoiceVariable {
            id
            alias
            source
            label
            site
            category
            choices {
              id
              label
              value
              default
            }
            controlType
            defaultValue
            isEditable
            mappings {
              id
              alias
              name
              site
              units
              precision
              properties {
                name
                value
              }
            }
          }
          ... on ReportTemplateWallMotionVariable {
            id
            alias
            source
            label
            site
            category
            variant
            defaultValue
            isEditable
            mappings {
              id
              alias
              name
              site
              units
              precision
              properties {
                name
                value
              }
            }
          }
          ... on ReportTemplateMediaAttachmentVariable {
            id
            alias
            source
            label
            category
            isEditable
            mappings {
              id
              alias
              name
              site
              units
              precision
              properties {
                name
                value
              }
            }
          }
        }
        reportNodes
        isLatest
        status
        labelColor
        billingActionId
        createdAt
        updatedAt
      }
    }
  }
`;

export const ReportTemplateMinimal = gql`
  query ReportTemplateMinimal($organizationId: ID!, $reportTemplateId: ID!) {
    organization(organizationId: $organizationId) {
      id
      reportTemplate(reportTemplateId: $reportTemplateId) {
        id
        name
      }
    }
  }
`;

export const ReportTemplateVersions = gql`
  query ReportTemplateVersions($organizationId: ID!, $reportTemplateId: ID!) {
    organization(organizationId: $organizationId) {
      id
      reportTemplate(reportTemplateId: $reportTemplateId) {
        versions {
          id
          versionId
          name
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const ReportTemplateDryRun = gql`
  query ReportTemplateDryRun($organizationId: ID!, $reportTemplateId: ID!, $reportId: ID!) {
    organization(organizationId: $organizationId) {
      reportTemplateDryRun(reportTemplateId: $reportTemplateId, reportId: $reportId) {
        reportId
        variables {
          __typename
          ... on ReportStaticVariable {
            id
            alias
            value
            units
          }
          ... on ReportChoiceVariable {
            id
            alias
            value
          }
          ... on ReportWallMotionVariable {
            id
            alias
            value
          }
          ... on ReportMediaAttachmentVariable {
            id
            alias
            value
          }
        }
        measurements {
          name
          value
          site
          units
          properties {
            name
            value
          }
          hash
          mapping {
            id
            name
          }
        }
      }
    }
  }
`;
