import React from 'react';

interface UseStackReturn<A> {
  clear: () => void;
  isEmpty: () => boolean;
  push: (item: A) => number;
  pop: () => A | undefined;
  peek: () => A | undefined;
  reset: () => void;
  length: number;
}

export const useStack = <A>(initialList: A[]): [A[], UseStackReturn<A>] => {
  const [list, setList] = React.useState<A[]>([...initialList]);
  const length = list.length;

  const push = React.useCallback(
    (item: A) => {
      const newList = [...list, item];
      setList(newList);

      return newList.length;
    },
    [list],
  );

  const pop = React.useCallback(() => {
    if (list.length > 0) {
      const lastItem = list[list.length - 1];
      setList([...list.slice(0, list.length - 1)]);

      return lastItem;
    }

    return undefined;
  }, [list]);

  const peek = React.useCallback(() => {
    if (list.length > 0) {
      return list[list.length - 1];
    }

    return undefined;
  }, [list]);

  const clear = () => setList([]);

  const reset = () => setList(initialList);

  const isEmpty = React.useCallback(() => list.length === 0, [list]);

  const controls: UseStackReturn<A> = {
    clear,
    isEmpty,
    length,
    peek,
    pop,
    push,
    reset,
  };

  return [list, controls];
};
