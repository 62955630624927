import {
  Button,
  ButtonGroup,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Error } from '../../components/generic/Error';
import { P, prettyBytes } from '@piccolohealth/util';
import { Job, JobStatus } from '@piccolohealth/echo-common';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FaFileArchive, FaFileDownload, FaFilePdf, FaLaptopMedical, FaVideo } from 'react-icons/fa';
import { useAppContext } from '../../hooks/useAppContext';
import { useReportsExportJob, UseReportsExportJobResponse } from '../../hooks/useJob';
import { FormSection } from '../forms/FormSection';
import { HookedCheckboxStack } from '../forms/hookform/HookedCheckboxStack';
import { HookedFormItem } from '../forms/hookform/HookedFormItem';
import { HookedSubmitButton } from '../forms/hookform/HookedSubmitButton';
import { HookedSwitch } from '../forms/hookform/HookedSwitch';
import { Divider } from '../generic/Divider';
import { createModal } from '../generic/Modal';

interface FormValues {
  options: string[];
  flattened: boolean;
}

interface Props {
  reportIds: string[];
}

const ReportsExportJobResultProgress = (props: { job: Job }) => {
  const { job } = props;

  const roundedProgress = P.round(job.progress, 0);
  const colorScheme = () => {
    switch (job.status) {
      case JobStatus.Queued:
      case JobStatus.InProgress:
        return 'purple';
      case JobStatus.Completed:
        return 'green';
      case JobStatus.Failed:
        return 'red';
    }
  };

  return (
    <Flex direction="row" alignItems="center" gridColumnGap={4}>
      <Progress value={roundedProgress} colorScheme={colorScheme()} rounded="xl" flexGrow={1} />
      <Text>{roundedProgress}%</Text>
    </Flex>
  );
};

const ReportsExportJobResultContent = (props: { exportJob: UseReportsExportJobResponse }) => {
  const { isLoading, isCompleted, job, error } = props.exportJob;

  if (error) {
    return <Error error={error} />;
  }

  if (isLoading && job) {
    const text =
      job.status === JobStatus.InProgress
        ? 'Exporting files...'
        : 'Your export will begin processing soon...';

    return (
      <Stack spacing={6}>
        <Text>{text}</Text>
        <ReportsExportJobResultProgress job={job} />
      </Stack>
    );
  }

  if (isCompleted && job) {
    return (
      <Stack spacing={4}>
        <Text>Export was successfully completed</Text>
        <ReportsExportJobResultProgress job={job} />
        <Button
          as="a"
          href={job?.payload?.downloadUrl}
          key="downloadExport"
          width="full"
          size="lg"
          colorScheme="purple"
          variant="solid"
          rightIcon={<Icon as={FaFileDownload} />}
        >
          Download - {prettyBytes(job?.payload?.size || 0)}
        </Button>
      </Stack>
    );
  }

  return null;
};

export const ReportsExportModal = createModal<Props>((props) => {
  const { reportIds, modal } = props;
  const { organization } = useAppContext();
  const exportJob = useReportsExportJob();

  const initialValues = {
    options: [],
    flattened: false,
  };

  const methods = useForm({
    defaultValues: initialValues as any,
  });

  const onSubmit = (values: Partial<FormValues>) => {
    const options = (values.options ?? []).reduce<Record<string, boolean>>((acc, option) => {
      acc[option] = true;
      return acc;
    }, {});

    const variables = {
      organizationId: organization.id,
      request: {
        reportIds,
        flattened: values.flattened,
        ...options,
      },
    };

    exportJob.start(variables);
  };

  return (
    <Modal isOpen={modal.visible} onClose={modal.hide} onCloseComplete={modal.remove} size="2xl">
      <ModalOverlay />
      <ModalContent data-pw="reportsExportModalContent">
        <ModalHeader>Export</ModalHeader>
        <ModalCloseButton />
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <ModalBody>
              <FormSection layout="vertical" heading="Files to export">
                <HookedCheckboxStack
                  name="options"
                  options={[
                    {
                      label: 'PDFs',
                      value: 'pdfs',
                      description: 'PDFs that were generated after finalizing a report',
                      icon: () => <Icon as={FaFilePdf} />,
                    },
                    {
                      label: 'Attachments',
                      value: 'attachments',
                      description:
                        'Attachments that were added to a report as part of the reporting process',
                      icon: () => <Icon as={FaFileArchive} />,
                    },
                    {
                      label: 'Images & Videos',
                      value: 'images',
                      description: 'Images and videos for a report',
                      icon: () => <Icon as={FaVideo} />,
                    },
                    {
                      label: 'DICOM',
                      value: 'dicom',
                      description: 'All raw DICOM files for a report',
                      icon: () => <Icon as={FaLaptopMedical} />,
                    },
                  ]}
                  data-pw="reportsExportModalOptions"
                />
              </FormSection>
              <Divider />
              <FormSection layout="vertical" heading="Additional options">
                <HookedFormItem
                  layout="horizontal"
                  name="flattened"
                  label="Flattened"
                  helperText="Export all files to a single folder"
                >
                  <HookedSwitch colorScheme="purple" key="flattened" name="flattened" />
                </HookedFormItem>
              </FormSection>
              <Divider />
              <ReportsExportJobResultContent exportJob={exportJob} />
            </ModalBody>
            <ModalFooter>
              <ButtonGroup size="sm">
                <Button key="close" onClick={modal.hide}>
                  Close
                </Button>
                <HookedSubmitButton
                  data-pw="reportsExportModalSubmitButton"
                  isLoading={exportJob.isLoading}
                >
                  Export
                </HookedSubmitButton>
              </ButtonGroup>
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
});
