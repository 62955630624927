import ListItem from '@tiptap/extension-list-item';

export interface StatementListItemOptions {
  limit: number | null;
  HTMLAttributes: Record<string, any>;
}

export const StatementListItem = ListItem.extend<StatementListItemOptions>({
  content: 'inline*',
  addAttributes() {
    return {
      keepOnSplit: true,
    };
  },

  addOptions() {
    return {
      limit: null,
      HTMLAttributes: {},
    };
  },

  addKeyboardShortcuts() {
    return {
      Enter: () => {
        let currentListItemsCount = 0;

        this.editor.state.doc.descendants((node) => {
          if (node.type.name === 'listItem') {
            currentListItemsCount += 1;
            return false;
          }
          return true;
        });

        if (this.options.limit === null || currentListItemsCount < this.options.limit) {
          return this.editor.commands.splitListItem(this.name);
        }

        return true;
      },
      Tab: () => this.editor.commands.sinkListItem(this.name),
      'Shift-Tab': () => this.editor.commands.liftListItem(this.name),
    };
  },
});
