import { Box, BoxProps } from '@chakra-ui/react';
import React from 'react';

export const PaperPage = (props: React.PropsWithChildren<BoxProps>) => {
  const { children, ...rest } = props;
  return (
    <Box
      boxShadow="0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)"
      w="full"
      h="full"
      {...rest}
    >
      {props.children}
    </Box>
  );
};
