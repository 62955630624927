import { useEditor, useEditorReturnType } from '@craftjs/core';
import { BaseNodeProps } from '../../../utils/craftjs';

/**
 * This hook is used so we can achieve SSR of the CraftJS nodes.
 * If we are in SSR mode, we stub out the Editor return type.
 *
 * If you try to call editor functions in SSR mode (which should
 * never happen) you will receive an error.
 */

export function useSSREditor(props: BaseNodeProps): useEditorReturnType {
  const { ssr } = props;

  if (ssr) {
    return {} as useEditorReturnType;
  } else {
    try {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      return useEditor();
    } catch (error) {
      return {} as useEditorReturnType;
    }
  }
}
