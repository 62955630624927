import { Button, Stack, Text } from '@chakra-ui/react';
import { Invoice } from '@piccolohealth/echo-common';
import React from 'react';
import { showModal } from '../../../components/generic/Modal';
import { InvoiceUsageBreakdownModal } from './InvoiceUsageBreakdownModal';

interface Props {
  invoice: Invoice;
}

export const InvoiceSubscriptionItemsControl = (props: Props) => {
  const { invoice } = props;

  const onClick = React.useCallback(() => {
    return showModal(InvoiceUsageBreakdownModal, {
      invoiceId: invoice.id,
      periodStart: invoice.periodStart,
      periodEnd: invoice.periodEnd,
    });
  }, [invoice]);

  return (
    <Stack align="start">
      <Text>{invoice.quantity} items</Text>
      <Button variant="link" onClick={onClick}>
        Show details
      </Button>
    </Stack>
  );
};
