import gql from 'graphql-tag';

export const UpdateReportStatus = gql`
  mutation UpdateReportStatus(
    $organizationId: ID!
    $reportId: ID!
    $updateReportStatusRequest: UpdateReportStatusRequest!
  ) {
    updateReportStatus(
      organizationId: $organizationId
      reportId: $reportId
      request: $updateReportStatusRequest
    ) {
      id
      status
    }
  }
`;
