import { Editor, EditorContent, Extensions } from '@tiptap/react';
import React from 'react';
import { TiptapContext, TiptapContextProvider } from './TiptapContext';
import { TiptapSSR } from './TiptapSSR';

interface Props {
  context: TiptapContext;
  editor: Editor | null;
  value: string;
  extensions: Extensions;
}

export const TiptapEditorContent = (props: Props) => {
  const { editor, value, extensions, context } = props;

  const ssr = !editor || context.isDisabled;

  const content = ssr ? (
    <TiptapSSR html={value} extensions={extensions} />
  ) : (
    <EditorContent editor={editor} tabIndex={-1} />
  );

  return <TiptapContextProvider value={context}>{content}</TiptapContextProvider>;
};
