import React from 'react';
import {
  Button,
  ButtonGroup,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { useAppContext } from '../../hooks/useAppContext';
import { useDeleteReportsMutation } from '../../graphql/hooks/useDeleteReportsMutation';
import { createModal } from '../generic/Modal';

interface Props {
  reportIds: string[];
  onDelete: () => Promise<void>;
}

export const ReportsDeleteModal = createModal<Props>(({ modal, reportIds, onDelete }) => {
  const { organization, successToast, errorToast } = useAppContext();

  const mutation = useDeleteReportsMutation({});

  const { hide, visible } = modal;

  const onClick = async () => {
    await mutation
      .mutateAsync({ organizationId: organization.id, request: { reportIds } })
      .then(async () => {
        hide();
        await onDelete();
        successToast('Reports deleted successfully');
      })
      .catch((err) => {
        errorToast(`Error deleting report: ${err.message}`);
      });
  };

  return (
    <Modal isOpen={visible} onClose={hide}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Do you want to delete these reports?</ModalHeader>
        <ModalCloseButton />
        <ModalFooter>
          <ButtonGroup size="sm">
            <Button key="close" onClick={hide}>
              Close
            </Button>
            <Button
              key="submit"
              colorScheme="red"
              isLoading={mutation.isLoading}
              loadingText="Deleting..."
              type="submit"
              onClick={onClick}
              data-pw="selectedReportsDeleteModalSubmitButton"
            >
              Delete
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});
