import { Stack } from '@chakra-ui/react';
import { Instance } from '@piccolohealth/echo-common';
import React from 'react';
import { ImageViewerMode, ImageViewerModeType } from '../controls/ModeControl';
import { InstanceStack } from '../hooks/useImageViewerActions';
import { InstanceThumbnail } from './InstanceThumbnail';

interface Props {
  mode: ImageViewerMode;
  viewportInstances: Instance[];
  instancesStacks: InstanceStack[];
  onClick: (instanceStack: InstanceStack) => void;
}

export const ImageViewerSidebar = React.memo((props: Props) => {
  const { mode, viewportInstances, instancesStacks, onClick } = props;

  const thumbs = instancesStacks.map((instanceStack, i) => {
    const firstInstance = instanceStack.instances[0];
    const isVisible = instanceStack.instances.some((instance) =>
      viewportInstances.includes(instance),
    );
    const index = mode.type === ImageViewerModeType.Normal ? i : undefined;
    const count = mode.type === ImageViewerModeType.Normal ? instancesStacks.length : undefined;
    const onClickThumb = () => onClick(instanceStack);

    return (
      <InstanceThumbnail
        key={firstInstance.id}
        instance={firstInstance}
        index={index}
        count={count}
        isVisible={isVisible}
        onClick={onClickThumb}
      />
    );
  });

  return (
    <Stack spacing={3} py={2} pr={3}>
      {thumbs}
    </Stack>
  );
});
