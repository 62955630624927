import { Heading } from '@chakra-ui/react';
import { MultiSelect, MultiSelectOption, OnChangeRequest } from '@piccolohealth/ui';
import { Label } from '@piccolohealth/echo-common';
import _ from 'lodash';
import React from 'react';
import { useLabelsQuery } from '../../../graphql/hooks/useLabelsQuery';
import { useAppContext } from '../../../hooks/useAppContext';
import { ReportsFilter } from '../../../hooks/useReportsFilter';

const labelToOption = (label: Label): MultiSelectOption<Label> => {
  return {
    value: label.id,
    label: label.name,
    color: label.color,
    raw: label,
  };
};

interface Props {
  filter: ReportsFilter;
}

export const LabelsFilterControl = ({ filter }: Props) => {
  const { onLabelsFilter, labelsFilter } = filter;
  const { organization } = useAppContext();
  const { data } = useLabelsQuery({ organizationId: organization.id });

  const options = React.useMemo(() => {
    const labels = (data?.organization?.labels as Label[]) || [];
    return _.map(labels, labelToOption);
  }, [data?.organization?.labels]);

  const selectedValues: MultiSelectOption<Label>[] = React.useMemo(() => {
    return _.compact(_.map(labelsFilter, (labelId) => _.find(options, (o) => o.value === labelId)));
  }, [labelsFilter, options]);

  const onChange = React.useCallback(
    (req: OnChangeRequest<Label>) => {
      onLabelsFilter(req.values.map(({ value }) => value));
    },
    [onLabelsFilter],
  );

  return (
    <>
      <Heading as="h5" size="sm" pb="2">
        Labels
      </Heading>
      <MultiSelect
        options={options}
        selectedValues={selectedValues}
        onChange={onChange}
        placeholder="All labels"
        data-pw="filterByLabelsMultiselect"
        optionVariant="tag"
      />
    </>
  );
};
