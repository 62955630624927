import gql from 'graphql-tag';

export const ReportTemplates = gql`
  query ReportTemplates(
    $organizationId: ID!
    $getReportTemplatesRequest: GetPaginatedReportTemplatesRequest
  ) {
    organization(organizationId: $organizationId) {
      id
      reportTemplates(request: $getReportTemplatesRequest) {
        reportTemplates {
          id
          versionId
          name
          isMaster
          isLatest
          status
          billingActionId
          labelColor
          createdAt
          updatedAt
        }
      }
    }
  }
`;
