import { Box, HStack, Image, Spacer, Text } from '@chakra-ui/react';
import React from 'react';
import { IntegrationStatusTag } from '../../generic/IntegrationStatusTag';

interface Props {
  title: string;
  description: string;
  logo: string;
  isIntegrationEnabled: boolean;
}

export const IntegrationStatus = (props: Props) => {
  const { title, description, logo, isIntegrationEnabled } = props;

  return (
    <Box w="full">
      <HStack h="16" spacing={4}>
        <Image src={logo} maxH={14} maxW={28} />
        <Text ml={4} fontSize="xl" fontWeight="semibold">
          {title}
        </Text>
        <Spacer />
        <IntegrationStatusTag enabled={isIntegrationEnabled} />
      </HStack>
      <Box w="full" mt={2} fontSize="md" fontWeight="normal">
        <Text>{description}</Text>
      </Box>
    </Box>
  );
};
