import { Icon } from '@chakra-ui/react';
import React from 'react';
import { FaImage, FaVideo } from 'react-icons/fa';
import { InstanceFormat } from './InstanceFormatTag';

interface Props {
  format: InstanceFormat;
}

export const InstanceFormatIcon = (props: Props) => {
  switch (props.format) {
    case InstanceFormat.JPEG:
      return <Icon as={FaImage} color="white" />;
    case InstanceFormat.MP4:
      return <Icon as={FaVideo} color="white" />;
  }
};
