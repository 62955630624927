import { chakra, Flex, Spacer } from '@chakra-ui/react';
import React from 'react';
import { OrganizationChooser } from '../../features/organization/components/OrganizationChooser';
import { ReportCreateButton } from '../controls/ReportCreateControl';
import { LinksNav } from './LinksNav';
import { UserMenu } from './UserMenu';

export const OrganizationNavbar = () => {
  return (
    <chakra.nav>
      <Flex px={4} py={3} w="full" bg="extraDarkPurple">
        <Flex gridColumnGap={4}>
          <OrganizationChooser />
          <LinksNav />
        </Flex>
        <Spacer />
        <Flex gridColumnGap={4}>
          <ReportCreateButton />
          <UserMenu />
        </Flex>
      </Flex>
    </chakra.nav>
  );
};
