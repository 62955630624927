import { Button, ButtonGroup, ModalFooter } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useUpdateIntegrationsMutation } from '../../../../graphql/hooks/useUpdateIntegrationMutation';
import { useAppContext } from '../../../../hooks/useAppContext';
import { FormSection } from '../../../forms/FormSection';
import { HookedFormItem } from '../../../forms/hookform/HookedFormItem';
import { HookedSubmitButton } from '../../../forms/hookform/HookedSubmitButton';
import { HookedSwitch } from '../../../forms/hookform/HookedSwitch';
import { Divider } from '../../../generic/Divider';

const validationSchema = Yup.object({
  enabled: Yup.boolean().required(),
});

type FormValues = {
  enabled: boolean;
};

interface Props {
  initialValues: FormValues;
  hide: () => void;
}

export const FaxIntegrationForm = (props: Props) => {
  const { initialValues, hide } = props;
  const { organization, successToast, errorToast } = useAppContext();

  const mutation = useUpdateIntegrationsMutation();
  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const { reset } = methods;

  const onSubmit = React.useCallback(
    async (values: FormValues) => {
      await mutation
        .mutateAsync({
          organizationId: organization.id,
          updateIntegrationsRequest: {
            fax: values,
          },
        })
        .then(() => {
          reset(values);
          successToast(`Fax integration updated successfully`);
          hide();
        })
        .catch((error) => {
          errorToast(`Error editing Fax integration: ${error.message}`);
        });
    },
    [mutation, hide, reset, successToast, errorToast, organization.id],
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormSection>
          <HookedFormItem label="Enabled" name="enabled" layout="horizontal">
            <HookedSwitch name="enabled" />
          </HookedFormItem>
          <Divider />
        </FormSection>
        <ModalFooter>
          <ButtonGroup size="sm">
            <Button key="close" onClick={hide}>
              Close
            </Button>
            <HookedSubmitButton key="submit">Save</HookedSubmitButton>
          </ButtonGroup>
        </ModalFooter>
      </form>
    </FormProvider>
  );
};
