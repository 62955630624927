import { Box } from '@chakra-ui/react';
import _ from 'lodash';
import React from 'react';
import { BaseNodeProps, cleanProps } from '../../../../utils/craftjs';
import { useSSREditor } from '../../hooks/useSSREditor';
import { useSSRNode } from '../../hooks/useSSRNode';
import { NodeBackgroundProps, NodeBackgroundSettings } from '../nodes/NodeBackground';
import { NodeBorderProps, NodeBorderSettings } from '../nodes/NodeBorder';
import { NodeLayoutProps, NodeLayoutSettings } from '../nodes/NodeLayout';
import { NodeSizeProps, NodeSizeSettings } from '../nodes/NodeSize';
import { NodeSpacingProps, NodeSpacingSettings } from '../nodes/NodeSpacing';
import { NodeTypographyProps, NodeTypographySettings } from '../nodes/NodeTypography';
import { Placeholder } from '../Placeholder';
import { SettingsAccordion } from '../settings/SettingsAccordion';
import { SettingsItem } from '../settings/SettingsItem';

export interface Props
  extends BaseNodeProps,
    NodeLayoutProps,
    NodeSizeProps,
    NodeSpacingProps,
    NodeTypographyProps,
    NodeBorderProps,
    NodeBackgroundProps {}

export const Column = (props: React.PropsWithChildren<Props>) => {
  const { children, ssr, ...rest } = props;
  const { query } = useSSREditor(props);
  const {
    connectors: { connect, drag },
    isEnabled,
    isEmpty,
  } = useSSRNode(props, (node) => ({
    isEnabled: node ? query.getOptions().enabled : false,
    isEmpty: node
      ? query.node(node.id).isCanvas() && _.isEmpty(query.node(node.id).childNodes())
      : false,
  }));

  const render = isEmpty && isEnabled ? <Placeholder /> : children;
  const cleanedProps = cleanProps(rest);

  return (
    <Box ref={(ref) => ref && connect(drag(ref))} sx={cleanedProps}>
      {render}
    </Box>
  );
};

const ColumnSettings = () => {
  return (
    <SettingsAccordion>
      <SettingsItem title="Layout">
        <NodeLayoutSettings />
      </SettingsItem>

      <SettingsItem title="Spacing">
        <NodeSpacingSettings />
      </SettingsItem>

      <SettingsItem title="Size">
        <NodeSizeSettings />
      </SettingsItem>

      <SettingsItem title="Typography">
        <NodeTypographySettings />
      </SettingsItem>

      <SettingsItem title="Background">
        <NodeBackgroundSettings />
      </SettingsItem>

      <SettingsItem title="Border">
        <NodeBorderSettings />
      </SettingsItem>
    </SettingsAccordion>
  );
};

Column.defaultProps = {
  ...NodeLayoutSettings.defaultProps,
  ...NodeSpacingSettings.defaultProps,
  ...NodeSizeSettings.defaultProps,
  ...NodeTypographySettings.defaultProps,
  ...NodeBackgroundSettings.defaultProps,
  ...NodeBorderSettings.defaultProps,
};

Column.craft = {
  name: 'Column',
  props: Column.defaultProps,
  related: {
    settings: ColumnSettings,
  },
};
