import { MultiSelectOption } from '@piccolohealth/ui';
import { AutoCompleteResult } from '@piccolohealth/echo-common';
import _ from 'lodash';
import React from 'react';
import { useController } from 'react-hook-form';
import {
  MultiSelectReportVariable,
  MultiSelectReportVariableProps,
} from '../MultiSelectReportVariable';

export const inputValueToAutoCompleteResult = (
  inputValue: string,
): MultiSelectOption<AutoCompleteResult> | null => {
  if (_.isNull(inputValue) || _.isEmpty(inputValue)) {
    return null;
  }

  return {
    label: inputValue,
    value: inputValue,
    raw: {
      count: 1,
      isExcluded: false,
      similarity: 1,
      value: inputValue,
    },
  };
};

export const valuesToAutoCompleteResult = (
  value: string | string[],
): MultiSelectOption<AutoCompleteResult>[] => {
  return _.chain([value])
    .flatten()
    .map((i) => {
      return inputValueToAutoCompleteResult(i);
    })
    .compact()
    .value();
};

interface Props
  extends Omit<MultiSelectReportVariableProps, 'onChange' | 'value' | 'selectedValues'> {
  name: string;
}

export const HookedMultiSelectReportVariable = (props: Props) => {
  const { name, ...rest } = props;
  const { field } = useController({ name });
  const { onChange } = field;

  const selectedValues: MultiSelectOption<AutoCompleteResult>[] = valuesToAutoCompleteResult(
    field.value,
  );

  const onChangePrime = React.useCallback(
    (value: MultiSelectOption<AutoCompleteResult>[]) => onChange(value.map((v) => v.value)),
    [onChange],
  );

  return (
    <MultiSelectReportVariable {...rest} onChange={onChangePrime} selectedValues={selectedValues} />
  );
};
