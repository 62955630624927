import { Tag, TagLabel, TagLeftIcon, TagProps } from '@chakra-ui/react';
import React from 'react';
import { FaCheck, FaPlus } from 'react-icons/fa';

interface Props extends Omit<TagProps, 'onChange'> {
  value: boolean;
  onToggle: () => void;
}

export const ToggleTagControl = (props: Props) => {
  const { value, onToggle } = props;

  if (!value) {
    return (
      <Tag size="sm" variant={'outline'} onClick={() => onToggle()} _hover={{ cursor: 'pointer' }}>
        <TagLeftIcon boxSize="12px" as={FaPlus} />
        <TagLabel>Default</TagLabel>
      </Tag>
    );
  }

  return (
    <Tag
      size="sm"
      colorScheme="green"
      variant={'subtle'}
      onClick={() => onToggle()}
      _hover={{ cursor: 'pointer' }}
    >
      <TagLeftIcon boxSize="12px" as={FaCheck} />
      <TagLabel>Default</TagLabel>
    </Tag>
  );
};
