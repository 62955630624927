import { Machine, MachineFilter } from '@piccolohealth/echo-common';
import {
  MultiSelect,
  MultiSelectOption,
  MultiSelectProps,
  OnChangeRequest,
} from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { FormItem } from '../../forms/FormItem';

const machineToOption = (machine: Machine): MultiSelectOption<MachineFilter> => {
  return {
    value: JSON.stringify(machine),
    label: machine.model,
    raw: machine,
  };
};

interface Props
  extends Omit<
    MultiSelectProps<MachineFilter>,
    'value' | 'onChange' | 'options' | 'selectedValues'
  > {
  machines: Machine[];
  value: MachineFilter[];
  onChange: (value: MachineFilter[]) => void;
}

export const ReportTemplateMachineFilterControl = (props: Props) => {
  const { machines, value, onChange, ...rest } = props;

  const options = machines.map(machineToOption);

  const selectedValues: MultiSelectOption<MachineFilter>[] = React.useMemo(() => {
    return P.compact(
      value.map((machine) => options.find((o) => P.isEqual(JSON.parse(o.value), machine))),
    );
  }, [options, value]);

  const onChangePrime = React.useCallback(
    (req: OnChangeRequest<MachineFilter>) => {
      onChange(req.values.map(({ value }) => JSON.parse(value)));
    },
    [onChange],
  );

  return (
    <FormItem id="machine" label="Machine">
      <MultiSelect
        size="sm"
        options={options}
        selectedValues={selectedValues}
        onChange={onChangePrime}
        placeholder="All machines"
        data-pw="filterByMachinesMultiselect"
        optionVariant="tag"
        {...rest}
      />
    </FormItem>
  );
};
