import { Button, ButtonProps } from '@chakra-ui/react';
import { PERMISSIONS } from '@piccolohealth/echo-common';
import React from 'react';
import { showModal } from '../../../components/generic/Modal';
import { ReportAttachmentDeleteModal } from '../../../components/modals/ReportAttachmentDeleteModal';
import { usePermission } from '../../../hooks/usePermission';

interface Props extends ButtonProps {
  reportId: string;
  attachmentId: string;
}

export const AttachmentDeleteButton = (props: Props) => {
  const { reportId, attachmentId, ...rest } = props;
  const hasPermission = usePermission(PERMISSIONS.reportAttachmentDelete).value;

  return (
    <Button
      isDisabled={!hasPermission}
      onClick={() => showModal(ReportAttachmentDeleteModal, { reportId, attachmentId })}
      data-pw={`reportAttachmentDeleteButton-${attachmentId}`}
      {...rest}
    >
      Remove
    </Button>
  );
};
