import React from 'react';
import { useDropArea as useDropAreaRu } from 'react-use';
import { off, on } from 'react-use/lib/misc/util';
import { DropAreaOptions } from 'react-use/lib/useDrop';

export const useDropArea = (options?: DropAreaOptions | undefined) => {
  const [dropAreaProps, state] = useDropAreaRu(options);

  // https://github.com/streamich/react-use/issues/2368
  const onDragLeave = React.useCallback(
    (event: React.DragEvent<HTMLElement>) => {
      if (!(event.relatedTarget instanceof Node)) return;
      if (event.currentTarget.contains(event.relatedTarget)) return;

      dropAreaProps.onDragLeave(event);
    },
    [dropAreaProps],
  );

  // Global paste event
  const onPaste = React.useCallback(
    (e: React.ClipboardEvent) => {
      const files = e.clipboardData.files;

      if (files && files.length && options?.onFiles) {
        options.onFiles(Array.from(files), e);
      }
    },
    [options],
  );

  React.useEffect(() => {
    on(document, 'paste', onPaste);
    return () => {
      off(document, 'paste', onPaste);
    };
  }, [onPaste, options]);

  return [{ ...dropAreaProps, onDragLeave }, state] as const;
};
