import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { useFastForwardReportMutation } from '../../../graphql/hooks/useFastForwardReportMutation';
import { useAppContext } from '../../../hooks/useAppContext';
import { createModal } from '../../generic/Modal';

interface Props {
  reportId: string;
}

export const ReportFastForwardModal = createModal<Props>((props) => {
  const { modal, reportId } = props;
  const { visible, hide, remove } = modal;

  const { organization, successToast, errorToast } = useAppContext();
  const fastForwardReportMutation = useFastForwardReportMutation();

  const onFastForward = React.useCallback(async () => {
    await fastForwardReportMutation
      .mutateAsync({
        organizationId: organization.id,
        reportId,
      })
      .then(() => {
        hide();
        successToast('Report updated successfully');
      })
      .catch((err) => {
        errorToast(`Error updating report: ${err.message}`);
      });
  }, [fastForwardReportMutation, organization.id, reportId, hide, successToast, errorToast]);

  return (
    <Modal isOpen={visible} onClose={hide} onCloseComplete={remove} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Do you want to update this report to the latest report template version?
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack>
            <Text>
              Updating to the latest version may result in data loss if fields are not compatible or
              deleted. Are you sure you want to continue?
            </Text>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup size="sm">
            <Button key="close" onClick={hide}>
              Close
            </Button>
            <Button
              key="submit"
              colorScheme="purple"
              isLoading={fastForwardReportMutation.isLoading}
              loadingText="Updating..."
              type="submit"
              onClick={onFastForward}
            >
              Update
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});
