const variants = {
  highlighted: () => ({
    _hover: {
      textDecoration: 'underline solid #bfbfbf',
      textDecorationThickness: '2px',
      textUnderlineOffset: '2px',
    },
    textDecoration: 'underline dotted #bfbfbf',
    textDecorationThickness: '2px',
    textUnderlineOffset: '2px',
  }),
};

export const Text = {
  variants,
};
