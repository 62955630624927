import * as Types from '@piccolohealth/echo-common';
import { useMutation, useQueryClient } from 'react-query';
import { gqlFetcher, MutationOptions } from '../fetcher';
import { createEchoIQAssessment } from '../mutations/createEchoIQAssessment.mutation';

export const useCreateEchoIQAssessmentMutation = (
  options?: MutationOptions<
    Types.CreateEchoIqAssessmentMutation,
    Types.CreateEchoIqAssessmentMutationVariables
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(createEchoIQAssessment), {
    ...options,
    onSettled: async (resp, error, variables, ctx) => {
      await options?.onSettled?.(resp, error, variables, ctx);

      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('echoiq'),
      });
    },
  });
};
