import { HStack, Tag } from '@chakra-ui/react';
import { getUserAndRoles, User } from '@piccolohealth/echo-common';
import _ from 'lodash';
import React from 'react';

interface Props {
  organizationId: string;
  user: User;
}

export const UserRoles = (props: Props) => {
  const { organizationId, user } = props;
  const userAndRoles = getUserAndRoles(organizationId, user);

  return (
    <HStack data-pw="userRoles">
      {_.chain(userAndRoles.roles)
        .sortBy((role) => role.name)
        .map((role) => <Tag key={role.id}>{role.name}</Tag>)
        .value()}
    </HStack>
  );
};
