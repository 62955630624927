import {
  arrayOfParam,
  dateTimeParam,
  stringParam,
  useDebouncedCallback,
  useQueryParams,
} from '@piccolohealth/ui';
import { DateTime } from '@piccolohealth/util';

export interface CubeQueryFilter {
  startDate: DateTime;
  endDate: DateTime;
  reportTemplateType: string[];
  referringPhysician: string[];
  sonographer: string[];
  cardiologist: string[];
  onStartDateFilter: (value?: DateTime) => void;
  onEndDateFilter: (value?: DateTime) => void;
  onReportTemplateTypeFilter: (value?: string[]) => void;
  onReferringPhysicianFilter: (value?: string[]) => void;
  onSonographerFilter: (value?: string[]) => void;
  onCardiologistFilter: (value?: string[]) => void;
}

export const useCubeQueryFilter = (): CubeQueryFilter => {
  const [params, setParams] = useQueryParams({
    startDate: dateTimeParam,
    endDate: dateTimeParam,
    reportTemplateType: arrayOfParam(stringParam),
    referringPhysician: arrayOfParam(stringParam),
    sonographer: arrayOfParam(stringParam),
    cardiologist: arrayOfParam(stringParam),
  });

  const onStartDateFilter = useDebouncedCallback(
    (value?: DateTime) =>
      setParams({
        startDate: value,
      }),
    300,
  );

  const onEndDateFilter = useDebouncedCallback(
    (value?: DateTime) =>
      setParams({
        endDate: value,
      }),
    300,
  );

  const onReportTemplateTypeFilter = useDebouncedCallback(
    (value?: string[]) =>
      setParams({
        reportTemplateType: value,
      }),
    300,
  );

  const onReferringPhysicianFilter = useDebouncedCallback(
    (value?: string[]) =>
      setParams({
        referringPhysician: value,
      }),
    300,
  );

  const onSonographerFilter = useDebouncedCallback(
    (value?: string[]) =>
      setParams({
        sonographer: value,
      }),
    300,
  );

  const onCardiologistFilter = useDebouncedCallback(
    (value?: string[]) =>
      setParams({
        cardiologist: value,
      }),
    300,
  );

  return {
    startDate: params.startDate ?? DateTime.now().minus({ months: 1 }).startOf('day'),
    endDate: params.endDate ?? DateTime.now().endOf('day'),
    reportTemplateType: params.reportTemplateType ?? [],
    referringPhysician: params.referringPhysician ?? [],
    sonographer: params.sonographer ?? [],
    cardiologist: params.cardiologist ?? [],
    onStartDateFilter,
    onEndDateFilter,
    onReportTemplateTypeFilter,
    onReferringPhysicianFilter,
    onSonographerFilter,
    onCardiologistFilter,
  };
};
