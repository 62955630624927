import { Checkbox, Text } from '@chakra-ui/react';
import { createColumnHelper, DataTable } from '@piccolohealth/ui';
import { Role } from '@piccolohealth/echo-common';
import _ from 'lodash';
import React from 'react';
import { useAppContext } from '../../../hooks/useAppContext';
import { HookedCheckboxGroup } from '../../forms/hookform/HookedCheckboxGroup';
import { UserRoleDetailsPopover } from './UserRoleDetailsPopover';

interface Props {
  name: string;
}

export const UserRolesSelectionTable = (props: Props) => {
  const { organization } = useAppContext();

  const sortedRoles = React.useMemo(
    () => _.sortBy(organization.roles, (role) => role.name),
    [organization],
  );

  const columns = React.useMemo(() => {
    const columnHelper = createColumnHelper<Role>();

    return [
      columnHelper.display({
        header: 'Name',
        cell: (ps) => (
          <Checkbox
            size="sm"
            colorScheme="purple"
            key={ps.row.original.id}
            value={ps.row.original.id}
            data-pw={`${ps.row.original.name}-checkbox`}
          >
            {ps.row.original.name}
          </Checkbox>
        ),
        size: 60,
      }),
      columnHelper.display({
        header: 'Description',
        cell: (ps) => (
          <Text fontSize="md" key="description">
            {ps.row.original.description}
          </Text>
        ),
      }),
      columnHelper.display({
        header: 'Details',
        cell: (ps) => <UserRoleDetailsPopover role={ps.row.original} />,
        size: 40,
        minSize: 40,
      }),
    ];
  }, []);

  return (
    <HookedCheckboxGroup name={props.name}>
      <DataTable columns={columns} data={sortedRoles} data-pw="usersRolesSelectionTable" />
    </HookedCheckboxGroup>
  );
};
