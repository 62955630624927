import { Tag, TagLabel, TagProps, TagRightIcon, Tooltip, Wrap, WrapItem } from '@chakra-ui/react';
import { inflection, P } from '@piccolohealth/util';
import { Distribution, DistributionStatus } from '@piccolohealth/echo-common';
import React from 'react';
import { FaCheck, FaClock, FaSpinner, FaTimesCircle } from 'react-icons/fa';
import { DistributionTooltip } from './DistributionTooltip';

interface Props extends TagProps {
  distributions: Distribution[];
}

export const ReportDistributionsStatus = (props: Props) => {
  const { distributions, ...rest } = props;

  const { failed, pending, processing, sent } = React.useMemo(() => {
    const failed = distributions.filter(
      (distribution) => distribution.status === DistributionStatus.Failed,
    );

    const pending = distributions.filter(
      (distribution) => distribution.status === DistributionStatus.Pending,
    );

    const processing = distributions.filter(
      (distribution) => distribution.status === DistributionStatus.Processing,
    );

    const sent = distributions.filter(
      (distribution) => distribution.status === DistributionStatus.Sent,
    );

    return {
      failed,
      pending,
      processing,
      sent,
    };
  }, [distributions]);

  if (P.isEmpty(distributions)) {
    return (
      <Tag colorScheme="gray" size="md" {...rest}>
        No distributions
      </Tag>
    );
  }

  return (
    <Wrap>
      {!P.isEmpty(failed) && (
        <WrapItem>
          <Tooltip label={<DistributionTooltip distributions={distributions} />} maxW="xl">
            <Tag colorScheme="red" {...rest}>
              <TagLabel>
                {failed.length} {inflection.inflect('distribution', failed.length)} failed
              </TagLabel>
              <TagRightIcon as={FaTimesCircle} fontSize="2xs" />
            </Tag>
          </Tooltip>
        </WrapItem>
      )}
      {!P.isEmpty(pending) && (
        <WrapItem>
          <Tooltip label={<DistributionTooltip distributions={distributions} />} maxW="xl">
            <Tag colorScheme="yellow" {...rest}>
              <TagLabel>
                {pending.length} {inflection.inflect('distribution', pending.length)} pending
              </TagLabel>
              <TagRightIcon as={FaClock} fontSize="2xs" />
            </Tag>
          </Tooltip>
        </WrapItem>
      )}
      {!P.isEmpty(processing) && (
        <WrapItem>
          <Tooltip label={<DistributionTooltip distributions={distributions} />} maxW="xl">
            <Tag colorScheme="blue" {...rest}>
              <TagLabel>
                {processing.length} {inflection.inflect('distribution', processing.length)}{' '}
                processing
              </TagLabel>
              <TagRightIcon as={FaSpinner} fontSize="2xs" />
            </Tag>
          </Tooltip>
        </WrapItem>
      )}
      {!P.isEmpty(sent) && (
        <WrapItem>
          <Tooltip label={<DistributionTooltip distributions={distributions} />} maxW="xl">
            <Tag colorScheme="green" {...rest}>
              <TagLabel>
                {sent.length} {inflection.inflect('distribution', sent.length)} sent
              </TagLabel>
              <TagRightIcon as={FaCheck} fontSize="2xs" />
            </Tag>
          </Tooltip>
        </WrapItem>
      )}
    </Wrap>
  );
};
