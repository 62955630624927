import { useEditor } from '@craftjs/core';
import React from 'react';
import { JsonTree } from '../../generic/JsonTree';

export const EditorDebug = () => {
  const { json } = useEditor((_state, query) => {
    return {
      json: JSON.parse(query.serialize()),
    };
  });

  return <JsonTree src={json} collapsed={1} />;
};
