import { Tag } from '@chakra-ui/react';
import { DistributionTrigger } from '@piccolohealth/echo-common';
import React from 'react';

interface Props {
  trigger: DistributionTrigger;
}

export const DistributionTriggerTag = (props: Props) => {
  const { trigger } = props;

  switch (trigger) {
    case DistributionTrigger.Manual:
      return <Tag colorScheme="yellow">Send later</Tag>;
    case DistributionTrigger.Immediate:
      return <Tag colorScheme="blue">Send now</Tag>;
    case DistributionTrigger.OnFinalize:
      return <Tag colorScheme="green">Send on finalize</Tag>;
  }
};
