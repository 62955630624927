import {
  Box,
  Button,
  Center,
  Divider,
  Heading,
  HStack,
  Icon,
  Image,
  List,
  ListIcon,
  ListItem,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Spin } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import {
  ASSESSMENT_SEVERE_AORTIC_STENOSIS_REQUIRED_MEASUREMENTS,
  getMeasurementIssues,
  PERMISSIONS,
  ReportTemplate,
  ReportVariable,
} from '@piccolohealth/echo-common';
import React from 'react';
import { useWatch } from 'react-hook-form';
import {
  FaArrowsAltH,
  FaCheckCircle,
  FaCrosshairs,
  FaExternalLinkAlt,
  FaTimes,
} from 'react-icons/fa';
import { Error } from '../../components/generic/Error';
import { showModal } from '../../components/generic/Modal';
import { useReport } from '../../context/ReportContext';
import { useEchoIQIntegrationAssessmentQuery } from '../../graphql/hooks/useIntegrationsQuery';
import { useAppContext } from '../../hooks/useAppContext';
import { EchoIQAnalyzeConfirmModal } from './EchoIQAnalyzeConfirmModal';
import { EchoIQVariableIssuesTooltip } from './EchoIQVariableIssuesTooltip';
import PiccoloLogo from '/fire-solid.png';
import EchoIQLogo from '/logos/echoiq.logo.png';
import { usePermission } from '../../hooks/usePermission';

export const getMeasurementStatus = (
  reportTemplate: ReportTemplate,
  variables: Record<string, ReportVariable>,
  measurement: string,
) => {
  const name = reportTemplate?.variables.find((variable) => variable.alias === measurement)?.label;
  const measurementIssues = getMeasurementIssues(measurement, Object.values(variables));
  const color = P.isEmpty(measurementIssues.issues) ? 'green.500' : 'red.500';
  const icon = P.isEmpty(measurementIssues.issues) ? FaCheckCircle : FaTimes;

  return { name, color, icon, issues: measurementIssues.issues };
};

interface Props {
  reportId: string;
}

export const EchoIQAssessment = (props: Props) => {
  const { reportId } = props;

  const { organization } = useAppContext();
  const { reportTemplate } = useReport();
  const variables = useWatch({ name: 'variables' }) as Record<string, ReportVariable>;

  const hasEchoIqCreatePermission = usePermission(PERMISSIONS.echoIqCreate).value;

  const { data, isLoading, error } = useEchoIQIntegrationAssessmentQuery(
    {
      organizationId: organization.id,
      request: {
        reportId,
      },
    },
    // Refetch echoIQ results periodically, as they happen async on server since they can take a while
    {
      refetchInterval: (data) =>
        P.isNil(data?.organization?.integrations.echoIQ.assessment) ? 30000 : false,
    },
  );

  const assessment = data?.organization?.integrations.echoIQ.assessment;

  if (isLoading) {
    return <Spin />;
  }

  if (error) {
    return <Error error={error} />;
  }

  if (!assessment) {
    const variableStatuses = ASSESSMENT_SEVERE_AORTIC_STENOSIS_REQUIRED_MEASUREMENTS.map(
      (measurement) => getMeasurementStatus(reportTemplate, variables, measurement),
    );

    const isDisabled =
      variableStatuses.some((status) => !P.isEmpty(status.issues)) || !hasEchoIqCreatePermission;

    return (
      <Center h="full">
        <Stack align="center" layerStyle="bordered" px={6} py={8} w="3xl" spacing={6}>
          <HStack color="gray.600" align="center">
            <Box mb={1}>
              <Image h={12} src={PiccoloLogo} />
            </Box>
            <Icon as={FaArrowsAltH} />
            <Box>
              <Image ml={1} h={9} src={EchoIQLogo} />
            </Box>
          </HStack>
          <Stack textAlign="center" align="center">
            <Heading size="md" fontWeight="bold">
              Analyze study with Echo IQ EchoSolv™
            </Heading>
          </Stack>

          <Divider />

          <Stack align="start" w="full" spacing="6">
            <Stack spacing={0}>
              <Text fontSize="2xl" fontWeight="bold">
                Echo IQ EchoSolv™
              </Text>

              <Text color="secondary" lineHeight={1.4}>
                Echo IQ&apos;s EchoSolv™ is a cloud-based AI platform that provides automated
                analysis of echocardiograms to help clinicians identify and track cardiovascular
                disease.
              </Text>
            </Stack>

            <List spacing={1} fontSize="sm">
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Identify patients within ASE and ESC guidelines for: Severe Aortic Stenosis and
                Mitral Regurgitation.
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Clinically proven to identify up to 72% more patients in guidelines for Severe
                Aortic Stenosis
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Access to the full EchoSolv™ finding report in Piccolo.
              </ListItem>
            </List>

            <Divider />

            <Stack spacing={1}>
              <Text fontSize="xs">
                EchoSolv™ is not meant to substitute clinical evaluation for patients; instead, it
                serves as decision support tool that provides access to ASE and ESC clinical
                guideline checks as well as AI detection models for various cardiovascular diseases
              </Text>
              <Text fontSize="xs">
                Piccolo Health is not associated with Echo IQ, and its results are entirely its own.
                Any diagnostic or treatment decisions should be made by a qualified healthcare
                professional.
              </Text>
            </Stack>
          </Stack>

          <HStack w="full">
            <Button
              as="a"
              href="https://www.echoiq.ai/physicians"
              target="_blank"
              rel="noopener noreferrer"
              variant="outline"
              leftIcon={<FaExternalLinkAlt />}
            >
              Find out more
            </Button>
            <Spacer />

            <EchoIQVariableIssuesTooltip
              variableStatuses={variableStatuses}
              fontSize="sm"
              isDisabled={!isDisabled}
            >
              <Button
                colorScheme="purple"
                leftIcon={<FaCrosshairs />}
                isDisabled={isDisabled}
                onClick={() =>
                  showModal(EchoIQAnalyzeConfirmModal, {
                    reportId,
                    integrationSettings: data.organization?.integrations.echoIQ.settings,
                  })
                }
                loadingText="Analyzing..."
              >
                Analyze study
              </Button>
            </EchoIQVariableIssuesTooltip>
          </HStack>
        </Stack>
      </Center>
    );
  }

  return (
    <Box h="full">
      <iframe src={`data:application/pdf;base64,${assessment.pdf}`} height="100%" width="100%" />
    </Box>
  );
};
