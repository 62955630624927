import { MenuItem, Text } from '@chakra-ui/react';
import { MeasurementMapping } from '@piccolohealth/echo-common';
import React from 'react';
import { FaRegTrashAlt } from 'react-icons/fa';
import { showModal } from '../../../components/generic/Modal';
import { MeasurementMappingDeleteModal } from './MeasurementMappingDeleteModal';

interface Props {
  measurementMapping: MeasurementMapping;
}

export const MeasurementMappingDeleteMenuItem = (props: Props) => {
  const { measurementMapping } = props;

  return (
    <MenuItem
      onClick={() => showModal(MeasurementMappingDeleteModal, { measurementMapping })}
      icon={<FaRegTrashAlt />}
      data-pw="measurementMappingDeleteMenuItem"
    >
      <Text color="danger">Delete</Text>
    </MenuItem>
  );
};
