import { Box, Checkbox, Divider, HStack, Stack, Text } from '@chakra-ui/react';
import { PropGetter } from '@chakra-ui/react-utils';
import React from 'react';

const RadioItem = () => {
  return (
    <Box
      boxSize={5}
      bg="gray.100"
      rounded="full"
      position="relative"
      _after={{
        content: "''",
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        rounded: 'full',
      }}
      _groupChecked={{
        bg: 'purple.300',
        _after: {
          bg: 'white',
          boxSize: 2,
        },
      }}
    />
  );
};

export interface SelectableItemProps {
  type: 'checkbox' | 'radio';
  label: string;
  description: string;
  extraContent?: () => React.ReactElement;
  icon?: () => React.ReactElement;
  isDisabled?: boolean;
  isChecked: boolean;
  getInputProps: PropGetter<HTMLInputElement, {}>;
  getItemProps: PropGetter<any, {}>;
}
export const SelectableItem = (props: SelectableItemProps) => {
  const {
    type,
    isChecked,
    label,
    description,
    extraContent,
    icon,
    isDisabled,
    getInputProps,
    getItemProps,
    ...rest
  } = props;

  const selectableProps = {
    bg: 'white',
    size: 'lg',
    isChecked: isChecked,
    pointerEvents: 'none',
    colorScheme: 'purple',
    disabled: isDisabled,
  } as const;

  const input = getInputProps();
  const item = getItemProps();

  return (
    <Box
      w="full"
      as="label"
      border={'2px'}
      borderColor="gray.100"
      rounded="lg"
      px={4}
      py={3}
      cursor="pointer"
      transition="0.2s"
      _hover={{ bg: 'gray.50' }}
      _checked={{
        borderColor: 'purple.300',
        bg: 'purple.50',
      }}
      _disabled={{ bg: 'gray.50', cursor: 'not-allowed' }}
      role="group"
      {...item}
      {...rest}
    >
      <input {...input} hidden />
      <HStack spacing="4">
        <Box>{type === 'checkbox' ? <Checkbox {...selectableProps} /> : <RadioItem />}</Box>
        {icon && <Box fontSize="lg">{icon()}</Box>}
        <Stack spacing={1}>
          <Text fontWeight="semibold" fontSize="sm">
            {label}
          </Text>
          <Box color="secondary" fontSize="sm">
            {description}
          </Box>
        </Stack>
      </HStack>
      {extraContent && (
        <Box>
          <Divider my={2} />
          {extraContent()}
        </Box>
      )}
    </Box>
  );
};
