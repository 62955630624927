import { Box, Stack } from '@chakra-ui/react';
import { Spin } from '@piccolohealth/ui';
import {
  DistributionTrigger,
  getAllowedDistributionTriggers,
  PiccoloError,
  Report,
} from '@piccolohealth/echo-common';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormSection } from '../../../components/forms/FormSection';
import { Error } from '../../../components/generic/Error';
import { HookedDistributionTypeChooser } from '../../../components/forms/hookform/HookedDistributionTypeChooser';
import { HookedFormItem } from '../../../components/forms/hookform/HookedFormItem';
import { HookedRadioStack } from '../../../components/forms/hookform/HookedRadioStack';
import { Divider } from '../../../components/generic/Divider';
import { useReportQuery } from '../../../graphql/hooks/useReportQuery';
import { useAppContext } from '../../../hooks/useAppContext';
import { useDistributionTypeInfo } from '../../../hooks/useDistributionTypeInfo';
import { DistributionPayloadForm } from './DistributionPayloadForm';
import { DistributionReportSummary } from './DistributionReportSummary';

interface Props {
  reportId: string;
}

export const DistributionForm = (props: Props) => {
  const { reportId } = props;
  const { organization } = useAppContext();
  const { watch } = useFormContext();
  const { type } = useDistributionTypeInfo(watch('type'));

  const { data, isLoading, error } = useReportQuery({
    organizationId: organization.id,
    reportId: reportId,
  });

  const report = data?.organization?.report as Report;

  if (isLoading) {
    return (
      <Box h="full">
        <Spin />
      </Box>
    );
  } else if (error) {
    return <Error error={error} />;
  } else if (!report) {
    return (
      <Error
        error={
          new PiccoloError({
            type: 'ReportNotFound',
            message: 'Report not found',
          })
        }
      />
    );
  } else {
    return (
      <Stack spacing={6}>
        <FormSection heading="Report details">
          <DistributionReportSummary report={report} />
        </FormSection>
        <Divider />
        <FormSection heading="Distribution details">
          <HookedFormItem
            name="type"
            label="Distribution type"
            helperText="Choose what sort of distribution to create"
          >
            <HookedDistributionTypeChooser name="type" data-pw="distributionModalTypeChooser" />
          </HookedFormItem>

          <DistributionPayloadForm type={type} reportId={reportId} />
          <HookedFormItem
            name="trigger"
            label="Distribution trigger"
            helperText="When should this distribution be triggered?"
            data-pw="distributionModalTriggerRadioStack"
          >
            <HookedRadioStack
              name="trigger"
              options={[
                {
                  value: DistributionTrigger.OnFinalize,
                  label: 'Send on finalize',
                  description: 'Send the distribution when the report is finalized',
                  isDisabled: !getAllowedDistributionTriggers(type, report.status).includes(
                    DistributionTrigger.OnFinalize,
                  ),
                },
                {
                  value: DistributionTrigger.Manual,
                  label: 'Send later',
                  description: 'Send the distribution at a later time',
                  isDisabled: !getAllowedDistributionTriggers(type, report.status).includes(
                    DistributionTrigger.Manual,
                  ),
                },
                {
                  value: DistributionTrigger.Immediate,
                  label: 'Send now',
                  description: 'Send the distribution immediately',
                  isDisabled: !getAllowedDistributionTriggers(type, report.status).includes(
                    DistributionTrigger.Immediate,
                  ),
                },
              ]}
            />
          </HookedFormItem>
        </FormSection>
      </Stack>
    );
  }
};
