import { RangeDatepicker } from '@piccolohealth/ui';
import React from 'react';
import { AuditTrailFilter } from '../../../hooks/useAuditTrailFilter';
import { FormItem } from '../../forms/FormItem';

interface Props {
  filter: AuditTrailFilter;
}

export const AuditTrailDateFilterControl = ({ filter }: Props) => {
  const { startDateFilter, endDateFilter, onStartDateFilter, onEndDateFilter } = filter;

  return (
    <FormItem id="timestamp" label="Timestamp">
      <RangeDatepicker
        startDate={startDateFilter}
        endDate={endDateFilter}
        onStartDateChange={(date) => onStartDateFilter(date ?? undefined)}
        onEndDateChange={(date) => onEndDateFilter(date ?? undefined)}
      />
    </FormItem>
  );
};
