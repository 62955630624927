import { Flex } from '@chakra-ui/react';
import { Page } from '@piccolohealth/echo-common';
import React from 'react';
import { BaseNodeProps } from '../../../../utils/craftjs';
import { useSSRNode } from '../../hooks/useSSRNode';

export const Pages = (props: React.PropsWithChildren<BaseNodeProps>) => {
  const { children } = props;

  const {
    connectors: { connect, drag },
  } = useSSRNode(props);

  return (
    <Flex
      ref={(ref) => connect(drag(ref))}
      flexDir="column"
      alignItems="center"
      gridRowGap={8}
      w={Page.clientWidth}
    >
      {children}
    </Flex>
  );
};

Pages.defaultProps = {};

Pages.craft = {
  name: 'Pages',
  props: Pages.defaultProps,
};
