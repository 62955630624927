import React from 'react';
import { Content } from '../../../components/generic/Content';
import { MeasurementMappingsAndVariants } from './MeasurementMappingsAndVariants';

export const Dicom = () => {
  return (
    <Content title="DICOM">
      <MeasurementMappingsAndVariants />
    </Content>
  );
};
