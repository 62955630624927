import { Icon, Tooltip, Box } from '@chakra-ui/react';
import React from 'react';
import { FaInfoCircle } from 'react-icons/fa';

export const ChartAttachmentTooltip = () => {
  return (
    <Tooltip label="There was not enough history or the provided data was not able to be graphed">
      <Box>
        <Icon as={FaInfoCircle} />
      </Box>
    </Tooltip>
  );
};
