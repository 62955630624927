import { Input, SimpleGrid } from '@chakra-ui/react';
import { useNode } from '@craftjs/core';
import React from 'react';
import { FormItem } from '../../../forms/FormItem';

export interface NodeSpacingProps {
  padding?: string;
  margin?: string;
  gap?: string | number;
}

export const NodeSpacingSettings = () => {
  const {
    actions: { setProp },
    padding,
    margin,
    gap,
  } = useNode<NodeSpacingProps>((node) => ({
    padding: node.data.props.padding,
    margin: node.data.props.margin,
    gap: node.data.props.gap,
  }));

  return (
    <SimpleGrid columns={2} spacing={4}>
      <FormItem id="padding" label="Padding">
        <Input
          defaultValue={padding}
          onChange={(e) => setProp((props: NodeSpacingProps) => (props.padding = e.target.value))}
          size="sm"
        />
      </FormItem>
      <FormItem id="margin" label="Margin">
        <Input
          defaultValue={margin}
          onChange={(e) => setProp((props: NodeSpacingProps) => (props.margin = e.target.value))}
          size="sm"
        />
      </FormItem>
      <FormItem id="gap" label="Gap">
        <Input
          defaultValue={gap}
          onChange={(e) => setProp((props: NodeSpacingProps) => (props.gap = e.target.value))}
          size="sm"
        />
      </FormItem>
    </SimpleGrid>
  );
};

NodeSpacingSettings.defaultProps = {
  padding: undefined,
  margin: undefined,
  gap: undefined,
};
