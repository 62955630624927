import gql from 'graphql-tag';

export const DeleteReportTemplateVariables = gql`
  mutation DeleteReportTemplateVariables(
    $organizationId: ID!
    $reportTemplateId: ID!
    $reportTemplateVariableIds: [ID!]!
  ) {
    deleteReportTemplateVariables(
      organizationId: $organizationId
      reportTemplateId: $reportTemplateId
      reportTemplateVariableIds: $reportTemplateVariableIds
    ) {
      id
      versionId
      name
      statementSites {
        id
        name
        statements {
          id
          value
          default
        }
      }
      variables {
        __typename
        ... on ReportTemplateStaticVariable {
          id
          alias
          label
          site
          category
          units
          precision
          controlType
          defaultValue
        }
        ... on ReportTemplateChoiceVariable {
          id
          alias
          label
          site
          category
          choices {
            id
            label
            value
            default
          }
          controlType
          defaultValue
        }
        ... on ReportTemplateWallMotionVariable {
          id
          alias
          label
          site
          category
          variant
        }
        ... on ReportTemplateMediaAttachmentVariable {
          id
          alias
          source
          label
          category
        }
      }
      reportNodes
      isLatest
      status
      labelColor
      createdAt
      updatedAt
    }
  }
`;
