import { defineStyle } from '@chakra-ui/react';

const defaultProps = {};

const baseStyle = defineStyle((props) => {
  const { colorScheme } = props;

  return {
    container: {
      color: `${colorScheme}.600`,
      bg: `${colorScheme}.50`,
      borderWidth: '1px',
      borderColor: `${colorScheme}.300`,
      px: 4,
      py: 4,
      rounded: 'xl',
    },
    title: {
      fontWeight: 'bold',
      lineHeight: '6',
      marginEnd: '2',
      color: `${colorScheme}.600`,
    },
    description: {
      lineHeight: '6',
      color: `${colorScheme}.600`,
    },
    icon: {
      color: `${colorScheme}.400`,
      flexShrink: 0,
      marginEnd: '3',
      w: '5',
      h: '6',
    },
    spinner: {
      color: `${colorScheme}.400`,
      flexShrink: 0,
      marginEnd: '3',
      w: '5',
      h: '5',
    },
  };
});

export const Alert = {
  baseStyle,
  defaultProps,
};
