import { HStack, Select } from '@chakra-ui/react';
import { View } from '@piccolohealth/echo-common';
import { RadioGroup } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { ControlTooltip } from './ControlTooltip';

export enum ImageViewerModeType {
  Normal = 'Normal',
  Stress = 'Stress',
}

export interface NormalMode {
  type: ImageViewerModeType.Normal;
}

export interface StressMode {
  type: ImageViewerModeType.Stress;
  view: View;
}

export type ImageViewerMode = NormalMode | StressMode;

interface Props {
  currentMode: ImageViewerMode;
  hasStressInstances: boolean;
  onChangeMode: (mode: ImageViewerMode) => void;
}

export const ModeControl = (props: Props) => {
  const { currentMode, hasStressInstances, onChangeMode } = props;

  const onModeChange = (type: ImageViewerModeType) => {
    onChangeMode({ type, view: View.Plax });
  };

  const onViewChange = (view: View) => {
    onChangeMode({ type: ImageViewerModeType.Stress, view } as StressMode);
  };

  const viewControl =
    currentMode.type === ImageViewerModeType.Stress ? (
      <Select
        size="xs"
        borderRadius="md"
        fontSize="xs"
        value={currentMode.view}
        onChange={(e) => onViewChange(e.target.value as View)}
        bg="gray.100"
        color="gray.900"
      >
        <optgroup label="Parasternal">
          <option value={View.Plax}>PLAX</option>
          <option value={View.PsaxBasal}>PSAX Basal</option>
          <option value={View.PsaxMid}>PSAX Mid</option>
          <option value={View.PsaxApical}>PSAX Apical</option>
        </optgroup>
        <optgroup label="Apical">
          <option value={View.Ap4}>AP4</option>
          <option value={View.Ap3}>AP3</option>
          <option value={View.Ap2}>AP2</option>
        </optgroup>
      </Select>
    ) : null;

  const [changeModeMessage, isDisabled] = P.run(() => {
    switch (hasStressInstances) {
      case true:
        return ['Toggle between stress and normal mode', false];
      case false:
        return ['No stress images detected', true];
    }
  });

  return (
    <HStack>
      <ControlTooltip label={changeModeMessage} aria-label={changeModeMessage}>
        <RadioGroup
          color="gray.900"
          size="xs"
          value={currentMode.type}
          onChange={(m) => onModeChange(m as ImageViewerModeType)}
          options={[
            { label: 'Normal', value: ImageViewerModeType.Normal },
            { label: 'Stress', value: ImageViewerModeType.Stress },
          ]}
          isDisabled={isDisabled}
        />
      </ControlTooltip>
      {viewControl && (
        <ControlTooltip label="Change image view" aria-label="Change image view">
          {viewControl}
        </ControlTooltip>
      )}
    </HStack>
  );
};
