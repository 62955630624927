import { ChakraV3 } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';

interface Props extends ChakraV3.ErrorProps {
  type: 'Unauthorized' | 'Forbidden' | 'NotFound';
}

export const PageError = (props: Props) => {
  const { type, ...rest } = props;

  const { title, description } = P.run(() => {
    switch (type) {
      case 'Unauthorized':
      case 'Forbidden':
        return {
          title: 'Unauthorized',
          description: 'Sorry, you are not authorized to access this page',
        };
      case 'NotFound':
        return {
          title: 'Not Found',
          description: 'Sorry, we could not find the page you were looking for',
        };
    }
  });

  return <ChakraV3.Error title={title} description={description} {...rest} />;
};
