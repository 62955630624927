import { Button, ButtonProps, Icon } from '@chakra-ui/react';
import { PERMISSIONS } from '@piccolohealth/echo-common';
import React from 'react';
import { FaPlus } from 'react-icons/fa';
import { ReportCreateModal } from '../../features/upload/ReportCreateModal';
import { usePermission } from '../../hooks/usePermission';
import { showModal } from '../generic/Modal';

export const ReportCreateButton = (props: ButtonProps) => {
  const hasPermission = usePermission(PERMISSIONS.reportsCreate).value;

  return (
    <>
      <Button
        onClick={() => showModal(ReportCreateModal)}
        colorScheme="purple"
        size="sm"
        leftIcon={<Icon as={FaPlus} />}
        isDisabled={!hasPermission}
        {...props}
        data-pw="reportCreateModalButton"
      >
        Create or Upload
      </Button>
    </>
  );
};
