import { Box, Button, ButtonProps, Flex, Icon, Text } from '@chakra-ui/react';
import React from 'react';
import { IconType } from 'react-icons';

interface Props extends ButtonProps {
  heading: string;
  subHeading: string;
  icon: IconType;
  iconSize?: number;
  onClick: () => void;
}

export const AttachmentButton = (props: Props) => {
  const { onClick, heading, subHeading, icon, height, width, iconSize, ...rest } = props;

  return (
    <Button
      display="flex"
      h="100%"
      w="100%"
      position="relative"
      flexDir="column"
      paddingInline={0}
      borderRadius="lg"
      borderWidth="3px"
      borderStyle="dashed"
      color="gray.400"
      bg="white"
      onClick={onClick}
      _hover={{
        bg: 'white',
        borderColor: 'gray.400',
        color: 'gray.500',
      }}
      _active={{
        bg: 'white',
      }}
      _focus={{
        bg: 'white',
      }}
      {...rest}
    >
      <Box w={width} h={height} p={4}>
        <Flex h="full" w="full" justify="center" align="center" flexDir="column">
          <Icon as={icon} fontSize={iconSize ?? 48} />
          <Text fontSize="2xl" fontWeight="bold">
            {heading}
          </Text>
          <Text fontSize="sm" fontWeight="bold" mt={1}>
            {subHeading}
          </Text>
        </Flex>
      </Box>
    </Button>
  );
};
