import { Text } from '@chakra-ui/react';
import {
  createColumnHelper,
  DataTable,
  FancyDate,
  PaginationOptions,
  RowSelectionOptions,
} from '@piccolohealth/ui';
import { DateTime } from '@piccolohealth/util';
import {
  getReportVariableValueAsString,
  PiccoloError,
  renderNullable,
  Report,
  ReportShare,
} from '@piccolohealth/echo-common';
import React from 'react';
import { useAppContext } from '../../../hooks/useAppContext';
import { ReportActionMenu } from '../../controls/ReportActionMenu';
import { ReportStatusTag } from '../../generic/ReportStatusTag';
import { ReportTypeTag } from '../../generic/ReportTypeTag';
import { TextLink } from '../../generic/TextLink';
import { ReportShareExpiryTag } from '../custom/ReportShareExpiryTag';

interface Props {
  isLoading: boolean;
  error: PiccoloError | undefined | null;
  reportShares: NonExpiredReportShare[];
  refetch: () => Promise<void>;
  pagination?: PaginationOptions;
  rowSelection: RowSelectionOptions<NonExpiredReportShare>;
}

export interface NonExpiredReportShare extends Omit<ReportShare, 'report'> {
  report: Required<Report>;
}

export const SharedReportsTable = (props: Props) => {
  const { isLoading, error, pagination, reportShares, rowSelection } = props;

  const { organization } = useAppContext();

  const columns = React.useMemo(() => {
    const columnHelper = createColumnHelper<NonExpiredReportShare>();

    return [
      columnHelper.display({
        id: 'name',
        header: 'Patient name',
        cell: (props) => {
          const share = props.row.original;
          const patientName = getReportVariableValueAsString(share.report.variables, 'patientName');

          return (
            <TextLink
              fontWeight="bold"
              color="purple.600"
              to={`/organizations/${organization.id}/reports/${share.report.id}/shared`}
              data-pw={`reportTablePatientNameLink-${share.report.id}`}
            >
              {renderNullable(patientName)}
            </TextLink>
          );
        },
      }),
      columnHelper.display({
        header: 'Report type',
        cell: (props) => (
          <ReportTypeTag
            name={props.row.original.report.reportTemplate.name}
            labelColor={props.row.original.report.reportTemplate.labelColor}
          />
        ),
        minSize: 200,
      }),
      columnHelper.display({
        header: 'Date',
        cell: (props) => (
          <FancyDate
            date={DateTime.fromISO(props.row.original.report.createdAt as unknown as string)}
          />
        ),
        minSize: 220,
      }),
      columnHelper.display({
        header: 'Status',
        cell: (props) => <ReportStatusTag status={props.row.original.report.status} />,
        minSize: 160,
      }),
      columnHelper.display({
        header: 'Shared by',
        cell: (props) => {
          return <Text>{props.row.original.sharerEmail}</Text>;
        },
      }),
      columnHelper.display({
        header: 'Expiry',
        cell: (props) => (
          <ReportShareExpiryTag
            expiresAt={props.row.original.expiresAt ?? null}
            isExpired={props.row.original.isExpired}
          />
        ),
      }),
      columnHelper.display({
        header: 'Actions',
        cell: (props) => {
          return (
            <ReportActionMenu
              reportId={props.row.original.report.id}
              reportStatus={props.row.original.report.status}
              onDelete={() => Promise.resolve()}
            />
          );
        },
        minSize: 150,
      }),
    ];
  }, [organization]);

  return (
    <DataTable
      columns={columns}
      data={reportShares}
      isLoading={isLoading}
      error={error}
      pagination={pagination}
      rowSelection={rowSelection}
      size="sm"
    />
  );
};
