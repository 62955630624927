import gql from 'graphql-tag';

export const InviteUser = gql`
  mutation InviteUser($organizationId: ID!, $inviteUserRequest: InviteUserRequest!) {
    inviteUser(organizationId: $organizationId, request: $inviteUserRequest) {
      id
      name
      email
      picture
      organizationMemberships {
        roles {
          id
          name
        }
      }
    }
  }
`;
