import {
  arrayOfParam,
  dateTimeParam,
  numberParam,
  stringParam,
  useDebouncedCallback,
  useQueryParams,
} from '@piccolohealth/ui';
import { DateTime } from '@piccolohealth/util';
import { AuditTrailEvent } from '@piccolohealth/echo-common';
import React from 'react';
import { TimestampPaginationFilter } from '../graphql/fetcher';

const DEFAULT_LAST_SEEN = null;
const DEFAULT_FIRST_SEEN = null;
const DEFAULT_AUDIT_TRAIL_PAGE_SIZE = 10;
const DEFAULT_START_DATE_FILTER = null;
const DEFAULT_END_DATE_FILTER = null;
const DEFAULT_MESSAGE_FILTER = '';
const DEFAULT_EVENTS_FILTER: AuditTrailEvent[] = [];
const DEFAULT_USERS_FILTER: string[] = [];

export interface AuditTrailFilter extends TimestampPaginationFilter {
  lastSeen: DateTime | null;
  setLastSeen: (value: DateTime) => void;
  firstSeen: DateTime | null;
  setFirstSeen: (value: DateTime) => void;
  pageSize: number;
  setPageSize: (n: number) => void;

  messageFilter: string;
  eventsFilter: AuditTrailEvent[];
  usersFilter: string[];
  startDateFilter: DateTime | null;
  endDateFilter: DateTime | null;

  onMessageFilter: (name: string) => void;
  onEventsFilter: (events: AuditTrailEvent[]) => void;
  onUsersFilter: (users: string[]) => void;
  onStartDateFilter: (start?: DateTime) => void;
  onEndDateFilter: (end?: DateTime) => void;
}

export const useAuditTrailFilter: () => AuditTrailFilter = () => {
  const [params, setParams] = useQueryParams({
    lastSeen: dateTimeParam,
    firstSeen: dateTimeParam,
    pageSize: numberParam,
    start: dateTimeParam,
    end: dateTimeParam,
    message: stringParam,
    events: arrayOfParam(stringParam, { delimiter: ',' }),
    users: arrayOfParam(stringParam),
  });

  const setLastSeen = React.useCallback(
    (value: DateTime) =>
      setParams({
        lastSeen: value,
        firstSeen: undefined,
      }),
    [setParams],
  );

  const setFirstSeen = React.useCallback(
    (value: DateTime) => {
      setParams({
        firstSeen: value,
        lastSeen: undefined,
      });
    },
    [setParams],
  );

  const setPageSize = React.useCallback(
    (value: number) => {
      setParams({
        pageSize: value,
        lastSeen: undefined,
        firstSeen: undefined,
      });
    },
    [setParams],
  );

  const onMessageFilter = useDebouncedCallback(
    (value: string) =>
      setParams({
        message: value,
        lastSeen: undefined,
        firstSeen: undefined,
      }),
    1000,
  );

  const onEventsFilter = React.useCallback(
    (value: AuditTrailEvent[]) => {
      setParams({
        events: value,
        lastSeen: undefined,
        firstSeen: undefined,
      });
    },
    [setParams],
  );

  const onUsersFilter = React.useCallback(
    (value: string[]) => {
      setParams({
        users: value,
        lastSeen: undefined,
        firstSeen: undefined,
      });
    },
    [setParams],
  );

  const onStartDateFilter = React.useCallback(
    (value?: DateTime) => {
      setParams({
        start: value,
        lastSeen: undefined,
      });
    },
    [setParams],
  );

  const onEndDateFilter = React.useCallback(
    (value?: DateTime) => {
      setParams({
        end: value,
        lastSeen: undefined,
      });
    },
    [setParams],
  );

  const showTotal = (total: number) => `${total} entries`;

  return {
    pageSizeOptions: [10, 20, 30],
    lastSeen: params.lastSeen ?? DEFAULT_LAST_SEEN,
    firstSeen: params.firstSeen ?? DEFAULT_FIRST_SEEN,
    pageSize: params.pageSize ?? DEFAULT_AUDIT_TRAIL_PAGE_SIZE,
    startDateFilter: params.start ?? DEFAULT_START_DATE_FILTER,
    endDateFilter: params.end ?? DEFAULT_END_DATE_FILTER,
    messageFilter: params.message ?? DEFAULT_MESSAGE_FILTER,
    usersFilter: params.users ?? DEFAULT_USERS_FILTER,
    eventsFilter: (params.events as AuditTrailEvent[]) ?? DEFAULT_EVENTS_FILTER,
    showTotal,
    setLastSeen,
    setFirstSeen,
    setPageSize,
    onMessageFilter,
    onStartDateFilter,
    onEndDateFilter,
    onEventsFilter,
    onUsersFilter,
  };
};
