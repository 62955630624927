import { Box, HStack } from '@chakra-ui/react';
import React from 'react';
import { DicomFilter } from '../hooks/useDicomFilter';
import { MachineFilterControl } from './MachineFilterControl';
import { NameFilterControl } from './NameFilterControl';
import { SiteFilterControl } from './SiteFilterControl';

interface Props {
  filter: DicomFilter;
}

export const UnusedMeasurementVariantsFilterHeader = (props: Props) => {
  const { filter } = props;

  return (
    <HStack spacing={4} w="full">
      <Box width="2xs">
        <NameFilterControl value={filter.variantNameFilter} onChange={filter.onVariantNameFilter} />
      </Box>
      <Box width="2xs">
        <SiteFilterControl value={filter.variantSiteFilter} onChange={filter.onVariantSiteFilter} />
      </Box>
      <Box width="2xs">
        <MachineFilterControl
          value={filter.variantMachineFilter}
          onChange={filter.onVariantMachineFilter}
        />
      </Box>
    </HStack>
  );
};
