import {
  MachineFilter,
  MeasurementMappingAndVariants,
  MeasurementVariant,
} from '@piccolohealth/echo-common';
import {
  arrayOfParam,
  booleanParam,
  stringParam,
  useDebouncedCallback,
  useQueryParams,
} from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';

interface DicomFilterOptions {
  variants: MeasurementVariant[];
  mappingsAndVariants: MeasurementMappingAndVariants[];
}

const DEFAULT_VARIANT_NAME_FILTER = '';
const DEFAULT_VARIANT_SITE_FILTER = '';
const DEFAULT_VARIANT_MACHINE_FILTER: MachineFilter[] = [];
const DEFAULT_MAPPING_NAME_FILTER = '';
const DEFAULT_MAPPING_SITE_FILTER = '';

export interface DicomFilter {
  variantNameFilter: string;
  onVariantNameFilter: (name: string) => void;
  variantSiteFilter: string;
  onVariantSiteFilter: (site: string) => void;
  variantMachineFilter: MachineFilter[];
  onVariantMachineFilter: (machine: MachineFilter[]) => void;

  mappingNameFilter: string;
  onMappingNameFilter: (name: string) => void;
  mappingSiteFilter: string;
  onMappingSiteFilter: (site: string) => void;

  filteredVariants: MeasurementVariant[];
  filteredMappings: MeasurementMappingAndVariants[];

  isExpanded: boolean;
  onExpand: () => void;
}

export const useDicomFilter = (options: DicomFilterOptions) => {
  const { variants, mappingsAndVariants } = options;

  const [params, setParams] = useQueryParams({
    variantName: stringParam,
    variantSite: stringParam,
    variantMachine: arrayOfParam<MachineFilter>(
      {
        encode: JSON.stringify,
        decode: JSON.parse,
      },
      { delimiter: '~' },
    ),
    mappingName: stringParam,
    mappingSite: stringParam,
    isExpanded: booleanParam,
  });

  const onVariantNameFilter = useDebouncedCallback(
    (value: string) =>
      setParams({
        variantName: value,
      }),
    500,
  );

  const onVariantSiteFilter = useDebouncedCallback(
    (value: string) =>
      setParams({
        variantSite: value,
      }),
    500,
  );

  const onVariantMachineFilter = useDebouncedCallback(
    (value: MachineFilter[]) =>
      setParams({
        variantMachine: value,
      }),
    500,
  );

  const onMappingNameFilter = useDebouncedCallback(
    (value: string) =>
      setParams({
        mappingName: value,
      }),
    500,
  );

  const onMappingSiteFilter = useDebouncedCallback(
    (value: string) =>
      setParams({
        mappingSite: value,
      }),
    500,
  );

  const onExpand = () => {
    setParams({ isExpanded: !params.isExpanded });
  };

  const filteredVariants = variants.filter((variant) => {
    const nameFiltered = params.variantName
      ? new RegExp(params.variantName, 'i').test(variant.name)
      : true;
    const siteFiltered =
      params.variantSite && variant.site
        ? new RegExp(params.variantSite, 'i').test(variant.site)
        : true;
    const machineFiltered = params.variantMachine
      ? params.variantMachine.some((machine) => P.isEqual(machine, variant.machine))
      : true;

    return nameFiltered && siteFiltered && machineFiltered;
  });

  const filteredMappings = mappingsAndVariants.filter((mappingAndVariants) => {
    const nameFiltered = params.mappingName
      ? new RegExp(params.mappingName, 'i').test(mappingAndVariants.mapping.name)
      : true;
    const siteFiltered =
      params.mappingSite && mappingAndVariants.mapping.site
        ? new RegExp(params.mappingSite, 'i').test(mappingAndVariants.mapping.site)
        : true;

    return nameFiltered && siteFiltered;
  });

  return {
    variantSiteFilter: params.variantSite ?? DEFAULT_VARIANT_SITE_FILTER,
    variantNameFilter: params.variantName ?? DEFAULT_VARIANT_NAME_FILTER,
    variantMachineFilter: params.variantMachine ?? DEFAULT_VARIANT_MACHINE_FILTER,
    onVariantSiteFilter,
    onVariantNameFilter,
    onVariantMachineFilter,
    mappingNameFilter: params.mappingName ?? DEFAULT_MAPPING_NAME_FILTER,
    mappingSiteFilter: params.mappingSite ?? DEFAULT_MAPPING_SITE_FILTER,
    onMappingNameFilter,
    onMappingSiteFilter,
    filteredVariants,
    filteredMappings,
    isExpanded: params.isExpanded ?? false,
    onExpand,
  };
};
