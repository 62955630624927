import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';
import { IconType } from 'react-icons';

export interface IconButtonProps extends IconProps {
  icon: IconType;
}

export const IconButton = (props: IconButtonProps) => {
  const { icon, ...rest } = props;

  return (
    <Icon
      as={icon}
      fontSize="xs"
      color="gray.600"
      cursor="pointer"
      _hover={{ opacity: 0.8 }}
      {...rest}
    />
  );
};
