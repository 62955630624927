import { Button, Collapse, HStack, Spacer, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { DateTime } from '@piccolohealth/util';
import { WorklistEntry, WorklistEntryStatus } from '@piccolohealth/echo-common';
import React from 'react';
import { FaPlus } from 'react-icons/fa';
import { Content } from '../../components/generic/Content';
import { showModal } from '../../components/generic/Modal';
import { NumberedBadge } from '../../components/generic/NumberedBadge';
import { useWorklistEntriesQuery } from '../../graphql/hooks/useWorklistEntriesQuery';
import { useAppContext } from '../../hooks/useAppContext';
import { WorklistEntryAddModal } from './components/WorklistEntryAddModal';
import { WorklistEntryTable } from './components/WorklistEntryTable';

export const Worklist = () => {
  const { organization } = useAppContext();
  const completedDisclosure = useDisclosure();
  const nowMinus12Hours = React.useMemo(() => DateTime.now().minus({ hours: 12 }), []);

  const scheduledWorklistEntriesQuery = useWorklistEntriesQuery({
    organizationId: organization.id,
    request: {
      statuses: [WorklistEntryStatus.Scheduled],
      pagination: {
        limit: 20,
        offset: 0,
      },
    },
  });

  const completedWorklistEntriesQuery = useWorklistEntriesQuery({
    organizationId: organization.id,
    request: {
      statuses: [WorklistEntryStatus.Completed],
      pagination: {
        limit: 10,
        offset: 0,
      },
      start: nowMinus12Hours,
    },
  });

  const scheduledWorklistEntries =
    (scheduledWorklistEntriesQuery.data?.organization?.worklist?.entries
      .results as WorklistEntry[]) || [];

  const completedWorklistEntries =
    (completedWorklistEntriesQuery.data?.organization?.worklist?.entries
      .results as WorklistEntry[]) || [];

  return (
    <Content title="Worklist">
      <Stack spacing={4} px={6}>
        <Stack spacing={4}>
          <HStack>
            <HStack>
              <Text fontSize="sm" fontWeight="semibold" color="secondary">
                Scheduled
              </Text>
              <NumberedBadge count={scheduledWorklistEntries.length} />
            </HStack>
            <Spacer />
            <Button
              size="sm"
              variant="solid"
              colorScheme="purple"
              leftIcon={<FaPlus />}
              onClick={() => showModal(WorklistEntryAddModal)}
            >
              Add new entry
            </Button>
          </HStack>
          <WorklistEntryTable
            worklistEntries={scheduledWorklistEntries}
            isLoading={scheduledWorklistEntriesQuery.isLoading}
            error={scheduledWorklistEntriesQuery.error}
            emptyText="No scheduled worklist entries"
          />
        </Stack>

        <Stack spacing={4}>
          <HStack>
            <HStack>
              <Button
                fontSize="sm"
                fontWeight="semibold"
                color="secondary"
                variant="link"
                {...completedDisclosure.getButtonProps()}
              >
                {completedDisclosure.isOpen ? 'Hide' : 'Show'} recently completed
              </Button>
              <NumberedBadge count={completedWorklistEntries.length} />
            </HStack>
          </HStack>
          <Collapse in={completedDisclosure.isOpen} animateOpacity>
            <WorklistEntryTable
              worklistEntries={completedWorklistEntries}
              isLoading={completedWorklistEntriesQuery.isLoading}
              error={completedWorklistEntriesQuery.error}
              emptyText="No completed worklist entries"
            />
          </Collapse>
        </Stack>
      </Stack>
    </Content>
  );
};
