import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react';
import React from 'react';
import { createModal } from '../../../../../generic/Modal';

export const AttachedMediaModal = createModal<React.PropsWithChildren<{}>>((props) => {
  const { modal, children } = props;
  const { hide, remove, visible } = modal;

  return (
    <Modal
      isOpen={visible}
      onClose={hide}
      onCloseComplete={remove}
      size="6xl"
      isCentered
      returnFocusOnClose={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody>{children}</ModalBody>
      </ModalContent>
    </Modal>
  );
});
