export const noop = async (): Promise<void> => {
  return;
};

export const map = async <A, B>(
  arr: A[],
  f: (value: A, index: number) => Promise<B>,
  options?: { concurrency: number },
): Promise<B[]> => {
  const concurrency = options?.concurrency ?? arr.length;

  if (arr.length <= concurrency) {
    return Promise.all(arr.map((item, index) => f(item, index)));
  }

  const inProgress = new Set<Promise<B>>();
  const results: Promise<B>[] = [];

  for (const [index, item] of arr.entries()) {
    if (inProgress.size >= concurrency) {
      await Promise.race(inProgress);
    }

    const itemPromise = f(item, index);
    inProgress.add(itemPromise);
    results.push(itemPromise);

    itemPromise.finally(() => inProgress.delete(itemPromise));
  }

  return Promise.all(results);
};

export const each = async <A>(
  arr: A[],
  f: (value: A, index: number) => Promise<void>,
): Promise<void> => {
  await map(arr, f, { concurrency: 1 });
};

export const flatMap = async <A, B>(
  arr: A[],
  f: (value: A, index: number) => Promise<B[]>,
  options?: { concurrency: number },
) => {
  return map(arr, f, options).then((results) => results.flat());
};

export const allFlatten = async <A>(arr: Promise<A[]>[]): Promise<A[]> => {
  const res = await Promise.all(arr);
  return res.flat();
};

export const retry = async <A>(
  times: number,
  f: () => Promise<A>,
  retryable: (err: unknown) => boolean = () => true,
): Promise<A> => {
  try {
    return await f();
  } catch (err_1) {
    if (--times < 0 || !retryable(err_1)) {
      throw err_1;
    }
    return await retry(times - 1, f, retryable);
  }
};

export const retryWithDelay = async <A>(
  fn: () => Promise<A>,
  options: { retries: number; interval: number; condition?: (result: A) => boolean },
): Promise<A> => {
  const retry = async () => {
    await delay(options.interval);
    return retryWithDelay(fn, {
      retries: options.retries - 1,
      interval: options.interval,
      condition: options.condition,
    });
  };

  try {
    const result = await fn();
    if (options.condition) {
      return options.condition(result) ? result : retry();
    }
    return result;
  } catch (err) {
    if (options.retries <= 0) {
      return Promise.reject(new Error('Too many retries'));
    }
    return retry();
  }
};

export const some = async <A>(arr: A[], f: (value: A) => Promise<boolean>): Promise<boolean> => {
  for (const item of arr) {
    const ret = await f(item);
    if (ret) {
      return ret;
    }
  }

  return false;
};

export const find = async <A>(arr: A[], f: (value: A) => Promise<boolean>): Promise<A | null> => {
  for (const item of arr) {
    const ret = await f(item);
    if (ret) {
      return item;
    }
  }

  return null;
};

export const reduce = async <T, R>(
  arr: T[],
  f: (accumulator: R, current: T, index: number, length: number) => Promise<R>,
  initial: R,
): Promise<R> => {
  if (arr.length === 0) {
    return initial;
  }

  for (const [index, value] of arr.entries()) {
    initial = await f(initial, value, index, arr.length);
  }

  return initial;
};

export const delay = (milliseconds: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), milliseconds);
  });
};

export const props = async <A extends object>(
  obj: A,
): Promise<{ [P in keyof A]: Awaited<A[P]> }> => {
  const awaiters: Promise<void>[] = [];
  const ret = {} as { [P in keyof A]: Awaited<A[P]> };

  for (const key in obj) {
    awaiters.push(
      Promise.resolve(obj[key]).then((val) => {
        ret[key] = val;
      }),
    );
  }

  await Promise.all(awaiters);
  return ret;
};

export const allSettled = <A>(promises: Promise<A>[]) => {
  return Promise.all(
    promises.map((promise) =>
      promise
        .then((value) => ({
          status: 'fulfilled',
          value,
        }))
        .catch((reason) => ({
          status: 'rejected',
          reason,
        })),
    ),
  );
};

export const pollUntil = async <A>(
  fn: () => Promise<A>,
  condition: (result: A) => boolean,
  delayMs: number,
  maxAttempts = 30,
): Promise<A> => {
  const go = async (
    fn: () => Promise<A>,
    condition: (result: A) => boolean,
    delayMs: number,
    attempts: number,
  ): Promise<A> => {
    const result = await fn();

    if (condition(result)) {
      return result;
    } else if (attempts === maxAttempts) {
      return Promise.reject(new Error('Too many attempts'));
    } else {
      await delay(delayMs);
      return go(fn, condition, delayMs, attempts + 1);
    }
  };

  return go(fn, condition, delayMs, 1);
};
