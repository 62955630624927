import React from 'react';
import { ImageViewerMode, ImageViewerModeType } from './ModeControl';
import { RadioGroup } from '@piccolohealth/ui';

export enum Sync {
  None = 'None',
  Rate = 'Rate',
}

interface Props {
  currentMode: ImageViewerMode;
  currentSync: Sync;
  onChangeSync: (sync: Sync) => void;
}

export const SyncControl = ({ currentMode, currentSync, onChangeSync }: Props) => {
  return currentMode.type === ImageViewerModeType.Stress ? (
    <RadioGroup
      onChange={(m) => onChangeSync(m as Sync)}
      value={currentSync}
      options={[
        { label: 'Synced', value: Sync.Rate },
        { label: 'Unsynced', value: Sync.None },
      ]}
      size="xs"
      color="gray.900"
    />
  ) : null;
};
