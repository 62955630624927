import { useOutsideClick } from '@chakra-ui/react';
import React from 'react';
import { dataAttr } from '../utils/dataAttr';
import { useOptionsFocus } from './useOptionsFocus';
import { SelectOption } from './useSelect';

export type AutoCompleteOption<A> = SelectOption<A>;

interface UseAutoCompleteOptions<A> {
  options: AutoCompleteOption<A>[];
  value: string;
  onChange: (value: string) => void;
  onInputChange: (value: string) => void;
  onOpen?: () => void;
}

export const useAutoComplete = <A extends unknown>(opts: UseAutoCompleteOptions<A>) => {
  const { value, options, onChange, onInputChange, onOpen } = opts;

  const [isOpen, setIsOpen] = React.useState(false);
  const { focusedOption, focusFirstOption, focusPrevOption, focusNextOption, focusOption } =
    useOptionsFocus<A>(options);
  const focusedRef = React.useRef<HTMLLIElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const menuRef = React.useRef<HTMLDivElement>(null);

  const openMenu = React.useCallback(() => {
    if (!isOpen) {
      setIsOpen(true);
      onOpen?.();
    }
  }, [isOpen, onOpen]);

  const closeMenu = React.useCallback(() => {
    setIsOpen(false);
  }, []);

  const toggleMenu = React.useCallback(() => {
    if (!isOpen) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [isOpen, setIsOpen]);

  const focusInput = React.useCallback(() => {
    inputRef.current?.focus();
  }, []);

  const selectOption = React.useCallback(
    (value: AutoCompleteOption<A>) => {
      onChange(value.value);
      closeMenu();
      focusInput();
    },
    [onChange, closeMenu, focusInput],
  );

  const selectFocusedOption = React.useCallback(() => {
    if (focusedOption) {
      selectOption(focusedOption.option);
    }
  }, [focusedOption, selectOption]);

  const menuProps = React.useCallback(() => {
    return {
      ref: menuRef,
    };
  }, []);

  const inputProps = React.useCallback(() => {
    return {
      ref: inputRef,
      role: 'group',
      tabIndex: 0,
      value,

      onMouseDown: () => {
        focusInput();
        toggleMenu();
      },

      onFocus: (e: React.FocusEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();
        openMenu();
      },

      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        onInputChange(e.target.value);
        openMenu();
      },

      onKeyDown: (e: React.KeyboardEvent) => {
        if (isOpen) {
          if (e.key === 'ArrowDown') {
            e.preventDefault();
            focusNextOption();
          }

          if (e.key === 'ArrowUp') {
            e.preventDefault();
            focusPrevOption();
          }

          if (e.key === 'Enter') {
            e.preventDefault();
            selectFocusedOption();
          }

          if (e.key === 'Escape') {
            e.preventDefault();
            closeMenu();
          }

          if (e.key === 'Tab') {
            closeMenu();
          }
        } else {
          if (e.key === 'ArrowDown' || e.key === 'ArrowUp' || e.key === 'Enter') {
            e.preventDefault();
            openMenu();
          }
        }
      },
    };
  }, [
    isOpen,
    value,
    onInputChange,
    openMenu,
    closeMenu,
    toggleMenu,
    focusInput,
    focusNextOption,
    focusPrevOption,
    selectFocusedOption,
  ]);

  const optionProps = React.useCallback(
    (option: SelectOption<A>) => {
      const isFocused = focusedOption?.option.value === option.value;

      return {
        ref: isFocused ? focusedRef : undefined,
        role: 'group',
        'data-active': dataAttr(isFocused),
        'data-label': option.label,
        'data-value': option.value,

        onMouseDown: (e: React.MouseEvent) => {
          e.preventDefault();
          e.stopPropagation();
          selectOption(option);
        },

        onMouseEnter: () => {
          focusOption(option);
        },
      };
    },
    [focusedOption, selectOption, focusOption],
  );

  React.useLayoutEffect(focusFirstOption, [focusFirstOption]);

  React.useLayoutEffect(() => {
    if (focusedOption?.keyboard) {
      focusedRef.current?.scrollIntoView({
        behavior: 'auto',
        block: 'nearest',
      });
    }
  }, [focusedOption, focusedRef]);

  useOutsideClick({
    enabled: isOpen,
    ref: menuRef,
    handler: closeMenu,
  });

  return {
    options,
    isOpen,
    value,
    focusedOption,
    selectOption,
    inputProps,
    menuProps,
    optionProps,
  };
};
