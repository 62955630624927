import { MenuItem } from '@chakra-ui/react';
import { PERMISSIONS, User } from '@piccolohealth/echo-common';
import React from 'react';
import { FaCog } from 'react-icons/fa';
import { usePermission } from '../../hooks/usePermission';
import { showModal } from '../generic/Modal';
import { UserEditModal } from '../modals/UserEditModal';

interface Props {
  user: User;
}

export const UserEditMenuItem = (props: Props) => {
  const { user } = props;
  const hasPermission = usePermission(PERMISSIONS.usersUpdate).value;

  return (
    <MenuItem
      isDisabled={!hasPermission}
      onClick={() => showModal(UserEditModal, { user })}
      icon={<FaCog />}
      data-pw={`userEditMenuItem-${user.id}`}
    >
      Edit
    </MenuItem>
  );
};
