import React from 'react';

export const useCountdown = (initialSeconds = 60) => {
  const [seconds, setSeconds] = React.useState(initialSeconds);
  const [working, setWorking] = React.useState(false);
  const timerRef = React.useRef<ReturnType<typeof setInterval> | null>(null);

  function start() {
    setSeconds(seconds || 60);
    setWorking(true);
  }

  function stop() {
    setSeconds(60);
    setWorking(false);
  }
  function pause() {
    setWorking(false);
  }

  React.useEffect(() => {
    if (working) {
      if (seconds > 0 && !timerRef.current) {
        timerRef.current = setInterval(() => {
          setSeconds((x) => x - 1);
        }, 1000);
      } else if (seconds <= 0 && timerRef.current) {
        clearInterval(timerRef.current);
        timerRef.current = null;
        setWorking(false);
      }
    } else {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
      timerRef.current = null;
      setWorking(false);
    }
  }, [seconds, working]);

  React.useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
      timerRef.current = null;
    };
  }, []);

  return {
    seconds,
    start,
    stop,
    pause,
  };
};
