import { Box, Divider, HStack, Spacer, Stack, Text } from '@chakra-ui/react';
import { DatumValue, ValueFormat } from '@nivo/core';
import {
  Line,
  LineSvgProps,
  PointSymbolProps,
  ResponsiveLine,
  Serie,
  SliceTooltip,
  SliceTooltipProps,
} from '@nivo/line';
import { Dot, FancyDate } from '@piccolohealth/ui';
import { DateTime, P } from '@piccolohealth/util';
import React from 'react';
import { chartsTheme } from '../../components/charts/ChartsTheme';
import { GREEN } from '../../components/templates/report/components/attachments/media/chart/ChartAttachmentForm';
import { CreateMediaAttachmentChartRequest } from '../../components/templates/report/components/attachments/media/MediaAttachmentZone';
import { HistoricalVariableChartData } from '../../hooks/useHistoricalVariables';

export type MeasurementSeries = Serie;

export type MeasurementChartData = {
  series: any[]; // Data from chart lib
  units: string | null;
  color: string;
};

const CustomPointSymbol = (props: PointSymbolProps) => {
  const { size, borderWidth, borderColor, color } = props;

  return (
    <g>
      <circle fill="#fff" r={size / 2} strokeWidth={borderWidth} stroke={borderColor} />
      <circle
        r={size / 5}
        strokeWidth={borderWidth}
        stroke={borderColor}
        fill={color}
        fillOpacity={0.35}
      />
    </g>
  );
};

const getSliceTooltip =
  (props: { data1: MeasurementChartData; data2?: MeasurementChartData }) =>
  ({ slice }: SliceTooltipProps) => {
    const { data1, data2 } = props;

    const formatValue = (value: string | null, units: string | null) => {
      return P.isNil(value) ? 'N/A' : `${value} ${units}`;
    };

    return (
      <Box w="sm" bg="white" p={3} borderWidth="1px" borderRadius="lg" shadow="md">
        {slice.points.map((point) => {
          const xValue = slice.points[0].data.x;
          const item1 = data1.series[0].data.find((d: any) => d.x === xValue);
          const item2 = data2?.series[0].data.find((d: any) => d.x === xValue);

          return (
            <Stack key={point.id}>
              <FancyDate
                date={DateTime.fromISO(point.data.x as string)}
                direction="row"
                fontSize="sm"
                fontWeight="semibold"
                color="secondary"
              />
              <Divider />

              <HStack fontSize="xs">
                <Dot size="3" bg={data1.color} />
                <Text fontWeight="normal">{data1.series[0].id}</Text>
                <Spacer />
                <Text flexShrink={0} fontWeight="semibold">
                  {formatValue(item1?.y?.toString() ?? null, data1.units ?? null)}
                </Text>
              </HStack>
              {data2 && (
                <HStack fontSize="xs">
                  <Dot size="3" bg={data2.color} />
                  <Text fontWeight="normal">{data2.series[0].id}</Text>
                  <Spacer />
                  <Text flexShrink={0} fontWeight="semibold">
                    {formatValue(item2?.y?.toString() ?? null, data2.units ?? null)}
                  </Text>
                </HStack>
              )}
            </Stack>
          );
        })}
      </Box>
    );
  };

const getMaxValue = (data: Serie[]): number | 'auto' => {
  const yValues = P.compact(data.flatMap((d) => d.data).map((d) => P.parseNumeric(d.y)));
  const maxY = P.maxBy(yValues, P.identity);

  if (typeof maxY === 'number') {
    return maxY * 1.4;
  }

  return 'auto';
};

const combineData = (data: CreateMediaAttachmentChartRequest['data']): MeasurementChartData[] => {
  const allXValues = P.uniqBy(
    data.flatMap((d) => d.series).flatMap((d) => d.data),
    (d) => d.x,
  );

  return data.map((d) => ({
    color: d.color ?? GREEN,
    units: d.units,
    series: d.series.map((s) => ({
      ...s,
      data: allXValues.map((x) => {
        const found = s.data.find((v: any) => v.x === x.x);
        return found ? found : { x: x.x, y: null };
      }),
    })),
  }));
};

const getCommonOptions = (data: HistoricalVariableChartData) => {
  const max = getMaxValue(data.series);

  const formatY: ValueFormat<DatumValue> = (value: DatumValue) =>
    P.isNil(data.units) ? `${value}` : `${value} ${data.units}`;

  const formatX: ValueFormat<DatumValue> = (value: DatumValue) => {
    return DateTime.fromISO(value as string).toLocaleString({ year: '2-digit', month: '2-digit' });
  };

  const options = {
    data: data.series,
    colors: chartsTheme.purple,
    curve: 'natural',
    theme: chartsTheme,
    margin: { top: 10, right: 80, bottom: 40, left: 80 },
    xScale: { type: 'point' },
    xFormat: formatX,
    yScale: { type: 'linear', min: 0, max: max },
    yFormat: formatY,
    axisTop: null,
    axisRight: null,
    axisLeft: null,
    axisBottom: {
      format: formatX,
    },
    enableGridX: true,
    enableGridY: false,
    pointSymbol: CustomPointSymbol,
    pointSize: 16,
    pointBorderWidth: 1,
    pointBorderColor: {
      from: 'color',
      modifiers: [['darker', 0.3]],
    },
    lineWidth: 2,
  } as const;

  return options;
};

export const FirstChart = (props: {
  series: MeasurementSeries[];
  units: string | null;
  color: string;
  ssr: boolean;
  width?: string;
  height?: string;
  sliceTooltip?: SliceTooltip;
}) => {
  const formatY: ValueFormat<DatumValue> = (value: DatumValue) =>
    P.isNil(props.units) ? `${value}` : `${value} ${props.units}`;

  const tooltipOptions = props.sliceTooltip
    ? {
        useMesh: true,
        pointSymbol: CustomPointSymbol,
        enableSlices: 'x' as const,
        sliceTooltip: props.sliceTooltip,
      }
    : {};

  const min = P.first(props.series[0].data)?.x;

  const markerOptions = min
    ? {
        markers: [
          {
            axis: 'x' as const,
            value: min,
            lineStyle: { stroke: props.color, strokeWidth: 2 },
          },
        ],
      }
    : {};

  const options: LineSvgProps = {
    ...getCommonOptions({
      series: props.series,
      units: props.units,
      isGraphable: true,
      color: props.color,
      reportTemplateVariable: props.series[0].reportTemplateVariable,
    }),
    colors: props.color,
    pointLabelYOffset: -12,
    enableGridY: true,
    enableGridX: false,
    axisLeft: {
      format: formatY,
      tickSize: 5,
      tickPadding: 14,
      tickRotation: 0,
    },
    axisRight: null,
    ...tooltipOptions,
    ...markerOptions,
  };

  if (props.width && props.height && props.ssr) {
    const w = P.parseNumeric(props.width.replace('px', '')) ?? 0;
    const h = P.parseNumeric(props.height.replace('px', '')) ?? 0;
    return <Line {...options} width={w} height={h} />;
  }

  return <ResponsiveLine {...options} />;
};

export const SecondChart = (props: {
  series: MeasurementSeries[];
  units: string | null;
  color: string;
  ssr: boolean;
  width?: string;
  height?: string;
  sliceTooltip?: SliceTooltip;
}) => {
  const formatY: ValueFormat<DatumValue> = (value: DatumValue) =>
    P.isNil(props.units) ? `${value}` : `${value} ${props.units}`;

  const tooltipOptions = props.sliceTooltip
    ? {
        useMesh: true,
        pointSymbol: CustomPointSymbol,
        enableSlices: 'x' as const,
        sliceTooltip: props.sliceTooltip,
      }
    : {};

  const max = P.last(props.series[0].data)?.x;

  const markerOptions = max
    ? {
        markers: [
          {
            axis: 'x' as const,
            value: max,
            lineStyle: { stroke: props.color, strokeWidth: 2 },
          },
        ],
      }
    : {};

  const options: LineSvgProps = {
    ...getCommonOptions({
      series: props.series,
      units: props.units,
      isGraphable: true,
      color: props.color,
      reportTemplateVariable: props.series[0].reportTemplateVariable,
    }),
    colors: props.color,
    pointLabelYOffset: 24,
    axisRight: {
      format: formatY,
      tickSize: 5,
      tickPadding: 14,
      tickRotation: 0,
    },
    axisLeft: null,
    ...tooltipOptions,
    ...markerOptions,
  };

  if (props.width && props.height && props.ssr) {
    const w = P.parseNumeric(props.width.replace('px', '')) ?? 0;
    const h = P.parseNumeric(props.height.replace('px', '')) ?? 0;
    return <Line {...options} width={w} height={h} />;
  }

  return <ResponsiveLine {...options} />;
};

interface Props {
  data: CreateMediaAttachmentChartRequest['data'];
  ssr: boolean;
  width?: string;
  height?: string;
}

export const MeasurementsChart = (props: Props) => {
  const { ssr, width, height, data } = props;

  const [firstChartData, secondChartData] = combineData(data);

  return (
    <Box h="full" w="full" pos="relative">
      <Box w="full" h="full" pos="absolute" top="0px">
        {firstChartData && (
          <FirstChart
            series={firstChartData.series as MeasurementSeries[]}
            sliceTooltip={
              secondChartData
                ? undefined
                : getSliceTooltip({
                    data1: firstChartData,
                  })
            }
            units={firstChartData.units}
            color={firstChartData.color}
            width={width}
            height={height}
            ssr={ssr}
          />
        )}
      </Box>
      {secondChartData && (
        <Box w="full" h="full" pos="absolute" top="0px">
          <SecondChart
            series={secondChartData.series as MeasurementSeries[]}
            sliceTooltip={getSliceTooltip({
              data1: firstChartData,
              data2: secondChartData,
            })}
            units={secondChartData.units}
            color={secondChartData.color}
            width={width}
            height={height}
            ssr={ssr}
          />
        </Box>
      )}
    </Box>
  );
};
