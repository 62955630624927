import { Button, Icon } from '@chakra-ui/react';
import { PERMISSIONS } from '@piccolohealth/echo-common';
import React from 'react';
import { FaPlus } from 'react-icons/fa';
import { usePermission } from '../../hooks/usePermission';
import { showModal } from '../generic/Modal';
import { UserInviteModal } from '../modals/UserInviteModal';

export const UserInviteButton = () => {
  const hasPermission = usePermission(PERMISSIONS.usersUpdate).value;

  return (
    <Button
      rightIcon={<Icon as={FaPlus} />}
      isDisabled={!hasPermission}
      onClick={() => showModal(UserInviteModal)}
      data-pw="userInviteButton"
    >
      Invite user
    </Button>
  );
};
