import { MenuItem } from '@chakra-ui/react';
import _ from 'lodash';
import React from 'react';
import { FaDownload } from 'react-icons/fa';
import { useReportPdfsQuery } from '../../graphql/hooks/useReportQuery';
import { useAppContext } from '../../hooks/useAppContext';
import { Spin } from '@piccolohealth/ui';

interface ReportPdfMenuItemProps {
  reportId: string;
  isLoading: boolean;
  pdfs: {
    onClick: () => void;
    version: number;
    key: string;
  }[];
}

export const ReportPdfMenuItem = (props: ReportPdfMenuItemProps) => {
  const { reportId, isLoading, pdfs } = props;

  if (isLoading) {
    return (
      <MenuItem key={`${reportId}-pdf-loading`} justifyContent="center">
        <Spin size="sm" />
      </MenuItem>
    );
  }

  // If no PDF's
  if (_.isEmpty(pdfs)) {
    return (
      <MenuItem icon={<FaDownload />} isDisabled={true} data-pw="reportNoPdfsMenuItem">
        No PDFs
      </MenuItem>
    );
  }

  // If only one PDF
  if (_.size(pdfs) === 1) {
    const items = _.map(pdfs, ({ onClick, key }) => (
      <MenuItem icon={<FaDownload />} onClick={onClick} key={key} data-pw="viewPdfMenuButton">
        View finalized PDF
      </MenuItem>
    ));
    return <>{items}</>;
  }

  // Multiple PDF versions
  return (
    <>
      {_.map(pdfs, ({ onClick, key, version }) => (
        <MenuItem key={key} icon={<FaDownload />} onClick={onClick}>
          View version {version} finalized PDF
        </MenuItem>
      ))}
    </>
  );
};

interface Props {
  reportId: string;
}

export const ReportViewPdfMenuItem = (props: Props) => {
  const { reportId } = props;
  const { organization } = useAppContext();

  const { isLoading, data } = useReportPdfsQuery({
    organizationId: organization.id,
    reportId: reportId,
  });

  const pdfs = _.map(
    _.reverse(_.sortBy(data?.organization?.report?.pdfs || [], 'version')),
    (pdf) => {
      const { viewUrl, version } = pdf;
      const onClick = () => window.open(viewUrl || '', '_blank');
      const key = `view-pdf-${viewUrl}-version`;

      return {
        onClick,
        version,
        key,
      };
    },
  );

  return <ReportPdfMenuItem reportId={reportId} pdfs={pdfs} isLoading={isLoading} />;
};
