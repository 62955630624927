import React from 'react';

export const Bullseye = {
  /* eslint-disable react/jsx-key */
  paths: [
    <path
      transform="matrix(1.285061, 0, 0, 1.30507, 345.473022, -33.313084)"
      d="M 114.688184386 529.669184386 A 46.69 46.69 0 0 1 180.717815614 529.669184386 L 164.210407807 546.176592193 A 23.345 23.345 0 0 0 131.195592193 546.176592193 Z"
      id="apicalAnterior"
      className="wall-area"
    />,
    <path
      transform="matrix(1.285061, 0, 0, 1.30507, 345.479767, -33.300144)"
      d="M 180.717815614 529.669184386 A 46.69 46.69 0 0 1 180.717815614 595.698815614 L 164.210407807 579.191407807 A 23.345 23.345 0 0 0 164.210407807 546.176592193 Z"
      id="apicalLateral"
      className="wall-area"
    />,
    <path
      transform="matrix(1.285061, 0, 0, 1.30507, 345.495911, -33.299713)"
      d="M 180.717815614 595.698815614 A 46.69 46.69 0 0 1 114.688184386 595.698815614 L 131.195592193 579.191407807 A 23.345 23.345 0 0 0 164.210407807 579.191407807 Z"
      id="apicalInferior"
      className="wall-area"
    />,
    <path
      transform="matrix(1.285061, 0, 0, 1.30507, 345.478882, -33.315445)"
      d="M 114.688184386 595.698815614 A 46.69 46.69 0 0 1 114.688184386 529.669184386 L 131.195592193 546.176592193 A 23.345 23.345 0 0 0 131.195592193 579.191407807 Z"
      id="apicalSeptal"
      className="wall-area"
    />,
    <path
      transform="matrix(1.285061, 0, 0, 1.30507, 345.484619, -33.375225)"
      d="M 112.685 502.031044821 A 70.036 70.036 0 0 1 182.721 502.031044821 L 171.048 522.249273897 A 46.69 46.69 0 0 0 124.358 522.249273897 Z"
      id="midAnterior"
      className="wall-area"
    />,
    <path
      transform="matrix(1.285061, 0, 0, 1.30507, 345.464874, -33.415829)"
      d="M 182.721 502.031044821 A 70.036 70.036 0 0 1 217.739 562.684 L 194.393 562.684 A 46.69 46.69 0 0 0 171.048 522.249273897 Z"
      id="midAnterolateral"
      className="wall-area"
    />,
    <path
      transform="matrix(1.285061, 0, 0, 1.30507, 345.464874, -33.415829)"
      d="M 217.739 562.684 A 70.036 70.036 0 0 1 182.721 623.336955179 L 171.048 603.118726103 A 46.69 46.69 0 0 0 194.393 562.684 Z"
      id="midInferolateral"
      className="wall-area"
    />,
    <path
      transform="matrix(1.285061, 0, 0, 1.30507, 345.488007, -33.401558)"
      d="M 77.667 562.684 A 70.036 70.036 0 0 1 112.685 502.031044821 L 124.358 522.249273897 A 46.69 46.69 0 0 0 101.013 562.684 Z"
      id="midAnteroseptal"
      className="wall-area"
    />,
    <path
      transform="matrix(1.285061, 0, 0, 1.30507, 345.477081, -33.418613)"
      d="M 182.721 623.336955179 A 70.036 70.036 0 0 1 112.685 623.336955179 L 124.358 603.118726103 A 46.69 46.69 0 0 0 171.048 603.118726103 Z"
      id="midInferior"
      className="wall-area"
    />,
    <path
      transform="matrix(1.285061, 0, 0, 1.30507, 345.488007, -33.401558)"
      d="M 112.685 623.336955179 A 70.036 70.036 0 0 1 77.667 562.684 L 101.013 562.684 A 46.69 46.69 0 0 0 124.358 603.118726103 Z"
      id="midInferoseptal"
      className="wall-area"
    />,
    <path
      transform="matrix(1.285061, 0, 0, 1.30507, 345.479126, -33.415829)"
      d="M 101.0125 481.813681769 A 93.381 93.381 0 0 1 194.3935 481.813681769 L 182.721 502.031044821 A 70.036 70.036 0 0 0 112.685 502.031044821 Z"
      id="basalAnterior"
      className="wall-area"
    />,
    <path
      transform="matrix(1.285061, 0, 0, 1.30507, 345.484619, -33.415829)"
      d="M 194.3935 481.813681769 A 93.381 93.381 0 0 1 241.084 562.684 L 217.739 562.684 A 70.036 70.036 0 0 0 182.721 502.031044821 Z"
      id="basalAnterolateral"
      className="wall-area"
    />,
    <path
      transform="matrix(1.285061, 0, 0, 1.30507, 345.477081, -33.415829)"
      d="M 241.084 562.684 A 93.381 93.381 0 0 1 194.3935 643.554318231 L 182.721 623.336955179 A 70.036 70.036 0 0 0 217.739 562.684 Z"
      id="basalInferolateral"
      className="wall-area"
    />,
    <path
      transform="matrix(1.285061, 0, 0, 1.30507, 345.484619, -33.401558)"
      d="M 54.322 562.684 A 93.381 93.381 0 0 1 101.0125 481.813681769 L 112.685 502.031044821 A 70.036 70.036 0 0 0 77.667 562.684 Z"
      id="basalAnteroseptal"
      className="wall-area"
    />,
    <path
      transform="matrix(1.285061, 0, 0, 1.30507, 345.498901, -33.401558)"
      d="M 194.3935 643.554318231 A 93.381 93.381 0 0 1 101.0125 643.554318231 L 112.685 623.336955179 A 70.036 70.036 0 0 0 182.721 623.336955179 Z"
      id="basalInferior"
      className="wall-area"
    />,
    <path
      transform="matrix(1.285061, 0, 0, 1.30507, 345.484619, -33.401558)"
      d="M 101.0125 643.554318231 A 93.381 93.381 0 0 1 54.322 562.684 L 77.667 562.684 A 70.036 70.036 0 0 0 112.685 623.336955179 Z"
      id="basalInferoseptal"
      className="wall-area"
    />,
    <circle cx="535.296" cy="701.214" r="31" id="apical" className="wall-area" />,
    <path
      className="text"
      d="M 537.913 589.412 L 537.913 599.482 L 536.633 599.482 L 536.633 590.992 L 534.063 591.932 L 534.063 590.782 L 537.713 589.412 L 537.913 589.412 Z"
      transform="matrix(1, 0, 0, 1, 0, 0)"
    />,
    <path
      className="text"
      d="M 447.918 648.666 L 447.918 649.706 L 441.348 649.706 L 441.348 648.786 L 444.818 644.936 C 445.331 644.349 445.688 643.876 445.888 643.516 C 446.081 643.149 446.178 642.769 446.178 642.376 C 446.178 641.856 446.018 641.429 445.698 641.096 C 445.385 640.756 444.965 640.586 444.438 640.586 C 443.805 640.586 443.311 640.769 442.958 641.136 C 442.605 641.496 442.428 641.996 442.428 642.636 L 441.158 642.636 C 441.158 641.716 441.455 640.969 442.048 640.396 C 442.641 639.829 443.438 639.546 444.438 639.546 C 445.371 639.546 446.108 639.789 446.648 640.276 C 447.188 640.763 447.458 641.413 447.458 642.226 C 447.458 643.213 446.828 644.389 445.568 645.756 L 442.888 648.666 L 447.918 648.666 Z"
      transform="matrix(1, 0, 0, 1, 0, 0)"
    />,
    <path
      className="text"
      d="M 628.103 639.676 L 628.333 639.676 L 628.333 640.756 L 628.103 640.756 C 627.11 640.776 626.32 641.069 625.733 641.636 C 625.146 642.203 624.806 643.003 624.713 644.036 C 625.24 643.436 625.96 643.136 626.873 643.136 C 627.746 643.136 628.443 643.443 628.963 644.056 C 629.483 644.669 629.743 645.463 629.743 646.436 C 629.743 647.469 629.463 648.296 628.903 648.916 C 628.343 649.536 627.59 649.846 626.643 649.846 C 625.683 649.846 624.903 649.476 624.303 648.736 C 623.71 648.003 623.413 647.056 623.413 645.896 L 623.413 645.406 C 623.413 643.559 623.806 642.149 624.593 641.176 C 625.38 640.196 626.55 639.696 628.103 639.676 Z M 626.663 644.196 C 626.223 644.196 625.82 644.326 625.453 644.586 C 625.086 644.846 624.833 645.173 624.693 645.566 L 624.693 646.036 C 624.693 646.863 624.88 647.529 625.253 648.036 C 625.62 648.536 626.083 648.786 626.643 648.786 C 627.216 648.786 627.666 648.576 627.993 648.156 C 628.32 647.736 628.483 647.183 628.483 646.496 C 628.483 645.809 628.316 645.253 627.983 644.826 C 627.65 644.406 627.21 644.196 626.663 644.196 Z"
      transform="matrix(1, 0, 0, 1, 0, 0)"
    />,
    <path
      className="text"
      d="M 531.991 695.26 L 531.991 705.33 L 530.711 705.33 L 530.711 696.84 L 528.141 697.78 L 528.141 696.63 L 531.791 695.26 L 531.991 695.26 ZM 542.183 695.31 L 542.183 696.02 L 538.033 705.33 L 536.703 705.33 L 540.843 696.35 L 535.413 696.35 L 535.413 695.31 L 542.183 695.31 Z"
      transform="matrix(1, 0, 0, 1, 0, 0)"
    />,
    <path
      className="text"
      d="M 598.22 655.933 L 598.22 666.003 L 596.94 666.003 L 596.94 657.513 L 594.37 658.453 L 594.37 657.303 L 598.02 655.933 L 598.22 655.933 ZM 608.512 664.963 L 608.512 666.003 L 601.942 666.003 L 601.942 665.083 L 605.412 661.233 C 605.925 660.646 606.282 660.173 606.482 659.813 C 606.675 659.446 606.772 659.066 606.772 658.673 C 606.772 658.153 606.612 657.726 606.292 657.393 C 605.979 657.053 605.559 656.883 605.032 656.883 C 604.399 656.883 603.905 657.066 603.552 657.433 C 603.199 657.793 603.022 658.293 603.022 658.933 L 601.752 658.933 C 601.752 658.013 602.049 657.266 602.642 656.693 C 603.235 656.126 604.032 655.843 605.032 655.843 C 605.965 655.843 606.702 656.086 607.242 656.573 C 607.782 657.06 608.052 657.71 608.052 658.523 C 608.052 659.51 607.422 660.686 606.162 662.053 L 603.482 664.963 L 608.512 664.963 Z"
      transform="matrix(1, 0, 0, 1, 0, 0)"
    />,
    <path
      className="text"
      d="M 539.185 620.389 L 539.185 621.099 L 535.035 630.409 L 533.705 630.409 L 537.845 621.429 L 532.415 621.429 L 532.415 620.389 L 539.185 620.389 Z"
      transform="matrix(1, 0, 0, 1, 0, 0)"
    />,
    <path
      className="text"
      d="M 474.269 658.593 C 474.269 659.093 474.139 659.54 473.879 659.933 C 473.612 660.32 473.256 660.623 472.809 660.843 C 473.329 661.07 473.742 661.4 474.049 661.833 C 474.356 662.266 474.509 662.753 474.509 663.293 C 474.509 664.166 474.216 664.86 473.629 665.373 C 473.042 665.886 472.272 666.143 471.319 666.143 C 470.352 666.143 469.579 665.883 468.999 665.363 C 468.419 664.85 468.129 664.16 468.129 663.293 C 468.129 662.753 468.279 662.266 468.579 661.833 C 468.872 661.393 469.279 661.063 469.799 660.843 C 469.359 660.623 469.009 660.316 468.749 659.923 C 468.496 659.536 468.369 659.093 468.369 658.593 C 468.369 657.753 468.639 657.083 469.179 656.583 C 469.719 656.09 470.432 655.843 471.319 655.843 C 472.199 655.843 472.912 656.09 473.459 656.583 C 473.999 657.083 474.269 657.753 474.269 658.593 Z M 473.229 663.273 C 473.229 662.713 473.052 662.256 472.699 661.903 C 472.346 661.55 471.879 661.373 471.299 661.373 C 470.726 661.373 470.266 661.546 469.919 661.893 C 469.572 662.24 469.399 662.7 469.399 663.273 C 469.399 663.84 469.569 664.286 469.909 664.613 C 470.249 664.94 470.719 665.103 471.319 665.103 C 471.912 665.103 472.379 664.94 472.719 664.613 C 473.059 664.28 473.229 663.833 473.229 663.273 Z M 471.319 656.883 C 470.819 656.883 470.412 657.04 470.099 657.353 C 469.792 657.66 469.639 658.08 469.639 658.613 C 469.639 659.126 469.792 659.54 470.099 659.853 C 470.399 660.166 470.806 660.323 471.319 660.323 C 471.826 660.323 472.232 660.166 472.539 659.853 C 472.846 659.54 472.999 659.126 472.999 658.613 C 472.999 658.106 472.839 657.693 472.519 657.373 C 472.206 657.046 471.806 656.883 471.319 656.883 Z"
      transform="matrix(1, 0, 0, 1, 0, 0)"
    />,
    <path
      className="text"
      d="M 531.999 651.077 L 531.999 661.147 L 530.719 661.147 L 530.719 652.657 L 528.149 653.597 L 528.149 652.447 L 531.799 651.077 L 531.999 651.077 ZM 537.581 656.557 L 537.581 655.517 L 538.531 655.517 C 539.131 655.504 539.604 655.344 539.951 655.037 C 540.298 654.73 540.471 654.317 540.471 653.797 C 540.471 652.617 539.884 652.027 538.711 652.027 C 538.158 652.027 537.718 652.187 537.391 652.507 C 537.064 652.82 536.901 653.237 536.901 653.757 L 535.631 653.757 C 535.631 652.957 535.921 652.297 536.501 651.777 C 537.081 651.25 537.818 650.987 538.711 650.987 C 539.658 650.987 540.398 651.237 540.931 651.737 C 541.471 652.237 541.741 652.93 541.741 653.817 C 541.741 654.257 541.601 654.68 541.321 655.087 C 541.034 655.494 540.648 655.8 540.161 656.007 C 540.714 656.18 541.141 656.467 541.441 656.867 C 541.741 657.274 541.891 657.767 541.891 658.347 C 541.891 659.247 541.598 659.964 541.011 660.497 C 540.424 661.024 539.661 661.287 538.721 661.287 C 537.781 661.287 537.014 661.03 536.421 660.517 C 535.834 660.01 535.541 659.34 535.541 658.507 L 536.821 658.507 C 536.821 659.034 536.991 659.454 537.331 659.767 C 537.678 660.087 538.141 660.247 538.721 660.247 C 539.334 660.247 539.804 660.087 540.131 659.767 C 540.458 659.44 540.621 658.977 540.621 658.377 C 540.621 657.797 540.441 657.35 540.081 657.037 C 539.721 656.724 539.204 656.564 538.531 656.557 L 537.581 656.557 Z"
      transform="matrix(1, 0, 0, 1, 0, 0)"
    />,
    <path
      className="text"
      d="M 487.048 695.265 L 487.048 705.335 L 485.768 705.335 L 485.768 696.845 L 483.198 697.785 L 483.198 696.635 L 486.848 695.265 L 487.048 695.265 ZM 496.15 695.315 L 496.15 701.965 L 497.54 701.965 L 497.54 703.005 L 496.15 703.005 L 496.15 705.335 L 494.87 705.335 L 494.87 703.005 L 490.3 703.005 L 490.3 702.255 L 494.79 695.315 L 496.15 695.315 Z M 494.72 697.325 L 491.75 701.965 L 494.87 701.965 L 494.87 697.055 L 494.72 697.325 Z"
      transform="matrix(1, 0, 0, 1, 0, 0)"
    />,
    <path
      className="text"
      d="M 473.064 737.011 C 472.804 737.331 472.487 737.588 472.114 737.781 C 471.747 737.974 471.344 738.071 470.904 738.071 C 470.324 738.071 469.817 737.928 469.384 737.641 C 468.957 737.354 468.627 736.954 468.394 736.441 C 468.161 735.921 468.044 735.351 468.044 734.731 C 468.044 734.058 468.171 733.454 468.424 732.921 C 468.677 732.381 469.037 731.968 469.504 731.681 C 469.977 731.401 470.524 731.261 471.144 731.261 C 472.137 731.261 472.921 731.631 473.494 732.371 C 474.061 733.111 474.344 734.121 474.344 735.401 L 474.344 735.771 C 474.344 737.724 473.961 739.151 473.194 740.051 C 472.421 740.944 471.257 741.404 469.704 741.431 L 469.454 741.431 L 469.454 740.351 L 469.724 740.351 C 470.771 740.338 471.577 740.064 472.144 739.531 C 472.711 738.998 473.017 738.158 473.064 737.011 Z M 471.104 737.011 C 471.531 737.011 471.924 736.881 472.284 736.621 C 472.644 736.361 472.907 736.038 473.074 735.651 L 473.074 735.141 C 473.074 734.308 472.894 733.628 472.534 733.101 C 472.167 732.581 471.707 732.321 471.154 732.321 C 470.594 732.321 470.144 732.534 469.804 732.961 C 469.464 733.394 469.294 733.961 469.294 734.661 C 469.294 735.341 469.457 735.904 469.784 736.351 C 470.117 736.791 470.557 737.011 471.104 737.011 Z"
      transform="matrix(1, 0, 0, 1, 0, 0)"
    />,
    <path
      className="text"
      d="M 440.057 753.132 L 440.057 752.092 L 441.007 752.092 C 441.607 752.079 442.08 751.919 442.427 751.612 C 442.774 751.305 442.947 750.892 442.947 750.372 C 442.947 749.192 442.36 748.602 441.187 748.602 C 440.634 748.602 440.194 748.762 439.867 749.082 C 439.54 749.395 439.377 749.812 439.377 750.332 L 438.107 750.332 C 438.107 749.532 438.397 748.872 438.977 748.352 C 439.557 747.825 440.294 747.562 441.187 747.562 C 442.134 747.562 442.874 747.812 443.407 748.312 C 443.947 748.812 444.217 749.505 444.217 750.392 C 444.217 750.832 444.077 751.255 443.797 751.662 C 443.51 752.069 443.124 752.375 442.637 752.582 C 443.19 752.755 443.617 753.042 443.917 753.442 C 444.217 753.849 444.367 754.342 444.367 754.922 C 444.367 755.822 444.074 756.539 443.487 757.072 C 442.9 757.599 442.137 757.862 441.197 757.862 C 440.257 757.862 439.49 757.605 438.897 757.092 C 438.31 756.585 438.017 755.915 438.017 755.082 L 439.297 755.082 C 439.297 755.609 439.467 756.029 439.807 756.342 C 440.154 756.662 440.617 756.822 441.197 756.822 C 441.81 756.822 442.28 756.662 442.607 756.342 C 442.934 756.015 443.097 755.552 443.097 754.952 C 443.097 754.372 442.917 753.925 442.557 753.612 C 442.197 753.299 441.68 753.139 441.007 753.132 L 440.057 753.132 Z"
      transform="matrix(1, 0, 0, 1, 0, 0)"
    />,
    <path
      className="text"
      d="M 531.999 740.289 L 531.999 750.359 L 530.719 750.359 L 530.719 741.869 L 528.149 742.809 L 528.149 741.659 L 531.799 740.289 L 531.999 740.289 ZM 537.331 745.599 L 536.311 745.329 L 536.821 740.339 L 541.951 740.339 L 541.951 741.509 L 537.901 741.509 L 537.601 744.249 C 538.088 743.956 538.644 743.809 539.271 743.809 C 540.184 743.809 540.908 744.112 541.441 744.719 C 541.981 745.319 542.251 746.136 542.251 747.169 C 542.251 748.196 541.971 749.009 541.411 749.609 C 540.858 750.202 540.081 750.499 539.081 750.499 C 538.194 750.499 537.471 750.252 536.911 749.759 C 536.351 749.266 536.031 748.586 535.951 747.719 L 537.161 747.719 C 537.234 748.292 537.438 748.726 537.771 749.019 C 538.098 749.312 538.534 749.459 539.081 749.459 C 539.668 749.459 540.131 749.256 540.471 748.849 C 540.811 748.449 540.981 747.892 540.981 747.179 C 540.981 746.506 540.798 745.966 540.431 745.559 C 540.064 745.159 539.578 744.959 538.971 744.959 C 538.418 744.959 537.981 745.079 537.661 745.319 L 537.331 745.599 Z"
      transform="matrix(1, 0, 0, 1, 0, 0)"
    />,
    <path
      className="text"
      d="M 531.988 771.422 L 531.988 781.492 L 530.708 781.492 L 530.708 773.002 L 528.138 773.942 L 528.138 772.792 L 531.788 771.422 L 531.988 771.422 ZM 542 775.562 L 542 777.212 C 542 778.705 541.743 779.815 541.23 780.542 C 540.723 781.269 539.93 781.632 538.85 781.632 C 537.777 781.632 536.983 781.275 536.47 780.562 C 535.957 779.855 535.69 778.799 535.67 777.392 L 535.67 775.692 C 535.67 774.219 535.927 773.122 536.44 772.402 C 536.947 771.689 537.743 771.332 538.83 771.332 C 539.91 771.332 540.703 771.675 541.21 772.362 C 541.723 773.055 541.987 774.122 542 775.562 Z M 540.73 777.562 L 540.73 775.472 C 540.73 774.392 540.577 773.605 540.27 773.112 C 539.97 772.619 539.49 772.372 538.83 772.372 C 538.177 772.372 537.703 772.619 537.41 773.112 C 537.11 773.605 536.957 774.362 536.95 775.382 L 536.95 777.412 C 536.95 778.499 537.107 779.299 537.42 779.812 C 537.733 780.332 538.21 780.592 538.85 780.592 C 539.477 780.592 539.943 780.349 540.25 779.862 C 540.55 779.375 540.71 778.609 540.73 777.562 Z"
      transform="matrix(1, 0, 0, 1, 0, 0)"
    />,
    <path
      className="text"
      d="M 538.084 800.96 L 538.084 807.61 L 539.474 807.61 L 539.474 808.65 L 538.084 808.65 L 538.084 810.98 L 536.804 810.98 L 536.804 808.65 L 532.234 808.65 L 532.234 807.9 L 536.724 800.96 L 538.084 800.96 Z M 536.654 802.97 L 533.684 807.61 L 536.804 807.61 L 536.804 802.7 L 536.654 802.97 Z"
      transform="matrix(1, 0, 0, 1, 0, 0)"
    />,
    <path
      className="text"
      d="M 624.934 752.962 L 623.914 752.692 L 624.424 747.702 L 629.554 747.702 L 629.554 748.872 L 625.504 748.872 L 625.204 751.612 C 625.691 751.319 626.247 751.172 626.874 751.172 C 627.787 751.172 628.511 751.475 629.044 752.082 C 629.584 752.682 629.854 753.499 629.854 754.532 C 629.854 755.559 629.574 756.372 629.014 756.972 C 628.461 757.565 627.684 757.862 626.684 757.862 C 625.797 757.862 625.074 757.615 624.514 757.122 C 623.954 756.629 623.634 755.949 623.554 755.082 L 624.764 755.082 C 624.837 755.655 625.041 756.089 625.374 756.382 C 625.701 756.675 626.137 756.822 626.684 756.822 C 627.271 756.822 627.734 756.619 628.074 756.212 C 628.414 755.812 628.584 755.255 628.584 754.542 C 628.584 753.869 628.401 753.329 628.034 752.922 C 627.667 752.522 627.181 752.322 626.574 752.322 C 626.021 752.322 625.584 752.442 625.264 752.682 L 624.934 752.962 Z"
      transform="matrix(1, 0, 0, 1, 0, 0)"
    />,
    <path
      className="text"
      d="M 600.316 730.703 L 600.316 740.773 L 599.036 740.773 L 599.036 732.283 L 596.466 733.223 L 596.466 732.073 L 600.116 730.703 L 600.316 730.703 ZM 608.228 730.703 L 608.228 740.773 L 606.948 740.773 L 606.948 732.283 L 604.378 733.223 L 604.378 732.073 L 608.028 730.703 L 608.228 730.703 Z"
      transform="matrix(1, 0, 0, 1, 0, 0)"
    />,
    <path
      className="text"
      d="M 575.872 695.261 L 575.872 705.331 L 574.592 705.331 L 574.592 696.841 L 572.022 697.781 L 572.022 696.631 L 575.672 695.261 L 575.872 695.261 ZM 584.364 695.301 L 584.594 695.301 L 584.594 696.381 L 584.364 696.381 C 583.371 696.401 582.581 696.694 581.994 697.261 C 581.407 697.828 581.067 698.628 580.974 699.661 C 581.501 699.061 582.221 698.761 583.134 698.761 C 584.007 698.761 584.704 699.068 585.224 699.681 C 585.744 700.294 586.004 701.088 586.004 702.061 C 586.004 703.094 585.724 703.921 585.164 704.541 C 584.604 705.161 583.851 705.471 582.904 705.471 C 581.944 705.471 581.164 705.101 580.564 704.361 C 579.971 703.628 579.674 702.681 579.674 701.521 L 579.674 701.031 C 579.674 699.184 580.067 697.774 580.854 696.801 C 581.641 695.821 582.811 695.321 584.364 695.301 Z M 582.924 699.821 C 582.484 699.821 582.081 699.951 581.714 700.211 C 581.347 700.471 581.094 700.798 580.954 701.191 L 580.954 701.661 C 580.954 702.488 581.141 703.154 581.514 703.661 C 581.881 704.161 582.344 704.411 582.904 704.411 C 583.477 704.411 583.927 704.201 584.254 703.781 C 584.581 703.361 584.744 702.808 584.744 702.121 C 584.744 701.434 584.577 700.878 584.244 700.451 C 583.911 700.031 583.471 699.821 582.924 699.821 Z"
      transform="matrix(1, 0, 0, 1, 0, 0)"
    />,
  ],
  viewBox: '415.292 579.057 240.001 243.752',
};
