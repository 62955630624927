import { MenuItem } from '@chakra-ui/react';
import { PERMISSIONS, ReportStatus } from '@piccolohealth/echo-common';
import React from 'react';
import { FaPlus } from 'react-icons/fa';
import { DistributionAddModal } from '../../features/distributions/components/DistributionAddModal';
import { usePermission } from '../../hooks/usePermission';
import { showModal } from '../generic/Modal';

interface Props {
  reportId: string;
  reportStatus: ReportStatus;
}

export const ReportDistributionsAddMenuItem = (props: Props) => {
  const { reportId, reportStatus } = props;
  const hasPermission = usePermission(PERMISSIONS.distributionCreate).value;

  const onClick = React.useCallback(() => {
    showModal(DistributionAddModal, {
      reportId,
      reportStatus,
    });
  }, [reportId, reportStatus]);

  return (
    <MenuItem
      icon={<FaPlus />}
      isDisabled={!hasPermission}
      data-pw="reportAddDistributionMenuItem"
      onClick={onClick}
    >
      Add distribution
    </MenuItem>
  );
};
