import { Avatar, Tag, TagCloseButton, TagLabel, TagProps } from '@chakra-ui/react';
import { Machine } from '@piccolohealth/echo-common';
import React from 'react';
import GELogo from '/logos/ge.logo.png';
import MindrayLogo from '/logos/mindray.logo.png';
import Philipslogo from '/logos/philips.logo.png';

interface Props extends TagProps {
  machine: Machine;
  onRemove?: () => void;
}

export const getTagOptions = (machine: Machine) => {
  const { manufacturer, model } = machine;

  if (manufacturer.includes('GE')) {
    return {
      text: model,
      color: 'blue',
      logo: GELogo,
    };
  }

  if (manufacturer.includes('Philips')) {
    return {
      text: model,
      color: 'blue',
      logo: Philipslogo,
    };
  }

  if (manufacturer.includes('Mindray')) {
    return {
      text: model,
      color: 'red',
      logo: MindrayLogo,
    };
  }

  return {
    text: `${manufacturer} - ${model}`,
    color: 'gray',
    logo: undefined,
  };
};

export const MachineTag = (props: Props) => {
  const { machine, onRemove, ...rest } = props;
  const { text, color, logo } = getTagOptions(machine);

  return (
    <Tag size="sm" colorScheme={color} {...rest}>
      <Avatar src={logo} size="2xs" ml={-1} mr={2} />
      <TagLabel lineHeight="normal">{text}</TagLabel>
      {onRemove && (
        <TagCloseButton
          tabIndex={-1}
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onRemove();
          }}
        />
      )}
    </Tag>
  );
};
