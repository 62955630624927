import type { DatePickerValueChangeDetails } from '@ark-ui/react/date-picker';
import { DatePicker } from '@ark-ui/react/date-picker';
import {
  Button,
  ButtonGroup,
  chakra,
  Divider,
  HStack,
  IconButton,
  Input,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import { parseAbsoluteToLocal } from '@internationalized/date';
import { DateTime, P } from '@piccolohealth/util';
import React from 'react';
import { FaCaretLeft, FaCaretRight } from 'react-icons/fa';
import { FloatingPopover } from './FloatingPopover';

export const LOCALE = 'en-AU';

export const Presets = () => {
  const presets = React.useMemo(() => {
    return [
      {
        label: 'Today',
        value: [
          parseAbsoluteToLocal(DateTime.now().startOf('day').toISO()),
          parseAbsoluteToLocal(DateTime.now().endOf('day').toISO()),
        ],
      },
      {
        label: 'This week',
        value: [
          parseAbsoluteToLocal(DateTime.now().startOf('week').toISO()),
          parseAbsoluteToLocal(DateTime.now().endOf('week').toISO()),
        ],
      },
      {
        label: 'Last week',
        value: [
          parseAbsoluteToLocal(DateTime.now().minus({ weeks: 1 }).startOf('week').toISO()),
          parseAbsoluteToLocal(DateTime.now().minus({ weeks: 1 }).endOf('week').toISO()),
        ],
      },
      {
        label: 'This month',
        value: [
          parseAbsoluteToLocal(DateTime.now().startOf('month').toISO()),
          parseAbsoluteToLocal(DateTime.now().endOf('month').toISO()),
        ],
      },
      {
        label: 'Last month',
        value: [
          parseAbsoluteToLocal(DateTime.now().minus({ months: 1 }).startOf('month').toISO()),
          parseAbsoluteToLocal(DateTime.now().minus({ months: 1 }).endOf('month').toISO()),
        ],
      },
    ];
  }, []);

  return (
    <HStack align="start">
      {presets.map(({ label, value }) => (
        <DatePicker.PresetTrigger key={label} value={value} asChild>
          <Button variant="solid" size="xs">
            {label}
          </Button>
        </DatePicker.PresetTrigger>
      ))}
    </HStack>
  );
};

export const TableCellTrigger = (props: { id: number; value: number | string }) => {
  return (
    <IconButton
      as={DatePicker.TableCellTrigger}
      aria-label={`${props.value}-${props.id}`}
      icon={<Text>{props.value}</Text>}
      size="sm"
      variant="ghost"
      m={1}
      sx={{
        '&[data-in-range]': { bg: 'gray.100', color: 'black' },
        '&[data-selected]': { bg: 'purple.500', color: 'white' },
      }}
    />
  );
};

export interface ViewControlProps {
  text: string;
}

export const ViewControl = (props: ViewControlProps) => {
  return (
    <HStack as={DatePicker.ViewControl}>
      <IconButton
        as={DatePicker.PrevTrigger}
        aria-label="prev"
        icon={<FaCaretLeft />}
        size="sm"
        variant="ghost"
      />
      <Spacer />
      <Button as={DatePicker.ViewTrigger} variant="ghost">
        {props.text}
      </Button>
      <Spacer />
      <IconButton
        as={DatePicker.NextTrigger}
        aria-label="prev"
        icon={<FaCaretRight />}
        size="sm"
        variant="ghost"
      />
    </HStack>
  );
};

export interface ViewProps {
  onClickPreset?: (start: DateTime, end: DateTime) => void;
}

export const DayView = (
  props: ViewProps & {
    months: number;
  },
) => {
  return (
    <DatePicker.View view="day">
      {(api) => {
        const offset = api.getOffset({ months: props.months - 1 });
        const singleMonth = props.months === 1;

        return (
          <Stack w="fit-content" bg="white" p={3} shadow="popover" rounded="lg">
            <ViewControl text={api.visibleRangeText.formatted} />
            <Divider />
            <HStack w="fit-content" align="start">
              <chakra.table as={DatePicker.Table}>
                <chakra.thead as={DatePicker.TableHead}>
                  <chakra.tr as={DatePicker.TableRow}>
                    {api.weekDays.map((weekDay, id) => (
                      <chakra.th
                        as={DatePicker.TableHeader}
                        key={id}
                        fontSize="sm"
                        color="secondary"
                        fontWeight="normal"
                        pb={2}
                      >
                        {weekDay.short}
                      </chakra.th>
                    ))}
                  </chakra.tr>
                </chakra.thead>
                <chakra.tbody as={DatePicker.TableBody}>
                  {api.weeks.map((week, id) => (
                    <chakra.tr as={DatePicker.TableRow} key={id}>
                      {week.map((day, id) => (
                        <chakra.td as={DatePicker.TableCell} key={id} value={day}>
                          <TableCellTrigger value={day.day} id={id} />
                        </chakra.td>
                      ))}
                    </chakra.tr>
                  ))}
                </chakra.tbody>
              </chakra.table>

              {!singleMonth && (
                <chakra.table as={DatePicker.Table}>
                  <chakra.thead as={DatePicker.TableHead}>
                    <chakra.tr as={DatePicker.TableRow}>
                      {api.weekDays.map((weekDay, id) => (
                        <chakra.th
                          as={DatePicker.TableHeader}
                          key={id}
                          fontSize="sm"
                          color="secondary"
                          fontWeight="normal"
                          pb={2}
                        >
                          {weekDay.short}
                        </chakra.th>
                      ))}
                    </chakra.tr>
                  </chakra.thead>
                  <chakra.tbody as={DatePicker.TableBody}>
                    {offset.weeks.map((week, id) => (
                      <chakra.tr as={DatePicker.TableRow} key={id}>
                        {week.map((day, id) => (
                          <chakra.td
                            as={DatePicker.TableCell}
                            key={id}
                            value={day}
                            visibleRange={offset.visibleRange}
                          >
                            <TableCellTrigger value={day.day} id={id} />
                          </chakra.td>
                        ))}
                      </chakra.tr>
                    ))}
                  </chakra.tbody>
                </chakra.table>
              )}
            </HStack>
            {props.onClickPreset && (
              <>
                <Divider />
                <Presets />
              </>
            )}
          </Stack>
        );
      }}
    </DatePicker.View>
  );
};

export const MonthView = (props: ViewProps) => {
  return (
    <DatePicker.View view="month">
      {(api) => (
        <Stack w="fit-content" bg="white" p={3} shadow="popover" rounded="lg">
          <ViewControl text={`${api.visibleRange.start.year}`} />
          <Divider />
          <chakra.table as={DatePicker.Table}>
            <chakra.tbody as={DatePicker.TableBody}>
              {api.getMonthsGrid({ columns: 4, format: 'short' }).map((months, id) => (
                <chakra.tr as={DatePicker.TableRow} key={id}>
                  {months.map((month, id) => (
                    <chakra.td as={DatePicker.TableCell} key={id} value={month.value}>
                      <TableCellTrigger value={month.label} id={id} />
                    </chakra.td>
                  ))}
                </chakra.tr>
              ))}
            </chakra.tbody>
          </chakra.table>

          {props.onClickPreset && (
            <>
              <Divider />
              <Presets />
            </>
          )}
        </Stack>
      )}
    </DatePicker.View>
  );
};

export const YearView = (props: ViewProps) => {
  return (
    <DatePicker.View view="year">
      {(api) => {
        const years = api.getYearsGrid({ columns: 4 });
        const firstYear = P.first(years.flat());
        const lastYear = P.last(years.flat());

        return (
          <Stack w="fit-content" bg="white" p={3} shadow="popover" rounded="lg">
            <ViewControl text={`${firstYear?.label} - ${lastYear?.label}`} />
            <Divider />
            <chakra.table as={DatePicker.Table}>
              <chakra.tbody as={DatePicker.TableBody}>
                {years.map((years, id) => (
                  <chakra.tr as={DatePicker.TableRow} key={id}>
                    {years.map((year, id) => (
                      <chakra.td as={DatePicker.TableCell} key={id} value={year.value}>
                        <TableCellTrigger value={year.label} id={id} />
                      </chakra.td>
                    ))}
                  </chakra.tr>
                ))}
              </chakra.tbody>
            </chakra.table>
            <Divider />
            {props.onClickPreset && (
              <>
                <Divider />
                <Presets />
              </>
            )}
          </Stack>
        );
      }}
    </DatePicker.View>
  );
};

interface Props {
  startDate: DateTime | null;
  endDate: DateTime | null;
  onStartDateChange: (date: DateTime | null) => void;
  onEndDateChange: (date: DateTime | null) => void;
  isDisabled?: boolean;
}

export const RangeDatepicker = (props: Props) => {
  const [popoverOpen, setPopoverOpen] = React.useState(false);
  const startInputRef = React.useRef<HTMLInputElement>(null);
  const endInputRef = React.useRef<HTMLInputElement>(null);

  const value = React.useMemo(
    () =>
      props.startDate && props.endDate
        ? [props.startDate?.toISODate(), props.endDate?.toISODate()]
        : [],
    [props.startDate, props.endDate],
  );

  const onStartInputChange = React.useCallback(
    (value: string) => {
      if (value === '') {
        props.onStartDateChange(null);
        return;
      }

      const inputDate = DateTime.fromFormat(
        startInputRef.current?.value ?? '',
        'dd/MM/yyyy',
      ).startOf('day');
      if (inputDate.isValid) {
        props.onStartDateChange(inputDate);
        return;
      }
    },
    [props],
  );

  const onEndInputChange = React.useCallback(
    (value: string) => {
      if (value === '') {
        props.onEndDateChange(null);
        return;
      }

      const inputDate = DateTime.fromFormat(endInputRef.current?.value ?? '', 'dd/MM/yyyy').endOf(
        'day',
      );
      if (inputDate.isValid) {
        props.onEndDateChange(inputDate);
        return;
      }
    },
    [props],
  );

  const onChange = React.useCallback(
    (details: DatePickerValueChangeDetails) => {
      if (details.value.length !== 2) {
        return;
      }

      const [start, end] = details.value;
      props.onStartDateChange(DateTime.fromJSDate(start.toDate('utc')).startOf('day'));
      props.onEndDateChange(DateTime.fromJSDate(end.toDate('utc')).endOf('day'));
    },
    [props],
  );

  const onClickPreset = React.useCallback(
    (start: DateTime, end: DateTime) => {
      props.onStartDateChange(start);
      props.onEndDateChange(end);
    },
    [props],
  );

  return (
    <DatePicker.Root
      open={true}
      numOfMonths={2}
      selectionMode="range"
      closeOnSelect={false}
      onValueChange={onChange}
      value={value}
      locale={LOCALE}
      disabled={props.isDisabled}
      aria-selected
    >
      <FloatingPopover
        isPortal
        open={popoverOpen && !props.isDisabled}
        setOpen={setPopoverOpen}
        placement="bottom-start"
        render={() => (
          <>
            <DayView onClickPreset={onClickPreset} months={2} />
            <MonthView onClickPreset={onClickPreset} />
            <YearView onClickPreset={onClickPreset} />
          </>
        )}
      >
        <DatePicker.Control>
          <ButtonGroup isAttached bg="white" w="full" isDisabled={props.isDisabled}>
            <Input
              as={DatePicker.Input}
              ref={startInputRef}
              index={0}
              size="sm"
              mr="-px"
              onChange={(e) => onStartInputChange(e.target.value)}
              placeholder="Start"
              data-pw="rangeDatepickerStartInput"
            />
            <Input
              as={DatePicker.Input}
              ref={endInputRef}
              index={1}
              size="sm"
              onChange={(e) => onEndInputChange(e.target.value)}
              placeholder="End"
              data-pw="rangeDatepickerEndInput"
            />
          </ButtonGroup>
        </DatePicker.Control>
      </FloatingPopover>
    </DatePicker.Root>
  );
};
