import gql from 'graphql-tag';

export const DeleteDistribution = gql`
  mutation DeleteDistribution(
    $organizationId: ID!
    $reportId: ID!
    $distributionId: ID!
    $request: JSON!
  ) {
    deleteDistribution(
      organizationId: $organizationId
      reportId: $reportId
      distributionId: $distributionId
      request: $request
    )
  }
`;
