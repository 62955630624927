import { AuditTrailEvent } from '@piccolohealth/echo-common';
import _ from 'lodash';
import React from 'react';
import { AuditTrailFilter } from '../../../hooks/useAuditTrailFilter';
import { FormItem } from '../../forms/FormItem';
import { MultiSelect, MultiSelectOption, OnChangeRequest } from '@piccolohealth/ui';
import { getColor } from '../AuditTrailEventTag';
import { inflection } from '@piccolohealth/util';

const eventToOption = (event: AuditTrailEvent): MultiSelectOption<AuditTrailEvent> => {
  return {
    label: inflection.titleize(event),
    value: event,
    color: getColor(event),
    raw: event,
  };
};

interface Props {
  filter: AuditTrailFilter;
}

export const AuditTrailEventFilterControl = ({ filter }: Props) => {
  const { eventsFilter, onEventsFilter } = filter;

  const options = _.map(AuditTrailEvent, eventToOption);

  const onChange = React.useCallback(
    (req: OnChangeRequest<AuditTrailEvent>) => {
      onEventsFilter(req.values.map(({ raw }) => raw));
    },
    [onEventsFilter],
  );

  const selectedValues = React.useMemo(() => {
    return _.compact(_.map(eventsFilter, (v) => _.find(options, (o) => _.isEqual(o.raw, v))));
  }, [eventsFilter, options]);

  return (
    <FormItem id="eventType" label="Event type">
      <MultiSelect
        options={options}
        selectedValues={selectedValues}
        onChange={onChange}
        placeholder="All event types"
        optionVariant="tag"
      />
    </FormItem>
  );
};
