const sizes = {
  sm: {
    field: {
      borderRadius: 'md',
    },
  },
};

const defaultProps = {
  size: 'sm',
  focusBorderColor: 'purple.500',
};

export const Textarea = {
  sizes,
  defaultProps,
};
