import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { DateTime } from '@piccolohealth/util';
import { WorklistEntry } from '@piccolohealth/echo-common';
import _ from 'lodash';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { HookedSubmitButton } from '../../../components/forms/hookform/HookedSubmitButton';
import { createModal } from '../../../components/generic/Modal';
import { useUpdateWorklistEntryMutation } from '../../../graphql/hooks/useUpdateWorklistEntryMutation';
import { useAppContext } from '../../../hooks/useAppContext';
import { WorklistItemsSchema } from './WorklistEntryAddModal';
import { WorklistEntryForm } from './WorklistEntryForm';

type FormValues = {
  patientFirstName: string;
  patientLastName: string;
  patientId: string;
  patientDob: string;
  patientSex: string;
  patientHeight?: string;
  patientWeight?: string;
  reportTemplate: { id: string };
  performingPhysicianId: string;
};

interface Props {
  worklistEntry: WorklistEntry;
}

export const WorklistEntryEditModal = createModal<Props>((props) => {
  const { worklistEntry, modal } = props;
  const { hide, visible } = modal;
  const { successToast, errorToast } = useAppContext();

  const initialValues: Partial<FormValues> = {
    patientFirstName: worklistEntry.patientFirstName,
    patientLastName: worklistEntry.patientLastName,
    patientId: worklistEntry.patientId,
    patientDob: worklistEntry.patientDob.toString(),
    patientSex: worklistEntry.patientSex,
    patientHeight: worklistEntry.patientHeight?.toString() ?? undefined,
    patientWeight: worklistEntry.patientWeight?.toString() ?? undefined,
    reportTemplate: worklistEntry.reportTemplate,
    performingPhysicianId: worklistEntry.performingPhysician.id,
  };

  const methods = useForm({
    defaultValues: initialValues as any,
    resolver: yupResolver(WorklistItemsSchema),
  });

  const mutation = useUpdateWorklistEntryMutation({});

  const onSubmit = React.useCallback(
    async (values: FormValues) => {
      await mutation
        .mutateAsync({
          organizationId: worklistEntry.organizationId,
          worklistEntryId: worklistEntry.id,
          request: {
            patientFirstName: values.patientFirstName,
            patientId: values.patientId,
            patientLastName: values.patientLastName,
            patientDob: values.patientDob as unknown as DateTime,
            patientSex: values.patientSex,
            patientHeight: _.toNumber(values.patientHeight),
            patientWeight: _.toNumber(values.patientWeight),
            reportTemplateId: values.reportTemplate.id,
            performingPhysicianId: values.performingPhysicianId,
          },
        })
        .then(() => {
          hide();
          successToast('Worklist entry edited successfully');
        })
        .catch((err) => {
          errorToast(`Error editing worklist entry: ${err.message}`);
        });
    },
    [mutation, worklistEntry, hide, errorToast, successToast],
  );

  return (
    <Modal isOpen={visible} onClose={hide} onCloseComplete={modal.remove} size="xl">
      <ModalOverlay />
      <ModalContent>
        <FormProvider {...methods}>
          <Box as="form" display="contents" onSubmit={methods.handleSubmit(onSubmit)}>
            <ModalHeader>
              <Flex align="start">
                <Box>
                  <Heading size="md">Edit worklist entry</Heading>
                  <Text fontSize="md" fontWeight="normal" color="secondary" mt={2}>
                    Please complete the following to proceed
                  </Text>
                </Box>
                <Spacer />
                <ModalCloseButton position="unset" top="unset" right="unset" />
              </Flex>
            </ModalHeader>
            <ModalBody>
              <WorklistEntryForm />
            </ModalBody>
            <ModalFooter>
              <HStack>
                <Button onClick={hide}>Close</Button>
                <HookedSubmitButton data-pw="distributionEditModalSubmitButton">
                  Save worklist entry
                </HookedSubmitButton>
              </HStack>
            </ModalFooter>
          </Box>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
});
