import { Empty } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { MeasurementsChart } from '../../../../../../../features/measurements/MeasurementsChart';
import { CreateMediaAttachmentChartRequest } from '../MediaAttachmentZone';

interface Props {
  data: CreateMediaAttachmentChartRequest['data'];
  ssr: boolean;
  width?: string;
  height?: string;
}

export const ChartAttachmentContent = (props: Props) => {
  const { ssr, width, height, data } = props;

  if (P.isEmpty(data)) {
    return (
      <Empty
        title="Choose some measurements"
        description="Select a measurement from the list of measurements"
      />
    );
  }

  return <MeasurementsChart ssr={ssr} data={data} width={width} height={height} />;
};
