import { Select, SelectOption, SelectProps } from '@piccolohealth/ui';
import _ from 'lodash';
import React from 'react';
import { useController } from 'react-hook-form';

interface Props<A, B> extends Omit<SelectProps<A, B>, 'onChange' | 'value'> {
  name: string;
}

export const HookedSelect = <A extends unknown, B>(props: React.PropsWithChildren<Props<A, B>>) => {
  const { name, options, ...rest } = props;
  const { field } = useController({ name });

  const { onChange, value } = field;

  const onChangePrime = React.useCallback(
    (value: SelectOption<A>) => onChange(value.value),
    [onChange],
  );

  const selectedValue: SelectOption<A> | null = React.useMemo(() => {
    return (
      // If value doesn't exist as an option, still display the value
      options.flatMap((o) => ('options' in o ? o.options : o)).find((o) => o.value === value) ?? {
        value,
        label: _.upperFirst(value),
        raw: value,
      }
    );
  }, [value, options]);

  return <Select {...rest} options={options} onChange={onChangePrime} value={selectedValue} />;
};
