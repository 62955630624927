import { Box, Input, ResponsiveValue } from '@chakra-ui/react';
import { useNode } from '@craftjs/core';
import _ from 'lodash';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useReport } from '../../../../context/ReportContext';
import { BaseNodeProps } from '../../../../utils/craftjs';
import { FormItem } from '../../../forms/FormItem';
import { TiptapStatementSite } from '../../../tiptap/editors/TiptapStatementSite';
import { TiptapContext } from '../../../tiptap/utils/TiptapContext';
import { NodeTypographySettings } from '../../common/nodes/NodeTypography';
import { SettingsAccordion } from '../../common/settings/SettingsAccordion';
import { SettingsItem } from '../../common/settings/SettingsItem';
import { StatementSiteChooser } from '../../common/settings/StatementSiteChooser';
import { useSSRNode } from '../../hooks/useSSRNode';

export interface Props extends BaseNodeProps {
  statementSiteId: string;
  size: ResponsiveValue<string>;
}

export const InlineStatementSite = (props: Props) => {
  const { statementSiteId, size } = props;
  const {
    connectors: { connect, drag },
  } = useSSRNode(props);

  const { reportTemplate, isDisabled, timezone } = useReport();
  const { getValues } = useFormContext();

  const { field } = useController({
    name: `statementSites.${statementSiteId}.statements[0].value`,
  });

  const { reportTemplateStatementSite, reportTemplateStatements } = React.useMemo(() => {
    const reportTemplateStatementSite = _.find(
      reportTemplate.statementSites,
      (statementSite) => statementSite.id === statementSiteId,
    );

    const reportTemplateStatements = reportTemplateStatementSite?.statements ?? [];

    return {
      reportTemplateStatementSite,
      reportTemplateStatements,
    };
  }, [statementSiteId, reportTemplate]);

  const variablePairs = React.useMemo(() => {
    const { variables } = getValues();
    return _.map(reportTemplate.variables, (template) => ({
      template,
      variable: _.find(variables, { id: template.id }),
    }));
  }, [getValues, reportTemplate]);

  const getFormName = React.useCallback((variableId: string) => {
    return `variables.${variableId}.value`;
  }, []);

  const context: TiptapContext = React.useMemo(
    () => ({
      isDisabled,
      timezone,
      reportTemplate,
      variablePairs,
      reportTemplateStatements,
      getFormName,
    }),
    [isDisabled, timezone, reportTemplate, variablePairs, reportTemplateStatements, getFormName],
  );

  if (!reportTemplateStatementSite) {
    return null;
  }

  return (
    <Box
      ref={(ref) => connect(drag(ref))}
      w="full"
      fontSize={size}
      data-pw={`statementSite-${reportTemplateStatementSite.name}`}
    >
      <TiptapStatementSite
        value={field.value}
        context={context}
        onChange={field.onChange}
        size={size}
      />
    </Box>
  );
};

const InlineStatementSiteSettings = () => {
  const {
    actions: { setProp },
    statementSiteId,
    size,
  } = useNode((node) => ({
    statementSiteId: node.data.props.statementSiteId,
    size: node.data.props.size,
  }));

  return (
    <SettingsAccordion>
      <SettingsItem title="Inline statement site">
        <FormItem id="statementSiteId" label="Statement site">
          <StatementSiteChooser
            statementSiteId={statementSiteId}
            onChange={(value) =>
              setProp((props: Props) => (props.statementSiteId = value as string))
            }
          />
        </FormItem>
        <FormItem id="size" label="Size">
          <Input
            defaultValue={size}
            value={size}
            size="sm"
            onChange={(e) =>
              setProp((props: Props) => (props.size = e.target.value as Props['size']))
            }
          />
        </FormItem>
      </SettingsItem>
    </SettingsAccordion>
  );
};

InlineStatementSite.defaultProps = {
  ...NodeTypographySettings.defaultProps,
  statementSiteId: '',
  size: 'xs',
};

InlineStatementSite.craft = {
  name: 'InlineStatementSite',
  props: InlineStatementSite.defaultProps,
  related: {
    settings: InlineStatementSiteSettings,
  },
};
