import {
  ReportTemplate,
  ReportTemplateStatement,
  ReportVariable,
  ReportTemplateVariable,
} from '@piccolohealth/echo-common';
import React from 'react';

export interface VariablePair {
  variable: ReportVariable;
  template: ReportTemplateVariable;
}

export interface TiptapContext {
  isDisabled: boolean;
  timezone: string;
  reportTemplate: ReportTemplate;
  variablePairs: VariablePair[];
  reportTemplateStatements: ReportTemplateStatement[];
  getFormName: (id: string) => string;
}

export const TiptapContext = React.createContext<TiptapContext>(null as any);

export const TiptapContextProvider = (
  props: React.PropsWithChildren<{
    value: TiptapContext;
  }>,
) => {
  return <TiptapContext.Provider value={props.value}>{props.children}</TiptapContext.Provider>;
};

export const useTiptapContext: () => TiptapContext = () => {
  return React.useContext(TiptapContext);
};
