import { Icon, IconButton, IconButtonProps } from '@chakra-ui/react';
import { DraggableAttributes } from '@dnd-kit/core';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import React from 'react';
import { FaGripVertical } from 'react-icons/fa';

interface Props extends Omit<IconButtonProps, 'aria-label'> {
  listeners: SyntheticListenerMap | undefined;
  attributes: DraggableAttributes;
}

export const DragHandle = (props: Props) => {
  const { listeners, attributes, ...rest } = props;

  return (
    <IconButton
      variant="ghost"
      aria-label="Drag handle"
      fontSize="10px"
      icon={<Icon as={FaGripVertical} />}
      color="gray.400"
      _hover={{
        bg: 'gray.100',
      }}
      cursor="grab"
      onClick={() => undefined}
      {...listeners}
      {...attributes}
      {...rest}
    />
  );
};
