import { Flex, FlexProps, Text } from '@chakra-ui/react';
import React from 'react';

interface ActionCardProps extends FlexProps {
  image: React.ReactNode;
  title: string;
  description: string;
}

export const ActionCard = (props: ActionCardProps) => {
  const { image, title, description, ...rest } = props;

  return (
    <Flex
      bg="white"
      w="sm"
      h="2xs"
      flexDir="column"
      align="center"
      textAlign="center"
      shadow="md"
      rounded="xl"
      borderWidth="1px"
      p={6}
      cursor="pointer"
      _hover={{
        boxShadow: 'lg',
        bg: 'gray.50',
      }}
      {...rest}
    >
      {image}
      <Text fontSize="lg" fontWeight="bold" mt={4}>
        {title}
      </Text>
      <Text fontSize="sm" color="secondary" mt={0}>
        {description}
      </Text>
    </Flex>
  );
};
