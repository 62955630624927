import { Filter, Query } from '@cubejs-client/core';
import { P } from '@piccolohealth/util';
import { CubeQueryFilter } from './hooks/useCubeQueryFilter';

export interface CubeChartQuery extends Query {
  chart: {
    x: string;
    y: string;
    color: string;
  };
}

export const queries = {
  reportsFinalizedCount: (filter: CubeQueryFilter) =>
    ({
      measures: ['Report.finalizedCount'],
      timeDimensions: [
        {
          dimension: 'Report.createdAt',
        },
      ],
      filters: createFilterPayload(filter),
    } as Query),

  reportsFinalizedByUniquePatientName: (filter: CubeQueryFilter) =>
    ({
      measures: ['Report.uniquePatients'],
      timeDimensions: [
        {
          dimension: 'Report.createdAt',
        },
      ],
      filters: createFilterPayload(filter),
    } as CubeChartQuery),

  reportsSharedCount: (filter: CubeQueryFilter) =>
    ({
      measures: ['ReportShare.count'],
      timeDimensions: [
        {
          dimension: 'ReportShare.sharedAt',
        },
      ],
      filters: createFilterPayload(filter),
    } as Query),

  reportsUploadedCount: (filter: CubeQueryFilter) =>
    ({
      measures: ['Report.count'],
      timeDimensions: [
        {
          dimension: 'Report.createdAt',
        },
      ],
      filters: createFilterPayload(filter),
    } as Query),

  reportsUploadedOverTime: (filter: CubeQueryFilter) =>
    ({
      measures: ['Report.count'],
      dimensions: [
        'Report.reportTemplateId',
        'Report.reportTemplateName',
        'Report.reportTemplateLabelColor',
      ],
      timeDimensions: [
        {
          dimension: 'Report.createdAt',
          granularity: 'day',
        },
      ],
      chart: {
        id: 'Report.reportTemplateId',
        x: 'Report.reportTemplateName',
        y: 'Report.count',
        color: 'Report.reportTemplateLabelColor',
      },
      filters: createFilterPayload(filter),
    } as CubeChartQuery),

  reportsByReportTemplateName: (filter: CubeQueryFilter) =>
    ({
      measures: ['Report.finalizedCount'],
      dimensions: ['Report.reportTemplateName', 'Report.reportTemplateLabelColor'],
      timeDimensions: [
        {
          dimension: 'Report.createdAt',
        },
      ],
      chart: {
        x: 'Report.reportTemplateName',
        y: 'Report.finalizedCount',
        color: 'Report.reportTemplateLabelColor',
      },
      filters: createFilterPayload(filter),
    } as CubeChartQuery),

  reportsBySonographer: (filter: CubeQueryFilter) =>
    ({
      measures: ['Report.finalizedCount'],
      dimensions: ['Report.sonographer'],
      order: [['Report.finalizedCount', 'desc']],
      limit: 100,
      timeDimensions: [
        {
          dimension: 'Report.createdAt',
        },
      ],
      chart: {
        x: 'Report.sonographer',
        y: 'Report.finalizedCount',
      },
      filters: createFilterPayload(filter),
    } as CubeChartQuery),

  reportsByCardiologist: (filter: CubeQueryFilter) =>
    ({
      measures: ['Report.finalizedCount'],
      dimensions: ['Report.cardiologist'],
      order: [['Report.finalizedCount', 'desc']],
      limit: 100,
      timeDimensions: [
        {
          dimension: 'Report.createdAt',
        },
      ],
      chart: {
        x: 'Report.cardiologist',
        y: 'Report.finalizedCount',
      },
      filters: createFilterPayload(filter),
    } as CubeChartQuery),

  reportsByReferrer: (filter: CubeQueryFilter) =>
    ({
      measures: ['Report.finalizedCount'],
      dimensions: ['Report.referringPhysician'],
      order: [['Report.finalizedCount', 'desc']],
      limit: 100,
      timeDimensions: [
        {
          dimension: 'Report.createdAt',
        },
      ],
      chart: {
        x: 'Report.referringPhysician',
        y: 'Report.finalizedCount',
      },
      filters: createFilterPayload(filter),
    } as CubeChartQuery),
};

const createFilterPayload = (filter: CubeQueryFilter): Filter[] => {
  const filters: Filter[] = [];

  if (!P.isEmpty(filter.cardiologist)) {
    filters.push({
      member: 'Report.cardiologist',
      operator: 'equals',
      values: filter.cardiologist,
    });
  }

  if (!P.isEmpty(filter.sonographer)) {
    filters.push({
      member: 'Report.sonographer',
      operator: 'equals',
      values: filter.sonographer,
    });
  }

  if (!P.isEmpty(filter.referringPhysician)) {
    filters.push({
      member: 'Report.referringPhysician',
      operator: 'equals',
      values: filter.referringPhysician,
    });
  }

  if (!P.isEmpty(filter.reportTemplateType)) {
    filters.push({
      member: 'Report.reportTemplateId',
      operator: 'equals',
      values: filter.reportTemplateType,
    });
  }

  return filters;
};
