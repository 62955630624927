import { Box, SimpleGrid, SimpleGridProps } from '@chakra-ui/react';
import { P } from '@piccolohealth/util';
import React from 'react';

export type DescriptionItem = {
  title: JSX.Element | string;
  content: JSX.Element | string;
};

interface Props extends SimpleGridProps {
  items: DescriptionItem[];
}

export const DescriptionList = (props: Props) => {
  const { items, ...rest } = props;

  return (
    <SimpleGrid
      columns={2}
      rowGap={4}
      columnGap={4}
      templateColumns="max-content auto"
      alignItems="start"
      {...rest}
    >
      {items.map((item, index) => (
        <React.Fragment key={index}>
          {P.isString(item.title) ? (
            <Box fontSize="sm" fontWeight="semibold">
              {item.title}
            </Box>
          ) : (
            <Box>{item.title}</Box>
          )}
          <Box>{item.content}</Box>
        </React.Fragment>
      ))}
    </SimpleGrid>
  );
};
