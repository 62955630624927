import { Box, HStack, Menu, MenuGroup, MenuItem, MenuList, Text } from '@chakra-ui/react';
import {
  autoPlacement,
  autoUpdate,
  FloatingNode,
  FloatingOverlay,
  FloatingPortal,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import { Dot } from '@piccolohealth/ui';
import { WallMotionModule } from '@piccolohealth/echo-common';
import _ from 'lodash';
import React from 'react';

export interface WallMotionContextMenuProps {
  wallName: string;
  module: WallMotionModule;
  isDisabled?: boolean;
  children: JSX.Element;
  onClick: (wallKey: string) => void;
}

export const WallContextMenu = (props: WallMotionContextMenuProps) => {
  const { wallName, module, isDisabled, children, onClick } = props;
  const [open, setOpen] = React.useState(false);

  const nodeId = useFloatingNodeId();

  const { x, y, reference, floating, strategy, context } = useFloating({
    open,
    onOpenChange: setOpen,
    middleware: [autoPlacement()],
    placement: 'right-start',
    nodeId,
    whileElementsMounted: autoUpdate,
  });

  const { getFloatingProps, getReferenceProps } = useInteractions([
    useRole(context),
    useDismiss(context),
  ]);

  const onContextMenu = React.useCallback(
    (event: MouseEvent) => {
      if (isDisabled) {
        return;
      }

      event.stopPropagation();
      event.preventDefault();
      reference({
        getBoundingClientRect() {
          return {
            x: event.clientX,
            y: event.clientY,
            width: 0,
            height: 0,
            top: event.clientY,
            right: event.clientX,
            bottom: event.clientY,
            left: event.clientX,
          };
        },
      });
      setOpen(true);
    },
    [reference, isDisabled],
  );

  return (
    <FloatingNode id={nodeId}>
      {React.cloneElement(
        children,
        getReferenceProps({ ref: reference, onContextMenu: onContextMenu, ...children.props }),
      )}
      <FloatingPortal>
        {open && (
          <FloatingOverlay lockScroll style={{ zIndex: 10000 }}>
            <div
              {...getFloatingProps({
                className: 'ContextMenu',
                ref: floating,
                style: {
                  position: strategy,
                  top: y ?? 0,
                  left: x ?? 0,
                },
              })}
            >
              <Box h="310px">
                <Menu isOpen={true} computePositionOnMount={true}>
                  <MenuList>
                    <MenuGroup title={wallName}>
                      {_.map(module.scores, (score, scoreKey) => (
                        <MenuItem
                          key={`${wallName}-${scoreKey}`}
                          onClick={() => {
                            onClick(scoreKey);
                            setOpen(false);
                          }}
                        >
                          <HStack overflow="hidden" white-space="nowrap">
                            <Dot size="8px" bg={score.color} />
                            <Text fontSize="sm">
                              {score.text} {score.number && `(${score.number})`}
                            </Text>
                          </HStack>
                        </MenuItem>
                      ))}
                    </MenuGroup>
                  </MenuList>
                </Menu>
              </Box>
            </div>
          </FloatingOverlay>
        )}
      </FloatingPortal>
    </FloatingNode>
  );
};
