import * as Types from '@piccolohealth/echo-common';
import { useMutation, useQueryClient } from 'react-query';
import { gqlFetcher, MutationOptions } from '../fetcher';
import { AddReportLabel } from '../mutations/addReportLabel.mutation';

export const useAddReportLabelMutation = (
  options?: MutationOptions<Types.AddReportLabelMutation, Types.AddReportLabelMutationVariables>,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(AddReportLabel), {
    ...options,
    onSuccess: async (resp, variables, ctx) => {
      await options?.onSuccess?.(resp, variables, ctx);

      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey.includes('labels') ||
          query.queryKey.includes('reports') ||
          query.queryKey.includes('report'),
      });
    },
  });
};
