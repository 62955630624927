import { Box, Divider, HStack, Stack } from '@chakra-ui/react';
import { UpdateDistributionSettingsRequest } from '@piccolohealth/echo-common';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { DefaultDistributionChooser } from '../../../features/distributions/components/DefaultDistributionChooser';
import { useUpdateOrganizationMutation } from '../../../graphql/hooks/useUpdateOrganizationMutation';
import { useAppContext } from '../../../hooks/useAppContext';
import { FormSection } from '../../forms/FormSection';
import { HookedDistributionTypeChooser } from '../../forms/hookform/HookedDistributionTypeChooser';
import { HookedFormItem } from '../../forms/hookform/HookedFormItem';
import { HookedResetButton } from '../../forms/hookform/HookedResetButton';
import { HookedSubmitButton } from '../../forms/hookform/HookedSubmitButton';
import { Content } from '../../generic/Content';

type FormValues = UpdateDistributionSettingsRequest;

export const DistributionSettings = () => {
  const { organization, successToast, errorToast } = useAppContext();

  const mutation = useUpdateOrganizationMutation();

  const initialValues: FormValues = React.useMemo(
    () => ({
      defaultDistributionType: organization.distributionSettings.defaultDistributionType,
      defaultDistribution: undefined,
    }),
    [organization],
  );

  const methods = useForm({
    defaultValues: initialValues,
  });

  const { reset } = methods;

  React.useEffect(() => {
    reset(initialValues);
  }, [initialValues, reset]);

  const onSubmit = React.useCallback(
    async (values: FormValues) => {
      await mutation
        .mutateAsync({
          organizationId: organization.id,
          updateOrganizationRequest: {
            distributionSettings: values,
          },
        })
        .then(() => {
          successToast('Distribution settings updated successfully');
        })
        .catch((err) => {
          errorToast(`Error updating distribution settings: ${err.message}`);
        });
    },
    [mutation, organization.id, errorToast, successToast],
  );

  return (
    <Content title="Distributions">
      <Box maxW="600px" p={4}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack spacing={6}>
              <FormSection heading="Default distribution type">
                <HookedFormItem
                  name="defaultDistributionType"
                  helperText="Which distribution type should creating a distribution default to?"
                >
                  <HookedDistributionTypeChooser
                    name="defaultDistributionType"
                    data-pw="distributionDefaultTypeChooser"
                  />
                </HookedFormItem>
              </FormSection>
              <FormSection heading="Default distribution">
                <DefaultDistributionChooser />
              </FormSection>
              <Divider />
              <HStack alignItems="center">
                <HookedResetButton>Reset</HookedResetButton>
                <HookedSubmitButton>Save</HookedSubmitButton>
              </HStack>
            </Stack>
          </form>
        </FormProvider>
      </Box>
    </Content>
  );
};
