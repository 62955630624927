import gql from 'graphql-tag';

export const CreateReportsExportJob = gql`
  mutation CreateReportsExportJob($organizationId: ID!, $request: CreateReportsExportJobRequest!) {
    createReportsExportJob(organizationId: $organizationId, request: $request) {
      id
      type
      status
      progress
      queuedAt
      startedAt
      finishedAt
      payload
    }
  }
`;
