const variants = {
  inline: {
    _disabled: {
      opacity: 1.0,
    },
    label: {
      _disabled: {
        opacity: 1.0,
      },
    },
  },
};

export const Checkbox = {
  variants,
};
