import gql from 'graphql-tag';

export const UsersMinimal = gql`
  query UsersMinimal($organizationId: ID!) {
    organization(organizationId: $organizationId) {
      users {
        id
        name
      }
    }
  }
`;

export const Users = gql`
  query Users($organizationId: ID!) {
    organization(organizationId: $organizationId) {
      users {
        id
        name
        email
        picture
        organizationMemberships {
          organizationId
          roles {
            id
            name
          }
        }
      }
    }
  }
`;
