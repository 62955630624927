import { createColumnHelper, DataTable, FancyDate } from '@piccolohealth/ui';
import { DateTime } from '@piccolohealth/util';
import React from 'react';
import { TextLink } from '../../components/generic/TextLink';
import { useAppContext } from '../../hooks/useAppContext';
import { HistoricalVariable, VariableHistory } from '../../hooks/useHistoricalVariables';
import { HistoricalMeasurementValue } from './HistoricalMeasurementValue';

interface Props {
  history: VariableHistory;
}

export const MeasurementsTableContent = (props: Props) => {
  const { history } = props;
  const { organization } = useAppContext();

  const columns = React.useMemo(() => {
    const columnHelper = createColumnHelper<HistoricalVariable>();

    return [
      columnHelper.display({
        header: 'Date',
        cell: (ps) => (
          <TextLink to={`/organizations/${organization.id}/reports/${ps.row.original.reportId}`}>
            <FancyDate date={DateTime.fromJSDate(ps.row.original.createdAt)} />
          </TextLink>
        ),

        minSize: 200,
      }),
      columnHelper.display({
        header: 'Value',
        cell: (ps) => {
          return <HistoricalMeasurementValue variable={ps.row.original.variable} />;
        },
      }),
    ];
  }, [organization.id]);

  return <DataTable columns={columns} data={history.all} size="sm" />;
};
