import gql from 'graphql-tag';

export const Labels = gql`
  query Labels($organizationId: ID!) {
    organization(organizationId: $organizationId) {
      id
      labels {
        id
        name
        description
        color
        createdAt
        updatedAt
      }
    }
  }
`;
