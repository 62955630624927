import {
  Button,
  ButtonGroup,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import React from 'react';
import { createModal } from '../../../components/generic/Modal';
import { useUpdateOrganizationMutation } from '../../../graphql/hooks/useUpdateOrganizationMutation';
import { useAppContext } from '../../../hooks/useAppContext';

export const DefaultDistributionDeleteModal = createModal((props) => {
  const { modal } = props;
  const { organization, successToast, errorToast } = useAppContext();
  const mutation = useUpdateOrganizationMutation({});
  const { hide, visible } = modal;

  const onClick = React.useCallback(async () => {
    await mutation
      .mutateAsync({
        organizationId: organization.id,
        updateOrganizationRequest: {
          distributionSettings: {
            defaultDistribution: null,
          },
        },
      })
      .then(async () => {
        hide();
        successToast('Default distribution deleted successfully');
      })
      .catch((err) => {
        errorToast(`Error deleting default distribution: ${err.message}`);
      });
  }, [mutation, organization, hide, successToast, errorToast]);

  return (
    <Modal isOpen={visible} onClose={hide} onCloseComplete={modal.remove}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Do you want to delete this distribution?</ModalHeader>
        <ModalCloseButton />
        <ModalFooter>
          <ButtonGroup size="sm">
            <Button key="close" onClick={hide}>
              Close
            </Button>
            <Button
              key="submit"
              colorScheme="red"
              isLoading={mutation.isLoading}
              loadingText="Deleting..."
              type="submit"
              onClick={onClick}
              data-pw="distributionDeleteModalSubmitButton"
            >
              Delete
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});
