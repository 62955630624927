import { AppState, Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useConfig } from './ConfigContext';

export const AuthContextProvider = (props: React.PropsWithChildren<{}>) => {
  const { config } = useConfig();
  const navigate = useNavigate();

  const onAuthRedirectCallback = React.useCallback(
    (appState: AppState | undefined) => {
      if (appState?.targetUrl) {
        navigate(appState.targetUrl || window.location.pathname);
      }
    },
    [navigate],
  );

  return (
    <Auth0Provider
      domain={config.auth.domain}
      audience={config.auth.audience}
      clientId={config.auth.clientId}
      redirectUri={window.location.origin}
      onRedirectCallback={onAuthRedirectCallback}
      cacheLocation={config.auth.cacheLocation}
    >
      {props.children}
    </Auth0Provider>
  );
};
