import {
  ReportTemplate,
  ReportTemplateStatementSite,
  ReportTemplateVariable,
} from '../graphql/types';
import { LooseObject } from './generic';

export interface ExportedReportTemplate {
  id: string;
  name: string;
  statementSites: ReportTemplateStatementSite[];
  variables: ReportTemplateVariable[];
  reportNodes: LooseObject;
}

export const serializeExportedReportTemplate = (
  reportTemplate: ReportTemplate,
): ExportedReportTemplate => {
  return {
    id: reportTemplate.id,
    name: reportTemplate.name,
    statementSites: reportTemplate.statementSites,
    variables: reportTemplate.variables,
    reportNodes: reportTemplate.reportNodes,
  };
};

export const deserializeExportedReportTemplate = (reportTemplate: ExportedReportTemplate) => {
  return {
    name: reportTemplate.name,
    statementSites: reportTemplate.statementSites,
    variables: reportTemplate.variables,
    reportNodes: reportTemplate.reportNodes,
  };
};

export const getReportTemplateVariableForVariable = (
  reportTemplate: ReportTemplate,
  variableId: string,
) => {
  return reportTemplate.variables.find((vt) => vt.id === variableId);
};

export const getReportTemplateVariableForVariableByAlias = (
  reportTemplate: ReportTemplate,
  alias: string,
) => {
  return reportTemplate.variables.find((vt) => vt.alias === alias);
};

export const extractVariableIdsFromStatement = (statement: string): string[] => {
  const regex = /<variable id="(.*?)"/g;
  const matches = [];
  let match;
  while ((match = regex.exec(statement)) !== null) {
    matches.push(match[1]);
  }
  return matches;
};
