import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/react';
import React from 'react';
import { createModal } from '../../components/generic/Modal';
import { ChooseActionStep } from './ChooseActionStep';
import { CreateReportActionStep } from './CreateReportActionStep';
import { UploadActionStep } from './UploadActionStep';

type Action = 'choose-action' | 'upload' | 'create-report';

export const ReportCreateModal = createModal<{}>((props) => {
  const { modal } = props;

  const [currentStep, setCurrentStep] = React.useState<Action>('choose-action');

  const onClose = React.useCallback(() => {
    modal.hide();
    setCurrentStep('choose-action');
  }, [modal, setCurrentStep]);

  const content = React.useMemo(() => {
    switch (currentStep) {
      case 'choose-action': {
        return (
          <ModalContent h="lg">
            <ChooseActionStep
              onClose={onClose}
              onUploadAction={() => setCurrentStep('upload')}
              onCreateReportAction={() => setCurrentStep('create-report')}
            />
          </ModalContent>
        );
      }
      case 'upload':
        return (
          <ModalContent h="2xl">
            <UploadActionStep onClose={onClose} onBack={() => setCurrentStep('choose-action')} />
          </ModalContent>
        );
      case 'create-report':
        return (
          <ModalContent>
            <CreateReportActionStep
              onClose={onClose}
              onBack={() => setCurrentStep('choose-action')}
            />
          </ModalContent>
        );
    }
  }, [currentStep, onClose]);

  return (
    <Modal isOpen={modal.visible} onClose={onClose} size="3xl">
      <ModalOverlay />
      {content}
    </Modal>
  );
});
