import gql from 'graphql-tag';

export const SubscriptionItems = gql`
  query SubscriptionItems($organizationId: ID!) {
    organization(organizationId: $organizationId) {
      billing {
        subscriptionItems {
          id
          product {
            id
            name
          }
        }
      }
    }
  }
`;

export const BillingActions = gql`
  query BillingActions($organizationId: ID!) {
    organization(organizationId: $organizationId) {
      billing {
        actions {
          id
          stripeProductId
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const Invoices = gql`
  query Invoices($organizationId: ID!) {
    organization(organizationId: $organizationId) {
      billing {
        invoices {
          id
          organizationId
          periodStart
          periodEnd
          createdAt
          due
          status
          number
          amountDue
          quantity
          pdfDownloadUrl
          hostedPaymentUrl
        }
      }
    }
  }
`;

export const Invoice = gql`
  query Invoice($organizationId: ID!, $invoiceId: ID!) {
    organization(organizationId: $organizationId) {
      billing {
        invoice(invoiceId: $invoiceId) {
          id
          organizationId
          periodStart
          periodEnd
          createdAt
          due
          status
          number
          amountDue
          quantity
          subscriptionItems {
            id
            product {
              id
              name
            }
            usageQuantity
            usageRecords {
              timestamp
              reportId
              patientName
              report {
                createdAt
                status
                reportTemplate {
                  name
                  labelColor
                }
              }
            }
          }
        }
      }
    }
  }
`;
