import { Box, Button, Divider, HStack, Stack, VStack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { LooseObject } from '@piccolohealth/util';
import { FinalizeBehavior, User } from '@piccolohealth/echo-common';
import _ from 'lodash';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { FormItem } from '../../components/forms/FormItem';
import { FormSection } from '../../components/forms/FormSection';
import { HookedAvatarUploader } from '../../components/forms/hookform/HookedAvatarUploader';
import { HookedFormItem } from '../../components/forms/hookform/HookedFormItem';
import { HookedInput } from '../../components/forms/hookform/HookedInput';
import { HookedRadioStack } from '../../components/forms/hookform/HookedRadioStack';
import { HookedResetButton } from '../../components/forms/hookform/HookedResetButton';
import { HookedSelect } from '../../components/forms/hookform/HookedSelect';
import { HookedSubmitButton } from '../../components/forms/hookform/HookedSubmitButton';
import { Content } from '../../components/generic/Content';
import { useCreatePasswordChangeTicketUrlMutation } from '../../graphql/hooks/useCreatePasswordChangeTicketUrlMutation';
import { useUpdateProfileMutation } from '../../graphql/hooks/useUpdateProfileMutation';
import { useAppContext } from '../../hooks/useAppContext';

export const Profile = () => {
  const { successToast, errorToast, user } = useAppContext();

  const updateProfileMutation = useUpdateProfileMutation();
  const changePasswordMutation = useCreatePasswordChangeTicketUrlMutation();

  const initialValues: Partial<User> = {
    name: user.name,
    email: user.email,
    picture: user.picture,
    settings: user.settings,
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email address').required('Required'),
    picture: Yup.string().nullable(),
    settings: Yup.object({
      defaultOrganization: Yup.string().nullable(),
      finalizeBehavior: Yup.string().nullable(),
    }),
  });

  const methods = useForm({
    defaultValues: initialValues as any,
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (values: Partial<User>) => {
    const updateProfileRequest = _.reduce(
      values,
      (acc: LooseObject, value, key) => {
        return !_.isEqual(value, (initialValues as LooseObject)[key])
          ? { ...acc, [key]: value }
          : acc;
      },
      {},
    );

    await updateProfileMutation
      .mutateAsync({
        updateProfileRequest,
      })
      .then(() => {
        successToast('Profile edited successfully');
      })
      .catch((err) => {
        errorToast(`Error editing profile: ${err.message}`);
      });
  };

  const resetPassword = async () => {
    const resp = await changePasswordMutation.mutateAsync({});
    window.location.href = resp.createPasswordChangeTicketUrl;
  };

  return (
    <Content title="Profile">
      <Box w="lg" p={4}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack spacing={8}>
              <FormSection heading="Account">
                <HStack w="full" spacing={8}>
                  <VStack flexGrow={1} spacing={4}>
                    <HookedFormItem label="Name" name="name">
                      <HookedInput name="name" placeholder="Name" />
                    </HookedFormItem>
                    <HookedFormItem label="Email" name="email">
                      <HookedInput name="email" placeholder="Email" />
                    </HookedFormItem>
                  </VStack>

                  <Box>
                    <HookedFormItem label="Picture" name="picture">
                      <HookedAvatarUploader name="picture" size="xl" username={user.name} />
                    </HookedFormItem>
                  </Box>
                </HStack>
              </FormSection>
              <Divider />
              <FormSection heading="Settings">
                <HookedFormItem
                  label="Default organization"
                  helperText="Which organization should load when you login?"
                  name="settings.defaultOrganization"
                >
                  <HookedSelect
                    name="settings.defaultOrganization"
                    options={_.map(user.organizationMemberships, (membership) => ({
                      label: membership.name,
                      value: membership.organizationId,
                      raw: membership.organizationId,
                    }))}
                  />
                </HookedFormItem>

                <HookedFormItem
                  label="Finalize Behavior"
                  helperText="What should happen when you finalize a report?"
                  name="settings.finalizeBehavior"
                >
                  <HookedRadioStack
                    name="settings.finalizeBehavior"
                    options={[
                      {
                        value: FinalizeBehavior.Noop,
                        label: 'Stay on this report',
                        description: 'Finalize and stay on the current report',
                      },
                      {
                        value: FinalizeBehavior.Reports,
                        label: 'Go to reports page',
                        description: 'Finalize and go to the reports page',
                      },
                      {
                        value: FinalizeBehavior.NextReport,
                        label: 'Go to next report',
                        description: 'Finalize and go to the next assigned report',
                      },
                    ]}
                  />
                </HookedFormItem>
              </FormSection>
              <Divider />
              <FormSection heading="Security">
                <FormItem id="resetPassword" helperText="Request a new password">
                  <Button
                    onClick={resetPassword}
                    colorScheme="purple"
                    size="sm"
                    alignSelf="flex-start"
                  >
                    Reset Password
                  </Button>
                </FormItem>
              </FormSection>
              <Divider />
              <HStack alignItems="center">
                <HookedResetButton>Reset</HookedResetButton>
                <HookedSubmitButton>Save</HookedSubmitButton>
              </HStack>
            </Stack>
          </form>
        </FormProvider>
      </Box>
    </Content>
  );
};
