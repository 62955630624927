import { P } from '@piccolohealth/util';
import React from 'react';

export interface TransferListOption<A> {
  label: string;
  value: string;
  raw: A;
}

export interface UseTransferListOptions<A> {
  options: TransferListOption<A>[];
  value: TransferListOption<A>[];
  onChange: (values: TransferListOption<A>[]) => void;
  isLeftLoading?: boolean;
  isRightLoading?: boolean;
}

export const useTransferList = <A,>(opts: UseTransferListOptions<A>) => {
  const { options, value, isLeftLoading, isRightLoading, onChange } = opts;

  const [selectedLeftOptions, setSelectedLeftOptions] = React.useState<TransferListOption<A>[]>([]);
  const [selectedRightOptions, setSelectedRightOptions] = React.useState<TransferListOption<A>[]>(
    [],
  );

  const onClickLeftOption = React.useCallback(
    (option: TransferListOption<A>) => {
      if (selectedLeftOptions.includes(option)) {
        setSelectedLeftOptions((prev) => prev.filter((o) => o.value !== option.value));
      } else {
        setSelectedLeftOptions((prev) => P.uniqBy([...prev, option], (option) => option.value));
      }
    },
    [selectedLeftOptions],
  );

  const onClickRightOption = React.useCallback(
    (option: TransferListOption<A>) => {
      if (selectedRightOptions.includes(option)) {
        setSelectedRightOptions((prev) => prev.filter((o) => o.value !== option.value));
      } else {
        setSelectedRightOptions((prev) => P.uniqBy([...prev, option], (option) => option.value));
      }
    },
    [selectedRightOptions],
  );

  const leftOptions = React.useMemo(() => {
    return options
      .filter((option) => !value.some((v: TransferListOption<A>) => v.value === option.value))
      .map((option) => {
        return {
          ...option,
          isSelected: selectedLeftOptions.some((o) => o.value === option.value),
          onClick: () => onClickLeftOption(option),
        };
      });
  }, [options, value, selectedLeftOptions, onClickLeftOption]);

  const rightOptions = React.useMemo(() => {
    return value.map((option) => {
      return {
        ...option,
        isSelected: selectedRightOptions.some((o) => o.value === option.value),
        onClick: () => onClickRightOption(option),
      };
    });
  }, [onClickRightOption, selectedRightOptions, value]);

  const onClickTransferToLeft = React.useCallback(() => {
    onChange(value.filter((option) => !selectedRightOptions.includes(option)));
    setSelectedLeftOptions([]);
    setSelectedRightOptions([]);
  }, [onChange, selectedRightOptions, value]);

  const onClickTransferToRight = React.useCallback(() => {
    onChange(P.uniqBy([...value, ...selectedLeftOptions], (option) => option.value));
    setSelectedLeftOptions([]);
    setSelectedRightOptions([]);
  }, [onChange, selectedLeftOptions, value]);

  return {
    options,
    isLeftLoading,
    isRightLoading,
    rightOptions,
    leftOptions,
    selectedLeftOptions,
    selectedRightOptions,
    setSelectedLeftOptions,
    setSelectedRightOptions,
    onClickTransferToLeft,
    onClickTransferToRight,
  };
};

export type UseTransferListReturn<A> = ReturnType<typeof useTransferList<A>>;
