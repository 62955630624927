const baseStyle = () => ({
  list: {
    p: 1.5,
  },
  item: {
    rounded: 'md',
  },
});

export const Menu = {
  baseStyle,
};
