import { Box } from '@chakra-ui/react';
import { Invoice } from '@piccolohealth/echo-common';
import React from 'react';
import { Content } from '../../components/generic/Content';
import { useInvoicesQuery } from '../../graphql/hooks/useBillingQuery';
import { useAppContext } from '../../hooks/useAppContext';
import { InvoicesTable } from './components/InvoicesTables';

export const Invoices = () => {
  const { organization } = useAppContext();

  const { isLoading, error, data } = useInvoicesQuery({
    organizationId: organization.id,
  });

  const invoices = (data?.organization?.billing.invoices as Invoice[]) ?? [];

  return (
    <Content title="Invoices">
      <Box p={4}>
        <InvoicesTable invoices={invoices} isLoading={isLoading} error={error} />
      </Box>
    </Content>
  );
};
