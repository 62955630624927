import { Button, HStack, Stack } from '@chakra-ui/react';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { uuid, ReportTemplateVariableChoice } from '@piccolohealth/echo-common';
import _ from 'lodash';
import React from 'react';
import { useFieldArray } from 'react-hook-form';
import { HookedInput } from '../../forms/hookform/HookedInput';
import { HookedSwitch } from '../../forms/hookform/HookedSwitch';
import { RemoveButton } from '../../generic/RemoveButton';
import { DragHandle } from '../../sortable/DragHandle';
import { Sortable } from '../../sortable/Sortable';

interface SortableItemProps {
  id: string;
  onRemove: () => void;
}

const SortableItem = (props: React.PropsWithChildren<SortableItemProps>) => {
  const { id, onRemove, children } = props;

  const { attributes, setNodeRef, listeners, transform, transition } = useSortable({
    id,
  });

  return (
    <Sortable ref={setNodeRef} transform={transform} transition={transition}>
      <HStack mb={2}>
        <DragHandle
          listeners={listeners}
          attributes={attributes}
          variant="unstyled"
          color="gray.800"
          minW="auto"
          h="auto"
          fontSize="12px"
        />
        <RemoveButton onRemove={onRemove} isDisabled={false} />
        {children}
      </HStack>
    </Sortable>
  );
};

interface Props {
  name: string;
}

export const ReportTemplateVariableChoiceForm = (props: Props) => {
  const { name } = props;

  const { fields, append, remove, move } = useFieldArray({
    name,
    keyName: 'sortableId',
  });

  const choices = fields as unknown as (ReportTemplateVariableChoice & { sortableId: string })[];

  const onRemoveItem = React.useCallback(
    (index: number) => () => {
      remove(index);
    },
    [remove],
  );

  const onAddItem = React.useCallback(() => {
    append({
      id: uuid(),
      label: 'New choice',
      value: 'new choice',
      default: false,
    });
  }, [append]);

  const onDragEnd = React.useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;
      if (over && active.id !== over.id) {
        const oldIndex = _.findIndex(choices, (item) => item.id === active.id);
        const newIndex = _.findIndex(choices, (item) => item.id === over.id);
        move(oldIndex, newIndex);
      }
    },
    [move, choices],
  );

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
  );

  return (
    <Stack align="start">
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={onDragEnd}>
        <SortableContext
          strategy={verticalListSortingStrategy}
          items={_.map(choices, ({ id }) => id)}
        >
          <Stack w="full">
            {_.map(choices, (choice, index) => {
              return (
                <SortableItem key={choice.id} id={choice.id} onRemove={onRemoveItem(index)}>
                  <HookedInput name={`${name}[${index}].label`} />
                  <HookedInput name={`${name}[${index}].value`} />
                  <HookedSwitch name={`${name}[${index}].default`} colorScheme="purple" />
                </SortableItem>
              );
            })}
          </Stack>
        </SortableContext>
      </DndContext>
      <Button colorScheme="purple" variant="outline" size="sm" onClick={() => onAddItem()}>
        Add choice
      </Button>
    </Stack>
  );
};
