import { Handlebars } from '@piccolohealth/echo-common';
import _ from 'lodash';
import { useWatch } from 'react-hook-form';
import { useReport } from '../context/ReportContext';

export const useCompiledTemplate = (template: string) => {
  const { timezone, reportTemplate } = useReport();

  const handlebarsVariables = _.flatMap(
    Handlebars.getHandlebarsVariables(template),
    (v) => v.variables,
  );
  const variableIds = _.map(handlebarsVariables, (v) => `variables.${v}`);
  const variables = _.compact(useWatch({ name: variableIds }));
  const values = _.keyBy(variables, 'id');

  const compiledTemplate = Handlebars.compileHandlebarsTemplate({
    timezone,
    template,
    reportTemplate,
    values,
  });

  return {
    values,
    compiledTemplate,
  };
};
