import { MenuItem, Text } from '@chakra-ui/react';
import { PERMISSIONS } from '@piccolohealth/echo-common';
import React from 'react';
import { FaRegTrashAlt } from 'react-icons/fa';
import { usePermission } from '../../hooks/usePermission';
import { showModal } from '../generic/Modal';
import { ReportDeleteModal } from '../modals/ReportDeleteModal';

interface Props {
  reportId: string;
  options: {
    onDelete: () => Promise<void>;
  };
}

export const ReportDeleteMenuItem = (props: Props) => {
  const { reportId, options } = props;
  const hasPermission = usePermission(PERMISSIONS.reportsDelete).value;

  return (
    <MenuItem
      isDisabled={!hasPermission}
      onClick={() => showModal(ReportDeleteModal, { reportId, onDelete: options.onDelete })}
      icon={<FaRegTrashAlt />}
      data-pw="reportDeleteMenuItem"
    >
      <Text color="danger">Delete</Text>
    </MenuItem>
  );
};
