import React from 'react';
import { HookedFormItem } from '../../../components/forms/hookform/HookedFormItem';
import { HookedMedicalObjectsProviderFromChooser } from '../../../components/forms/hookform/HookedMedicalObjectsProviderFromChooser';
import { HookedMedicalObjectsProviderYoChooser } from '../../../components/forms/hookform/HookedMedicalObjectsProviderToChooser';

interface Props {
  reportId?: string;
}

export const DistributionMedicalObjectsPayloadForm = (props: Props) => {
  const { reportId } = props;

  return (
    <>
      <HookedFormItem
        name="payload.from.providerNumber"
        label="From provider"
        helperText="Which provider do you want to distribute from?"
      >
        <HookedMedicalObjectsProviderFromChooser name="payload.from" reportId={reportId} />
      </HookedFormItem>

      <HookedFormItem
        name="payload.to.providerNumber"
        label="To provider"
        helperText="Which provider do you want to distribute to?"
      >
        <HookedMedicalObjectsProviderYoChooser name="payload.to" />
      </HookedFormItem>
    </>
  );
};
