import {
  Button,
  ButtonGroup,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { User } from '@piccolohealth/echo-common';
import React from 'react';
import { useRemoveUserMutation } from '../../graphql/hooks/useRemoveUserMutation';
import { useAppContext } from '../../hooks/useAppContext';
import { createModal } from '../generic/Modal';

interface Props {
  user: User;
}

export const UserRemoveModal = createModal<Props>((props) => {
  const { user, modal } = props;
  const { organization, successToast, errorToast } = useAppContext();

  const mutation = useRemoveUserMutation();

  const onClick = () => {
    mutation
      .mutateAsync({
        organizationId: organization.id,
        userId: user.id,
      })
      .then(() => {
        successToast('User removed successfully');
        modal.hide();
      })
      .catch((err) => {
        errorToast(`Error removing user: ${err.message}`);
      });
  };

  return (
    <Modal isOpen={modal.visible} onClose={modal.hide} onCloseComplete={modal.remove}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader data-pw="userRemoveModalDescriptionHeader">
          Do you want to remove this user?
        </ModalHeader>
        <ModalCloseButton />
        <ModalFooter>
          <ButtonGroup size="sm">
            <Button key="close" onClick={modal.hide} data-pw="userRemoveModalCloseButton">
              Close
            </Button>
            <Button
              key="submit"
              colorScheme="red"
              isLoading={mutation.isLoading}
              loadingText="Removing..."
              type="submit"
              onClick={onClick}
              data-pw="userRemoveModalSubmitButton"
            >
              Remove
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});
