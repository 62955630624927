import { Tag, TagProps } from '@chakra-ui/react';
import { ReportTemplateVariableSource } from '@piccolohealth/echo-common';
import React from 'react';

interface Props extends TagProps {
  source: ReportTemplateVariableSource;
}

export const getColorAndText = (source: ReportTemplateVariableSource) => {
  switch (source) {
    case ReportTemplateVariableSource.Dynamic:
      return { color: 'purple', text: 'Dynamic' };
    case ReportTemplateVariableSource.Static:
      return { color: 'orange', text: 'Static' };
  }
};

export const ReportTemplateVariableSourceTag = (props: Props) => {
  const { source, ...rest } = props;
  const { color, text } = getColorAndText(source);

  return (
    <Tag colorScheme={color} {...rest}>
      {text}
    </Tag>
  );
};
