import React from 'react';

/**
 * A custom hook that converts a callback to a ref to avoid triggering re-renders when passed as a
 * prop or avoid re-executing effects when passed as a dependency
 */
export function useStableCallback<T extends (...args: any[]) => any>(callback: T | undefined): T {
  const callbackRef = React.useRef(callback);

  React.useEffect(() => {
    callbackRef.current = callback;
  });

  // https://github.com/facebook/react/issues/19240
  return React.useMemo(() => ((...args) => callbackRef.current?.(...args)) as T, []);
}

export const useDebouncedCallback = <Callback extends (...args: any[]) => any>(
  cb: Callback,
  delay = 3000,
) => {
  const latestCallback = useStableCallback(cb);
  const latestTimerId = React.useRef<ReturnType<typeof setTimeout>>();

  React.useEffect(() => {
    return function cleanup() {
      if (latestTimerId.current) {
        clearTimeout(latestTimerId.current);
      }
    };
  }, []);

  return React.useCallback(
    function cb(...args: Parameters<Callback>) {
      if (latestTimerId.current) {
        clearTimeout(latestTimerId.current);
      }
      latestTimerId.current = setTimeout(() => {
        latestCallback(...args);
      }, delay);
    },
    [delay, latestCallback],
  );
};
