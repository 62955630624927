import { Box, Flex, VStack } from '@chakra-ui/react';
import { SerializedNodes } from '@craftjs/core';
import React from 'react';
import { CopyPaste } from './CopyPaste';
import { EditorSave } from './EditorSave';
import { Toolbox, ToolboxComponent } from './Toolbox';
import { UndoRedo } from './UndoRedo';

interface Props {
  componentGroups: {
    name: string;
    components: ToolboxComponent[];
  }[];
  onSave: (nodes: SerializedNodes) => Promise<void>;
}

export const LeftSidebar = (props: Props) => {
  const { componentGroups, onSave } = props;

  return (
    <Flex h="100%" flexDir="column" justifyContent="space-between" overflowY="auto" padding={4}>
      <Box>
        <Toolbox componentGroups={componentGroups} />
      </Box>
      <VStack>
        <UndoRedo />
        <CopyPaste />
        <EditorSave onSave={onSave} />
      </VStack>
    </Flex>
  );
};
