import React from 'react';
import { HookedFormItem } from '../../../components/forms/hookform/HookedFormItem';
import { HookedHealthLinkProviderFromChooser } from '../../../components/forms/hookform/HookedHealthLinkProviderFromChooser';
import { HookedHealthProviderToChooser } from '../../../components/forms/hookform/HookedHealthLinkProviderToChooser';

export const DistributionHealthLinkPayloadForm = () => {
  return (
    <>
      <HookedFormItem
        name="payload.from.providerNumber"
        label="From Healthlink provider"
        helperText="Which provider do you want to distribute from?"
      >
        <HookedHealthLinkProviderFromChooser name="payload.from" />
      </HookedFormItem>
      <HookedFormItem
        name="payload.to.providerNumber"
        label="To Healthlink provider"
        helperText="Which provider do you want to distribute to?"
      >
        <HookedHealthProviderToChooser name="payload.to" />
      </HookedFormItem>
    </>
  );
};
