import React from 'react';
import { HookedAutoCompleteEmail } from '../../../components/forms/hookform/HookedAutoCompleteEmail';
import { HookedFormItem } from '../../../components/forms/hookform/HookedFormItem';
import { HookedTextArea } from '../../../components/forms/hookform/HookedTextArea';
import { HookedUserChooser } from '../../../components/forms/hookform/HookedUserChooser';

export const DistributionEmailPayloadForm = () => {
  return (
    <>
      <HookedFormItem name="payload.sharerId" label="From user" helperText="Who is email from?">
        <HookedUserChooser
          name="payload.sharerId"
          data-pw="distributionModalEmailSharerSelect"
          placeholder="Choose a user"
          isDisabled={false}
        />
      </HookedFormItem>
      <HookedFormItem
        name="payload.shareeEmail"
        label="To email"
        helperText="Who should receive the email?"
      >
        <HookedAutoCompleteEmail
          name="payload.shareeEmail"
          data-pw="distributionModalEmailShareeAutocomplete"
          placeholder="Enter an email"
          isDisabled={false}
        />
      </HookedFormItem>
      <HookedFormItem
        name="payload.message"
        label="Message"
        helperText="What should the email say?"
      >
        <HookedTextArea
          name="payload.message"
          data-pw="distributionModalEmailMessageTextArea"
          placeholder="Enter a message"
          size="sm"
          minRows={2}
          maxRows={3}
        />
      </HookedFormItem>
    </>
  );
};
