import React from 'react';
import { useController } from 'react-hook-form';
import { FeatureFlagSwitches } from '../FeatureFlagSwitches';

interface Props {
  name: string;
}

export const HookedFeatureFlagSwitches = (props: Props) => {
  const { name } = props;

  const {
    field: { ref, ...inputProps },
  } = useController({ name });

  return <FeatureFlagSwitches {...inputProps} />;
};
