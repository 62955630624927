import { Flex, Heading, Stack } from '@chakra-ui/react';
import { Query } from '@cubejs-client/core';
import { P } from '@piccolohealth/util';
import React from 'react';
import { CubeQuery } from './CubeQuery';

interface Props {
  query: Query;
}

export const CubeValue = (props: Props) => {
  const { query } = props;

  return (
    <CubeQuery
      query={query}
      component={(resultSet) => {
        const data = resultSet.series({ fillMissingDates: true }).map(({ title, series }) => {
          return {
            name: title,
            value: series[0].value,
          };
        });

        const current = P.first(data);

        return (
          <Flex direction="column" w="full">
            <Stack direction="row" align="center" spacing={4}>
              <Heading lineHeight={1.1} size="2xl">
                {current?.value}
              </Heading>
            </Stack>
          </Flex>
        );
      }}
    />
  );
};
