import { DistributionType } from '@piccolohealth/echo-common';
import * as Yup from 'yup';

export const distributionValidationSchema = Yup.lazy(
  <A extends { type: DistributionType }>(values: A) => {
    switch (values.type) {
      case DistributionType.MedicalObjects: {
        return Yup.object({
          trigger: Yup.string().required('Required trigger'),
          payload: Yup.object({
            to: Yup.object({
              providerNumber: Yup.string().required('To provider number is required'),
            }),
            from: Yup.object({
              providerNumber: Yup.string().required('From provider number is required'),
            }),
          }),
        });
      }

      case DistributionType.Email: {
        return Yup.object({
          trigger: Yup.string().required('Required trigger'),
          payload: Yup.object({
            sharerId: Yup.string().required('Sharer is required'),
            shareeEmail: Yup.string()
              .email('Email must be a valid address')
              .required('Email is required'),
          }),
        });
      }

      case DistributionType.HealthLink: {
        return Yup.object({
          trigger: Yup.string().required('Required trigger'),
          payload: Yup.object({
            to: Yup.object({
              practitionerId: Yup.string().required('To practitioner is required'),
              practitionerName: Yup.object().required('To practitioner name is required'),
              endpointId: Yup.string().required('To endpoint is required'),
              endpointName: Yup.string().required('To endpoint name is required'),
              organizationId: Yup.string().required('To organization is required'),
              organizationName: Yup.string().required('To organization name is required'),
              organizationAddress: Yup.object().required('To organization address is required'),
              providerNumber: Yup.string().required('To provider number is required'),
            }),
            from: Yup.object({
              name: Yup.string().required('From provider name is required'),
              providerNumber: Yup.string().required('From provider number is required'),
            }),
          }),
        });
      }

      case DistributionType.Fax: {
        return Yup.object({
          trigger: Yup.string().required('Required trigger'),
          payload: Yup.object({
            to: Yup.object({
              faxNumber: Yup.string()
                .required('To fax number is required')
                .nullable()
                .matches(
                  /^(02[3-9]\d{7}|03[4-9]\d{7}|07[3-9]\d{7}|08\d{8})$/,
                  'Invalid fax number format. Should be 10 digits starting with 02, 03, 07 or 08',
                ),
            }),
            from: Yup.object({
              sharerId: Yup.string().required('From id is required'),
            }),
          }),
        });
      }

      default: {
        return Yup.object();
      }
    }
  },
);
