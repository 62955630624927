import {
  Box,
  Flex,
  HStack,
  IconButton,
  SkeletonText,
  Spacer,
  Stack,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import {
  FinalizeBehavior,
  getReportVariableValue,
  getReportVariableValueAsString,
  renderNullable,
  Report,
  ReportStatusFilter,
} from '@piccolohealth/echo-common';
import _ from 'lodash';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FaInfoCircle } from 'react-icons/fa';
import { useReportsQuery } from '../../../graphql/hooks/useReportsQuery';
import { useAppContext } from '../../../hooks/useAppContext';
import { Error } from '../../generic/Error';
import { FancyDate } from '@piccolohealth/ui';
import { ReportTypeTag } from '../../generic/ReportTypeTag';
import { DateTime } from '@piccolohealth/util';

const NextReportDetails = (props: { nextReport: Report }) => {
  const { nextReport } = props;

  const patientName = getReportVariableValueAsString(nextReport.variables, 'patientName');
  const uploadDate = DateTime.fromISO(nextReport.createdAt as unknown as string);
  const reportTemplate = nextReport.reportTemplate;
  const cardiologists = getReportVariableValue<string[]>(
    nextReport.variables || [],
    'cardiologist',
  );
  const sonographers = getReportVariableValue<string[]>(nextReport.variables || [], 'sonographer');
  const users = _.compact(_.uniq(_.concat(cardiologists, sonographers)));
  return (
    <VStack align="start" spacing={4} w="full" fontSize="sm">
      <Flex w="full" align="center">
        <Box>
          <Text fontWeight="semibold">{renderNullable(patientName)}</Text>
          <Box color="secondary">
            <FancyDate date={uploadDate} direction="row" />
            <HStack>
              {_.map(users, (u) => (
                <Text key={u}>{u}</Text>
              ))}
            </HStack>
          </Box>
        </Box>
        <Spacer />
        <VStack align="end">
          <ReportTypeTag name={reportTemplate.name} labelColor={reportTemplate.labelColor} />
        </VStack>
      </Flex>
    </VStack>
  );
};

export const FinalizeBehaviorSummary = (props: { report: Report }) => {
  const { report } = props;
  const { organization, user } = useAppContext();
  const { setValue } = useFormContext();
  const finalizeBehavior = useWatch({ name: 'finalizeBehavior' }) as FinalizeBehavior;

  const { isLoading, data, error } = useReportsQuery(
    {
      organizationId: organization.id,
      getReportsRequest: {
        pagination: {
          // Get the 2 latest reports incase the latest report is the current report. If the current report
          // is the latest report, we stil want to show a previous report as a candidate.
          limit: 2,
        },
        statuses: [ReportStatusFilter.AwaitingReview],
        assignedTo: [user.name],
      },
    },
    // TODO: Move cacheTime to query, as we never want to cache reports query as can't afford to have
    // out of date data for anyone using this query
    { cacheTime: 0 },
  );

  // The next report is the latest report that isn't the current report
  const nextReport = _.find(data?.organization?.reports?.reports, (r) => r.id !== report.id) as
    | Report
    | undefined;

  React.useEffect(() => {
    if (isLoading) {
      return;
    }

    if (nextReport) {
      setValue('nextReportId', nextReport.id);
    } else {
      const finalizeBehavior =
        user.settings.finalizeBehavior === FinalizeBehavior.NextReport
          ? FinalizeBehavior.Noop
          : user.settings.finalizeBehavior;
      setValue('finalizeBehavior', finalizeBehavior);
    }
  }, [nextReport, setValue, user.settings.finalizeBehavior, isLoading]);

  if (isLoading) {
    return <SkeletonText noOfLines={5} />;
  }

  if (error) {
    return <Error error={error} />;
  }

  const message = {
    [FinalizeBehavior.Noop]: 'Upon finalizing you will remain on this report',
    [FinalizeBehavior.Reports]: 'Upon finalizing you will be taken back to the reports page',
    [FinalizeBehavior.NextReport]: nextReport
      ? 'Upon finalizing you will be taken to the next assigned report awaiting review'
      : `No reports assigned to ${user.name} are currently in awaiting review. Upon finalizing you will remain on this report.`,
  };

  return (
    <Stack>
      <HStack>
        <Text fontSize="sm" color="secondary">
          {message[user.settings.finalizeBehavior]}
        </Text>
        <Spacer />
        <Tooltip
          label="You can change the default behaviour on finalizing studies in the settings page."
          fontSize="sm"
        >
          <IconButton
            icon={<FaInfoCircle />}
            size="sm"
            aria-label="User finalize behavior help"
            variant="ghost"
            _hover={undefined}
          />
        </Tooltip>
      </HStack>
      {finalizeBehavior === FinalizeBehavior.NextReport && nextReport && (
        <NextReportDetails nextReport={nextReport} />
      )}
    </Stack>
  );
};
