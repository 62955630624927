import gql from 'graphql-tag';

export const Job = gql`
  query Job($organizationId: ID!, $jobId: ID!) {
    organization(organizationId: $organizationId) {
      job(jobId: $jobId) {
        id
        type
        status
        progress
        queuedAt
        startedAt
        finishedAt
        payload
      }
    }
  }
`;
