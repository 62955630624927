import { Theme } from '@nivo/core';

export interface ChartTheme extends Theme {
  purple: string;
  darkPurple: string;
}

export const chartsTheme: ChartTheme = {
  fontSize: 11,
  fontFamily: 'Inter, sans-serif',

  axis: {
    ticks: {
      line: {
        stroke: '#EDF2F7',
      },
      text: {
        fill: '#718096',
        fontWeight: 'normal',
      },
    },
  },
  dots: {
    text: {
      fontWeight: 'bold',
      fill: '#718096',
    },
  },
  grid: {
    line: {
      stroke: '#EDF2F7',
      strokeWidth: 1,
    },
  },
  purple: '#D6BCFA',
  darkPurple: '#B794F4',
};
