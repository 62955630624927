import gql from 'graphql-tag';

export const AuditTrail = gql`
  query AuditTrail(
    $organizationId: ID!
    $getAuditTrailRequest: GetPaginatedAuditTrailEntriesRequest
  ) {
    organization(organizationId: $organizationId) {
      id
      auditTrail(request: $getAuditTrailRequest) {
        results {
          id
          organizationId
          user {
            id
            email
            name
            picture
          }
          event
          message
          payload
          createdAt
        }
        pagination {
          total
          limit
          lastSeen
          firstSeen
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`;
