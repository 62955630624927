import { Avatar, AvatarProps } from '@chakra-ui/react';
import { Button, ButtonGroup, VStack } from '@chakra-ui/react';
import _ from 'lodash';
import React from 'react';
import { useController } from 'react-hook-form';
import { blobToBase64, FileInput, readResizedImage } from '@piccolohealth/ui';

interface Props extends Omit<AvatarProps, 'name'> {
  name: string;
  username: string;
}

export const HookedAvatarUploader = (props: Props) => {
  const { name, username, size } = props;

  const {
    field: { ref, ...inputProps },
  } = useController({ name });

  const onChange = React.useCallback(
    async (files: File[]) => {
      const file = _.head(files);
      if (file) {
        const image = await readResizedImage(file, { maxWidth: 96, maxHeight: 96 });
        const base64 = await blobToBase64(image);
        inputProps.onChange(base64);
      } else {
        inputProps.onChange(null);
      }
    },
    [inputProps],
  );

  return (
    <VStack>
      <Avatar size={size} src={inputProps.value} name={username} />
      <ButtonGroup>
        <FileInput
          files={inputProps.value}
          onFilesChange={onChange}
          multiple={false}
          variant="solid"
          size="xs"
        >
          Change
        </FileInput>
        <Button variant="link" size="xs" onClick={() => onChange([])}>
          Remove
        </Button>
      </ButtonGroup>
    </VStack>
  );
};
