import gql from 'graphql-tag';

export const UpdateWorklistEntry = gql`
  mutation UpdateWorklistEntry(
    $organizationId: ID!
    $worklistEntryId: ID!
    $request: UpdateWorklistEntryRequest!
  ) {
    updateWorklistEntry(
      organizationId: $organizationId
      worklistEntryId: $worklistEntryId
      request: $request
    ) {
      id
      organizationId
      machineAet {
        id
        aet
        name
      }
      studyInstanceUID
      patientId
      patientFirstName
      patientLastName
      patientDob
      patientSex
      patientHeight
      patientWeight
      modality
      reportTemplate {
        id
        name
      }
      performingPhysician {
        id
        name
      }
      createdAt
      updatedAt
    }
  }
`;
