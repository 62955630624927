import gql from 'graphql-tag';

export const User = gql`
  query User {
    user {
      id
      name
      email
      picture
      organizationMemberships {
        organizationId
        name
        roles {
          id
          name
        }
        permissions
      }
      extraPermissions
      settings {
        defaultOrganization
        finalizeBehavior
      }
      intercomHash
    }
  }
`;
