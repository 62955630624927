import * as Types from '@piccolohealth/echo-common';
import { createGqlQuery } from '../fetcher';
import { Users, UsersMinimal } from '../queries/users.query';

export const useUsersQuery = createGqlQuery<Types.UsersQueryVariables, Types.UsersQuery>(
  (variables) => ['users', variables],
  Users,
);

export const useUsersMinimalQuery = createGqlQuery<
  Types.UsersMinimalQueryVariables,
  Types.UsersMinimalQuery
>((variables) => ['users', variables, 'minimal'], UsersMinimal);
