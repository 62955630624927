import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  DistributionPayloadRequest,
  DistributionRequest,
  DistributionTrigger,
  DistributionType,
  ReportStatus,
} from '@piccolohealth/echo-common';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { HookedSubmitButton } from '../../../components/forms/hookform/HookedSubmitButton';
import { createModal } from '../../../components/generic/Modal';
import { useCreateDistributionMutation } from '../../../graphql/hooks/useCreateDistributionMutation';
import { useAppContext } from '../../../hooks/useAppContext';
import { getDistributionTypes } from '../../../hooks/useDistributionTypeInfo';
import { distributionValidationSchema } from '../distributionValidationSchema';
import { DistributionForm } from './DistributionForm';

type FormValues = Partial<DistributionRequest & { type: DistributionType }>;

interface Props {
  reportId: string;
  reportStatus: ReportStatus;
}

export const DistributionAddModal = createModal<Props>((props) => {
  const { reportId, reportStatus, modal } = props;
  const { organization, successToast, errorToast } = useAppContext();

  const { hide, visible } = modal;

  const initialValues: FormValues = {
    trigger:
      reportStatus === ReportStatus.Finalized
        ? DistributionTrigger.Manual
        : DistributionTrigger.OnFinalize,
    type: organization.distributionSettings.defaultDistributionType ?? DistributionType.Email,
  };

  const methods = useForm({
    defaultValues: initialValues as any,
    resolver: yupResolver(distributionValidationSchema),
    mode: 'all',
  });

  const mutation = useCreateDistributionMutation({});

  const onSubmit = React.useCallback(
    async (values: FormValues) => {
      const { payloadRequestTypename } = getDistributionTypes(values.type);

      const request: DistributionRequest = {
        trigger: values.trigger as DistributionTrigger,
        payload: {
          __typename: payloadRequestTypename,
          ...values.payload,
        } as DistributionPayloadRequest,
      };

      await mutation
        .mutateAsync({
          organizationId: organization.id,
          reportId,
          request,
        })
        .then(() => {
          hide();
          successToast('Distribution created successfully');
        })
        .catch((err) => {
          errorToast(`Error creating distribution: ${err.message}`);
        });
    },
    [mutation, organization.id, reportId, hide, errorToast, successToast],
  );

  return (
    <Modal isOpen={visible} onClose={hide} onCloseComplete={modal.remove} size="xl">
      <ModalOverlay />
      <ModalContent>
        <FormProvider {...methods}>
          <Box as="form" display="contents" onSubmit={methods.handleSubmit(onSubmit)}>
            <ModalHeader>
              <Flex align="start">
                <Box>
                  <Heading size="md">Add a distribution</Heading>
                  <Text fontSize="md" fontWeight="normal" color="secondary" mt={2}>
                    Please complete the following to proceed
                  </Text>
                </Box>
                <Spacer />
                <ModalCloseButton position="unset" top="unset" right="unset" />
              </Flex>
            </ModalHeader>
            <ModalBody>
              <DistributionForm reportId={reportId} />
            </ModalBody>
            <ModalFooter>
              <HStack>
                <Button onClick={hide}>Close</Button>
                <HookedSubmitButton data-pw="distributionAddModalSubmitButton">
                  Add distribution
                </HookedSubmitButton>
              </HStack>
            </ModalFooter>
          </Box>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
});
