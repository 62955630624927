import { P } from '@piccolohealth/util';
import React from 'react';
import { SelectOption } from './useSelect';

type FocusedOption<A> = {
  keyboard: boolean;
  option: SelectOption<A>;
};

export const useOptionsFocus = <A>(options: SelectOption<A>[]) => {
  const [focusedOption, setFocusedOption] = React.useState<FocusedOption<A> | null>(null);

  const focusOption = React.useCallback(
    (value: SelectOption<A> | null, keyboard?: boolean) => {
      if (P.isNil(value)) {
        setFocusedOption(null);
      } else {
        if (value.disabled) {
          return;
        }

        setFocusedOption({
          keyboard: keyboard || false,
          option: value,
        });
      }
    },
    [setFocusedOption],
  );

  const focusNextOption = React.useCallback(() => {
    const currentIndex = options.findIndex((v) => v.value === focusedOption?.option.value);

    if (currentIndex === options.length - 1) {
      // we are at end of list do nothing
      return;
    }
    focusOption(options[currentIndex + 1], true);
  }, [options, focusedOption, focusOption]);

  const focusPrevOption = React.useCallback(() => {
    const currentIndex = options.findIndex((v) => v.value === focusedOption?.option.value);

    if (currentIndex === 0) {
      // we are at start of list do nothing
      return;
    }

    focusOption(options[currentIndex - 1], true);
  }, [options, focusedOption, focusOption]);

  const focusFirstOption = React.useCallback(() => {
    const firstOption = options[0];
    focusOption(firstOption ?? null, false);
  }, [options, focusOption]);

  return {
    focusedOption,
    focusOption,
    focusNextOption,
    focusPrevOption,
    focusFirstOption,
  };
};
