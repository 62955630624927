import { v4 as uuidv4 } from 'uuid';

export const uuid = () => uuidv4();

export const generateShortId = () => {
  const base = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

  const generator = (base: string, len: number) => {
    return [...Array(len)].map(() => base[(Math.random() * base.length) | 0]).join('');
  };

  return generator(base, 8);
};
