import { Button, Icon, Menu, MenuButton, MenuList } from '@chakra-ui/react';
import { MeasurementMapping } from '@piccolohealth/echo-common';
import React from 'react';
import { FaAngleDown } from 'react-icons/fa';
import { MeasurementMappingDeleteMenuItem } from './MeasurementMappingDeleteMenuItem';
import { MeasurementMappingEditMenuItem } from './MeasurementMappingEditMenuItem';

interface Props {
  measurementMapping: MeasurementMapping;
}

export const MeasurementMappingActionsMenu = (props: Props) => {
  const { measurementMapping } = props;

  return (
    <Menu isLazy lazyBehavior="keepMounted">
      <MenuList>
        <MeasurementMappingEditMenuItem
          measurementMapping={measurementMapping}
          key="measurementMappingEdit"
        />
        <MeasurementMappingDeleteMenuItem
          measurementMapping={measurementMapping}
          key="measurementMappingDelete"
        />
      </MenuList>
      <MenuButton
        as={Button}
        variant="outline"
        size="xs"
        rightIcon={<Icon as={FaAngleDown} />}
        data-pw={`measurementMappingActionsButton-${measurementMapping.id}`}
        onClick={(e) => e.stopPropagation()}
      >
        Actions
      </MenuButton>
    </Menu>
  );
};
