import { DistributionTrigger, DistributionType, ReportStatus } from '../graphql/types';

export const getAllowedDistributionTriggers = (
  type: DistributionType,
  reportStatus: ReportStatus,
): DistributionTrigger[] => {
  switch (type) {
    case DistributionType.Email:
    case DistributionType.MedicalObjects:
    case DistributionType.Fax:
    case DistributionType.HealthLink: {
      if (reportStatus === ReportStatus.Unreported) {
        return [DistributionTrigger.Manual, DistributionTrigger.OnFinalize];
      }

      if (reportStatus === ReportStatus.AwaitingReview) {
        return [DistributionTrigger.Manual, DistributionTrigger.OnFinalize];
      }

      if (reportStatus === ReportStatus.Finalized) {
        return [DistributionTrigger.Manual, DistributionTrigger.Immediate];
      }
    }
  }

  return [];
};
