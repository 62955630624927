import { User } from '@piccolohealth/echo-common';
import _ from 'lodash';
import React from 'react';
import { useUsersMinimalQuery } from '../../graphql/hooks/useUsersQuery';
import { useAppContext } from '../../hooks/useAppContext';
import { Select, SelectOption, SelectProps } from '@piccolohealth/ui';

export interface UserChooserProps
  extends Omit<SelectProps<User, unknown>, 'onChange' | 'value' | 'options'> {
  value: string;
  onChange: (value: string) => void;
}

export const UserChooser = (props: UserChooserProps) => {
  const { onChange, value, ...rest } = props;
  const { organization, user } = useAppContext();
  const { data, isFetching } = useUsersMinimalQuery({
    organizationId: organization.id,
  });

  const users = (data?.organization?.users as User[]) ?? [];

  const options: SelectOption<User>[] = _.map(users, (user) => ({
    value: user.id,
    label: user.name,
    raw: user,
  }));

  const onChangePrime = React.useCallback(
    (value: SelectOption<User>) => {
      onChange(value.value);
    },
    [onChange],
  );

  const selectedValue = React.useMemo(() => {
    return _.find(options, (option) => option.value === value) ?? null;
  }, [options, value]);

  React.useEffect(() => {
    if (_.isNil(selectedValue) && _.isEmpty(value)) {
      const currentUserDefault = _.find(options, (option) => option.value === user.id);
      if (currentUserDefault) {
        onChange(currentUserDefault?.value);
      }
    }
  }, [options, selectedValue, user.id, value, onChange]);

  return (
    <Select
      {...rest}
      options={options}
      value={selectedValue}
      onChange={onChangePrime}
      isLoading={isFetching}
    />
  );
};
