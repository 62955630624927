import { useTheme } from '@chakra-ui/react';
import { Query, ResultSet } from '@cubejs-client/core';
import { P } from '@piccolohealth/util';
import React from 'react';
import { PieChart, PieChartData } from '../../../components/charts/PieChart';
import { ChakraColorMap, getChakraColor } from '../../../utils/colors';
import { CubeQuery } from './CubeQuery';

interface Props {
  query: Query;
}

const getChartData = (
  query: Query,
  resultSet: ResultSet<any>,
  colors: ChakraColorMap,
): PieChartData[] => {
  if (P.isEmpty(resultSet.seriesNames())) {
    return [];
  }

  const chart = (query as any).chart;

  return resultSet.tablePivot().map((row) => {
    return {
      id: `${row[chart.x] as string}`,
      label: row[chart.x] as string,
      value: row[chart.y] as number,
      color: getChakraColor(colors, row[chart.color] as string),
    };
  });
};

export const CubePieChart = (props: Props) => {
  const { query } = props;

  const theme = useTheme();

  return (
    <CubeQuery
      query={query}
      component={(resultSet) => {
        return <PieChart chartData={getChartData(query, resultSet, theme.colors)} />;
      }}
    />
  );
};
