import gql from 'graphql-tag';

export const FastForwardReport = gql`
  mutation FastForwardReport($organizationId: ID!, $reportId: ID!) {
    fastForwardReport(organizationId: $organizationId, reportId: $reportId) {
      id
      organizationId
    }
  }
`;
