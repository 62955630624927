import { Button } from '@chakra-ui/react';
import React from 'react';
import { FaCog } from 'react-icons/fa';
import { useAppContext } from '../../hooks/useAppContext';

interface Props {
  reportId: string;
}

export const ReportDebugButton = (props: Props) => {
  const { reportId } = props;

  const { config, organization } = useAppContext();

  const url = `${config.auth.redirectUri}/admin/graphiql`;

  const query = `query {
    organization(organizationId: "${organization.id}") {
      report(reportId: "${reportId}") {
        id
        study {
          id
          measurements {
            site
            name
            value
            units
            mapping {
              id
              alias
            }
            properties {
              name
              value
            }
          }

          # instances {
          #   id
          #   studyId
          #   seriesId
          #   dicom {
          #     manufacturer
          #     model
          #     tags
          #   }
          # }
        }
      }
    }
  }`;

  const onClick = () => {
    localStorage.setItem('graphiql:query', query);
    window.open(url || '', '_blank');
  };

  return (
    <Button leftIcon={<FaCog />} variant="outline" onClick={onClick}>
      Debug
    </Button>
  );
};
