import { Stack, Text } from '@chakra-ui/react';
import {
  createColumnHelper,
  DataTable,
  Empty,
  FancyDate,
  PaginationOptions,
  RowSelectionOptions,
} from '@piccolohealth/ui';
import { DateTime } from '@piccolohealth/util';
import { Error } from '../../generic/Error';

import {
  getReportVariableValue,
  getReportVariableValueAsDateTime,
  getReportVariableValueAsString,
  PiccoloError,
  renderNullable,
  Report,
} from '@piccolohealth/echo-common';
import _ from 'lodash';
import React from 'react';
import { ReportDistributionsStatus } from '../../../features/distributions/components/ReportDistributionsStatus';
import { useAppContext } from '../../../hooks/useAppContext';
import { AssignLabelsControl } from '../../controls/AssignLabelsControl';
import { ReportActionMenu } from '../../controls/ReportActionMenu';
import { ReportChangeTypeControl } from '../../controls/ReportChangeTypeControl';
import { DateTooltip } from '../../generic/DateTooltip';
import { ReportStatusTag } from '../../generic/ReportStatusTag';
import { TextLink } from '../../generic/TextLink';

interface Props {
  isLoading: boolean;
  error: PiccoloError | null;
  reports: Report[];
  refetch: () => Promise<void>;
  pagination?: PaginationOptions;
  rowSelection?: RowSelectionOptions<Report>;
}

export const ReportsTable = (props: Props) => {
  const { organization } = useAppContext();
  const { isLoading, error, reports, refetch, pagination, rowSelection } = props;

  const columns = React.useMemo(() => {
    const columnHelper = createColumnHelper<Report>();

    return [
      columnHelper.display({
        header: 'Patient',
        minSize: 240,
        maxSize: 240,
        cell: (props) => {
          const report = props.cell.row.original;
          const patientName = getReportVariableValueAsString(report.variables, 'patientName');
          const studyDate = getReportVariableValueAsDateTime(report.variables, 'studyDate');
          const uploadDate = DateTime.fromISO(report.createdAt as unknown as string);

          return (
            <Stack spacing={2} py={2}>
              <TextLink
                to={`/organizations/${organization.id}/reports/${report.id}`}
                data-pw={`reportTablePatientNameLink-${report.id}`}
                fontWeight="bold"
                color="purple.600"
                fontSize="md"
              >
                {renderNullable(patientName)}
              </TextLink>

              <FancyDate
                date={uploadDate}
                direction="column"
                tooltip={() => <DateTooltip uploadDate={uploadDate} studyDate={studyDate} />}
              />
            </Stack>
          );
        },
      }),
      columnHelper.display({
        id: 'type',
        header: 'Report type',
        cell: (props) => <ReportChangeTypeControl report={props.row.original} size="md" />,
        minSize: 220,
        maxSize: 220,
      }),
      columnHelper.display({
        id: 'status',
        header: 'Status',
        cell: (props) => <ReportStatusTag status={props.row.original.status} size="md" />,
        maxSize: 160,
        minSize: 160,
      }),

      columnHelper.display({
        id: 'distributions',
        header: 'Distributions',
        minSize: 220,
        maxSize: 220,
        cell: (props) => {
          return (
            <ReportDistributionsStatus distributions={props.row.original.distributions} size="md" />
          );
        },
      }),
      columnHelper.display({
        header: 'Users',
        minSize: 200,
        maxSize: 380,
        cell: (props) => {
          const report = props.row.original;
          const cardiologists = getReportVariableValue<string[]>(
            report.variables || [],
            'cardiologist',
          );
          const sonographers = getReportVariableValue<string[]>(
            report.variables || [],
            'sonographer',
          );

          const users = _.uniq(_.concat(cardiologists, sonographers));

          if (_.isEmpty(users)) {
            return <Text>{renderNullable(users)}</Text>;
          }

          const renderedUsers = _.map(users, (user) => {
            return (
              <Text key={`${report.id}-${user}`} noOfLines={1}>
                {user}
              </Text>
            );
          });

          return <Stack>{renderedUsers}</Stack>;
        },
      }),
      columnHelper.display({
        header: 'Labels',
        cell: (props) => {
          return (
            <AssignLabelsControl
              reportId={props.row.original.id}
              reportLabels={props.row.original.labels}
            />
          );
        },
        minSize: 200,
      }),
      columnHelper.display({
        header: 'Actions',
        maxSize: 140,
        cell: (props) => {
          return (
            <ReportActionMenu
              reportId={props.row.original.id}
              reportStatus={props.row.original.status}
              onDelete={refetch}
            />
          );
        },
      }),
    ];
  }, [organization, refetch]);

  return (
    <DataTable
      columns={columns}
      data={reports}
      size="sm"
      error={error}
      isLoading={isLoading}
      pagination={pagination}
      rowSelection={rowSelection}
      renderError={(error) => <Error error={error} />}
      renderEmpty={() => <Empty title="No reports found" />}
    />
  );
};
