import {
  forwardRef,
  List,
  ListIcon,
  ListItem,
  Stack,
  Text,
  Tooltip,
  TooltipProps,
} from '@chakra-ui/react';
import { P } from '@piccolohealth/util';
import React from 'react';
import { IconType } from 'react-icons';

export interface VariableStatus {
  name: string | undefined;
  color: string;
  icon: IconType;
  issues: string[];
}

interface Props extends TooltipProps {
  variableStatuses: VariableStatus[];
}

export const EchoIQVariableIssuesTooltip = forwardRef<Props, 'div'>((props, ref) => {
  const { variableStatuses, children, ...rest } = props;

  return (
    <Tooltip
      ref={ref}
      label={
        <Stack spacing={2} p={2}>
          <Stack spacing={1}>
            <Text fontSize="md">Issues</Text>
            <Text fontSize="xs" fontWeight="normal">
              The following measurements are missing or not usable, but are required to perform an
              analysis:
            </Text>
          </Stack>
          <List fontSize="xs" fontWeight="normal">
            {variableStatuses
              .filter((status) => !P.isEmpty(status.issues))
              .map((status) => (
                <ListItem key={status.name}>
                  <ListIcon as={status.icon} color={status.color} />
                  {status.name} - {status.issues.join(' and ')}
                </ListItem>
              ))}
          </List>
        </Stack>
      }
      {...rest}
    >
      {children}
    </Tooltip>
  );
});
