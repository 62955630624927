import {
  getSelectedOptions,
  MultiSelect,
  MultiSelectOption,
  MultiSelectProps,
  OnChangeRequest,
} from '@piccolohealth/ui';
import React from 'react';
import { useController } from 'react-hook-form';

interface Props<A> extends Omit<MultiSelectProps<A>, 'onChange' | 'selectedValues'> {
  name: string;
}

export const HookedMultiSelect = <A extends unknown>(props: React.PropsWithChildren<Props<A>>) => {
  const { name, options, ...rest } = props;
  const { field } = useController({ name });
  const { onChange, value } = field;

  const onChangePrime = React.useCallback(
    (req: OnChangeRequest<A>) => onChange(req.values.map((v) => v.value)),
    [onChange],
  );

  const selectedValues: MultiSelectOption<A>[] = React.useMemo(() => {
    return getSelectedOptions(options, value);
  }, [value, options]);

  return (
    <MultiSelect
      {...rest}
      options={options}
      onChange={onChangePrime}
      selectedValues={selectedValues}
    />
  );
};
