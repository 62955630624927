import gql from 'graphql-tag';

export const UpdateUser = gql`
  mutation UpdateUser($organizationId: ID!, $userId: ID!, $updateUserRequest: UpdateUserRequest!) {
    updateUser(organizationId: $organizationId, userId: $userId, request: $updateUserRequest) {
      id
      name
      email
      picture
      organizationMemberships {
        roles {
          id
          name
        }
      }
      settings {
        defaultOrganization
        finalizeBehavior
      }
    }
  }
`;
