import * as Types from '@piccolohealth/echo-common';
import { useMutation, useQueryClient } from 'react-query';
import { gqlFetcher, MutationOptions } from '../fetcher';
import { ShareReport } from '../mutations/shareReport.mutation';
import { useReportSharesMinimalQuery } from './useReportQuery';

export const useShareReportMutation = (
  options?: MutationOptions<Types.ShareReportMutation, Types.ShareReportMutationVariables>,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(ShareReport), {
    ...options,
    onSuccess: async (resp, variables, ctx) => {
      await options?.onSuccess?.(resp, variables, ctx);

      await queryClient.invalidateQueries(
        useReportSharesMinimalQuery.getKey({
          organizationId: variables.organizationId,
          reportId: variables.reportId,
        }),
      );
    },
  });
};
