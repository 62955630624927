import { useLagRadar } from '@piccolohealth/ui';
import 'focus-visible/dist/focus-visible';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';
import { ErrorBoundary } from './components/generic/ErrorBoundary';
import { PageError } from './components/generic/PageError';
import { ProtectedRoute } from './components/generic/ProtectedRoute';
import { IdleLogoutModal } from './components/modals/IdleLogoutModal';
import { Config } from './config';
import { AuthContextProvider } from './context/AuthContext';
import { ConfigContextProvider } from './context/ConfigContext';
import { UserContextProvider } from './context/UserContext';
import { adminRoutes } from './features/admin/routes';
import { OrganizationRedirect, organizationRoutes } from './features/organization/routes';
import { Logout } from './pages/Logout';
import { ChakraProvider } from './theme/chakra';

interface RouterProps {
  queryClient: QueryClient;
  config: Config;
}

const Router = (props: RouterProps) => {
  const { queryClient, config } = props;

  const router = createBrowserRouter([
    {
      path: '/',
      element: (
        <ErrorBoundary>
          <AuthContextProvider>
            <Outlet />
          </AuthContextProvider>
        </ErrorBoundary>
      ),
      children: [
        {
          path: '/logout',
          element: <Logout />,
        },
        {
          path: '/',
          element: (
            <QueryClientProvider client={queryClient}>
              <IntercomProvider
                appId={config.intercom.appId}
                shouldInitialize={config.intercom.enabled}
              >
                <UserContextProvider>
                  <IdleLogoutModal />
                  <Outlet />
                </UserContextProvider>
              </IntercomProvider>
            </QueryClientProvider>
          ),
          children: [
            {
              path: '/',
              element: (
                <ProtectedRoute>
                  <Outlet />
                </ProtectedRoute>
              ),
              children: [
                {
                  path: '/',
                  element: <OrganizationRedirect />,
                },

                {
                  path: '/reports',
                  element: <OrganizationRedirect />,
                },
                {
                  path: 'organizations',
                  children: organizationRoutes,
                },
                {
                  path: 'admin',
                  children: adminRoutes,
                },
                {
                  path: '*',
                  element: <PageError type="NotFound" />,
                },
              ],
            },
          ],
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
};

interface Props {
  config: Config;
  queryClient: QueryClient;
}

export const Root = (props: Props) => {
  const { config, queryClient } = props;

  useLagRadar(config.lagRadarEnabled ?? false);

  return (
    <ConfigContextProvider config={config}>
      <ChakraProvider>
        <Router queryClient={queryClient} config={config} />
      </ChakraProvider>
    </ConfigContextProvider>
  );
};
