import { Button, Icon, Stack } from '@chakra-ui/react';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { PERMISSIONS, ReportTemplateStatementSite, uuid } from '@piccolohealth/echo-common';
import _ from 'lodash';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { FaPlus } from 'react-icons/fa';
import { usePermission } from '../../../../hooks/usePermission';
import { SortableReportTemplateStatementSite } from './SortableReportTemplateStatementSite';

export const SortableReportTemplateStatementSites = () => {
  const { setValue, watch } = useFormContext();

  const isSupport = usePermission(PERMISSIONS.organizationSupport).value;
  const currentReportTemplateStatementSiteId = watch('currentReportTemplateStatementSiteId');

  const { fields, append, remove, move } = useFieldArray({
    name: 'statementSites',
    keyName: 'sortableId',
  });

  const reportTemplateStatementSites = fields as unknown as ReportTemplateStatementSite[];

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
  );

  const onClick = React.useCallback(
    (id: string) => {
      setValue(`currentReportTemplateStatementSiteId`, id);
    },
    [setValue],
  );

  const onDragEnd = React.useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;
      if (over && active.id !== over.id) {
        const oldIndex = _.findIndex(reportTemplateStatementSites, (item) => item.id === active.id);
        const newIndex = _.findIndex(reportTemplateStatementSites, (item) => item.id === over.id);
        move(oldIndex, newIndex);
      }
    },
    [move, reportTemplateStatementSites],
  );

  const onAdd = React.useCallback(() => {
    const newReportTemplateStatementSite: ReportTemplateStatementSite = {
      id: uuid(),
      name: 'New site',
      statements: [],
    };
    append(newReportTemplateStatementSite);
  }, [append]);

  const onRemove = React.useCallback(
    (id: string) => {
      const index = _.findIndex(reportTemplateStatementSites, (item) => item.id === id);
      remove(index);

      const firstReportTemplateStatementSite = _.head(reportTemplateStatementSites);

      if (id === currentReportTemplateStatementSiteId && firstReportTemplateStatementSite) {
        setValue(`currentReportTemplateStatementSiteId`, firstReportTemplateStatementSite.id);
      }
    },
    [remove, reportTemplateStatementSites, currentReportTemplateStatementSiteId, setValue],
  );

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={onDragEnd}>
      <SortableContext
        strategy={verticalListSortingStrategy}
        items={_.map(reportTemplateStatementSites, ({ id }) => id)}
      >
        <Stack spacing={2}>
          {reportTemplateStatementSites.map((reportTemplateStatementSite, index) => (
            <SortableReportTemplateStatementSite
              key={reportTemplateStatementSite.id}
              name={`statementSites[${index}]`}
              isActive={reportTemplateStatementSite.id === currentReportTemplateStatementSiteId}
              onClick={() => onClick(reportTemplateStatementSite.id)}
              onRemove={() => onRemove(reportTemplateStatementSite.id)}
            />
          ))}
          {isSupport && (
            <Button rightIcon={<Icon as={FaPlus} />} onClick={onAdd}>
              Add new site
            </Button>
          )}
        </Stack>
      </SortableContext>
    </DndContext>
  );
};
