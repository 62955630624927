const variants = {
  responsive: {
    popper: {
      zIndex: '1700',
      maxWidth: 'unset',
      width: 'unset',
    },
  },
};

export const Popover = {
  variants,
};
