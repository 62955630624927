import { HStack, Spacer, Stack, Text } from '@chakra-ui/react';
import { Select, SelectCheck, SelectOption } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import { ReportTemplateVariable } from '@piccolohealth/echo-common';
import React from 'react';
import { useReport } from '../../../../context/ReportContext';

interface Props {
  variableId: string;
  type: ReportTemplateVariable['__typename'][];
  onChange: (value: string) => void;
}

export const VariableChooser = (props: Props) => {
  const { variableId, type, onChange } = props;
  const { reportTemplate } = useReport();

  const options: SelectOption<ReportTemplateVariable>[] = React.useMemo(
    () =>
      P.compact(
        reportTemplate.variables
          .filter((variable) => type.includes(variable.__typename))
          .map((variable) => {
            return { value: variable.id, label: variable.label, raw: variable };
          }),
      ),
    [reportTemplate.variables, type],
  );

  const onChangePrime = React.useCallback(
    (value: SelectOption<ReportTemplateVariable>) => {
      return onChange(value.value);
    },
    [onChange],
  );

  const selectedValue: SelectOption<ReportTemplateVariable> | null = React.useMemo(() => {
    return (
      options.find((option) => {
        return option.value === variableId;
      }) ?? null
    );
  }, [options, variableId]);

  return (
    <Select
      onChange={onChangePrime}
      value={selectedValue}
      options={options}
      placeholder="Select an option"
      components={{
        Option: (props) => {
          switch (props.option.raw.__typename) {
            case 'ReportTemplateStaticVariable':
            case 'ReportTemplateChoiceVariable': {
              const { label, alias, site } = props.option.raw;

              return (
                <HStack spacing={2} py={1} fontSize="sm" w="full">
                  <Stack spacing={1} lineHeight={1} align="start">
                    <Text fontWeight="semibold">{label}</Text>
                    {site && (
                      <Text fontSize="xs" color="secondary">
                        {site}
                      </Text>
                    )}
                  </Stack>
                  <Spacer />
                  <Text fontWeight="semibold">{alias}</Text>
                  <SelectCheck />
                </HStack>
              );
            }

            default: {
              const { label, alias } = props.option.raw;

              return (
                <HStack spacing={2} py={1} fontSize="sm" w="full">
                  <Stack spacing={1} lineHeight={1} align="start">
                    <Text fontWeight="semibold">{label}</Text>
                  </Stack>
                  <Spacer />
                  <Text fontWeight="semibold">{alias}</Text>
                  <SelectCheck />
                </HStack>
              );
            }
          }
        },
      }}
    />
  );
};
