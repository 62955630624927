import { Box, Center, HStack, Image, Spacer, Text } from '@chakra-ui/react';
import { DistributionPayload, DistributionStatus } from '@piccolohealth/echo-common';
import React from 'react';
import { useDistributionTypeInfo } from '../../../hooks/useDistributionTypeInfo';
import { DistributionStatusTag } from './DistributionStatusTag';

interface Props {
  payload: DistributionPayload;
  status?: DistributionStatus;
}

export const DistributionHeader = (props: Props) => {
  const { payload, status } = props;
  const { title, logo } = useDistributionTypeInfo(payload.__typename);

  return (
    <Box w="full">
      <HStack spacing={4} h="auto">
        <Center w={10} h={10} p={1} rounded="xl" bg="gray.100">
          <Image src={logo} />
        </Center>
        <Text ml={4} fontSize="xl" fontWeight="semibold">
          {title}
        </Text>
        <Spacer />
        {status && <DistributionStatusTag status={status} />}
      </HStack>
    </Box>
  );
};
