import { DateTime, P } from '@piccolohealth/util';
import { ReportVariable } from '../graphql/types';

export type MeasurementIssues = { measurement: string; issues: string[] };

export type SubmittableAssessment = { type: 'SUBMITTABLE' };
export type NotSubmittableAssessment = { type: 'NOT_SUBMITTABLE'; issues: MeasurementIssues[] };
export type IsSubmittableAssessmentResult = SubmittableAssessment | NotSubmittableAssessment;

export const ASSESSMENT_CORE_REQUIRED_MEASUREMENTS = ['patientId', 'dob', 'studyDate'];
export const ASSESSMENT_SEVERE_AORTIC_STENOSIS_REQUIRED_MEASUREMENTS = [
  ...ASSESSMENT_CORE_REQUIRED_MEASUREMENTS,
  'weight',
  'height',
  'avPvMeasurement',
  'lvEfBpMeasurement',
];

export const getMeasurementIssues = (
  measurement: string,
  variables: ReportVariable[],
): MeasurementIssues => {
  const variable = variables.find((variable) => variable.alias === measurement);
  const hasValue = !P.isNil(variable?.value);
  const issues = [];

  if (!variable || !hasValue) {
    issues.push('Measurement is required');
  }

  switch (measurement) {
    case 'dob': {
      const over18 = -DateTime.fromISO(variable?.value).diffNow('years').years >= 18;

      if (!over18) {
        issues.push('Patient is under 18 years old');
      }
    }
  }

  return { measurement, issues };
};

/**
 * Checks if an assessment is submittable to EchoIQ. All core measurements must be present
 * and atleast 1 set of all required measurements for a specific assessment type must be recorded.
 * E.g. All required measurements must be present for Severe Aortic Stenosis, but not necessarily
 * required for Chronic Mitral Regurgitation. Having neither will result in a non-submittable assessment.
 */
export const isSubmittableAssessment = (
  variables: ReportVariable[],
): IsSubmittableAssessmentResult => {
  const severeAorticStenosisIssues = ASSESSMENT_CORE_REQUIRED_MEASUREMENTS.map((measurement) => {
    return getMeasurementIssues(measurement, variables);
  });

  const allIssues = severeAorticStenosisIssues.flatMap((issue) => issue.issues);

  if (P.isEmpty(allIssues)) {
    return { type: 'SUBMITTABLE' };
  }

  return {
    type: 'NOT_SUBMITTABLE',
    issues: severeAorticStenosisIssues,
  };
};
