import React from 'react';
import { useReportDistributionsMinimalQuery } from '../../../graphql/hooks/useReportQuery';
import { useAppContext } from '../../../hooks/useAppContext';
import { NumberedBadge } from '../../../components/generic/NumberedBadge';
import _ from 'lodash';
import { DistributionStatus } from '@piccolohealth/echo-common';

interface Props {
  reportId: string;
}

export const DistributionsSendBadge = (props: Props) => {
  const { reportId } = props;
  const { organization } = useAppContext();

  const { data } = useReportDistributionsMinimalQuery({
    organizationId: organization.id,
    reportId,
  });

  const distributions = data?.organization?.report?.distributions || [];

  const filteredDistributions = _.filter(distributions, (distribution) => {
    return distribution.status !== DistributionStatus.Sent;
  });

  return <NumberedBadge count={filteredDistributions.length} />;
};
