import gql from 'graphql-tag';

export const UpdateMeasurementVariants = gql`
  mutation UpdateMeasurementVariants($hash: String!, $request: UpdateMeasurementVariantsRequest!) {
    updateMeasurementVariants(hash: $hash, request: $request) {
      id
      name
      site
      units
      properties {
        name
        value
      }
      machine {
        manufacturer
        model
      }
      createdAt
      updatedAt
    }
  }
`;
