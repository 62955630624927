import gql from 'graphql-tag';

export const ReportShare = gql`
  query ReportShare($organizationId: ID!, $reportId: ID!) {
    organization(organizationId: $organizationId) {
      id
      reportShare(reportId: $reportId) {
        id
        reportId
        sharerEmail
        shareeEmail
        report {
          id
          organizationId
          status
          variables {
            __typename
            ... on ReportStaticVariable {
              id
              alias
              value
              units
            }
            ... on ReportChoiceVariable {
              id
              alias
              value
            }
            ... on ReportWallMotionVariable {
              id
              alias
              value
            }
            ... on ReportMediaAttachmentVariable {
              id
              alias
              value
            }
          }
          statementSites {
            id
            statements {
              id
              value
            }
          }
          reportTemplate {
            id
            versionId
            name
            statementSites {
              id
              name
              statements {
                id
                value
                default
              }
            }
            variables {
              __typename
              ... on ReportTemplateStaticVariable {
                id
                alias
                source
                label
                site
                category
                units
                precision
                controlType
                defaultValue
                isEditable
              }
              ... on ReportTemplateChoiceVariable {
                id
                alias
                source
                label
                site
                category
                choices {
                  id
                  label
                  value
                  default
                }
                controlType
                defaultValue
                isEditable
              }
              ... on ReportTemplateWallMotionVariable {
                id
                alias
                source
                label
                category
                variant
                isEditable
              }
              ... on ReportTemplateMediaAttachmentVariable {
                id
                alias
                source
                label
                category
                isEditable
              }
            }
            reportNodes
            isLatest
            status
            labelColor
            createdAt
            updatedAt
          }
          study {
            id
            organizationId
            healthcareRegion
            healthcareDatasetId
            instances {
              id
              studyId
              seriesId
              dicom {
                modality
                instanceNumber
                numberOfFrames
                numberOfStages
                transferSyntaxUID
                imageType
                stage
                view
                heartRate
                frameRate
                duration
              }
              fileUrl
              frameUrl
              mp4Url
              size
            }
          }
          createdAt
          updatedAt
        }
        isExpired
        expiresAt
      }
    }
  }
`;
