import { Text } from '@chakra-ui/react';
import { Handlebars, ReportVariable } from '@piccolohealth/echo-common';
import _ from 'lodash';
import React from 'react';
import { useReport } from '../../context/ReportContext';
import { useAppContext } from '../../hooks/useAppContext';

interface Props {
  variable: ReportVariable;
}

export const HistoricalMeasurementValue = (props: Props) => {
  const { variable } = props;
  const { organization } = useAppContext();
  const { reportTemplate } = useReport();

  const compiledValue = React.useMemo(() => {
    return Handlebars.compileHandlebarsTemplate({
      timezone: organization.timezone,
      template: `{{${variable.id}}}`,
      reportTemplate,
      values: {
        [variable.id]: variable,
      },
    });
  }, [variable, organization.timezone, reportTemplate]);

  const text =
    !_.isNil(variable.value) && variable.value !== '' ? compiledValue : 'No measurement recorded';

  return <Text>{text}</Text>;
};
