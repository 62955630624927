import React from 'react';
import { useController } from 'react-hook-form';
import { ReportTypeChooser } from '../ReportTypeChooser';

interface Props {
  name: string;
}

export const HookedReportTypeChooser = (props: Props) => {
  const { name } = props;

  const {
    field: { ref, ...inputProps },
  } = useController({ name });

  return (
    <ReportTypeChooser
      reportTemplate={inputProps.value}
      onClick={(reportTemplate) => {
        inputProps.onChange(reportTemplate);
      }}
    />
  );
};
