import * as Types from '@piccolohealth/echo-common';
import { createGqlQuery } from '../fetcher';
import { UnusedMeasurementVariants } from '../queries/dicom.query';

export const getUnusedMeasurementVariantsBaseKey = (
  variables: Types.UnusedMeasurementVariantsQueryVariables,
) => ['dicom', 'unusedMeasurementVariants', variables];

export const useUnusedMeasurementVariantsQuery = createGqlQuery<
  Types.UnusedMeasurementVariantsQueryVariables,
  Types.UnusedMeasurementVariantsQuery
>(getUnusedMeasurementVariantsBaseKey, UnusedMeasurementVariants);
