import { Box } from '@chakra-ui/react';
import _ from 'lodash';
import React from 'react';
import { ControlTooltip } from './ControlTooltip';
import { Layout, layoutToChooserColors } from './Layout';

const Wrapper = (
  props: React.PropsWithChildren<{
    cols: number;
    gutter: number;
    onMouseLeave: () => void;
  }>,
) => {
  const { children, cols, gutter, onMouseLeave } = props;

  return (
    <Box
      color="black"
      display="inline-grid"
      gridTemplateColumns={`repeat(${cols}, 1fr)`}
      gridColumnGap={`${gutter}px`}
      gridRowGap={`${gutter}px`}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </Box>
  );
};

const Cell = (
  props: React.PropsWithChildren<{
    w: number;
    h: number;
    backgroundColor: string;
    helpText: string;
    onMouseOver: () => void;
    onClick: () => void;
  }>,
) => {
  const { children, w, h, backgroundColor, helpText, onMouseOver, onClick } = props;
  return (
    <ControlTooltip label={helpText}>
      <Box
        w={`${w}px`}
        h={`${h}px`}
        bg={backgroundColor}
        lineHeight={`${w}px`}
        alignItems="center"
        onMouseOver={onMouseOver}
        onClick={onClick}
      >
        {children}
      </Box>
    </ControlTooltip>
  );
};

interface Props {
  onChangeLayout: (layout: Layout) => void;
  currentLayout: Layout;
}

export const LayoutControl = (props: Props) => {
  const { onChangeLayout, currentLayout } = props;
  const [colors, setColors] = React.useState(layoutToChooserColors(currentLayout));

  const onLayoutMouseOver = (layout: Layout) => {
    setColors(layoutToChooserColors(currentLayout, layout));
  };

  const onLayoutMouseLeave = () => {
    setColors(layoutToChooserColors(currentLayout));
  };

  const onLayoutClick = (layout: Layout) => {
    onChangeLayout(layout);
    setColors(layoutToChooserColors(layout));
  };

  return (
    <Wrapper cols={2} gutter={2} onMouseLeave={() => onLayoutMouseLeave()}>
      <Cell
        w={13}
        h={13}
        backgroundColor={_.get(colors, Layout.layout1x1)}
        onMouseOver={() => onLayoutMouseOver(Layout.layout1x1)}
        onClick={() => onLayoutClick(Layout.layout1x1)}
        helpText="Single pane"
      />
      <Cell
        w={13}
        h={13}
        backgroundColor={_.get(colors, Layout.layout1x2)}
        onMouseOver={() => onLayoutMouseOver(Layout.layout1x2)}
        onClick={() => onLayoutClick(Layout.layout1x2)}
        helpText="Horizontal split"
      />
      <Cell
        w={13}
        h={13}
        backgroundColor={_.get(colors, Layout.layout2x1)}
        onMouseOver={() => onLayoutMouseOver(Layout.layout2x1)}
        onClick={() => onLayoutClick(Layout.layout2x1)}
        helpText="Vertical split"
      />
      <Cell
        w={13}
        h={13}
        backgroundColor={_.get(colors, Layout.layout2x2)}
        onMouseOver={() => onLayoutMouseOver(Layout.layout2x2)}
        onClick={() => onLayoutClick(Layout.layout2x2)}
        helpText="Quad pane"
      />
    </Wrapper>
  );
};
