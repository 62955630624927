import { Button, ButtonProps } from '@chakra-ui/react';
import React from 'react';
import { useFormContext } from 'react-hook-form';

export const HookedResetButton = (props: ButtonProps) => {
  const { children, ...rest } = props;
  const { formState, reset } = useFormContext();

  return (
    <Button
      size="sm"
      colorScheme="purple"
      variant="outline"
      onClick={() => reset()}
      isDisabled={formState.isSubmitting || !formState.isDirty}
      {...rest}
    >
      {children}
    </Button>
  );
};
