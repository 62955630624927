import {
  Box,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  HStack,
  Spacer,
  Text,
  useEditableControls,
} from '@chakra-ui/react';
import { useSortable } from '@dnd-kit/sortable';
import { inflection } from '@piccolohealth/util';
import { PERMISSIONS } from '@piccolohealth/echo-common';
import _ from 'lodash';
import React from 'react';
import { useController, useWatch } from 'react-hook-form';
import { FaCheck, FaPen, FaTimes, FaTrash } from 'react-icons/fa';
import { useHoverDirty } from 'react-use';
import { usePermission } from '../../../../hooks/usePermission';
import { IconButton } from '../../../generic/IconButton';
import { DragHandle } from '../../../sortable/DragHandle';
import { Sortable } from '../../../sortable/Sortable';

const Controls = (props: { isHovering: boolean; onRemove: () => void }) => {
  const { isHovering, onRemove } = props;
  const { isEditing, getSubmitButtonProps, getEditButtonProps, getCancelButtonProps } =
    useEditableControls();

  const isSupport = usePermission(PERMISSIONS.organizationSupport).value;

  if (!isSupport) {
    return null;
  }

  return (
    <HStack visibility={isHovering || isEditing ? 'visible' : 'hidden'}>
      {isEditing ? (
        <>
          <IconButton icon={FaCheck} {..._.omit(getSubmitButtonProps(), 'ref')} />
          <IconButton icon={FaTimes} {..._.omit(getCancelButtonProps(), 'ref')} />
        </>
      ) : (
        <IconButton icon={FaPen} {..._.omit(getEditButtonProps(), 'ref')} />
      )}
      <IconButton
        icon={FaTrash}
        aria-label="Remove"
        onClick={(e) => {
          e.stopPropagation();
          onRemove();
        }}
      />
    </HStack>
  );
};

interface Props {
  name: string;
  isActive?: boolean;
  onClick: () => void;
  onRemove: () => void;
}

export const SortableReportTemplateStatementSite = (props: Props) => {
  const { name, isActive, onClick, onRemove } = props;

  const isSupport = usePermission(PERMISSIONS.organizationSupport).value;
  const reportTemplateStatementSite = useWatch({ name });

  const { field } = useController({ name: `${name}.name` });
  const ref = React.useRef(null);
  const isHovering = useHoverDirty(ref);

  const { attributes, setNodeRef, listeners, transform, transition } = useSortable({
    id: reportTemplateStatementSite.id,
    disabled: !isSupport,
  });

  return (
    <Sortable ref={setNodeRef} transform={transform} transition={transition}>
      <Editable
        isPreviewFocusable={false}
        value={field.value}
        onChange={field.onChange}
        onBlur={field.onBlur}
      >
        <Box
          ref={ref}
          px={3}
          py={2}
          rounded="md"
          shadow="sm"
          boxShadow={isActive ? 'rgb(128 90 213) 0px 0px 0px 1px' : undefined}
          borderColor={isActive ? 'purple.500' : 'gray.100'}
          borderWidth="1px"
          onClick={(e) => {
            e.stopPropagation();
            onClick();
          }}
          cursor="pointer"
        >
          <HStack spacing={3} h="full" userSelect="none">
            <DragHandle listeners={listeners} attributes={attributes} w="24px" h="40px" />

            <Box w="full" flexGrow={1}>
              <Flex w="full">
                <Flex flexDir="column" align="start" fontSize="md" w="full" flexGrow={1}>
                  <EditablePreview w="full" cursor="pointer" />
                  <EditableInput bg="white" />
                </Flex>
                <Spacer />
                <Controls onRemove={onRemove} isHovering={isHovering} />
              </Flex>
              <Text fontSize="xs" fontWeight="semibold" color="secondary">
                {reportTemplateStatementSite.statements.length}{' '}
                {inflection.inflect('statements', reportTemplateStatementSite.statements.length)}
              </Text>
            </Box>
          </HStack>
        </Box>
      </Editable>
    </Sortable>
  );
};
