import { useOutsideClick } from '@chakra-ui/react';
import { dataAttr } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { useCustomCompareEffect } from 'react-use';
import { useOptionsFocus } from './useOptionsFocus';
import { SelectOption } from './useSelect';

export type MultiSelectAction = 'ADD' | 'REMOVE' | 'CREATE';

export type MultiSelectOption<A> = SelectOption<A>;

export interface OnChangeRequest<A> {
  value: MultiSelectOption<A>;
  values: MultiSelectOption<A>[];
  action: MultiSelectAction;
}

export interface UseMultiSelectOptions<A> {
  options: MultiSelectOption<A>[];
  selectedValues: MultiSelectOption<A>[];
  inputValue?: string;
  isCreatable?: boolean;
  isDisabled?: boolean;
  onChange: (req: OnChangeRequest<A>) => void;
  onInputChange?: (value: string) => void;
  onOpen?: () => void;
}

export const useMultiSelect = <A extends unknown>(opts: UseMultiSelectOptions<A>) => {
  const {
    selectedValues,
    isCreatable,
    isDisabled,
    inputValue,
    options,
    onChange,
    onInputChange,
    onOpen,
  } = opts;

  const [isOpen, setIsOpen] = React.useState(false);
  const [inputValueInternal, setInputValueInternal] = React.useState<string>('');
  const inputValuePrime = inputValue ?? inputValueInternal;

  const focusedRef = React.useRef<HTMLLIElement>(null);
  const menuRef = React.useRef<HTMLDivElement>(null);
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const filter = React.useCallback(
    (option: SelectOption<A>) => {
      if (P.isUndefined(inputValue)) {
        return option.label.toLowerCase().includes(inputValuePrime.toLowerCase());
      }
      return true;
    },
    [inputValuePrime, inputValue],
  );

  const filteredOptions: SelectOption<A>[] = React.useMemo(() => {
    // Remove duplicate options
    const uniqueOptions = P.uniqBy(options, (o) => o.label);
    // Remove empty options
    const nonEmptyOptions = uniqueOptions.filter((o) => o.label !== '' || o.value !== '');
    // Remove selected options
    const unselectedOptions = nonEmptyOptions.filter(
      (o) => !selectedValues.some((v) => v.value === o.value),
    );

    const allOptions = [...selectedValues, ...unselectedOptions];

    if (inputValuePrime === '') {
      return allOptions;
    }

    const optionExists = allOptions.some((o) => o.label === inputValuePrime);
    const trimmedInputValue = inputValuePrime?.trim();
    const showCreatable = trimmedInputValue.length > 0 && !optionExists;

    const items: SelectOption<A>[] = [];

    // Add a creatable option if the input value is not empty
    // and the option does not exist, at top of list
    if (showCreatable && isCreatable) {
      items.push({
        value: trimmedInputValue,
        label: trimmedInputValue,
        raw: 'CREATABLE' as A,
      });
    }

    // Add all other options that match the input value
    // at bottom of list
    for (const option of allOptions) {
      filter(option) && items.push(option);
    }

    return items;
  }, [inputValuePrime, isCreatable, options, selectedValues, filter]);

  const { focusedOption, focusFirstOption, focusPrevOption, focusNextOption, focusOption } =
    useOptionsFocus<A>(filteredOptions);

  const onInputChangePrime = React.useCallback(
    (value: string) => {
      onInputChange ? onInputChange(value) : setInputValueInternal(value);
    },
    [onInputChange],
  );

  const focusInput = React.useCallback(() => {
    // wait for the input to be rendered
    setTimeout(() => {
      inputRef.current?.focus();
    }, 1);
  }, []);

  const openMenu = React.useCallback(() => {
    if (!isOpen) {
      setIsOpen(true);
      focusInput();
      focusFirstOption();
      onOpen?.();
    }
  }, [focusFirstOption, focusInput, isOpen, onOpen]);

  const closeMenu = React.useCallback(() => {
    setIsOpen(false);
    onInputChangePrime('');
  }, [onInputChangePrime]);

  const toggleMenu = React.useCallback(() => {
    if (!isOpen) {
      openMenu();
    } else {
      closeMenu();
    }
  }, [closeMenu, isOpen, openMenu]);

  const selectOption = React.useCallback(
    (value: MultiSelectOption<A>) => {
      if (value.disabled) {
        return;
      }

      if (value.raw === 'CREATABLE') {
        const newValue = {
          value: value.value,
          raw: value.value as A,
          label: value.value,
        };

        onChange({
          value: newValue,
          values: [...selectedValues, newValue],
          action: 'CREATE',
        });
        onInputChangePrime('');
        return;
      }

      const alreadyExists = selectedValues.some((v) => v.value === value.value);

      // If item already exists in selectedValues, remove it
      if (alreadyExists) {
        return onChange({
          value,
          values: selectedValues.filter((v) => v.value !== value.value),
          action: 'REMOVE',
        });
      }

      // If item doesn't already exist in selectedValues, add it
      if (!alreadyExists) {
        onChange({ value, values: [...selectedValues, value], action: 'ADD' });
      }
    },
    [selectedValues, onChange, onInputChangePrime],
  );

  const selectFocusedOption = React.useCallback(() => {
    if (focusedOption) {
      selectOption(focusedOption.option);
    }
  }, [focusedOption, selectOption]);

  const removeLastSelectedValue = React.useCallback(() => {
    const lastValue = P.last(selectedValues);
    if (lastValue) {
      selectOption(lastValue);
    }
  }, [selectedValues, selectOption]);

  const menuProps = React.useCallback(() => {
    return {
      ref: menuRef,
      role: 'group',
    };
  }, []);

  const buttonProps = React.useCallback(() => {
    return {
      ref: buttonRef,
      tabIndex: isDisabled ? -1 : 0,
      role: 'group',

      onMouseDown: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();
        toggleMenu();
      },

      onFocus: (e: React.FocusEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();

        // if menuRef contains the relatedTarget, then we came from inside the menu
        // If we did not come from inside the menu, then we should open the menu
        const cameFromMenu = menuRef.current?.contains(e.relatedTarget);

        if (!cameFromMenu) {
          openMenu();
        }
      },
    };
  }, [isDisabled, openMenu, toggleMenu]);

  const inputProps = React.useCallback(() => {
    return {
      ref: inputRef,
      tabIndex: 0,
      value: inputValuePrime,

      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        onInputChangePrime(e.target.value);
        focusFirstOption();

        if (isCreatable) {
          onInputChangePrime(e.target.value);
        }
      },

      onKeyDown: (e: React.KeyboardEvent) => {
        if (e.key === 'Backspace') {
          if (inputValuePrime?.length === 0) {
            removeLastSelectedValue();
            openMenu();
          }
        }

        if (isOpen) {
          if (e.key === 'ArrowDown') {
            e.preventDefault();
            focusNextOption();
          }

          if (e.key === 'ArrowUp') {
            e.preventDefault();
            focusPrevOption();
          }

          if (e.key === 'Enter') {
            e.preventDefault();
            selectFocusedOption();
          }

          if (e.key === 'Escape') {
            e.preventDefault();
            closeMenu();
          }

          if (e.key === 'Tab') {
            closeMenu();
          }
        } else {
          if (e.key === 'ArrowDown' || e.key === 'ArrowUp' || e.key === 'Enter') {
            e.preventDefault();
            openMenu();
          }
        }
      },
    };
  }, [
    inputValuePrime,
    onInputChangePrime,
    focusFirstOption,
    isCreatable,
    isOpen,
    removeLastSelectedValue,
    openMenu,
    focusNextOption,
    focusPrevOption,
    selectFocusedOption,
    closeMenu,
  ]);

  const optionProps = React.useCallback(
    (option: MultiSelectOption<A>) => {
      const isFocused = focusedOption?.option.value === option.value;
      const isSelected = selectedValues.some((v) => v.value === option.value);

      return {
        ref: isFocused ? focusedRef : undefined,
        role: 'group',
        'data-active': dataAttr(isFocused),
        'data-checked': dataAttr(isSelected),
        'data-selected': dataAttr(isSelected),
        'data-label': option.label,
        'data-value': option.value,

        onClick: (e: React.MouseEvent) => {
          e.preventDefault();
          e.stopPropagation();
          selectOption(option);
          focusInput();
        },

        onMouseEnter: () => {
          focusOption(option);
        },
      };
    },
    [focusedOption, selectedValues, selectOption, focusOption, focusInput],
  );

  useCustomCompareEffect(
    () => {
      focusFirstOption();
    },
    [inputValuePrime, focusFirstOption],
    (prevDeps, nextDeps) => {
      return prevDeps[0] === nextDeps[0];
    },
  );

  React.useLayoutEffect(() => {
    if (focusedOption?.keyboard) {
      focusedRef.current?.scrollIntoView({
        behavior: 'auto',
        block: 'nearest',
      });
    }
  }, [focusedOption, focusedRef]);

  useOutsideClick({
    enabled: isOpen,
    ref: menuRef,
    handler: closeMenu,
  });

  return {
    filteredOptions,
    isOpen,
    selectedValues,
    focusedOption,
    selectOption,
    buttonProps,
    inputProps,
    menuProps,
    optionProps,
  };
};
