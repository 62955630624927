import { Box, BoxProps, Flex, Image, Text } from '@chakra-ui/react';
import React from 'react';
import { CommandTooltip } from './CommandMenu';

interface Props extends BoxProps {
  tooltip?: CommandTooltip;
}

export const CommandMenuItemTooltip = (props: Props) => {
  const { tooltip, ...rest } = props;

  if (!tooltip) {
    return null;
  }

  let content;

  switch (tooltip.kind) {
    case 'image': {
      content = (
        <Flex flexDir="column" overflowY="hidden" w="140px">
          <Box bg="gray.100" rounded="md">
            <Image src={tooltip.src} fallback={<Box w="144px" h="96px" />} rounded="md" />
          </Box>
          <Box maxH="3xs">
            <Text color="white" fontWeight="semibold" mt="1">
              {tooltip.description}
            </Text>
          </Box>
        </Flex>
      );
      break;
    }
    case 'content':
      content = <>{tooltip.content}</>;
      break;
    default:
      content = null;
      break;
  }

  return (
    <Box
      w="fit-content"
      p={2}
      rounded="md"
      shadow="2xl"
      fontSize="sm"
      bg="gray.700"
      borderColor="red.700"
      color="white"
      zIndex={100000}
      {...rest}
    >
      {content}
    </Box>
  );
};
