import { Button, Spacer, Text } from '@chakra-ui/react';
import { MultiSelectComponents } from './MultiSelect';
import { inflection } from '@piccolohealth/util';
import React from 'react';

interface Props {
  items: string;
  total: number;
  hasMore: boolean;
  isLoading: boolean;
  fetchNextPage: () => void;
}

export const PaginationSelectFooter = (props: Props) => {
  return (
    <MultiSelectComponents.Footer>
      <Text fontSize="xs" whiteSpace="nowrap">
        {props.total} {inflection.inflect(props.items, props.total)}
      </Text>
      <Spacer minW={4} />
      <Button
        variant="link"
        onClick={props.fetchNextPage}
        fontSize="xs"
        isDisabled={!props.hasMore}
        isLoading={props.isLoading}
        justifyContent="end"
      >
        Load more
      </Button>
    </MultiSelectComponents.Footer>
  );
};
