import * as Types from '@piccolohealth/echo-common';
import { useMutation, useQueryClient } from 'react-query';
import { gqlFetcher, MutationOptions } from '../fetcher';
import { DeleteOrganization } from '../mutations/deleteOrganization.mutation';

export const useDeleteOrganizationMutation = (
  options?: MutationOptions<
    Types.DeleteOrganizationMutation,
    Types.DeleteOrganizationMutationVariables
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(DeleteOrganization), {
    ...options,
    onSuccess: async (resp, variables, ctx) => {
      await options?.onSuccess?.(resp, variables, ctx);
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('user'),
      });
    },
  });
};
