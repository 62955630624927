import {
  Box,
  HStack,
  InputGroup,
  InputRightAddon,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useNode } from '@craftjs/core';
import { Empty, Spin } from '@piccolohealth/ui';
import {
  getReportTemplateVariableForVariable,
  PiccoloError,
  ReportTemplateStaticVariable,
} from '@piccolohealth/echo-common';
import _ from 'lodash';
import React from 'react';
import { useReport } from '../../../../context/ReportContext';
import { MeasurementsChart } from '../../../../features/measurements/MeasurementsChart';
import { useAppContext } from '../../../../hooks/useAppContext';
import { useCompiledTemplate } from '../../../../hooks/useCompiledTemplate';
import { useHistoricalVariables } from '../../../../hooks/useHistoricalVariables';
import { BaseNodeProps, cleanProps } from '../../../../utils/craftjs';
import { FormItem } from '../../../forms/FormItem';
import { HookedInput } from '../../../forms/hookform/HookedInput';
import { Divider } from '../../../generic/Divider';
import { Error } from '../../../generic/Error';
import { NodeSpacingProps, NodeSpacingSettings } from '../../common/nodes/NodeSpacing';
import { NodeTypographyProps, NodeTypographySettings } from '../../common/nodes/NodeTypography';
import { SettingsAccordion } from '../../common/settings/SettingsAccordion';
import { SettingsItem } from '../../common/settings/SettingsItem';
import { VariableChooser } from '../../common/settings/VariableChooser';
import { useSSRNode } from '../../hooks/useSSRNode';

interface MeasurementChartContentProps {
  variableId: string;
  ssr: boolean;
}

const MeasurementChartContent = (props: MeasurementChartContentProps) => {
  const { variableId, ssr } = props;

  const { organization } = useAppContext();
  const { reportId, reportTemplate } = useReport();

  //TODO: Add error state on return for this hook
  const { isLoading, getHistory } = useHistoricalVariables({
    organizationId: organization.id,
    reportId,
    reportTemplate,
  });

  const history = getHistory(variableId);

  if (isLoading) {
    return <Spin />;
  }

  if (!history) {
    return (
      <Error
        error={
          new PiccoloError({
            type: 'InternalClientError',
            message: 'Error getting measurement history. Could not find history for measurement.',
          })
        }
      />
    );
  }

  if (!history.chart.isGraphable) {
    return <Empty title="No measurement history" />;
  }

  return (
    <Box h="full">
      <MeasurementsChart ssr={ssr} data={[history.chart]} />
    </Box>
  );
};

interface MeasurementPopoverContentProps {
  variableId: string;
  ssr: boolean;
}

const MeasurementPopoverContent = (props: MeasurementPopoverContentProps) => {
  const { variableId, ssr } = props;

  const name = `variables.${variableId}.value`;
  const { isDisabled, reportTemplate } = useReport();
  const reportTemplateVariable = getReportTemplateVariableForVariable(reportTemplate, variableId);

  if (!reportTemplateVariable) {
    return (
      <PopoverContent boxShadow="lg" w="xl">
        <PopoverBody display="flex" flexDir="column" h="sm">
          <Error
            error={
              new PiccoloError({
                type: 'InternalClientError',
                message: 'Error getting measurement history. Could not find measurement.',
              })
            }
          />
        </PopoverBody>
      </PopoverContent>
    );
  }

  const units = (reportTemplateVariable as ReportTemplateStaticVariable).units;

  return (
    <PopoverContent boxShadow="lg" w="xl">
      <PopoverHeader fontWeight="semibold">
        <Stack spacing={0}>
          <Text fontWeight="semibold" color="gray.500" fontSize="xs">
            {reportTemplateVariable.label}
          </Text>
        </Stack>
      </PopoverHeader>
      <PopoverBody display="flex" flexDir="column" h="sm">
        <InputGroup size="sm">
          <HookedInput name={name} borderRightRadius={units ? 0 : 'md'} isDisabled={isDisabled} />
          {units && <InputRightAddon borderRightRadius="md">{units}</InputRightAddon>}
        </InputGroup>
        <Divider />
        <MeasurementChartContent variableId={variableId} ssr={ssr} />
      </PopoverBody>
    </PopoverContent>
  );
};

export interface Props extends BaseNodeProps, NodeSpacingProps, NodeTypographyProps {
  variableId: string;
  secondaryVariableId: string | null;
}

export const Measurement = (props: Props) => {
  const { variableId, secondaryVariableId, bold, italic, underline, ssr, ...rest } = props;

  const compiledVariable = useCompiledTemplate(`{{withPlaceholder ${variableId} '-'}}`);
  const compiledIndexedVariable = useCompiledTemplate(
    `({{withPlaceholder ${secondaryVariableId} '-'}})`,
  );

  const {
    connectors: { connect, drag },
  } = useSSRNode(props);

  const style = _.pickBy(
    {
      fontWeight: bold ? 'bold' : undefined,
      fontStyle: italic ? 'italic' : undefined,
      textDecoration: underline ? 'underline' : undefined,
      textDecorationThickness: '2px',
      textUnderlineOffset: '2px',
      cursor: 'pointer',
      borderColor: 'transparent',
      borderWidth: '1px 4px',
      _hover: {
        bg: 'gray.100',
        borderColor: 'gray.100',
      },
      ...rest,
    },
    _.identity,
  );

  return (
    <Box ref={(ref) => connect(drag(ref))}>
      <Popover
        placement="right"
        isLazy
        lazyBehavior="unmount"
        trigger="hover"
        isOpen={ssr ? false : undefined}
      >
        <PopoverTrigger>
          <HStack {...cleanProps(style)} spacing={1}>
            <Text>{compiledVariable.compiledTemplate}</Text>
            <Text>{secondaryVariableId && compiledIndexedVariable.compiledTemplate}</Text>
          </HStack>
        </PopoverTrigger>
        <MeasurementPopoverContent variableId={variableId} ssr={ssr ?? false} />
      </Popover>
    </Box>
  );
};

const MeasurementSettings = () => {
  const {
    actions: { setProp },
    variableId,
    secondaryVariableId,
  } = useNode((node) => ({
    variableId: node.data.props.variableId,
    secondaryVariableId: node.data.props.secondaryVariableId,
  }));

  return (
    <SettingsAccordion>
      <SettingsItem title="Measurement">
        <FormItem id="variableId" label="Variable">
          <VariableChooser
            variableId={variableId}
            type={['ReportTemplateStaticVariable']}
            onChange={(value) => setProp((props: Props) => (props.variableId = value as string))}
          />
        </FormItem>
        <FormItem id="secondaryVariableId" label="Secondary variable ID">
          <VariableChooser
            variableId={secondaryVariableId}
            type={['ReportTemplateStaticVariable']}
            onChange={(value) => setProp((props: Props) => (props.secondaryVariableId = value))}
          />
        </FormItem>
      </SettingsItem>
      <SettingsItem title="Spacing">
        <NodeSpacingSettings />
      </SettingsItem>
      <SettingsItem title="Typography">
        <NodeTypographySettings />
      </SettingsItem>
    </SettingsAccordion>
  );
};

Measurement.defaultProps = {
  variableId: 'lvEddMeasurement',
  secondaryVariableId: null,
  ...NodeSpacingSettings.defaultProps,
  ...NodeTypographySettings.defaultProps,
};

Measurement.craft = {
  name: 'Measurement',
  props: Measurement.defaultProps,
  related: {
    settings: MeasurementSettings,
  },
};
