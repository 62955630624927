import * as Types from '@piccolohealth/echo-common';
import { useMutation, useQueryClient } from 'react-query';
import { gqlFetcher, MutationOptions } from '../fetcher';
import { SendDistribution } from '../mutations/sendDistribution.mutation';

export const useSendDistributionMutation = (
  options?: MutationOptions<Types.SendDistributionMutation, Types.MutationSendDistributionArgs>,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(SendDistribution), {
    ...options,
    onSettled: async (resp, error, variables, ctx) => {
      await options?.onSettled?.(resp, error, variables, ctx);
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey.includes('distributions') || query.queryKey.includes('reports'),
      });
    },
  });
};
