import { ImageUploader, ImageUploaderProps } from '@piccolohealth/ui';
import React from 'react';
import { useController } from 'react-hook-form';

interface Props extends Omit<ImageUploaderProps, 'onChange' | 'value'> {
  name: string;
}

export const HookedImageUploader = (props: Props) => {
  const { name, ...rest } = props;

  const {
    field: { ref, ...inputProps },
  } = useController({ name });

  return <ImageUploader {...inputProps} {...rest} />;
};
