import gql from 'graphql-tag';

export const CreateMeasurementMapping = gql`
  mutation CreateMeasurementMapping($request: CreateMeasurementMappingRequest!) {
    createMeasurementMapping(request: $request) {
      id
      name
      site
      units
      precision
      properties {
        name
        value
      }
    }
  }
`;
