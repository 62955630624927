import { Box } from '@chakra-ui/react';
import React from 'react';
import { Layout } from '../controls/Layout';
import { InstanceFormat } from '../controls/InstanceFormatTag';
import { InstanceInfo, InstanceOverlayInfo } from './InstanceInfoOverlay';

interface Props {
  instanceInfo: InstanceInfo;
  format: InstanceFormat;
  layout: Layout;
}

export const Overlay = React.memo((props: Props) => {
  const { instanceInfo, format, layout } = props;

  const [left, right] = React.useMemo(() => {
    const odd = instanceInfo.viewportIndex % 2 === 1;
    const padding = 2;

    switch (layout) {
      case Layout.layout1x1:
        return [padding, undefined];
      case Layout.layout1x2:
        return [odd ? undefined : padding, odd ? padding : undefined];
      case Layout.layout2x1:
        return [padding, padding];
      case Layout.layout2x2:
        return [odd ? undefined : padding, odd ? padding : undefined];
    }
  }, [instanceInfo.viewportIndex, layout]);

  return (
    <Box position="absolute" top={2} left={left} right={right} zIndex={1} userSelect="none">
      <InstanceOverlayInfo info={instanceInfo} format={format} />
    </Box>
  );
});
