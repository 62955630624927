import gql from 'graphql-tag';

export const UpdateMeasurementMapping = gql`
  mutation UpdateMeasurementMapping($mappingId: ID!, $request: UpdateMeasurementMappingRequest!) {
    updateMeasurementMapping(mappingId: $mappingId, request: $request) {
      id
      name
      site
      units
      precision
      properties {
        name
        value
      }
    }
  }
`;
