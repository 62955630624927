import { Progress, Text } from '@chakra-ui/react';
import { ResultSet } from '@cubejs-client/core';
import { createColumnHelper, DataTable } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { CubeChartQuery } from '../queries';
import { CubeQuery } from './CubeQuery';

interface Props {
  query: CubeChartQuery;
  limit: number;
}

interface Item {
  id: string;
  label: string;
  value: number;
  percentage: number;
  color: string;
}

export interface ChartData {
  items: Item[];
  total: number;
}

const getChartData = (
  query: CubeChartQuery,
  resultSet: ResultSet<any>,
  limit: number,
): ChartData => {
  if (P.isEmpty(resultSet.seriesNames())) {
    return {
      items: [],
      total: 0,
    };
  }

  const rows = P.take(
    P.orderBy(
      resultSet.tablePivot().map((row, index) => {
        return {
          id: `${row[query.chart.x] as string}-${index}`,
          label: !P.isNull(row[query.chart.x]) ? (row[query.chart.x] as string) : 'Unknown',
          value: P.parseNumeric(row[query.chart.y] as string) ?? 0,
          color: 'purple',
        };
      }),
      (r) => r.value,
      'desc',
    ),
    limit,
  );

  const total = P.sum(rows.map((r) => r.value));

  const items = rows.map((row) => {
    return {
      ...row,
      percentage: P.round((row.value / total) * 100, 2),
    };
  });

  return {
    items,
    total,
  };
};

export const CubeTable = (props: Props) => {
  const { query, limit } = props;

  const columns = React.useMemo(() => {
    const columnHelper = createColumnHelper<Item>();

    return [
      columnHelper.display({
        header: 'Name',
        cell: (ps) => <Text noOfLines={1}>{ps.row.original.label}</Text>,
      }),
      columnHelper.display({
        header: 'Reports',
        size: 40,
        cell: (ps) => <Text>{ps.row.original.value}</Text>,
      }),
      columnHelper.display({
        header: 'Percent',
        size: 80,
        cell: (ps) => (
          <Progress
            value={ps.row.original.percentage}
            colorScheme="purple"
            width="100%"
            rounded="xl"
          />
        ),
      }),
    ];
  }, []);

  return (
    <CubeQuery
      query={query}
      component={(resultSet) => {
        const chartData = getChartData(query, resultSet, limit);
        return <DataTable columns={columns} data={chartData.items} size="sm" />;
      }}
    />
  );
};
