import gql from 'graphql-tag';

export const UpdateReportType = gql`
  mutation UpdateReportType(
    $organizationId: ID!
    $reportId: ID!
    $updateReportTypeRequest: UpdateReportTypeRequest!
  ) {
    updateReportType(
      organizationId: $organizationId
      reportId: $reportId
      request: $updateReportTypeRequest
    ) {
      id
      organizationId
      changesetId
      status
      variables {
        __typename
        ... on ReportStaticVariable {
          id
          alias
          value
          units
        }
        ... on ReportChoiceVariable {
          alias
          id
          value
        }
        ... on ReportWallMotionVariable {
          alias
          id
          value
        }
        ... on ReportMediaAttachmentVariable {
          id
          alias
          value
          isTouched
        }
      }
      statementSites {
        id
        statements {
          id
          value
        }
      }
      reportTemplate {
        id
        versionId
        name
        reportNodes
        isLatest
        status
        labelColor
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
