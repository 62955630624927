import * as Types from '@piccolohealth/echo-common';
import { useMutation, useQueryClient } from 'react-query';
import { gqlFetcher, MutationOptions } from '../fetcher';
import { UpdateReportStatus } from '../mutations/updateReportStatus.mutation';
import { getReportBaseKey } from './useReportQuery';

export const useUpdateReportStatusMutation = (
  options?: MutationOptions<
    Types.UpdateReportStatusMutation,
    Types.UpdateReportStatusMutationVariables
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(UpdateReportStatus), {
    ...options,
    onSuccess: async (resp, variables, ctx) => {
      await options?.onSuccess?.(resp, variables, ctx);

      queryClient.invalidateQueries(
        getReportBaseKey({
          organizationId: variables.organizationId,
          reportId: variables.reportId,
        }),
      );
    },
  });
};
