import { uuid } from '@piccolohealth/util';

declare global {
  interface Window {
    tabId: string;
    renderId: string;
  }
}

export interface TabInfoOptions {
  appId: string;
}

export interface TabInfo {
  tabId: string;
  renderId: string;
}

export const initTabInfo = (options: TabInfoOptions) => {
  window.addEventListener('beforeunload', () => {
    window.sessionStorage.tabId = window.tabId;
  });

  window.addEventListener('load', () => {
    if (window.sessionStorage.tabId) {
      window.tabId = window.sessionStorage.tabId;
      window.sessionStorage.removeItem('tabId');
    } else {
      window.tabId = `${options.appId}-${uuid()}`;
    }

    window.renderId = `${options.appId}-${uuid()}`;
  });
};

export const getTabInfo = (): TabInfo => {
  return {
    tabId: window.tabId,
    renderId: window.renderId,
  };
};
