import { Button, ButtonProps } from '@chakra-ui/react';
import React from 'react';

interface Props extends ButtonProps {
  viewUrl: string;
}

export const AttachmentViewButton = (props: Props) => {
  const { viewUrl, ...rest } = props;

  return (
    <Button onClick={() => window.open(viewUrl)} {...rest}>
      View
    </Button>
  );
};
