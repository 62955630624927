import { Flex } from '@chakra-ui/react';
import React from 'react';
import { FaCompressArrowsAlt, FaExpand } from 'react-icons/fa';
import { IconButton } from '../../generic/IconButton';
import { ControlTooltip } from './ControlTooltip';

export const isFullScreenApiAvailable = () =>
  document.fullscreenEnabled ||
  (document as any).mozFullscreenEnabled ||
  (document as any).webkitFullscreenEnabled ||
  (document as any).msFullscreenEnabled;

interface Props {
  currentFullscreen: boolean;
  onChangeFullscreen: (value: boolean) => void;
}

export const FullScreenControl = (props: Props) => {
  const { currentFullscreen, onChangeFullscreen } = props;

  const fullscreenApiAvailable = isFullScreenApiAvailable();

  if (!fullscreenApiAvailable) {
    return null;
  }

  return (
    <ControlTooltip label="Toggle fullscreen">
      <Flex>
        <IconButton
          color="purple.500"
          boxSize="32px"
          icon={currentFullscreen ? FaCompressArrowsAlt : FaExpand}
          _hover={{
            color: 'purple.300',
          }}
          onClick={() => onChangeFullscreen(!currentFullscreen)}
        />
      </Flex>
    </ControlTooltip>
  );
};
