import { Center, HStack, Image, SkeletonText, Text } from '@chakra-ui/react';
import { Distribution, DistributionStatus, DistributionTrigger } from '@piccolohealth/echo-common';
import { inflection, P } from '@piccolohealth/util';
import React from 'react';
import { FormSection } from '../../../components/forms/FormSection';
import { Error } from '../../../components/generic/Error';
import { useReportDistributionsQuery } from '../../../graphql/hooks/useReportQuery';
import { useAppContext } from '../../../hooks/useAppContext';
import { useDistributionTypeInfo } from '../../../hooks/useDistributionTypeInfo';
import { DistributionSummaryList } from './DistributionSummaryList';

interface DistributionSummaryProps {
  distribution: Distribution;
}

const DistributionSummary = (props: DistributionSummaryProps) => {
  const { distribution } = props;
  const { logo } = useDistributionTypeInfo(distribution.payload.__typename);

  return (
    <HStack p={4} spacing={6} layerStyle="bordered" w="full" align="start">
      <Center flexShrink={0} w={14} h={14} p={1} rounded="xl" bg="gray.100">
        <Image src={logo} />
      </Center>
      <DistributionSummaryList
        payload={distribution.payload}
        trigger={distribution.trigger}
        createdAt={distribution.createdAt}
        hideBase={true}
      />
    </HStack>
  );
};

interface Props {
  reportId: string;
}

export const DistributionOnFinalizeSummary = (props: Props) => {
  const { organization } = useAppContext();

  const { data, isLoading, error } = useReportDistributionsQuery({
    organizationId: organization.id,
    reportId: props.reportId,
  });

  const distributions = (data?.organization?.report?.distributions as Distribution[]) ?? [];
  const distributionsToBeSent = distributions.filter(
    (distribution) =>
      distribution.status === DistributionStatus.Pending &&
      distribution.trigger === DistributionTrigger.OnFinalize,
  );

  if (isLoading) {
    return <SkeletonText noOfLines={5} />;
  }

  if (error) {
    return <Error error={error} />;
  }

  if (P.isEmpty(distributionsToBeSent)) {
    return null;
  }

  return (
    <FormSection
      heading="Distributions"
      description={
        <Text color="secondary">
          There {distributionsToBeSent.length === 1 ? 'is' : 'are'} {distributionsToBeSent.length}{' '}
          {inflection.inflect('distribution', distributionsToBeSent.length)} that will be sent out
          when finalized
        </Text>
      }
    >
      {distributionsToBeSent.map((distribution) => (
        <DistributionSummary key={distribution.id} distribution={distribution} />
      ))}
    </FormSection>
  );
};
