import {
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { readFileAsJson } from '@piccolohealth/ui';
import _ from 'lodash';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useAddReportTemplateMutation } from '../../../graphql/hooks/useAddReportTemplateMutation';
import { useAppContext } from '../../../hooks/useAppContext';
import { FormSection } from '../../forms/FormSection';
import { HookedColorPicker } from '../../forms/hookform/HookedColorPicker';
import { HookedFileInput } from '../../forms/hookform/HookedFileInput';
import { HookedFormItem } from '../../forms/hookform/HookedFormItem';
import { HookedInput } from '../../forms/hookform/HookedInput';
import { HookedResetButton } from '../../forms/hookform/HookedResetButton';
import { HookedSubmitButton } from '../../forms/hookform/HookedSubmitButton';
import { createModal } from '../../generic/Modal';

interface FormValues {
  name: string;
  labelColor: string;
  baseTemplateFile: File[] | null;
}

export const ReportTemplateCreateModal = createModal<{}>((props) => {
  const { modal } = props;
  const { organization, successToast, errorToast } = useAppContext();

  const initialValues: FormValues = {
    name: '',
    labelColor: 'blue',
    baseTemplateFile: [],
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Required'),
    labelColor: Yup.string().required('Required'),
  });

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const mutation = useAddReportTemplateMutation();

  const onSubmit = async (values: Partial<FormValues>) => {
    try {
      const { name, labelColor, baseTemplateFile } = values;

      // Read the from the file  and try and parse it as JSON
      const file = _.head(baseTemplateFile);
      const baseTemplateData = file ? await readFileAsJson(file) : null;

      await mutation.mutateAsync({
        organizationId: organization.id,
        request: {
          name: name as string,
          labelColor: labelColor as string,
          baseTemplateData,
        },
      });

      successToast('Report template created successfully');
      modal.hide();
      methods.reset();
    } catch (err) {
      errorToast(`Error creating report template: ${err.message ?? err}`);
    }
  };

  return (
    <Modal isOpen={modal.visible} onClose={modal.hide} onCloseComplete={modal.remove}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create report template</ModalHeader>
        <ModalCloseButton />
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <ModalBody>
              <FormSection>
                <HookedFormItem label="Name" name="name">
                  <HookedInput name="name" data-pw="reportTemplateCreateModalNameInput" />
                </HookedFormItem>
                <HookedFormItem label="From file" name="baseTemplateFile">
                  <HookedFileInput
                    name="baseTemplateFile"
                    multiple={false}
                    renderFilesSelected={(files) => <>Upload - {files.length} files selected</>}
                  >
                    Browse files
                  </HookedFileInput>
                </HookedFormItem>
                <HookedFormItem label="Color" name="labelColor">
                  <HookedColorPicker name="labelColor" />
                </HookedFormItem>
              </FormSection>
            </ModalBody>
            <ModalFooter>
              <ButtonGroup size="sm">
                <HookedResetButton>Reset</HookedResetButton>
                <HookedSubmitButton data-pw="reportTemplateCreateModalSubmitButton">
                  Create
                </HookedSubmitButton>
              </ButtonGroup>
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
});
