import { useEvent } from 'react-use';

export enum ScrollDirection {
  Down = 'DOWN',
  Up = 'UP',
}

export const useScroll = (
  handler: (direction: ScrollDirection, coords: { x: number; y: number }) => void,
) => {
  useEvent('wheel', (e) => {
    const coords = { x: e.x, y: e.y };

    if (e.deltaY < 0) {
      handler(ScrollDirection.Up, coords);
    } else if (e.deltaY > 0) {
      handler(ScrollDirection.Down, coords);
    }
  });
};
