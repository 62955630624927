import gql from 'graphql-tag';

export const GenerateDraftReportPdf = gql`
  mutation GenerateDraftReportPdf($organizationId: ID!, $reportId: ID!) {
    generateDraftReportPdf(organizationId: $organizationId, reportId: $reportId) {
      viewUrl
      downloadUrl
    }
  }
`;
