import { Button } from '@chakra-ui/react';
import { SerializedNodes, useEditor } from '@craftjs/core';
import _ from 'lodash';
import React from 'react';
import { FaRegSave } from 'react-icons/fa';
import { useBeforeUnload } from 'react-use';

interface Props {
  onSave: (nodes: SerializedNodes) => Promise<void>;
}

export const EditorSave = (props: Props) => {
  const { onSave } = props;

  const { nodes } = useEditor((_state, query) => ({
    nodes: query.getSerializedNodes(),
  }));
  const [savedNodes, setSavedNodes] = React.useState<SerializedNodes>(nodes);
  const [hasLoaded, setHasLoaded] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!hasLoaded && !_.isEmpty(nodes)) {
      setSavedNodes(nodes);
      setHasLoaded(true);
    }
  }, [nodes, hasLoaded]);

  const isDirty = React.useMemo(() => {
    return nodes !== savedNodes;
  }, [nodes, savedNodes]);

  useBeforeUnload(isDirty, 'Changes that you made may not be saved.');

  const onSavePrime = async () => {
    await onSave(nodes);
    setSavedNodes(nodes);
  };

  return (
    <Button
      size="sm"
      leftIcon={<FaRegSave />}
      w="100%"
      mt={2}
      colorScheme="purple"
      onClick={onSavePrime}
      isDisabled={!isDirty}
    >
      Save
    </Button>
  );
};
