import gql from 'graphql-tag';

export const ShareReport = gql`
  mutation ShareReport(
    $organizationId: ID!
    $reportId: ID!
    $shareReportRequest: ShareReportRequest!
  ) {
    shareReport(
      organizationId: $organizationId
      reportId: $reportId
      request: $shareReportRequest
    ) {
      id
      organizationId
      shareeId
      shareeEmail
      sharerId
      sharerEmail
      message
      createdAt
      updatedAt
      isExpired
      expiresAt
    }
  }
`;
