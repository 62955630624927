import { Text } from '@chakra-ui/react';
import { Query, ResultSet } from '@cubejs-client/core';
import { useCubeQuery } from '@cubejs-client/react';
import React from 'react';
import { Empty } from '@piccolohealth/ui';
import { Spin } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';

interface Props {
  query: Query & { chart?: { x: string; y: string; color: string } };
  component: (resultSet: ResultSet<any>) => React.ReactElement;
}

export const CubeQuery = (props: Props) => {
  const { query, component } = props;

  const { resultSet, isLoading, error } = useCubeQuery(P.omit(query, ['chart']));

  if (isLoading) {
    return <Spin />;
  }

  if (error) {
    return <Text>Could not load metric</Text>;
  }

  if (!resultSet) {
    return null;
  }

  if (P.isEmpty(resultSet.seriesNames())) {
    return <Empty title="No metric data found" />;
  }

  return component(resultSet);
};
