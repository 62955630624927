import { useTheme } from '@chakra-ui/react';
import { Query, ResultSet } from '@cubejs-client/core';
import { DateTime, P } from '@piccolohealth/util';
import React from 'react';
import { BarChart, BarChartData } from '../../../components/charts/BarChart';
import { ChakraColorMap, getChakraColor } from '../../../utils/colors';
import { CubeQuery } from './CubeQuery';

interface Props {
  query: Query;
  layout?: 'vertical' | 'horizontal';
}

const dateFormats = {
  hour: 'p EEEEEE',
  day: 'dd/MM',
  week: 'dd/MM',
  month: 'MM/yy',
};

const getChartData = (
  query: Query,
  resultSet: ResultSet<any>,
  colors: ChakraColorMap,
): BarChartData[] => {
  const seriesNames = resultSet.seriesNames();

  if (P.isEmpty(seriesNames)) {
    return [];
  }

  const chart = (query as any).chart;

  const chartPivot = resultSet.chartPivot();
  const timeDimensions = query.timeDimensions ?? [];
  const granularity: string = P.first(timeDimensions)?.granularity ?? 'month';

  const data = chartPivot.map((row) => {
    const y = resultSet.seriesNames().reduce((acc, series) => {
      const dimensions = resultSet.query().dimensions ?? [];

      const info: Record<string, string> = dimensions.reduce((acc, curr, index) => {
        return {
          [curr]: series.yValues[index],
          ...acc,
        };
      }, {});

      const name = info[chart.x];

      if (name === null) {
        return acc;
      }

      const value = row[series.key];
      const color = getChakraColor(colors, info[chart.color]);

      if (acc[name]) {
        acc[name].value += value;
      } else {
        acc[name] = {
          value,
          color,
        };
      }

      return acc;
    }, {} as Record<string, { value: number; color: string }>);

    return {
      x: DateTime.fromISO(row.x).toFormat(P.get(dateFormats, granularity) ?? dateFormats.day),
      y,
    };
  });

  return data;
};

export const CubeBarTimeChart = (props: Props) => {
  const { query, layout } = props;

  const theme = useTheme();

  return (
    <CubeQuery
      query={query}
      component={(resultSet) => {
        return (
          <BarChart chartData={getChartData(query, resultSet, theme.colors)} layout={layout} />
        );
      }}
    />
  );
};
