import * as Types from '@piccolohealth/echo-common';
import { useMutation } from 'react-query';
import { gqlFetcher, MutationOptions } from '../fetcher';
import { CreatePasswordChangeTicketUrl } from '../mutations/createPasswordChangeTicketUrl.mutation';

export const useCreatePasswordChangeTicketUrlMutation = (
  options?: MutationOptions<
    Types.CreatePasswordChangeTicketUrlMutation,
    Types.CreatePasswordChangeTicketUrlMutationVariables
  >,
) => useMutation(gqlFetcher(CreatePasswordChangeTicketUrl), options);
