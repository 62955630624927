import gql from 'graphql-tag';

export const createEchoIQAssessment = gql`
  mutation createEchoIQAssessment($organizationId: ID!, $reportId: ID!) {
    createEchoIQAssessment(organizationId: $organizationId, reportId: $reportId) {
      id
      pdf
    }
  }
`;
