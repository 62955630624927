import gql from 'graphql-tag';

export const DeleteReportAttachment = gql`
  mutation DeleteReportAttachment($organizationId: ID!, $reportId: ID!, $reportAttachmentId: ID!) {
    deleteReportAttachment(
      organizationId: $organizationId
      reportId: $reportId
      reportAttachmentId: $reportAttachmentId
    )
  }
`;
