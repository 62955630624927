import { IconButton } from '@chakra-ui/react';
import React from 'react';
import { FaFileAlt } from 'react-icons/fa';
import { ControlTooltip } from './ControlTooltip';

interface Props {
  isReportShowing: boolean;
  onToggleReportShowing: () => void;
}

export const ToggleReportControl = (props: Props) => {
  const { isReportShowing, onToggleReportShowing } = props;

  return (
    <ControlTooltip label={isReportShowing ? 'Hide report' : 'Show report'}>
      <IconButton
        aria-label="Settings"
        icon={<FaFileAlt />}
        onClick={onToggleReportShowing}
        size="sm"
        fontSize="xl"
        variant="ghost"
        colorScheme="purple"
        _hover={{
          bg: 'purple.600',
        }}
        _active={{
          bg: 'purple.400',
        }}
        color="white"
      />
    </ControlTooltip>
  );
};
