import {
  Button,
  ButtonGroup,
  ButtonGroupProps,
  useFormControl,
  useRadio,
  useRadioGroup,
  UseRadioProps,
} from '@chakra-ui/react';
import React from 'react';

export interface RadioButtonProps extends UseRadioProps {
  label: string;
  icon?: React.ReactElement;
}

const RadioButton = (props: RadioButtonProps) => {
  const { label, icon } = props;
  const { getCheckboxProps, getInputProps } = useRadio(props);
  const checkbox = getCheckboxProps();
  const input = getInputProps();

  return (
    <Button
      leftIcon={icon}
      as="label"
      {...checkbox}
      _checked={{
        bg: 'purple.500',
        borderColor: 'purple.500',
        color: 'white',
      }}
      _hover={{}}
    >
      <input {...input} />
      {label}
    </Button>
  );
};

export interface RadioGroupProps extends Omit<ButtonGroupProps, 'onChange'> {
  options: {
    value: string;
    label: string;
    icon?: React.ReactElement;
  }[];
  value?: string;
  onChange: (value: string) => void;
}

export const RadioGroup = (props: RadioGroupProps) => {
  const { onChange, options, value, isDisabled, ...rest } = props;
  const { 'aria-invalid': invalid } = useFormControl<HTMLInputElement>(props);
  const { getRootProps, getRadioProps } = useRadioGroup({
    value,
    onChange,
  });
  const group = getRootProps();

  return (
    <ButtonGroup
      {...group}
      {...rest}
      isAttached
      aria-invalid={invalid}
      _invalid={{
        border: '2px solid',
        borderColor: 'red.500',
        borderRadius: 'lg',
      }}
    >
      {options.map((option) => (
        <RadioButton
          {...getRadioProps({ value: option.value })}
          key={option.value}
          label={option.label}
          icon={option.icon}
          isDisabled={isDisabled}
        />
      ))}
    </ButtonGroup>
  );
};
