const baseStyle = {
  separator: {
    maxHeight: `calc(100% - var(--stepper-indicator-size)) !important`,
    top: `calc(var(--stepper-indicator-size) + 2px) !important`,
  },
};

export const Stepper = {
  baseStyle,
};
