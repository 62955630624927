import {
  StackProps,
  useCheckbox,
  useCheckboxGroup,
  UseCheckboxProps,
  VStack,
} from '@chakra-ui/react';
import { StringOrNumber } from '@chakra-ui/utils';
import React from 'react';
import { SelectableItem } from './SelectableItem';

export interface CheckboxStackOption {
  value: string;
  label: string;
  description: string;
  icon?: () => React.ReactElement;
}

interface CheckboxItemProps extends UseCheckboxProps {
  label: string;
  description: string;
  icon?: () => React.ReactElement;
}

const CheckboxItem = (props: CheckboxItemProps) => {
  const { getInputProps, getCheckboxProps, state } = useCheckbox(props);

  return (
    <SelectableItem
      {...props}
      type="checkbox"
      label={props.label}
      description={props.description}
      icon={props.icon}
      getInputProps={getInputProps}
      getItemProps={getCheckboxProps}
      isChecked={state.isChecked}
    />
  );
};

export interface CheckboxStackProps extends Omit<StackProps, 'onChange'> {
  options: CheckboxStackOption[];
  value: StringOrNumber[];
  onChange: (value: StringOrNumber[]) => void;
}

export const CheckboxStack = (props: CheckboxStackProps) => {
  const { options, value, onChange, ...rest } = props;

  const { getCheckboxProps } = useCheckboxGroup({
    value,
    onChange,
  });

  return (
    <VStack alignItems="flex-start" spacing={2} {...rest}>
      {options.map((option) => (
        <CheckboxItem
          key={option.value}
          data-pw={option.value}
          {...option}
          {...getCheckboxProps({ ...option })}
        />
      ))}
    </VStack>
  );
};
