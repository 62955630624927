import gql from 'graphql-tag';

export const CreateLabel = gql`
  mutation CreateLabel($organizationId: ID!, $request: CreateLabelRequest!) {
    createLabel(organizationId: $organizationId, request: $request) {
      id
      organizationId
      name
      description
      color
      createdAt
      updatedAt
    }
  }
`;
