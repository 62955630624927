export interface BoundingRectangle {
  height: number;
  width: number;
  x: number;
  y: number;
}

export interface Coords {
  x: number;
  y: number;
}

export const insideBound = (boundOne: BoundingRectangle, coords: Coords): boolean => {
  return (
    boundOne.x <= coords.x &&
    coords.x <= boundOne.x + boundOne.width &&
    boundOne.y <= coords.y &&
    coords.y <= boundOne.y + boundOne.height
  );
};
