import gql from 'graphql-tag';

export const UpdateProfile = gql`
  mutation UpdateProfile($updateProfileRequest: UpdateProfileRequest!) {
    updateProfile(request: $updateProfileRequest) {
      id
      name
      email
      picture
      organizationMemberships {
        roles {
          id
          name
        }
      }
      settings {
        defaultOrganization
        finalizeBehavior
      }
    }
  }
`;
