import { Tag } from '@chakra-ui/react';
import { P } from '@piccolohealth/util';
import React from 'react';

interface Props {
  status?: string;
}

export const InvoiceStatusTag = (props: Props) => {
  const status = props.status ?? 'unknown';

  const colors = {
    draft: 'gray',
    open: 'blue',
    paid: 'green',
    uncollectible: 'red',
    void: 'purple',
    unknown: 'gray',
  };

  return <Tag colorScheme={P.get(colors, status) ?? 'gray'}>{P.upperFirst(status)}</Tag>;
};
