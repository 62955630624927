import { Button, HStack, Icon } from '@chakra-ui/react';
import { useEditor } from '@craftjs/core';
import _ from 'lodash';
import React from 'react';
import { FaRedo, FaUndo } from 'react-icons/fa';
import { useKeyPressEvent } from 'react-use';
import useKeyboardJs from 'react-use/lib/useKeyboardJs';

export const UndoRedo = () => {
  const { actions, canUndo, canRedo } = useEditor((state, query) => ({
    canUndo: query.history.canUndo(),
    canRedo: query.history.canRedo(),
  }));

  useKeyPressEvent('ctrl + z', () => actions.history.undo(), _.noop, useKeyboardJs as any);
  useKeyPressEvent('command + z', () => actions.history.undo(), _.noop, useKeyboardJs as any);
  useKeyPressEvent('ctrl + shift + z', () => actions.history.redo(), _.noop, useKeyboardJs as any);
  useKeyPressEvent(
    'command + shift + z',
    () => actions.history.redo(),
    _.noop,
    useKeyboardJs as any,
  );

  return (
    <HStack>
      <Button
        leftIcon={<Icon as={FaUndo} />}
        isDisabled={!canUndo}
        onClick={() => actions.history.undo()}
        flex="1"
      >
        Undo
      </Button>
      <Button
        leftIcon={<Icon as={FaRedo} />}
        isDisabled={!canRedo}
        onClick={() => actions.history.redo()}
        flex="1"
      >
        Redo
      </Button>
    </HStack>
  );
};
