import { Button, ButtonGroup, ModalFooter, Stack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useUpdateIntegrationsMutation } from '../../../../graphql/hooks/useUpdateIntegrationMutation';
import { useAppContext } from '../../../../hooks/useAppContext';
import { FormSection } from '../../../forms/FormSection';
import { HookedFormItem } from '../../../forms/hookform/HookedFormItem';
import { HookedInput } from '../../../forms/hookform/HookedInput';
import { HookedSubmitButton } from '../../../forms/hookform/HookedSubmitButton';
import { HookedSwitch } from '../../../forms/hookform/HookedSwitch';
import { Divider } from '../../../generic/Divider';
import { MedicalObjectsProviderMappingsControl } from './MedicalObjectsProviderMappingControl';

type FormValues = {
  enabled: boolean;
  capricornUrl?: string | null;
  practiceId?: string | null;
  practiceName?: string | null;
  providerMappings: {
    providerNumber: string;
    userId: string;
  }[];
  hl7Overrides?: {
    clinicalArea?: string | null;
  } | null;
};

const validationSchema = Yup.object({
  enabled: Yup.boolean().required(),
  capricornUrl: Yup.string().nullable(),
  practiceId: Yup.string().nullable(),
  practiceName: Yup.string().nullable(),
  providerMappings: Yup.array().of(
    Yup.object({
      providerNumber: Yup.string().required(),
      userId: Yup.string().required(),
    }),
  ),
  hl7Overrides: Yup.object({
    clinicalArea: Yup.string().nullable(),
  }).nullable(),
});

interface Props {
  initialValues: FormValues;
  hide: () => void;
}

export const MedicalObjectsIntegrationForm = (props: Props) => {
  const { initialValues, hide } = props;
  const { organization, successToast, errorToast } = useAppContext();

  const mutation = useUpdateIntegrationsMutation();
  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema) as any,
  });

  const { reset } = methods;

  const onSubmit = React.useCallback(
    async (values: FormValues) => {
      await mutation
        .mutateAsync({
          organizationId: organization.id,
          updateIntegrationsRequest: {
            medicalObjects: values,
          },
        })
        .then(() => {
          reset(values);
          successToast(`Medical-Objects integration updated successfully`);
        })
        .catch((error) => {
          errorToast(`Error editing Medical-Objects integration: ${error.message}`);
        });
    },
    [mutation, reset, successToast, errorToast, organization.id],
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Stack spacing={6}>
          <FormSection heading="Basic">
            <HookedFormItem label="Enabled" name="enabled" layout="horizontal">
              <HookedSwitch name="enabled" />
            </HookedFormItem>
            <HookedFormItem
              label="Capricorn endpoint"
              name="capricornUrl"
              helperText="Endpoint is obtained by speaking to Medical-Objects"
            >
              <HookedInput name="capricornUrl" />
            </HookedFormItem>
            <HookedFormItem
              label="Practice ID"
              name="practiceId"
              helperText="Practice ID is obtained by speaking to Medical-Objects"
            >
              <HookedInput name="practiceId" />
            </HookedFormItem>
            <HookedFormItem
              label="Practice Name"
              name="practiceName"
              helperText="Practice Name is obtained by speaking to Medical-Objects"
            >
              <HookedInput name="practiceName" />
            </HookedFormItem>
          </FormSection>

          <Divider />

          <FormSection heading="Provider settings">
            <HookedFormItem
              label="Provider mappings"
              name="providerMappings"
              helperText="Link provider numbers to users"
            >
              <MedicalObjectsProviderMappingsControl name="providerMappings" />
            </HookedFormItem>
          </FormSection>

          <Divider />

          <FormSection heading="HL7 overrides">
            <HookedFormItem
              label="Clinical area (ORU.24)"
              name="hl7Overrides.clinicalArea"
              helperText="Optionally override the 'Clinical Area' in HL7 messages"
            >
              <HookedInput name="hl7Overrides.clinicalArea" placeholder="Enter a clinical area" />
            </HookedFormItem>
          </FormSection>
        </Stack>
        <ModalFooter>
          <ButtonGroup size="sm">
            <Button key="close" onClick={hide}>
              Close
            </Button>
            <HookedSubmitButton key="submit">Save</HookedSubmitButton>
          </ButtonGroup>
        </ModalFooter>
      </form>
    </FormProvider>
  );
};
