import { Extension } from '@tiptap/core';
import { ContextMenuPlugin, Renderer } from './ContextMenuPlugin';

interface ContextMenuOptions {
  renderer?: () => Renderer;
}

export const ContextMenu = Extension.create<ContextMenuOptions>({
  name: 'contextMenu',

  addOptions() {
    return {
      renderer: undefined,
    };
  },

  addProseMirrorPlugins() {
    return [
      ContextMenuPlugin({
        editor: this.editor,
        renderer: this.options.renderer,
      }),
    ];
  },
});
