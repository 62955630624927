import { useRequiredParams } from '@piccolohealth/ui';
import React from 'react';
import { useReportTemplateMinimalQuery } from '../../graphql/hooks/useReportTemplateQuery';
import { useAppContext } from '../../hooks/useAppContext';

export const TemplateBreadcrumb = () => {
  const { reportTemplateId } = useRequiredParams<{ reportTemplateId: string }>();
  const { organization } = useAppContext();

  const { isLoading, data, error } = useReportTemplateMinimalQuery({
    organizationId: organization.id,
    reportTemplateId,
  });

  if (isLoading) {
    return <span>...</span>;
  }

  if (error) {
    return <span>_</span>;
  }

  const name: string | undefined = data?.organization?.reportTemplate?.name;

  if (!name) {
    return <span>_</span>;
  }

  return <span>{name}</span>;
};
