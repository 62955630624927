import { Button, Icon } from '@chakra-ui/react';
import { PERMISSIONS, ReportTemplate } from '@piccolohealth/echo-common';
import React from 'react';
import { FaPlus } from 'react-icons/fa';
import { usePermission } from '../../../hooks/usePermission';
import { showModal } from '../../generic/Modal';
import { ReportTemplateVariableAddModal } from './ReportTemplateVariableAddModal';

interface Props {
  reportTemplate: ReportTemplate;
}

export const ReportTemplateVariableAddButton = (props: Props) => {
  const { reportTemplate } = props;
  const hasPermission = usePermission(PERMISSIONS.reportTemplateUpdate).value;

  if (!hasPermission) {
    return null;
  }

  return (
    <Button
      size="sm"
      variant="outline"
      leftIcon={<Icon as={FaPlus} />}
      isDisabled={!hasPermission}
      onClick={() => showModal(ReportTemplateVariableAddModal, { reportTemplate })}
    >
      Add new variable
    </Button>
  );
};
