const baseStyle = {
  field: {
    _disabled: {
      color: 'inherit',
      opacity: '1',
    },
  },
};

const sizes = {
  sm: {
    field: {
      borderRadius: 'md',
    },
  },
};

const variants = {
  unstyled: (props: any) => {
    const base = props.theme.components.Select.sizes[props.size ?? 'sm']?.field ?? {};
    return {
      field: {
        _hover: {
          bg: 'gray.100',
        },
        height: base.h,
        px: base.px,
      },
    };
  },
};

const defaultProps = {
  size: 'sm',
  colorScheme: 'purple',
};

export const Select = {
  baseStyle,
  sizes,
  defaultProps,
  variants,
};
