import { HStack } from '@chakra-ui/react';
import { PERMISSIONS, Report } from '@piccolohealth/echo-common';
import { usePermission } from '../../../hooks/usePermission';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useReportMinimalQuery } from '../../../graphql/hooks/useReportQuery';
import { useAppContext } from '../../../hooks/useAppContext';
import { ReportActionMenu } from '../../controls/ReportActionMenu';
import { ReportDebugButton } from '../../controls/ReportDebugButton';
import { ReportUpdateStatusButton } from '../../controls/status/ReportUpdateStatusControl';

interface Props {
  reportId: string;
}

export const ReportExtraContent = (props: Props) => {
  const { reportId } = props;
  const { organization } = useAppContext();
  const navigate = useNavigate();

  const isSupport = usePermission(PERMISSIONS.organizationSupport).value;

  const { data } = useReportMinimalQuery({
    organizationId: organization.id,
    reportId,
  });

  const report = data?.organization?.report as Report | undefined;

  if (!report) {
    return null;
  }

  const onDelete = async () => {
    navigate(`/organizations/${organization.id}`);
  };

  return (
    <HStack>
      {isSupport && <ReportDebugButton reportId={report.id} />}
      <ReportActionMenu reportId={report.id} reportStatus={report.status} onDelete={onDelete} />
      <ReportUpdateStatusButton report={report} />
    </HStack>
  );
};
