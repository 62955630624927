import React from 'react';
import { useReportRelatedReportsMinimalQuery } from '../../../graphql/hooks/useReportQuery';
import { useAppContext } from '../../../hooks/useAppContext';
import { NumberedBadge } from '../../generic/NumberedBadge';

interface Props {
  reportId: string;
}

export const RelatedReportsBadge = (props: Props) => {
  const { reportId } = props;
  const { organization } = useAppContext();

  const { data } = useReportRelatedReportsMinimalQuery({
    organizationId: organization.id,
    reportId,
  });

  const relatedReports = data?.organization?.report?.related || [];

  return <NumberedBadge count={relatedReports.length} />;
};
