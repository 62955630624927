import gql from 'graphql-tag';

export const AddReportLabel = gql`
  mutation AddReportLabel($organizationId: ID!, $reportId: ID!, $labelId: ID!) {
    addReportLabel(organizationId: $organizationId, reportId: $reportId, labelId: $labelId) {
      id
      name
      description
      color
      createdAt
      updatedAt
    }
  }
`;
