import { CheckboxStack, CheckboxStackProps } from '@piccolohealth/ui';
import React from 'react';
import { useController } from 'react-hook-form';

interface HookedCheckboxStackProps extends Omit<CheckboxStackProps, 'onChange' | 'value'> {
  name: string;
}

export const HookedCheckboxStack = (props: HookedCheckboxStackProps) => {
  const { name, options, ...rest } = props;

  const {
    field: { ref, ...inputProps },
  } = useController({ name });

  return <CheckboxStack options={options} {...inputProps} {...rest} />;
};
