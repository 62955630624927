import { Box, Heading, HStack, SimpleGrid, VStack } from '@chakra-ui/react';
import { WallMotionModule, WallMotionValues, WallMotionWall } from '@piccolohealth/echo-common';
import _ from 'lodash';
import React, { RefObject } from 'react';
import { Divider } from '../../generic/Divider';
import { Apical2Chamber } from './Apical2Chamber';
import { Apical3Chamber } from './Apical3Chamber';
import { Apical4Chamber } from './Apical4Chamber';
import { Bullseye } from './Bullseye';
import { HorizontalWallMotionLegend, VerticalWallMotionLegend } from './Legend';
import { PsaxApical } from './PsaxApical';
import { PsaxBasal } from './PsaxBasal';
import { PsaxMid } from './PsaxMid';
import { WallContextMenu } from './WallContextMenu';

export interface SvgParams {
  paths: JSX.Element[];
  viewBox: string;
}

export interface WallMotionDiagramProps {
  module: WallMotionModule;
  values: WallMotionValues;
  isDisabled?: boolean;
  width?: number | string;
  height?: number | string;
  showScoreNumbers?: boolean;
  onContextMenuClick: (wallKey: string, scoreKey: string) => void;
}

const SvgWallArea = (props: {
  containerRef: RefObject<HTMLElement>;
  values: WallMotionValues;
  module: WallMotionModule;
  path: JSX.Element;
  onContextMenuClick: (id: string, wallKey: string) => void;
  isDisabled?: boolean;
}) => {
  const { values, path, module, isDisabled, onContextMenuClick } = props;
  const id = path.props.id as WallMotionWall;
  const ref = React.useRef<SVGGElement>(null);

  if (!id) {
    return null;
  }

  const wallScore = values[id];
  const wallName = module.walls[id]?.text;
  const color = module.scores[wallScore]?.color;
  const onClick = (wallKey: string) => onContextMenuClick(id, wallKey);

  const element = React.cloneElement(path, {
    ref: ref,
    fill: color,
    strokeWidth: '1px',
    stroke: 'black',
    vectorEffect: 'non-scaling-stroke',
  });

  return (
    <WallContextMenu onClick={onClick} wallName={wallName} isDisabled={isDisabled} module={module}>
      {element}
    </WallContextMenu>
  );
};

const SvgWallValue = (props: {
  values: WallMotionValues;
  module: WallMotionModule;
  path: JSX.Element;
}) => {
  const { path, values, module } = props;
  const id = path.props.id as WallMotionWall;

  const wallScore = values[id];
  const number = module.scores[wallScore]?.number;

  return React.cloneElement(
    path,
    {
      fontSize: '12px',
      pointerEvents: 'none',
    },
    <>{number}</>,
  );
};

const SvgControl = (props: WallMotionDiagramProps & { params: SvgParams }) => {
  const {
    isDisabled,
    values,
    width,
    height,
    module,
    onContextMenuClick,
    params,
    showScoreNumbers,
  } = props;
  const { paths, viewBox } = params;

  const containerRef = React.useRef<HTMLDivElement>(null);

  const nodes = _.map(paths, (path, index) => {
    const { id, className } = path.props;

    if (className === 'wall-area') {
      return (
        <SvgWallArea
          containerRef={containerRef}
          key={`${id}-${index}`}
          values={values}
          module={module}
          path={path}
          onContextMenuClick={onContextMenuClick}
          isDisabled={isDisabled}
        />
      );
    }

    if (className === 'wall-value' && (showScoreNumbers ?? false)) {
      return (
        <SvgWallValue key={`${id}-${index}-value`} values={values} module={module} path={path} />
      );
    }

    if (className === 'wall-detail') {
      return React.cloneElement(path, { key: `${id}-${index}`, fill: '#d8d8d8' }, null);
    }

    if (className === 'text') {
      return React.cloneElement(
        path,
        { key: `${id}-${index}`, fontSize: '12px' },
        path.props.children,
      );
    }

    return null;
  });

  return (
    <svg width={width} height={height || 160} viewBox={viewBox} style={{ overflow: 'visible' }}>
      {nodes}
    </svg>
  );
};

export const Apical4ChamberControl = (props: WallMotionDiagramProps) => {
  return <SvgControl {...props} params={Apical4Chamber} />;
};

export const Apical2ChamberControl = (props: WallMotionDiagramProps) => {
  return <SvgControl {...props} params={Apical2Chamber} />;
};

export const Apical3ChamberControl = (props: WallMotionDiagramProps) => {
  return <SvgControl {...props} params={Apical3Chamber} />;
};

export const PsaxBasalControl = (props: WallMotionDiagramProps) => {
  return <SvgControl {...props} params={PsaxBasal} />;
};

export const PsaxMidControl = (props: WallMotionDiagramProps) => {
  return <SvgControl {...props} params={PsaxMid} />;
};

export const PsaxApicalControl = (props: WallMotionDiagramProps) => {
  return <SvgControl {...props} params={PsaxApical} />;
};

export const BullseyeControl = (props: WallMotionDiagramProps) => {
  return <SvgControl {...props} params={Bullseye} showScoreNumbers={false} />;
};

export const WallMotionAnatomicalControl = (props: WallMotionDiagramProps) => {
  return (
    <Box>
      <SimpleGrid columns={3} spacing={6}>
        <VStack>
          <Heading size="xs">Apical 4CH</Heading>
          <Apical4ChamberControl {...props} />
        </VStack>
        <VStack>
          <Heading size="xs">Apical 2CH</Heading>
          <Apical2ChamberControl {...props} />
        </VStack>
        <VStack>
          <Heading size="xs">Apical 3CH</Heading>
          <Apical3ChamberControl {...props} />
        </VStack>
        <VStack>
          <Heading size="xs">PSAX Basal</Heading>
          <PsaxBasalControl {...props} />
        </VStack>
        <VStack>
          <Heading size="xs">PSAX Mid</Heading>
          <PsaxMidControl {...props} />
        </VStack>
        <VStack>
          <Heading size="xs">PSAX Apical</Heading>
          <PsaxApicalControl {...props} />
        </VStack>
      </SimpleGrid>
      <Divider />
      <HorizontalWallMotionLegend module={props.module} />
    </Box>
  );
};

export const WallMotionBullseyeControl = (props: WallMotionDiagramProps) => {
  return (
    <HStack spacing={4}>
      <Box>
        <BullseyeControl {...props} />
      </Box>
      <Box>
        <VerticalWallMotionLegend module={props.module} />
      </Box>
    </HStack>
  );
};

export enum WallMotionDiagramType {
  Bullseye = 'Bullseye',
  Apical2Chamber = 'Apical2Chamber',
  Apical4Chamber = 'Apical4Chamber',
  Apical3Chamber = 'Apical3Chamber',
  Plax = 'Plax',
  PsaxBasal = 'PsaxBasal',
  PsaxMid = 'PsaxMid',
  PsaxApical = 'PsaxApical',
  // TODO: Remove after migrating old report templates 'Legend' to vertical legend.
  Legend = 'Legend',
  VerticalLegend = 'VerticalLegend',
  HorizontalLegend = 'HorizontalLegend',
}
