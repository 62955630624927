import { MenuItem } from '@chakra-ui/react';
import {
  PERMISSIONS,
  ReportTemplate,
  serializeExportedReportTemplate,
} from '@piccolohealth/echo-common';
import React from 'react';
import { FaDownload } from 'react-icons/fa';
import { useReportTemplateQuery } from '../../../graphql/hooks/useReportTemplateQuery';
import { useAppContext } from '../../../hooks/useAppContext';
import { usePermission } from '../../../hooks/usePermission';

interface Props {
  reportTemplateId: string;
}

export const ReportTemplateExportMenuItem = (props: Props) => {
  const { reportTemplateId, ...rest } = props;

  const { organization, errorToast } = useAppContext();
  const hasPermission = usePermission(PERMISSIONS.reportTemplateUpdate).value;

  const getReportTemplate = useReportTemplateQuery.getFetcher();

  const onClick = React.useCallback(() => {
    getReportTemplate({ organizationId: organization.id, reportTemplateId }).then((res) => {
      const reportTemplate = res?.organization?.reportTemplate as ReportTemplate | undefined;

      if (!reportTemplate) {
        errorToast(
          `Unable to export report template. report template not found ${reportTemplateId}`,
        );
        return;
      }

      const data = serializeExportedReportTemplate(reportTemplate);
      const fileData = JSON.stringify(data);
      const blob = new Blob([fileData], { type: 'text/plain' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      const filename = `${organization.name} - ${reportTemplate.name}.json`;
      link.download = filename;
      link.href = url;
      link.click();
    });
  }, [organization, reportTemplateId, getReportTemplate, errorToast]);

  return (
    <MenuItem {...rest} isDisabled={!hasPermission} onClick={onClick} icon={<FaDownload />}>
      Export
    </MenuItem>
  );
};
