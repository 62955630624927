import { limitPromiseConcurrency } from './throttle';

export const fetchLimiter: <T extends unknown>(fn: () => PromiseLike<T>) => Promise<T> =
  limitPromiseConcurrency(10);

export const fetchWithTimeout = (input: RequestInfo, init?: RequestInit & { timeout?: number }) => {
  const controller = new AbortController();
  const options: RequestInit = {
    ...init,
    signal: controller.signal,
  };
  const promise = fetch(input, options);

  setTimeout(() => controller.abort(), init?.timeout ?? 30000);

  return promise;
};
