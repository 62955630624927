import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import { DescriptionList, FancyDate, ScrollArea } from '@piccolohealth/ui';
import { DateTime } from '@piccolohealth/util';
import { DistributionEvent } from '@piccolohealth/echo-common';
import _ from 'lodash';
import React from 'react';
import { JsonTree } from '../../../components/generic/JsonTree';
import { createModal } from '../../../components/generic/Modal';
import { DistributionEventTypeTag } from './DistributionEventType';

interface Props {
  events: DistributionEvent[];
}

export const DistributionEventsModal = createModal<Props>((props) => {
  const { modal, events } = props;

  const { hide, visible } = modal;

  return (
    <Modal isOpen={visible} onClose={hide} onCloseComplete={modal.remove} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex align="start">
            <Box>
              <Heading size="md">Distribution events</Heading>
            </Box>
            <Spacer />
            <ModalCloseButton position="unset" top="unset" right="unset" />
          </Flex>
        </ModalHeader>
        <ModalBody>
          <Stack>
            {_.map(events, (event) => {
              return (
                <Stack>
                  <Box w="full" p={4} borderWidth="1px" borderColor="gray.200" rounded="lg">
                    <DescriptionList
                      items={[
                        {
                          title: 'ID',
                          content: <Text>{event.id}</Text>,
                        },
                        {
                          title: 'Date',
                          content: (
                            <FancyDate
                              date={DateTime.fromISO(event.createdAt.toString())}
                              direction="row"
                            />
                          ),
                        },
                        {
                          title: 'Type',
                          content: <DistributionEventTypeTag type={event.type} />,
                        },
                        {
                          title: 'Payload',
                          content: (
                            <ScrollArea height="140px" w="430px">
                              <JsonTree src={event.payload} collapsed={2} />
                            </ScrollArea>
                          ),
                        },
                      ]}
                      rowGap={4}
                      fontSize="sm"
                    />
                  </Box>
                </Stack>
              );
            })}
          </Stack>
        </ModalBody>
        <ModalFooter>
          <HStack>
            <Button onClick={hide}>Close</Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});
