import _ from 'lodash';

export interface ChakraColorMap {
  [id: string]: { [n: number]: string } | string;
}

export const getChakraColor = (colors: ChakraColorMap, color: string): string => {
  const chakraColor = _.get(colors, color);

  if (!chakraColor) {
    return 'purple.500';
  }

  if (_.isObject(chakraColor)) {
    return chakraColor[100];
  }

  return chakraColor;
};
