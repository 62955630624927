const baseStyle = {
  header: {
    borderBottomWidth: '1px',
    mb: 4,
  },
  dialog: {
    borderRadius: 'xl',
  },
};

export const Modal = {
  baseStyle,
};
