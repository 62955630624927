import gql from 'graphql-tag';

export const CreateReport = gql`
  mutation CreateReport($organizationId: ID!, $createReportRequest: CreateReportRequest!) {
    createReport(organizationId: $organizationId, createReportRequest: $createReportRequest) {
      id
      organizationId
    }
  }
`;
