import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';
import { DateTime } from '@piccolohealth/util';
import { ReportTemplate } from '@piccolohealth/echo-common';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { FormSection } from '../../components/forms/FormSection';
import { HookedFormItem } from '../../components/forms/hookform/HookedFormItem';
import { HookedInput } from '../../components/forms/hookform/HookedInput';
import { HookedRadioGroup } from '../../components/forms/hookform/HookedRadioGroup';
import { HookedReportTypeChooser } from '../../components/forms/hookform/HookedReportTypeChooser';
import { HookedSingleDatepicker } from '../../components/forms/hookform/HookedSingleDatepicker';
import { HookedSubmitButton } from '../../components/forms/hookform/HookedSubmitButton';
import { BulletSteps } from '../../components/generic/BulletSteps';
import { Divider } from '../../components/generic/Divider';
import { useCreateReportMutation } from '../../graphql/hooks/useCreateReportMutation';
import { useAppContext } from '../../hooks/useAppContext';
import { yupResolver } from '@hookform/resolvers/yup';

interface FormValues {
  reportTemplate: ReportTemplate;
  patientName: string;
  sex: string;
  dob: DateTime;
  studyDate: DateTime;
}

interface Props {
  onBack: () => void;
  onClose: () => void;
}

export const CreateReportActionStep = (props: Props) => {
  const { onBack, onClose } = props;
  const { organization, successToast, errorToast } = useAppContext();

  const initialValues: Partial<FormValues> = {
    reportTemplate: undefined,
    patientName: undefined,
    sex: 'male',
    dob: undefined,
    studyDate: DateTime.now().toISO() as unknown as DateTime,
  };

  const validationSchema = Yup.object({
    patientName: Yup.string().required('Name is required'),
    sex: Yup.string().required('Sex is required'),
    dob: Yup.date().required('Date of birth is required'),
    studyDate: Yup.date().required('Study date is required'),
    reportTemplate: Yup.object().required('Report type is required'),
  });

  const methods = useForm({
    defaultValues: initialValues as any,
    resolver: yupResolver(validationSchema),
  });

  const mutation = useCreateReportMutation();

  const onSubmit = async (values: FormValues) => {
    await mutation
      .mutateAsync({
        organizationId: organization.id,
        createReportRequest: {
          patientName: values.patientName,
          sex: values.sex,
          dob: values.dob,
          studyDate: values.studyDate,
          reportTemplateId: values.reportTemplate.id,
        },
      })
      .then(() => {
        successToast('Report created successfully');
        onClose();
      })
      .catch((err) => {
        errorToast(`Error creating report: ${err.message}`);
      });
  };

  return (
    <>
      <FormProvider {...methods}>
        <Box as="form" display="contents" onSubmit={methods.handleSubmit(onSubmit)}>
          <ModalHeader>
            <Flex align="start">
              <Box>
                <Heading size="md">Create a report</Heading>
                <Text fontSize="md" fontWeight="normal" color="secondary" mt={2}>
                  Please complete the following to proceed
                </Text>
              </Box>
              <Spacer />
              <HStack spacing={3}>
                <BulletSteps currentStep={2} totalSteps={2} />
                <ModalCloseButton position="unset" top="unset" right="unset" />
              </HStack>
            </Flex>
          </ModalHeader>
          <ModalBody>
            <VStack align="start" spacing={4}>
              <FormSection heading="Report type">
                <Box w="min-content">
                  <HookedReportTypeChooser name="reportTemplate" />
                </Box>
              </FormSection>
              <FormSection heading="Patient" w="lg">
                <HStack w="full" spacing={4}>
                  <HookedFormItem
                    label="Name"
                    name="patientName"
                    data-pw="nameCreateReportModalFormItem"
                  >
                    <HookedInput name="patientName" placeholder="Enter a name" />
                  </HookedFormItem>
                  <HookedFormItem label="Sex" name="sex" data-pw="sexCreateReportModalFormItem">
                    <HookedRadioGroup
                      name="sex"
                      options={[
                        { label: 'Male', value: 'male' },
                        { label: 'Female', value: 'female' },
                      ]}
                    />
                  </HookedFormItem>
                </HStack>
                <HStack w="full" spacing={4}>
                  <HookedFormItem
                    label="Date of birth"
                    name="dob"
                    data-pw="dobCreateReportModalFormItem"
                  >
                    <HookedSingleDatepicker name="dob" placeholder="Enter a date of birth" />
                  </HookedFormItem>
                  <HookedFormItem
                    label="Report date"
                    name="studyDate"
                    data-pw="reportDateCreateReportModalFormItem"
                  >
                    <HookedSingleDatepicker name="studyDate" placeholder="Enter a report date" />
                  </HookedFormItem>
                </HStack>
              </FormSection>
            </VStack>
            <Divider />
          </ModalBody>
          <ModalFooter>
            <HStack>
              <Button onClick={onBack}>Back</Button>
              <Button onClick={onClose}>Close</Button>
              <HookedSubmitButton data-pw="createReportModalSubmitButton">
                Create report
              </HookedSubmitButton>
            </HStack>
          </ModalFooter>
        </Box>
      </FormProvider>
    </>
  );
};
