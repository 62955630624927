import { Async, P } from '@piccolohealth/util';
import env from 'env-var';
import { interpolate } from './util';
export * from './resolvers';

export interface EnvLike {
  [key: string]: string | undefined;
}

export interface Resolver {
  match: (opts: { key: string; value: string }) => boolean;
  resolve: (value: string) => Promise<string | undefined>;
}

type EnvFn<A> = (
  env: env.IEnv<
    env.IPresentVariable<env.Extensions> & env.ExtenderType<env.Extensions>,
    env.IOptionalVariable<env.Extensions> & env.ExtenderTypeOptional<env.Extensions>
  >,
) => A;

export class ConfigResolver {
  resolvers: Resolver[];

  private constructor(resolvers: Resolver[]) {
    this.resolvers = resolvers;
  }

  static withResolver(resolver: Resolver): ConfigResolver {
    return new ConfigResolver([resolver]);
  }

  withResolver(resolver: Resolver): ConfigResolver {
    this.resolvers.push(resolver);
    return this;
  }

  static build<A>(envLike: EnvLike, f: EnvFn<A>) {
    return new ConfigResolver([]).build(envLike, f);
  }

  async resolve(envLike: EnvLike): Promise<EnvLike> {
    const resolvedEnv = await Async.props(
      P.mapValues(envLike, (value, key) => {
        if (!P.isNil(value)) {
          const match = this.resolvers.find((resolver) => resolver.match({ key, value }));

          if (match) {
            return match.resolve(value);
          }
        }

        return Promise.resolve(value);
      }),
    );

    return P.mapValues(resolvedEnv, (value) => {
      if (!P.isNil(value) && P.isString(value)) {
        return interpolate(value, resolvedEnv as Record<string, string>);
      }

      return value;
    });
  }

  async build<A>(envLike: EnvLike, f: EnvFn<A>): Promise<A> {
    const resolvedEnv = await this.resolve(envLike);
    return f(env.from(resolvedEnv));
  }
}
