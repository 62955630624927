import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
} from '@chakra-ui/react';
import React from 'react';
import { FormSection } from '../../../forms/FormSection';

interface SettingsItemProps {
  title: string;
}

export const SettingsItem = (props: React.PropsWithChildren<SettingsItemProps>) => {
  const { children, title } = props;

  return (
    <AccordionItem>
      <AccordionButton bg="gray.50">
        <Box flex="1" textAlign="left" fontWeight="bold">
          {title}
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel pb={4}>
        <FormSection>{children}</FormSection>
      </AccordionPanel>
    </AccordionItem>
  );
};
