import { ChakraV3 } from '@piccolohealth/ui';
import { PiccoloError, renderPiccoloError } from '@piccolohealth/echo-common';
import React from 'react';
import { sendExceptionToSentry } from '../../utils/errors';

interface Props extends ChakraV3.ErrorProps {
  error: PiccoloError;
}

export const Error = (props: Props) => {
  const { error, ...rest } = props;

  const rendered = renderPiccoloError(error);

  if (rendered.type === 'UnknownError') {
    sendExceptionToSentry(error);
  }

  return <ChakraV3.Error title={rendered.type} description={rendered.message} {...rest} />;
};
