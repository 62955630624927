import { Button } from '@chakra-ui/react';
import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

export const NavButton = (props: React.PropsWithChildren<NavLinkProps>) => {
  const { children, ...rest } = props;

  return (
    <NavLink {...rest}>
      {({ isActive }) => (
        <Button
          size="sm"
          colorScheme="purple"
          isActive={isActive}
          bg="transparent"
          _active={{
            bg: 'purple.500',
          }}
        >
          {children}
        </Button>
      )}
    </NavLink>
  );
};
