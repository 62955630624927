import { Box } from '@chakra-ui/react';
import { Page as PageSettings } from '@piccolohealth/echo-common';
import React from 'react';
import { BaseNodeProps } from '../../../../utils/craftjs';
import { AspectRatio } from '../../../generic/AspectRatio';
import { useSSRNode } from '../../hooks/useSSRNode';

export const Page = (props: React.PropsWithChildren<BaseNodeProps>) => {
  const { children } = props;

  const {
    connectors: { connect, drag },
  } = useSSRNode(props);

  return (
    <AspectRatio w="100%" ratio={PageSettings.a4Ratio} ref={(ref) => connect(drag(ref))}>
      <Box bg="white" display="flex" flexDir="column" h="full" w="full" px={14} py={10}>
        {children}
      </Box>
    </AspectRatio>
  );
};

Page.defaultProps = {};

Page.craft = {
  name: 'Page',
  props: Page.defaultProps,
};
