import { MenuItem } from '@chakra-ui/react';
import { inflection } from '@piccolohealth/util';
import { andPermission, PERMISSIONS } from '@piccolohealth/echo-common';
import React from 'react';
import { FaDownload } from 'react-icons/fa';
import { usePermission } from '../../hooks/usePermission';
import { showModal } from '../generic/Modal';
import { ReportsExportModal } from '../modals/ReportsExportModal';

interface Props {
  reportIds: string[];
}

export const SelectedReportsExportMenuItem = (props: Props) => {
  const { reportIds } = props;

  const hasPermission = usePermission(
    andPermission(PERMISSIONS.reportsExport, PERMISSIONS.jobRead),
  ).value;

  return (
    <MenuItem
      key="exportReports"
      icon={<FaDownload />}
      onClick={() => showModal(ReportsExportModal, { reportIds })}
      isDisabled={!hasPermission}
      data-pw="selectedReportsExportButton"
    >
      Export {reportIds.length} {inflection.inflect('report', reportIds.length)}
    </MenuItem>
  );
};
