import React from 'react';
import { useController } from 'react-hook-form';
import { BillingActionChooser } from '../BillingActionChooser';

interface Props {
  name: string;
}

export const HookedBillingActionChooser = (props: Props) => {
  const { name, ...rest } = props;

  const {
    field: { ref, ...inputProps },
  } = useController({ name });

  return <BillingActionChooser {...rest} value={inputProps.value} onChange={inputProps.onChange} />;
};
