import gql from 'graphql-tag';

export const UpdateOrganization = gql`
  mutation UpdateOrganization(
    $organizationId: ID!
    $updateOrganizationRequest: UpdateOrganizationRequest!
  ) {
    updateOrganization(organizationId: $organizationId, request: $updateOrganizationRequest) {
      id
      name
      addressLineOne
      addressLineTwo
      email
      phone
      logo
      timezone
      distributionSettings {
        defaultDistribution {
          trigger
          payload {
            __typename
            ... on DistributionMedicalObjectsPayload {
              to {
                providerDetails {
                  id
                  firstName
                  middleName
                  lastName
                  prefix
                  suffix
                  address {
                    class
                    streetAddress
                    otherDesignation
                    city
                    state
                    postCode
                    country
                    addressType
                    otherGeographicDesignation
                  }
                }
                providerNumber
              }
            }
            ... on DistributionEmailPayload {
              sharerId
              sharerEmail
              sharerName
              shareeEmail
              message
            }

            ... on DistributionHealthLinkPayload {
              to {
                practitionerId
                practitionerName {
                  text
                  family
                  given
                  prefix
                  suffix
                }
                endpointId
                endpointName
                providerNumber
              }
            }
          }
        }
      }

      featureFlags
      roles {
        id
        name
        description
        permissions
      }
    }
  }
`;
