import gql from 'graphql-tag';

export const UpdateDistribution = gql`
  mutation UpdateDistribution(
    $organizationId: ID!
    $reportId: ID!
    $distributionId: ID!
    $request: JSON!
  ) {
    updateDistribution(
      organizationId: $organizationId
      reportId: $reportId
      distributionId: $distributionId
      request: $request
    ) {
      id
      organizationId
      reportId
    }
  }
`;
