import { Box } from '@chakra-ui/react';
import { useEditor } from '@craftjs/core';
import { useLayer } from '@craftjs/layers';
import React from 'react';
import { LayerHeader } from './LayerHeader';

export const Layer = (props: React.PropsWithChildren<{}>) => {
  const {
    id,
    expanded,
    hovered,
    connectors: { layer },
  } = useLayer((layer) => ({
    hovered: layer.event.hovered,
    expanded: layer.expanded,
  }));
  const { hasChildCanvases } = useEditor((state, query) => {
    return {
      hasChildCanvases: query.node(id).isParentOfTopLevelNodes(),
    };
  });

  return (
    <Box
      ref={(ref) => ref && layer(ref)}
      bg={hovered ? '#f1f1f1' : 'transparent'}
      pb={hasChildCanvases && expanded ? '5px' : '0px'}
    >
      <LayerHeader />
      {props.children ? (
        <Box
          m={`0 0 0 ${hasChildCanvases ? 35 : 0}px`}
          bg={hasChildCanvases ? 'rgba(255, 255, 255, 0.02)' : 'transparent'}
        >
          {props.children}
        </Box>
      ) : null}
    </Box>
  );
};
