import gql from 'graphql-tag';

export const CreateWorklistEntry = gql`
  mutation CreateWorklistEntry($organizationId: ID!, $request: CreateWorklistEntryRequest!) {
    createWorklistEntry(organizationId: $organizationId, request: $request) {
      id
      organizationId
      machineAet {
        id
        aet
        name
      }
      studyInstanceUID
      patientId
      patientFirstName
      patientLastName
      patientDob
      patientSex
      patientHeight
      patientWeight
      modality
      reportTemplate {
        id
        name
      }
      performingPhysician {
        id
        name
      }
      createdAt
      updatedAt
    }
  }
`;
