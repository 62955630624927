import React from 'react';
import { ReactJsonViewProps } from 'react-json-view';
import { Spin } from '@piccolohealth/ui';

const ReactJson = React.lazy(() => import('react-json-view'));

export const JsonTree = (props: ReactJsonViewProps) => {
  return (
    <React.Suspense fallback={<Spin />}>
      <ReactJson {...props} />
    </React.Suspense>
  );
};
