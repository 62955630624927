import { useToast as useChakraToast } from '@chakra-ui/react';

export type UseToastReturn = {
  successToast: (title: string) => void;
  errorToast: (title: string) => void;
  toast: ReturnType<typeof useChakraToast>;
};

export const useToast = (): UseToastReturn => {
  const toast = useChakraToast();

  const successToast = (title: string) => {
    toast({
      title,
      status: 'success',
      isClosable: true,
      duration: 3000,
      position: 'top',
    });
  };

  const errorToast = (title: string) => {
    toast({
      title,
      status: 'error',
      isClosable: true,
      duration: 3000,
      position: 'top',
    });
  };

  return {
    successToast,
    errorToast,
    toast,
  };
};
