import { Button, Menu, MenuButton, MenuList, MenuOptionGroup } from '@chakra-ui/react';
import { renderReportStatus, Report, ReportStatus } from '@piccolohealth/echo-common';
import React from 'react';
import { FaAngleDown } from 'react-icons/fa';
import { AwaitingReviewMenuItemOption } from './AwaitingReviewMenuItemOption';
import { FinalizeMenuItemOption } from './FinalizeMenuItemOption';
import { UnreportedMenuItemOption } from './UnreportedMenuItemOption';

export const ReportUpdateStatusButton = (props: { report: Report }) => {
  const { report } = props;

  const currentStatus = renderReportStatus(report.status);

  return (
    <Menu closeOnSelect={false}>
      <MenuButton
        as={Button}
        rightIcon={<FaAngleDown />}
        colorScheme="purple"
        data-pw="reportStatusUpdateMenuButton"
      >
        {currentStatus}
      </MenuButton>
      <MenuList>
        <MenuOptionGroup type="radio">
          <UnreportedMenuItemOption key={ReportStatus.Unreported} report={report} />
          <AwaitingReviewMenuItemOption key={ReportStatus.AwaitingReview} report={report} />
          <FinalizeMenuItemOption key={ReportStatus.Finalized} report={report} />
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
};
