import { useAuth0 } from '@auth0/auth0-react';
import { getTabInfo, PaginationOptions, TimestampPaginationOptions } from '@piccolohealth/ui';
import { DateTime, P } from '@piccolohealth/util';
import { Maybe, Pagination, TimestampPagination, PiccoloError } from '@piccolohealth/echo-common';
import { GraphQLClient } from 'graphql-request';
import { DocumentNode } from 'graphql/language/ast';
import _ from 'lodash';
import React from 'react';
import {
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useConfig } from '../context/ConfigContext';
import { asPiccoloError } from '../utils/errors';
import { fetchWithTimeout } from '../utils/fetch';

export type MutationOptions<A, B> = UseMutationOptions<A, PiccoloError, B, unknown>;
export type MutationResult<A, B> = UseMutationResult<A, PiccoloError, B, unknown>;
export type MutationFn<A, B> = (options: MutationOptions<A, B>) => MutationResult<A, B>;

export type InfiniteQueryOptions<A> = UseInfiniteQueryOptions<unknown, PiccoloError, A>;
export type InfiniteQueryResult<A> = UseInfiniteQueryResult<A, PiccoloError>;
export type QueryOptions<A> = UseQueryOptions<unknown, PiccoloError, A>;
export type QueryResult<A> = UseQueryResult<A, PiccoloError>;
export type QueryFn<A, B> = (options: QueryOptions<A>) => QueryResult<B>;
export type KeyFn<A> = (variables: A) => QueryKey;

export interface PaginationFilter {
  currentPageNumber: number;
  pageSize: number;
  pageSizeOptions: number[];
  showTotal: (total: number, range: [number, number]) => string;
  setPageSize: (n: number) => void;
  setCurrentPageNumber: (n: number) => void;
}

export interface TimestampPaginationFilter {
  lastSeen: DateTime | null;
  firstSeen: DateTime | null;
  pageSize: number;
  pageSizeOptions: number[];
  showTotal: (total: number) => string;
  setPageSize: (n: number) => void;
  setLastSeen: (n: DateTime) => void;
  setFirstSeen: (n: DateTime) => void;
}

const getOperationName = (query: string | DocumentNode): string | undefined => {
  if (typeof query === 'string') {
    return undefined;
  }
  return (query.definitions[0] as any)?.name.value;
};

export const useGqlFetcher = () => {
  const { config } = useConfig();
  const queryClient = useQueryClient();
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { tabId, renderId } = getTabInfo();

  return async <TData, TVariables>(query: string | DocumentNode, variables?: TVariables) => {
    const token = isAuthenticated ? await getAccessTokenSilently() : null;
    const opName = getOperationName(query);
    const opNameParam = opName ? `?op=${opName}` : '';

    const client = new GraphQLClient(`${config.api.url}/api${opNameParam}`, {
      fetch: fetchWithTimeout,
      headers: P.deepTrim({
        Authorization: `Bearer ${token}`,
        'X-Piccolo-Version': config.buildInfo.commit,
        'X-Piccolo-OrganizationId': (variables as any)?.organizationId,
        'X-Piccolo-TabId': tabId,
        'X-Piccolo-RenderId': renderId,
      }),
      responseMiddleware: (resp: any) => {
        const version = resp?.headers?.get('X-Piccolo-Version');
        if (version) {
          queryClient.setQueryData([`X-Piccolo-Version`], version);
        }
      },
    });
    return client.request<TData>(query, variables ?? {}).catch((err) => {
      throw asPiccoloError(err);
    });
  };
};

export const gqlFetcher = <TData, TVariables>(
  query: string | DocumentNode,
): ((variables?: TVariables) => Promise<TData>) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const gqlFetcher = useGqlFetcher();
  return async (variables?: TVariables) => {
    return gqlFetcher(query, variables);
  };
};

export const createGqlQuery = <Variables, Query>(
  getKey: KeyFn<Variables>,
  document: DocumentNode,
) => {
  const getFetcher = () => gqlFetcher<Query, Variables>(document);

  const query = (variables: Variables, options?: QueryOptions<Query>) =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useQuery(getKey(variables), getFetcher().bind(null, variables), options);

  query.getKey = getKey;
  query.document = document;
  query.getFetcher = getFetcher;

  return query;
};

export const createGqlMutation = <Variables, Mutation>(document: DocumentNode) => {
  const getFetcher = () => gqlFetcher<Mutation, Variables>(document);

  const mutation = (options?: MutationOptions<Mutation, Variables>) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useMutation(getFetcher().bind(null), options);
  };

  mutation.getFetcher = getFetcher;
  mutation.document = document;

  return mutation;
};

export const createPaginatedGqlQuery = <Variables, Query>(
  getKey: KeyFn<Variables>,
  document: DocumentNode,
  options: {
    filter: PaginationFilter;
    getPaginationResponse: (response?: Query) => Maybe<Pagination | undefined>;
  },
) => {
  const { filter, getPaginationResponse } = options;
  const getFetcher = () => gqlFetcher<Query, Variables>(document);

  const query = (variables: Variables, options?: QueryOptions<Query>) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const resp = useQuery(getKey(variables), getFetcher().bind(null, variables), options);

    const paginationResp = getPaginationResponse(resp.data);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const refetch = React.useCallback(async () => {
      await resp.refetch();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resp.refetch]);

    const pagination: PaginationOptions | undefined = !_.isNil(paginationResp)
      ? {
          total: paginationResp.total,
          currentPage: filter.currentPageNumber,
          pageSize: filter.pageSize,
          pageSizeOptions: filter.pageSizeOptions,
          hasNextPage: paginationResp.hasNextPage,
          hasPreviousPage: paginationResp.hasPreviousPage,
          showTotal: filter.showTotal,
          nextPage: () => filter.setCurrentPageNumber(filter.currentPageNumber + 1),
          previousPage: () => filter.setCurrentPageNumber(filter.currentPageNumber - 1),
          onPageSizeChange: filter.setPageSize,
        }
      : undefined;

    return {
      ...resp,
      pagination,
      refetch,
    };
  };

  query.getKey = getKey;
  query.document = document;
  query.getFetcher = getFetcher;

  return query;
};

export const createTimestampPaginatedGqlQuery = <Variables, Query>(
  getKey: KeyFn<Variables>,
  document: DocumentNode,
  options: {
    filter: TimestampPaginationFilter;
    getPaginationResponse: (response?: Query) => Maybe<TimestampPagination | undefined>;
  },
) => {
  const { filter, getPaginationResponse } = options;
  const getFetcher = () => gqlFetcher<Query, Variables>(document);

  const query = (variables: Variables, options?: QueryOptions<Query>) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const resp = useQuery(getKey(variables), getFetcher().bind(null, variables), options);

    const paginationResp = getPaginationResponse(resp.data);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const refetch = React.useCallback(async () => {
      await resp.refetch();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resp.refetch]);

    const pagination: TimestampPaginationOptions | undefined = !_.isNil(paginationResp)
      ? {
          total: paginationResp.total ?? undefined,
          pageSize: filter.pageSize,
          pageSizeOptions: filter.pageSizeOptions,
          hasNextPage: paginationResp.hasNextPage,
          hasPreviousPage: paginationResp.hasPreviousPage,
          showTotal: filter.showTotal,
          nextPage: () => {
            if (paginationResp.lastSeen) {
              filter.setLastSeen(DateTime.fromISO(paginationResp.lastSeen.toString()));
            }
          },
          previousPage: () => {
            if (paginationResp.firstSeen) {
              filter.setFirstSeen(DateTime.fromISO(paginationResp.firstSeen.toString()));
            }
          },
          onPageSizeChange: filter.setPageSize,
        }
      : undefined;

    return {
      ...resp,
      pagination,
      refetch,
    };
  };

  query.getKey = getKey;
  query.document = document;
  query.getFetcher = getFetcher;

  return query;
};
