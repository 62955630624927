import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Distribution,
  DistributionPayloadRequest,
  DistributionRequest,
  DistributionType,
} from '@piccolohealth/echo-common';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { HookedSubmitButton } from '../../../components/forms/hookform/HookedSubmitButton';
import { createModal } from '../../../components/generic/Modal';
import { useUpdateDistributionMutation } from '../../../graphql/hooks/useUpdateDistributionMutation';
import { useAppContext } from '../../../hooks/useAppContext';
import { getDistributionTypes } from '../../../hooks/useDistributionTypeInfo';
import { distributionValidationSchema } from '../distributionValidationSchema';
import { DistributionForm } from './DistributionForm';

const getPayload = (distribution: Distribution): DistributionPayloadRequest | null => {
  switch (distribution.payload.__typename) {
    case 'DistributionEmailPayload': {
      return {
        __typename: 'DistributionEmailPayloadRequest',
        sharerId: distribution.payload.sharerId,
        shareeEmail: distribution.payload.shareeEmail,
        message: distribution.payload.message,
      };
    }
    case 'DistributionMedicalObjectsPayload': {
      return {
        __typename: 'DistributionMedicalObjectsPayloadRequest',
        from: {
          providerNumber: distribution.payload.from.providerNumber,
          providerDetails: distribution.payload.from.providerDetails,
        },
        to: {
          providerNumber: distribution.payload.to.providerNumber,
          providerDetails: distribution.payload.to.providerDetails,
        },
      };
    }
    case 'DistributionHealthLinkPayload': {
      return {
        __typename: 'DistributionHealthLinkPayloadRequest',
        to: distribution.payload.to,
        from: distribution.payload.from,
      };
    }
    case 'DistributionFaxPayload': {
      return {
        __typename: 'DistributionFaxPayloadRequest',
        to: distribution.payload.to,
        from: distribution.payload.from,
      };
    }

    default:
      return null;
  }
};

type FormValues = Partial<DistributionRequest & { type: DistributionType }>;

interface Props {
  distribution: Distribution;
}

export const DistributionEditModal = createModal<Props>((props) => {
  const { distribution, modal } = props;
  const { hide, visible } = modal;
  const { successToast, errorToast } = useAppContext();

  const initialValues: Partial<FormValues> = {
    trigger: distribution.trigger,
    type: getDistributionTypes(distribution.payload.__typename).type,
    payload: getPayload(distribution) ?? undefined,
  };

  const methods = useForm({
    defaultValues: initialValues as any,
    resolver: yupResolver(distributionValidationSchema),
    mode: 'all',
  });

  const mutation = useUpdateDistributionMutation({});

  const onSubmit = React.useCallback(
    async (values: FormValues) => {
      await mutation
        .mutateAsync({
          organizationId: distribution.organizationId,
          reportId: distribution.reportId,
          distributionId: distribution.id,
          request: {
            trigger: values.trigger,
            type: values.type,
            payload: {
              ...values.payload,
              __typename: getDistributionTypes(values.type).payloadRequestTypename,
            },
          },
        })
        .then(() => {
          hide();
          successToast('Distribution edited successfully');
        })
        .catch((err) => {
          errorToast(`Error editing distribution: ${err.message}`);
        });
    },
    [mutation, distribution, hide, errorToast, successToast],
  );

  return (
    <Modal isOpen={visible} onClose={hide} onCloseComplete={modal.remove} size="xl">
      <ModalOverlay />
      <ModalContent>
        <FormProvider {...methods}>
          <Box as="form" display="contents" onSubmit={methods.handleSubmit(onSubmit)}>
            <ModalHeader>
              <Flex align="start">
                <Box>
                  <Heading size="md">Edit distribution</Heading>
                  <Text fontSize="md" fontWeight="normal" color="secondary" mt={2}>
                    Please complete the following to proceed
                  </Text>
                </Box>
                <Spacer />
                <ModalCloseButton position="unset" top="unset" right="unset" />
              </Flex>
            </ModalHeader>
            <ModalBody>
              <DistributionForm reportId={distribution.reportId} />
            </ModalBody>
            <ModalFooter>
              <HStack>
                <Button onClick={hide}>Close</Button>
                <HookedSubmitButton data-pw="distributionEditModalSubmitButton">
                  Save distribution
                </HookedSubmitButton>
              </HStack>
            </ModalFooter>
          </Box>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
});
