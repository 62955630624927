import gql from 'graphql-tag';

export const EditLabel = gql`
  mutation EditLabel($organizationId: ID!, $labelId: ID!, $request: EditLabelRequest!) {
    editLabel(organizationId: $organizationId, labelId: $labelId, request: $request) {
      id
      name
      description
      color
      createdAt
      updatedAt
    }
  }
`;
