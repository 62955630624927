import { HStack, Text } from '@chakra-ui/react';
import { DateTime } from '@piccolohealth/util';
import React from 'react';

interface Props {
  periodStart: DateTime;
  periodEnd: DateTime;
}

export const InvoicePeriod = (props: Props) => {
  const { periodStart, periodEnd } = props;
  return (
    <HStack>
      <Text>
        {DateTime.fromISO(periodStart as unknown as string).toLocaleString(DateTime.DATETIME_SHORT)}
      </Text>
      <Text>-</Text>
      <Text>
        {DateTime.fromISO(periodEnd as unknown as string).toLocaleString(DateTime.DATETIME_SHORT)}
      </Text>
    </HStack>
  );
};
