import { IntegrationType } from '@piccolohealth/echo-common';
import React from 'react';
import { useAppContext } from '../../../../hooks/useAppContext';
import { showModal } from '../../../generic/Modal';
import { IntegrationCard } from '../IntegrationCard';
import { EchoIQIntegrationModal } from './EchoIQIntegrationModal';

import EchoSolvLogo from '/logos/echosolv.logo.png';

const title = 'Echo IQ';
const description = 'Use Echo IQ EchoSolv™ to analyze your reports';

export const EchoIQIntegrationCard = () => {
  const { organization } = useAppContext();

  const isIntegrationEnabled = organization.hasIntegrationEnabled(IntegrationType.Echoiq);

  return (
    <IntegrationCard
      title={title}
      description={description}
      logo={EchoSolvLogo}
      isIntegrationEnabled={isIntegrationEnabled}
      onEditIntegration={() =>
        showModal(EchoIQIntegrationModal, {
          title,
          description,
          logo: EchoSolvLogo,
          isIntegrationEnabled,
        })
      }
      h="4xs"
      w="sm"
    />
  );
};
