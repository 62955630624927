import { StyleFunctionProps } from '@chakra-ui/react';

const variants = {
  bordered: (props: StyleFunctionProps) => ({
    ...props.theme.components.Table.variants.simple(props),
    table: {
      borderWidth: '1px',
      borderColor: 'gray.100',
      borderCollapse: 'initial',
      borderSpacing: 0,
      rounded: 'lg',
    },
    th: {
      bg: 'gray.50',
      borderColor: 'gray.100',
      borderBottomWidth: '1px',
      rounded: 'lg',
      borderBottomRadius: 0,
    },
    tbody: {
      tr: {
        '&:last-child': {
          td: {
            borderWidth: 0,
          },
        },
      },
    },
  }),
};

export const Table = {
  variants,
};
