import gql from 'graphql-tag';

export const SendDistribution = gql`
  mutation SendDistribution($organizationId: ID!, $reportId: ID!, $distributionId: ID!) {
    sendDistribution(
      organizationId: $organizationId
      reportId: $reportId
      distributionId: $distributionId
    ) {
      id
      organizationId
      reportId
      status
      events {
        type
        payload
        createdAt
      }
    }
  }
`;
