import { Box, HStack } from '@chakra-ui/react';
import React from 'react';
import { ImageViewerActions } from '../hooks/useImageViewerActions';
import { ImageViewerOptions } from '../ImageViewer';
import { FullScreenControl } from './FullScreenControl';
import { LayoutControl } from './LayoutControl';
import { ModeControl } from './ModeControl';
import { OpenInNewTabControl } from './OpenInNewTabControl';
import { PlayControls } from './PlayControls';
import { SettingsControl } from './SettingsControl';
import { ToggleReportControl } from './ToggleReportControl';

interface Props {
  options: ImageViewerOptions;
  actions: ImageViewerActions;
}

export const ImageViewerControls = (props: Props) => {
  const { options, actions } = props;

  return (
    <HStack
      w="full"
      p={2}
      align="center"
      justify="space-between"
      bg="gray.900"
      borderBottomColor="gray.800"
      borderBottomWidth="1px"
      // Make the toolbar sit on top of Intercom, if toolbar is at the bottom
      zIndex={actions.toolbarPosition === 'bottom' ? 2147483002 : undefined}
    >
      <HStack flexShrink={0}>
        {options.allowFullscreen ?? true} && (
        <FullScreenControl
          currentFullscreen={actions.isFullscreen}
          onChangeFullscreen={actions.changeFullscreen}
        />
        )
        {(options.allowChangeLayout ?? true) && (
          <LayoutControl currentLayout={actions.layout} onChangeLayout={actions.changeLayout} />
        )}
        {(options.allowChangeModes ?? true) && (
          <ModeControl
            hasStressInstances={actions.hasStressInstances}
            currentMode={actions.mode}
            onChangeMode={actions.changeMode}
          />
        )}
      </HStack>
      <Box>
        <PlayControls
          isPlaying={actions.isPlaying}
          onClickNextFrame={actions.nextFrame}
          onClickNextClip={actions.nextClip}
          onClickPlayPause={actions.playPause}
          onClickPreviousFrame={actions.previousFrame}
          onClickPreviousClip={actions.previousClip}
        />
      </Box>

      <HStack spacing={0}>
        <OpenInNewTabControl />
        <SettingsControl
          speed={actions.speed}
          onChangeSpeed={actions.changeSpeed}
          brightness={actions.brightness}
          onChangeBrightness={actions.changeBrightness}
          contrast={actions.contrast}
          onChangeContrast={actions.changeContrast}
          onChangeToolbarPosition={actions.changeToolbarPosition}
          toolbarPosition={actions.toolbarPosition}
        />
        {(options.allowShowReport ?? false) && (
          <ToggleReportControl
            isReportShowing={actions.isReportShowing}
            onToggleReportShowing={actions.toggleShowReport}
          />
        )}
      </HStack>
    </HStack>
  );
};
