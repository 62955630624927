import gql from 'graphql-tag';

export const ReportShares = gql`
  query ReportShares(
    $organizationId: ID!
    $getReportSharesRequest: GetPaginatedReportSharesRequest
  ) {
    organization(organizationId: $organizationId) {
      id
      reportShares(request: $getReportSharesRequest) {
        shares {
          id
          sharerEmail
          sharerId
          report {
            id
            organizationId
            status
            variables(aliases: ["patientName"]) {
              __typename

              ... on ReportStaticVariable {
                id
                alias
                value
                units
              }
            }
            reportTemplate {
              id
              versionId
              name
              labelColor
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          isExpired
          expiresAt
        }
        pagination {
          total
          remaining
          offset
          limit
          currentPage
          totalPages
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`;
