import {
  getDefaultVariableValue,
  getWallMotionModule,
  renderFormattedValue,
  renderWallMotion,
} from '@piccolohealth/echo-common';
import { P } from '@piccolohealth/util';
import { VariablePair } from './TiptapContext';

export type VariableRender =
  | {
      type: 'placeholder';
    }
  | {
      type: 'value';
      value: string;
    }
  | {
      type: 'empty';
    };

export const renderVariablePair = (
  variablePairs: VariablePair[],
  id: string,
  capitalize: boolean,
): VariableRender => {
  const pair = variablePairs.find((pair) => pair.variable?.id === id);

  if (!pair) {
    return { type: 'empty' };
  }

  const defaultValue = getDefaultVariableValue(pair.template);

  switch (pair.template.__typename) {
    case 'ReportTemplateStaticVariable': {
      const value = pair.variable.value ?? defaultValue;
      const units = pair.template.units ?? undefined;
      const precision = pair.template.precision ?? undefined;

      const showPlaceholder = P.isNil(value) || (P.isArray(value) && P.isEmpty(value));
      const formattedValue = renderFormattedValue(value, units, precision);

      if (showPlaceholder) {
        return { type: 'placeholder' };
      }

      if (capitalize && P.isString(formattedValue)) {
        return { type: 'value', value: P.capitalize(formattedValue) };
      }

      return { type: 'value', value: formattedValue as string };
    }
    case 'ReportTemplateChoiceVariable': {
      const value = pair.variable.value ?? defaultValue;
      const showPlaceholder = P.isNil(value) || (P.isArray(value) && P.isEmpty(value));
      const formattedValue = renderFormattedValue(value);

      if (showPlaceholder) {
        return { type: 'placeholder' };
      }

      if (capitalize && P.isString(formattedValue)) {
        return { type: 'value', value: P.capitalize(formattedValue) };
      }

      return { type: 'value', value: formattedValue as string };
    }
    case 'ReportTemplateWallMotionVariable': {
      if (!pair.variable.value) {
        return { type: 'empty' };
      }

      const module = getWallMotionModule(pair.variable.value?.variant);

      return {
        type: 'value',
        value: renderWallMotion(
          pair.variable.value.wmPresent,
          pair.variable.value.wmComplex,
          module,
        ),
      };
    }
    case 'ReportTemplateMediaAttachmentVariable': {
      return { type: 'empty' };
    }
    default: {
      return { type: 'empty' };
    }
  }
};
