import { createColumnHelper, DataTable, FancyDate } from '@piccolohealth/ui';
import { DateTime } from '@piccolohealth/util';
import { PERMISSIONS, PiccoloError, ReportTemplate } from '@piccolohealth/echo-common';
import React from 'react';
import { useAppContext } from '../../hooks/useAppContext';
import { usePermission } from '../../hooks/usePermission';
import { ReportTemplateActionsMenu } from '../controls/templates/ReportTemplateActionsControl';
import { ReportTemplateStatusTag } from '../generic/ReportTemplateStatusTag';
import { ReportTypeTag } from '../generic/ReportTypeTag';
import { TextLink } from '../generic/TextLink';

interface Props {
  reportTemplates: ReportTemplate[];
  isLoading: boolean;
  error: PiccoloError | undefined | null;
  refetch: () => Promise<void>;
}

export const TemplatesTable = (props: Props) => {
  const { reportTemplates, isLoading, error, refetch } = props;
  const { organization } = useAppContext();

  const isDisabled = !usePermission(PERMISSIONS.reportTemplateUpdate).value;

  const columns = React.useMemo(() => {
    const columnHelper = createColumnHelper<ReportTemplate>();

    return [
      columnHelper.display({
        header: 'Name',
        cell: (ps) => {
          return (
            <TextLink
              to={`/organizations/${organization.id}/templates/${ps.row.original.id}`}
              color={isDisabled ? 'gray.300' : 'purple.600'}
              pointerEvents={isDisabled ? 'none' : undefined}
              data-pw="reportTemplateNameLink"
            >
              <ReportTypeTag name={ps.row.original.name} labelColor={ps.row.original.labelColor} />
            </TextLink>
          );
        },
        minSize: 300,
      }),
      columnHelper.display({
        header: 'Status',
        cell: (ps) => {
          return <ReportTemplateStatusTag status={ps.row.original.status} />;
        },
        minSize: 100,
      }),
      columnHelper.display({
        header: 'Created at',
        cell: (ps) => (
          <FancyDate date={DateTime.fromISO(ps.row.original.createdAt as unknown as string)} />
        ),
        minSize: 250,
      }),
      columnHelper.display({
        header: 'Last updated at',
        cell: (ps) => (
          <FancyDate date={DateTime.fromISO(ps.row.original.updatedAt as unknown as string)} />
        ),
        minSize: 250,
      }),
      columnHelper.display({
        header: 'Actions',
        cell: (ps) => {
          return (
            <ReportTemplateActionsMenu
              reportTemplate={ps.row.original}
              reportTemplates={reportTemplates}
              onDelete={refetch}
            />
          );
        },
        minSize: 100,
      }),
    ];
  }, [organization, isDisabled, reportTemplates, refetch]);

  return (
    <DataTable
      columns={columns}
      data={reportTemplates}
      isLoading={isLoading}
      error={error}
      size="sm"
    />
  );
};
