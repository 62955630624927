import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  ButtonGroup,
  HStack,
  Kbd,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Tag,
  Text,
} from '@chakra-ui/react';
import {
  createColumnHelper,
  DataTable,
  Empty,
  FancyDate,
  ScrollAreaAutosize,
  Spin,
} from '@piccolohealth/ui';
import { DateTime, P } from '@piccolohealth/util';
import { Invoice, InvoiceUsageRecord, Product } from '@piccolohealth/echo-common';
import React from 'react';
import { Card, CardContent, CardHeader } from '../../../components/generic/Card';
import { Error } from '../../../components/generic/Error';
import { createModal } from '../../../components/generic/Modal';
import { ReportTypeTag } from '../../../components/generic/ReportTypeTag';
import { useInvoiceQuery } from '../../../graphql/hooks/useBillingQuery';
import { useAppContext } from '../../../hooks/useAppContext';
import { InvoicePeriod } from './InvoicePeriod';
import { InvoiceProductTypeSummary } from './InvoiceProductTypeSummary';
import { InvoiceReportTypeSummary } from './InvoiceReportTypeSummary';

type InvoiceUsageRecordWithProduct = InvoiceUsageRecord & {
  product: Product;
};

const InvoiceSubscriptionItemsTable = (props: {
  usageRecords: InvoiceUsageRecordWithProduct[];
}) => {
  const columns = React.useMemo(() => {
    const columnHelper = createColumnHelper<InvoiceUsageRecordWithProduct>();

    return [
      columnHelper.display({
        header: 'Patient name',
        minSize: 220,
        maxSize: 300,
        cell: (ps) => {
          return (
            <Text fontWeight="bold" color="purple.600">
              {ps.row.original.patientName}
            </Text>
          );
        },
      }),
      columnHelper.display({
        header: 'Report type',
        cell: (ps) => {
          const reportTemplateName = ps.row.original?.report?.reportTemplate?.name ?? 'Unknown';
          const labelColor = ps.row.original.report?.reportTemplate?.labelColor ?? 'gray';

          return <ReportTypeTag name={reportTemplateName} labelColor={labelColor} size="sm" />;
        },
        minSize: 200,
        maxSize: 240,
      }),
      columnHelper.display({
        header: 'Product',
        cell: (ps) => {
          return <Tag size="sm">{ps.row.original.product.name}</Tag>;
        },
        minSize: 140,
        maxSize: 140,
      }),
      columnHelper.display({
        header: 'Upload date',
        cell: (ps) => {
          if (!ps.row.original.report) {
            return <Text>Unknown</Text>;
          }

          const uploadDate = DateTime.fromISO(
            ps.row.original.report.createdAt as unknown as string,
          );
          return <FancyDate date={uploadDate} direction="row" showHumanized={false} />;
        },
        maxSize: 200,
        minSize: 200,
      }),
      columnHelper.display({
        header: 'Finalize date',
        cell: (ps) => {
          if (!ps.row.original.report) {
            return <></>;
          }

          const finalizeDate = DateTime.fromISO(ps.row.original.timestamp as unknown as string);
          return <FancyDate date={finalizeDate} direction="row" showHumanized={false} />;
        },
        maxSize: 200,
        minSize: 200,
      }),
    ];
  }, []);

  return (
    <DataTable
      columns={columns}
      data={props.usageRecords}
      size="sm"
      renderEmpty={() => (
        <Empty
          title="No usage records available"
          description="We don't have records available for viewing for this invoice"
        />
      )}
    />
  );
};

interface Props {
  invoiceId: string;
  periodStart: DateTime;
  periodEnd: DateTime;
}

export const InvoiceUsageBreakdownModal = createModal<Props>((props) => {
  const { modal, invoiceId, periodStart, periodEnd } = props;

  const { organization } = useAppContext();

  const { data, error, isLoading } = useInvoiceQuery({
    organizationId: organization.id,
    invoiceId: invoiceId,
  });

  const invoice = data?.organization?.billing?.invoice as Invoice | undefined;

  const usageRecords: InvoiceUsageRecordWithProduct[] = React.useMemo(
    () =>
      (invoice?.subscriptionItems ?? []).flatMap((item) => {
        return P.compact(
          item.usageRecords.map((ur) => {
            if (!ur || !item.product) {
              return null;
            }

            return {
              ...ur,
              product: item.product,
            };
          }),
        );
      }),
    [invoice],
  );

  let content;

  if (isLoading) {
    content = <Spin />;
  } else if (error) {
    content = <Error error={error} />;
  } else if (!invoice) {
    content = <Empty title="No invoice found" />;
  } else {
    content = (
      <Stack spacing={6} h="full">
        <Alert status="info" fontSize="sm">
          <AlertIcon />
          <Box>
            <AlertTitle>Reports are billed only when finalized</AlertTitle>
            <AlertDescription>
              If a report is uploaded in period 1, and finalized in period 2, it will be included in
              the invoice for period 2, not period 1.
            </AlertDescription>
          </Box>
        </Alert>

        <ScrollAreaAutosize overflowY="auto" h="md">
          <InvoiceSubscriptionItemsTable usageRecords={usageRecords} />
        </ScrollAreaAutosize>

        <Card shadow="none">
          <HStack w="full" align="start">
            <Stack w="full">
              <CardHeader title="Product summary" fontWeight="bold" fontSize="lg" />
              <CardContent fontSize="base">
                <InvoiceProductTypeSummary invoice={invoice} />
              </CardContent>
            </Stack>
            <Stack w="full">
              <CardHeader title="Report type summary" fontWeight="bold" fontSize="lg" />
              <CardContent fontSize="base">
                <InvoiceReportTypeSummary invoice={invoice} />
              </CardContent>
            </Stack>
          </HStack>
          <Stack></Stack>
        </Card>
      </Stack>
    );
  }

  return (
    <Modal isOpen={modal.visible} onClose={modal.hide} onCloseComplete={modal.remove} size="6xl">
      <ModalOverlay />
      <ModalContent h="5xl">
        <ModalHeader>
          Usage breakdown for <Kbd>{invoice?.number}</Kbd>
          <Text fontSize="md" fontWeight="normal" color="secondary" mt={2}>
            <InvoicePeriod periodStart={periodStart} periodEnd={periodEnd} />
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>{content}</ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button key="close" onClick={modal.hide}>
              Close
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});
