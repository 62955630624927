import { Box } from '@chakra-ui/react';
import { Instance, LooseObject } from '@piccolohealth/echo-common';
import React from 'react';
import { useAppContext } from '../../../hooks/useAppContext';
import { Layout } from '../controls/Layout';
import { InstanceFormat } from '../controls/InstanceFormatTag';
import { Synchronizer } from '../hooks/useImageViewerActions';
import { Overlay } from '../overlays/Overlay';
import { JpegPlayer } from './JpegPlayer';
import { Mp4Player } from './Mp4Player';

interface Props {
  instance: Instance;
  isPlaying: boolean;
  brightness: number;
  contrast: number;
  playbackRate: number;
  layout: Layout;
  format: InstanceFormat;
  index: number;
  viewportIndex: number;
  total: number;
  synchronizer: Synchronizer;
  onScreenshot?: (data: string, metadata: LooseObject) => void;
}

export const Player = (props: Props) => {
  const {
    instance,
    isPlaying,
    brightness,
    contrast,
    playbackRate,
    layout,
    format,
    index,
    viewportIndex,
    total,
    synchronizer,
    onScreenshot,
  } = props;

  const { config } = useAppContext();

  let content = null;

  if (format === InstanceFormat.MP4 && instance.mp4Url) {
    const options = {
      src: instance.mp4Url,
      isPlaying,
      playbackRate,
      duration: instance.dicom.duration as number,
      frameRate: instance.dicom.frameRate as number,
      numberOfFrames: instance.dicom.numberOfFrames as number,
      metadata: instance.dicom,
      synchronizer,
      onScreenshot,
    };

    content = <Mp4Player {...options} />;
  } else if (format === InstanceFormat.JPEG && instance.frameUrl) {
    const options = {
      id: instance.id,
      src: `${config.api.url}${instance.frameUrl}/${1}`,
      metadata: instance.dicom,
      onScreenshot,
    };

    content = <JpegPlayer {...options} />;
  }

  const instanceInfo = {
    index,
    viewportIndex,
    total,
    instance,
  };

  const preventMouseInteraction = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => event.preventDefault(),
    [],
  );

  return (
    <Box
      bg="black"
      position="relative"
      onContextMenu={preventMouseInteraction}
      onMouseDown={preventMouseInteraction}
      filter={`
        brightness(${brightness}) contrast(${contrast})
      `}
    >
      <Overlay format={format} instanceInfo={instanceInfo} layout={layout} />
      {content}
    </Box>
  );
};
