import { P, prettyBytes } from '@piccolohealth/util';
import { LooseObject } from './generic';
import { exhaustiveMatchingGuard } from './index';

export type PiccoloErrorType =
  | 'Auth0UserIdNotFound'
  | 'BillingActionNotFound'
  | 'BillingSubscriptionNotFound'
  | 'BillingSubscriptionItemNotFound'
  | 'BillingProductNotFound'
  | 'DicomStudyNotFound'
  | 'DistributionAlreadySent'
  | 'DistributionEventNotFound'
  | 'DistributionNotFound'
  | 'DistributionInvalidPayload'
  | 'EchoIqInvalidPayload'
  | 'EchoIqRequestFailure'
  | 'EmailRequestFailure'
  | 'FaxInvalidSettings'
  | 'FaxRequestFailure'
  | 'FileTooLarge'
  | 'Forbidden'
  | 'SharedRoleUnassignable'
  | 'HealthLinkEndpointNotFound'
  | 'HealthLinkInvalidSettings'
  | 'HealthLinkRequestFailure'
  | 'HL7MissingValues'
  | 'ImageRequestFailure'
  | 'ImportInstanceFromStorageFailure'
  | 'InstanceNotFound'
  | 'IntegrationNotFound'
  | 'IntegrationsNotFound'
  | 'InternalClientError'
  | 'InternalServerError'
  | 'LabelAlreadyExists'
  | 'LabelNotFound'
  | 'MachineAetNotFound'
  | 'MedicalObjectsInvalidSettings'
  | 'MedicalObjectsMessageRejected'
  | 'MedicalObjectsPracticeInfoNotFound'
  | 'MedicalObjectsProviderNotFound'
  | 'MedicalObjectsRequestFailure'
  | 'NoBlobUriGiven'
  | 'NoFilesUploaded'
  | 'NoFilenameGiven'
  | 'OrganizationNotFound'
  | 'ReportNotFound'
  | 'ReportAttachmentNotFound'
  | 'ReportLabelNotFound'
  | 'ReportPdfNotFound'
  | 'ReportShareAlreadyExists'
  | 'ReportShareNotFound'
  | 'ReportStatusUpdateFailure'
  | 'ReportTemplateNotFound'
  | 'ReportTemplateMismatch'
  | 'ReportUpdateConflict'
  | 'RoleNotFound'
  | 'ReportTemplateStatementSiteNotFound'
  | 'ReportTemplateStatementSiteNodeNotFound'
  | 'StorageUploadInvalidDataType'
  | 'StudyNotFound'
  | 'StudyAlreadyExists'
  | 'Unauthorized'
  | 'UserNotFound'
  | 'ReportTemplateVariableNotFound'
  | 'ReportTemplateVariableAlreadyExists'
  | 'ReportVariableRenderingError'
  | 'WorklistEntryNotFound';

export interface PiccoloErrorProps {
  type: PiccoloErrorType;
  message: string;
  stack?: string;
  metadata?: LooseObject;
}

export class PiccoloError extends Error {
  type: PiccoloErrorType;
  message: string;
  statusCode: number;
  stack?: string;
  metadata?: LooseObject;

  constructor(response: PiccoloErrorProps) {
    const { type, message, stack, metadata } = response;
    super();

    this.name = type;
    this.type = type;
    this.message = message;
    this.statusCode = PiccoloError.getStatusCode(type);
    this.stack = stack ?? this.stack;
    this.metadata = metadata;
  }

  static getStatusCode(type: PiccoloErrorType): number {
    switch (type) {
      case 'Auth0UserIdNotFound':
      case 'BillingActionNotFound':
      case 'BillingSubscriptionNotFound':
      case 'BillingSubscriptionItemNotFound':
      case 'BillingProductNotFound':
      case 'DicomStudyNotFound':
      case 'DistributionEventNotFound':
      case 'DistributionNotFound':
      case 'HealthLinkEndpointNotFound':
      case 'InstanceNotFound':
      case 'IntegrationsNotFound':
      case 'IntegrationNotFound':
      case 'LabelNotFound':
      case 'OrganizationNotFound':
      case 'MachineAetNotFound':
      case 'MedicalObjectsProviderNotFound':
      case 'MedicalObjectsPracticeInfoNotFound':
      case 'ReportAttachmentNotFound':
      case 'ReportLabelNotFound':
      case 'ReportPdfNotFound':
      case 'ReportShareNotFound':
      case 'ReportNotFound':
      case 'ReportTemplateNotFound':
      case 'RoleNotFound':
      case 'ReportTemplateStatementSiteNotFound':
      case 'ReportTemplateStatementSiteNodeNotFound':
      case 'StudyNotFound':
      case 'UserNotFound':
      case 'ReportTemplateVariableNotFound':
      case 'WorklistEntryNotFound':
        return 404;
      case 'DistributionAlreadySent':
      case 'LabelAlreadyExists':
      case 'ReportShareAlreadyExists':
      case 'StudyAlreadyExists':
      case 'ReportTemplateMismatch':
      case 'ReportTemplateVariableAlreadyExists':
      case 'ReportUpdateConflict':
      case 'ReportStatusUpdateFailure':
        return 409;
      case 'Forbidden':
        return 403;
      case 'DistributionInvalidPayload':
      case 'EchoIqInvalidPayload':
      case 'FaxInvalidSettings':
      case 'SharedRoleUnassignable':
      case 'HealthLinkInvalidSettings':
      case 'HL7MissingValues':
      case 'MedicalObjectsInvalidSettings':
      case 'MedicalObjectsMessageRejected':
      case 'NoFilesUploaded':
      case 'NoFilenameGiven':
      case 'NoBlobUriGiven':
      case 'StorageUploadInvalidDataType':
        return 400;
      case 'Unauthorized':
        return 401;
      case 'FileTooLarge':
        return 413;
      case 'EchoIqRequestFailure':
      case 'EmailRequestFailure':
      case 'FaxRequestFailure':
      case 'HealthLinkRequestFailure':
      case 'ImageRequestFailure':
      case 'ImportInstanceFromStorageFailure':
      case 'InternalClientError':
      case 'InternalServerError':
      case 'MedicalObjectsRequestFailure':
      case 'ReportVariableRenderingError':
        return 500;
      default:
        // Will error when type has not been exhaustively matched
        return exhaustiveMatchingGuard(type);
    }
  }
}

export const renderPiccoloError = (error: PiccoloError) => {
  switch (error.type) {
    case 'Auth0UserIdNotFound':
    case 'UserNotFound':
      return {
        type: 'User Not Found',
        message: 'Sorry, we could not find the user you were looking for',
      };
    case 'DicomStudyNotFound':
    case 'InstanceNotFound':
    case 'StudyNotFound': {
      return {
        type: 'Study Not Found',
        message: 'Sorry, we could not find the study you were looking for',
      };
    }
    case 'LabelNotFound':
    case 'ReportLabelNotFound': {
      return {
        type: 'Label Not Found',
        message: 'Sorry, we could not find the label you were looking for',
      };
    }
    case 'OrganizationNotFound': {
      return {
        type: 'Organization Not Found',
        message: 'Sorry, we could not find the organization you were looking for',
      };
    }
    case 'ReportAttachmentNotFound': {
      return {
        type: 'Attachment Not Found',
        message: 'Sorry, we could not find the attachment you were looking for',
      };
    }
    case 'ReportNotFound':
    case 'ReportShareNotFound': {
      return {
        type: 'Report Not Found',
        message: 'Sorry, we could not find the report you were looking for',
      };
    }
    case 'ReportTemplateNotFound': {
      return {
        type: 'Report Template Not Found',
        message: 'Sorry, we could not find the report template you were looking for',
      };
    }
    case 'WorklistEntryNotFound': {
      return {
        type: 'Worklist Entry Not Found',
        message: 'Sorry, we could not find the worklist entry you were looking for',
      };
    }
    case 'DistributionAlreadySent': {
      return {
        type: 'Distribution Already Sent',
        message: 'Sorry, that distribution has already been sent',
      };
    }
    case 'LabelAlreadyExists': {
      return {
        type: 'Label Already Exists',
        message: 'Sorry, a label with that name already exists, please choose another unique name',
      };
    }
    case 'ReportShareAlreadyExists': {
      return {
        type: 'Report Already Shared',
        message: 'Sorry, that report has already been shared with that user',
      };
    }
    case 'ReportStatusUpdateFailure': {
      return {
        type: 'Report Status Update Failure',
        message: 'Sorry, the status cannot be changed to the same status',
      };
    }
    case 'ReportTemplateVariableAlreadyExists': {
      return {
        type: 'Variable Already Exists',
        message:
          'Sorry, a variable with that name already exists, please choose another unique name',
      };
    }
    case 'ReportTemplateMismatch': {
      return {
        type: 'Report Template Mismatch',
        message:
          'Sorry, the report you were editing has been changed to a different template. Please reload the page and try again',
      };
    }
    case 'ReportUpdateConflict': {
      return {
        type: 'Report Update Conflict',
        message:
          'Sorry, the report was updated by another user while you were editing. Please reload the page and try again',
      };
    }
    case 'Unauthorized':
      return {
        type: 'Unauthorized',
        message: 'Sorry, you are not authorized to access this resource',
      };
    case 'Forbidden':
      return {
        type: 'Forbidden',
        message: 'Sorry, you are not permitted to access this resource',
      };
    case 'FileTooLarge': {
      const limit = error.metadata?.limit;
      const message = P.compact([
        'Sorry, the file you are trying to upload is too large',
        limit && `Please ensure it is < ${prettyBytes(limit)}`,
      ]).join('. ');

      return {
        type: 'File Too Large',
        message,
      };
    }
    case 'DistributionInvalidPayload': {
      return {
        type: 'Invalid Distribution',
        message: 'Sorry, something went wrong sending the distribution',
      };
    }
    case 'EchoIqInvalidPayload': {
      return {
        type: 'Invalid EchoIQ Request',
        message: 'Sorry, something went wrong sending the EchoIQ request',
      };
    }
    case 'SharedRoleUnassignable': {
      return {
        type: 'User already exists',
        message: 'Sorry, the user you have shared with already has permission to view this report',
      };
    }
    case 'HL7MissingValues': {
      return {
        type: 'Missing Fields',
        message:
          'Sorry, the distribution you tried to send is missing some required fields. Please fill them in and try again',
      };
    }

    case 'NoFilesUploaded': {
      return {
        type: 'No Files Uploaded',
        message: 'Sorry, you must upload at least one file',
      };
    }
    case 'NoFilenameGiven': {
      return {
        type: 'No Filename Given',
        message: 'Sorry, the uploaded file must have a filename',
      };
    }
    case 'BillingActionNotFound':
    case 'BillingSubscriptionNotFound':
    case 'BillingSubscriptionItemNotFound':
    case 'BillingProductNotFound':
    case 'DistributionEventNotFound':
    case 'DistributionNotFound':
    case 'EchoIqRequestFailure':
    case 'EmailRequestFailure':
    case 'FaxInvalidSettings':
    case 'FaxRequestFailure':
    case 'HealthLinkEndpointNotFound':
    case 'HealthLinkInvalidSettings':
    case 'HealthLinkRequestFailure':
    case 'ImageRequestFailure':
    case 'ImportInstanceFromStorageFailure':
    case 'IntegrationsNotFound':
    case 'IntegrationNotFound':
    case 'InternalClientError':
    case 'InternalServerError':
    case 'MachineAetNotFound':
    case 'MedicalObjectsInvalidSettings':
    case 'MedicalObjectsMessageRejected':
    case 'MedicalObjectsProviderNotFound':
    case 'MedicalObjectsPracticeInfoNotFound':
    case 'MedicalObjectsRequestFailure':
    case 'NoBlobUriGiven':
    case 'ReportPdfNotFound':
    case 'ReportVariableRenderingError':
    case 'ReportTemplateStatementSiteNotFound':
    case 'ReportTemplateStatementSiteNodeNotFound':
    case 'ReportTemplateVariableNotFound':
    case 'RoleNotFound':
    case 'StorageUploadInvalidDataType':
    case 'StudyAlreadyExists':
      return {
        type: 'Server Error',
        message: 'Sorry, something broke. Please contact support.',
      };
    default:
      // Will error when type has not been exhaustively matched
      P.exhaustiveMatchingGuard(error.type);
      return { type: 'Unknown Error', message: 'Unknown error occurred. Please contact support.' };
  }
};
