import { cssVar, defineStyle } from '@chakra-ui/react';
import { transparentize } from '@chakra-ui/theme-tools';

const baseStyle = {};

const $bg = cssVar('badge-bg');
const $fg = cssVar('badge-color');

const variantSubtle = defineStyle((props) => {
  const { colorScheme: c, theme } = props;
  const darkBg = transparentize(`${c}.200`, 0.16)(theme);

  return {
    [$bg.variable]: transparentize(`${c}.100`, 0.75)(theme),
    [$fg.variable]: `colors.${c}.600`,
    _dark: {
      [$bg.variable]: darkBg,
      [$fg.variable]: `colors.${c}.200`,
    },
    bg: $bg.reference,
    color: $fg.reference,
  };
});

const variants = {
  subtle: variantSubtle,
};

export const Badge = {
  baseStyle,
  variants,
};
