import React from 'react';
import { useMedicalObjectsIntegrationSettingsQuery } from '../../graphql/hooks/useIntegrationsQuery';
import { useAppContext } from '../../hooks/useAppContext';
import { ProviderFromChooser } from './ProviderFromChooser';

type MedicalObjectsProvider = {
  providerNumber: string;
  name: string;
};

export interface MedicalObjectsProviderFromChooserProps {
  value: MedicalObjectsProvider | null;
  onChange: (value: MedicalObjectsProvider) => void;
  reportId?: string;
  isDisabled?: boolean;
}

export const MedicalObjectsProviderFromChooser = (
  props: MedicalObjectsProviderFromChooserProps,
) => {
  const { organization } = useAppContext();

  const { data, isLoading } = useMedicalObjectsIntegrationSettingsQuery({
    organizationId: organization.id,
  });

  const mappings = data?.organization?.integrations.medicalObjects.settings.providerMappings ?? [];

  return (
    <ProviderFromChooser
      {...props}
      mappings={mappings}
      isLoading={isLoading}
      data-pw="distributionModalMedicalObjectsFromProviderNumberChooser"
    />
  );
};
