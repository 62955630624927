import { isPlayableDicom } from '@piccolohealth/echo-common';
import React from 'react';
import { useReportStudyInstancesQuery } from '../../../graphql/hooks/useReportQuery';
import { useAppContext } from '../../../hooks/useAppContext';
import { NumberedBadge } from '../../generic/NumberedBadge';

interface Props {
  reportId: string;
}

export const ImagesBadge = (props: Props) => {
  const { reportId } = props;
  const { organization } = useAppContext();

  const { data } = useReportStudyInstancesQuery({
    organizationId: organization.id,
    reportId: reportId,
  });

  const imagesLength = (data?.organization?.report?.study?.instances ?? []).filter((instance) =>
    isPlayableDicom({
      modality: instance.dicom.modality,
      transferSyntaxUID: instance.dicom.transferSyntaxUID ?? undefined,
      imageType: instance.dicom.imageType ?? undefined,
    }),
  ).length;

  return <NumberedBadge count={imagesLength} />;
};
