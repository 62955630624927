import { MenuItem, Text } from '@chakra-ui/react';
import { PERMISSIONS, ReportTemplate } from '@piccolohealth/echo-common';
import React from 'react';
import { FaCog } from 'react-icons/fa';
import { usePermission } from '../../../hooks/usePermission';
import { showModal } from '../../generic/Modal';
import { ReportTemplateSettingsModal } from '../../modals/templates/ReportTemplateSettingsModal';

interface Props {
  reportTemplate: ReportTemplate;
}

export const ReportTemplateSettingsMenuItem = (props: Props) => {
  const { reportTemplate, ...rest } = props;
  const isDisabled = !usePermission(PERMISSIONS.reportTemplateUpdate).value;

  return (
    <MenuItem
      {...rest}
      isDisabled={isDisabled}
      onClick={() => showModal(ReportTemplateSettingsModal, { reportTemplate })}
      icon={<FaCog />}
    >
      <Text>Settings</Text>
    </MenuItem>
  );
};
