import { HStack, Icon, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';
import React from 'react';
import { FaInfoCircle, FaPaperPlane, FaPuzzlePiece, FaRegListAlt, FaUsers } from 'react-icons/fa';
import { AboutSettings } from '../../components/settings/about/AboutSettings';
import { DistributionSettings } from '../../components/settings/distributions/DistributionSettings';
import { IntegrationSettings } from '../../components/settings/integrations/IntegrationSettings';
import { OrganizationSettings } from '../../components/settings/organization/OrganizationSettings';
import { UsersSettings } from '../../components/settings/users/UsersSettings';

export const Settings = () => {
  return (
    <Tabs w="full" h="full" orientation="vertical" variant="soft-rounded" size="sm" isLazy>
      <TabList borderRightWidth="1px" px={4} py={4} minW="120px" flexShrink={0}>
        <Tab>
          <HStack>
            <Icon as={FaRegListAlt} />
            <Text fontSize="sm">Organization</Text>
          </HStack>
        </Tab>
        <Tab>
          <HStack>
            <Icon as={FaPaperPlane} />
            <Text fontSize="sm">Distributions</Text>
          </HStack>
        </Tab>
        <Tab data-pw="usersSettingsTab">
          <HStack>
            <Icon as={FaUsers} />
            <Text fontSize="sm">Users</Text>
          </HStack>
        </Tab>
        <Tab>
          <HStack>
            <Icon as={FaPuzzlePiece} />
            <Text fontSize="sm">Integrations</Text>
          </HStack>
        </Tab>
        <Tab>
          <HStack>
            <Icon as={FaInfoCircle} />
            <Text fontSize="sm">About</Text>
          </HStack>
        </Tab>
      </TabList>

      <TabPanels h="full" overflowY="auto">
        <TabPanel h="full">
          <OrganizationSettings />
        </TabPanel>
        <TabPanel h="full">
          <DistributionSettings />
        </TabPanel>
        <TabPanel h="full">
          <UsersSettings />
        </TabPanel>
        <TabPanel h="full">
          <IntegrationSettings />
        </TabPanel>
        <TabPanel h="full">
          <AboutSettings />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
