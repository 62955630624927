import { Box, Flex, Heading, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { Layers } from '@craftjs/layers';
import React from 'react';
import { Divider } from '../../generic/Divider';
import { Layer } from '../common/layers/Layer';
import { EditorDebug } from './EditorDebug';
import { Settings } from './Settings';

export const RightSidebar = () => {
  return (
    <Flex flexDir="column" height="100%">
      <Box height="40%">
        <Tabs defaultIndex={0} h="100%" display="flex" flexDir="column" colorScheme="purple" isLazy>
          <TabList>
            <Tab key="layers-tab-item">Layers</Tab>
            <Tab key="debug-tab-item">Debug</Tab>
          </TabList>
          <TabPanels h="100%" overflowY="auto">
            <TabPanel key="layers-tab-panel" h="100%" p={0}>
              <Layers expandRootOnLoad={true} renderLayer={Layer} />
            </TabPanel>
            <TabPanel key="debug-tab-panel">
              <EditorDebug />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
      <Box height="60%" overflowY="auto" p={4}>
        <Heading size="sm">Customize</Heading>
        <Divider />
        <Settings />
      </Box>
    </Flex>
  );
};
