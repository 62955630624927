import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import { EchoIqIntegrationSettings } from '@piccolohealth/echo-common';
import React from 'react';
import { FaCrosshairs } from 'react-icons/fa';
import { createModal } from '../../components/generic/Modal';
import { useCreateEchoIQAssessmentMutation } from '../../graphql/hooks/useCreateEchoIQAssessmentMutation';
import { useAppContext } from '../../hooks/useAppContext';

interface Props {
  reportId: string;
  integrationSettings: EchoIqIntegrationSettings;
}

export const EchoIQAnalyzeConfirmModal = createModal<Props>((props) => {
  const { reportId, integrationSettings, modal } = props;

  const { organization, successToast, errorToast } = useAppContext();

  const mutation = useCreateEchoIQAssessmentMutation();

  const onAnalyzeStudy = React.useCallback(async () => {
    await mutation
      .mutateAsync({
        organizationId: organization.id,
        reportId,
      })
      .then(() => {
        successToast(`Echo IQ assessment created`);
        modal.hide();
      })
      .catch((err) => {
        errorToast(`Error creating Echo IQ assessment: ${err.message}`);
      });
  }, [mutation, organization.id, reportId, successToast, modal, errorToast]);

  const pricingAlert = !integrationSettings.hasDiscount ? (
    <Stack spacing={4}>
      <Alert status="warning">
        <AlertIcon />
        <AlertTitle>Echo IQ EchoSolv analysis is priced at $5.00 per study analyzed</AlertTitle>
      </Alert>
      <Text>Are you sure you want to continue?</Text>
    </Stack>
  ) : null;

  return (
    <Modal isOpen={modal.visible} onClose={modal.hide} onCloseComplete={modal.remove} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Do you want to analyze this study?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{pricingAlert}</ModalBody>
        <ModalFooter>
          <ButtonGroup size="sm">
            <Button onClick={modal.hide}>Close</Button>
            <Button
              onClick={onAnalyzeStudy}
              isLoading={mutation.isLoading}
              leftIcon={<FaCrosshairs />}
              colorScheme="purple"
              loadingText="Analyzing..."
            >
              Analyze
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});
