import { Menu, MenuButton, MenuItem, MenuList, Tag } from '@chakra-ui/react';
import { P } from '@piccolohealth/util';
import React from 'react';

const items = [
  'blue',
  'cyan',
  'gray',
  'green',
  'orange',
  'pink',
  'purple',
  'red',
  'teal',
  'yellow',
];

export interface ColorPickerProps {
  value: string;
  onChange: (color: string) => void;
}

export const ColorPicker = (props: ColorPickerProps) => {
  const { value, onChange, ...rest } = props;

  return (
    <Menu closeOnSelect>
      <MenuButton
        as={Tag}
        size="xs"
        p={0}
        minW={6}
        w={6}
        h={6}
        minH={6}
        colorScheme={value}
        cursor="pointer"
        {...rest}
      />
      <MenuList>
        {items.map((item) => (
          <MenuItem
            key={item}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onChange(item);
            }}
            data-pw={`colorPickerItem-${item}`}
          >
            <Tag colorScheme={item}>{P.upperFirst(item)}</Tag>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};
