import gql from 'graphql-tag';

export const UpdateReport = gql`
  mutation UpdateReport(
    $organizationId: ID!
    $reportId: ID!
    $updateReportRequest: UpdateReportRequest!
  ) {
    updateReport(
      organizationId: $organizationId
      reportId: $reportId
      updateReportRequest: $updateReportRequest
    ) {
      id
      reportTemplate {
        versionId
      }
      organizationId
      changesetId
      updatedAt
      variables {
        __typename

        ... on ReportStaticVariable {
          id
          alias
          value
          units
          isTouched
        }

        ... on ReportChoiceVariable {
          alias
          id
          value
          isTouched
        }

        ... on ReportWallMotionVariable {
          alias
          id
          value
          isTouched
        }

        ... on ReportMediaAttachmentVariable {
          alias
          id
          value
          isTouched
        }
      }
      statementSites {
        id
        statements {
          id
          value
        }
      }
    }
  }
`;
