import * as Types from '@piccolohealth/echo-common';
import { useMutation, useQueryClient } from 'react-query';
import { gqlFetcher, MutationOptions } from '../fetcher';
import { DeleteReportTemplateVariable } from '../mutations/deleteReportTemplateVariable.mutation';
import { useReportTemplateQuery } from './useReportTemplateQuery';

export const useDeleteReportTemplateVariableMutation = (
  options?: MutationOptions<
    Types.DeleteReportTemplateVariableMutation,
    Types.DeleteReportTemplateVariableMutationVariables
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(DeleteReportTemplateVariable), {
    ...options,
    onSuccess: async (resp, variables, ctx) => {
      await options?.onSuccess?.(resp, variables, ctx);

      queryClient.invalidateQueries(
        useReportTemplateQuery.getKey({
          organizationId: variables.organizationId,
          reportTemplateId: variables.reportTemplateId,
        }),
      );

      queryClient.invalidateQueries({
        predicate: (query: any) => {
          return (
            query.state.data?.organization?.report?.reportTemplate?.id ===
            variables.reportTemplateId
          );
        },
      });
    },
  });
};
